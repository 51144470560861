import { FETCH_AUTH_USER_ROLES_SUCCESS_ACTION } from "../actions/registrationActions";

export const authUserRolesReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_AUTH_USER_ROLES_SUCCESS_ACTION:
            const Tenant = action.payload;
            return Tenant;
        default:
            return state;
    }
};
