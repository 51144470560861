import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Grid, Button, Container,Dimmer, Loader, Header, Icon, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import * as TEMPLATES_ACTIONS from '../../../actions/whatsappTemplateAction';

function WhatsAppTemplatesPage(props) {
  const [state] = useState({
      selectedTemplate: {},
      isViewTemplateOpen: false,
  });

  const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

  const canReadWhatsAppTemplates = () => {
    return (hasPermissions('WhatsApp Templates') && props.userPermissions['WhatsApp Templates'].actions.READ) || isAdmin();
  }

  useEffect(() => {
    if (!_.isEmpty(props.tenant)) {
        if (props.isPermissionsFetched) {
            if (canReadWhatsAppTemplates()) {
                props.fetchWhatsappTemplatesAction();
            }
            else {
                toast.error('You do not have permission to view available WhatsAppTemplates!');
            }
        }
    }
  }, [props.tenant]);



  const whatsAppTemplatesRender = () => {
    if (!props.fetchingData) {
      if (!props.templates || props.templates.length === 0) {
        return (
          <Segment>
            <Header icon>
              <Icon name='file' color="black" />
              No templates to view
            </Header>
          </Segment>
        )
      }

      return (
        <Table compact selectable size='small' celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '15%' }}>Template Name</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '70%' }}>Template Description</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '15%' }}>Template Languages</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.templates.map((template, index) => (
              <Table.Row
                key={`${template.id}.${index}`}
                active={template.id === state.selectedTemplate.id}
              >
                <Table.Cell>{template.templateName}</Table.Cell>
                <Table.Cell>{template.templateDesc}</Table.Cell>
                <Table.Cell>{template.language}</Table.Cell>

              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <div style={{ height: '200px', margin: '10px 0px' }}>
          <Dimmer inverted active>
            <Loader content='Loading' />
          </Dimmer>
        </div>
      );
    }
  };



  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width='8'>
            <h3>Available WhatsApp Templates:</h3>
          </Grid.Column>
          <Grid.Column width='8'>
          {canReadWhatsAppTemplates() &&(
            <Button compact className='primary' icon='refresh' size='small' floated='right' onClick={(e) => {
              props.fetchWhatsappTemplatesAction();
            }}></Button>)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {whatsAppTemplatesRender()}
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: state.tenantManagement.activeTenant,
    templates: state.whatsAppTemplates,
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,
  };
};

export default connect(
  mapStateToProps,
  (dispatch) => ({
    fetchWhatsappTemplatesAction: () => dispatch(TEMPLATES_ACTIONS.fetchWhatsappTemplatesAction()),
  })
)(WhatsAppTemplatesPage);
