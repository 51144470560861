import _ from "lodash";
import { toast } from "react-toastify";
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { createItemWs, transactItemWs, updateItemWs } from "../webservices/saveWS";
import { QuestionDTO } from "./DTOs/QuestionDTO";
import { ConditionDTO } from "./DTOs/ConditionDTO";
import { mapLayerSaveFiles, mapLayerDeleteFile } from "./mapLayerActions";
import { fetchItemsWS } from "../webservices/fetchWS";

export const FETCHING_QUESTIONS_ACTION = "FETCHING_QUESTIONS_ACTION";
export const FETCH_QUESTIONS_SUCCESS_ACTION = "FETCH_QUESTIONS_SUCCESS_ACTION";
export const FETCH_QUESTIONS_ERROR_ACTION = "FETCH_QUESTIONS_ERROR_ACTION";

export const QUESTION_ADD_ACTION = "QUESTION_ADD_ACTION";
export const QUESTION_EDIT_ACTION = "QUESTION_EDIT_ACTION";
export const QUESTION_DELETE_ACTION = "QUESTION_DELETE_ACTION";
export const CONDITION_ADD_ACTION = "CONDITION_ADD_ACTION";
export const CONDITION_EDIT_ACTION = "CONDITION_EDIT_ACTION";

export const fetchQuestionsAction = (questionaireId, sectionId, tenantId) => {
  const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${questionaireId}`,
    SK: 'QUESTION%23',
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== "isDeleted");
        dispatch({
          type: FETCH_QUESTIONS_SUCCESS_ACTION,
          payload: returnItems,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_QUESTIONS_ERROR_ACTION, err });
      });
  };
};

export const fetchAllQuestionsAction = (payload) => {
  const queryParams = {
    GSI11PK: `${payload}%23QUESTIONS`,
    GSI11SK: 'QUESTION%23',
  };

  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_QUESTIONS_ACTION});

    try {
      // Fetch questions from the current action
      const resultQuestions = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const items = resultQuestions.Items.filter((item) => item.status !== 'isDeleted');

      const state = getState();
      const baseQnaires = state.tenantManagement.activeTenant.baseQnaires;

      // Fetch questions from fetchBaseQanireQuestionsActions
      if (!_.isEmpty(baseQnaires)){
        const resultBaseQnaireQuestions = await dispatch(fetchBaseQanireQuestionsActions(baseQnaires));
      
        // Combine questions from both sources
        const combinedQuestions = [...items, ...resultBaseQnaireQuestions];
        
        // Dispatch final action with combined questions
        dispatch({ type: FETCH_QUESTIONS_SUCCESS_ACTION, payload: combinedQuestions });
      }
      else{
        dispatch({ type: FETCH_QUESTIONS_SUCCESS_ACTION, payload: items });
      }
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_QUESTIONS_ERROR_ACTION, err });
    }
  };
};

export const questionSaveAction = ( question,  condition,  mapLayer, tenantId ,isNewItem) => {
  return (dispatch, getState) => {
    const saveObject = new QuestionDTO(question);
    if(mapLayer !== undefined){
      saveObject.kmlName = mapLayer.name;
    }
    saveObject.sequence = parseInt(saveObject.sequence);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (isNewItem === true) {
      transactItemWs(saveObject, getState)
        .then((result) => {
          mapLayerSaveFiles(result.data, mapLayer)
            .then(() => {
              dispatch({ type: QUESTION_EDIT_ACTION, payload: result.data });
              toast.success("The new question has been added successfully");
              if (question.isConditional && condition.id === undefined) {
                condition.PK = saveObject.SK;
                condition.questionaireId = question.questionaireId;
                condition.sectionId = question.sectionId;
                condition.questionId = result.data.id;
                condition.status = "active";
                const conditionObject = new ConditionDTO(condition , tenantId);
                createItemWs(conditionObject,  getState)
                  .then((result) => {
                    dispatch({ type: CONDITION_EDIT_ACTION, payload: result.data });
                    dispatch({ type: SAVE_PROCESS_DONE });
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch({ type: SAVE_PROCESS_DONE });
                  });
              } else if (question.isConditional) {
                condition.sectionId = question.sectionId;
                condition.questionId = result.data.id;
                condition.status = "active";
                const conditionObject = new ConditionDTO(condition , tenantId);
                createItemWs(conditionObject,  getState)
                  .then((result) => {
                    dispatch({ type: CONDITION_EDIT_ACTION, payload: result.data });
                    dispatch({ type: SAVE_PROCESS_DONE });
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch({ type: SAVE_PROCESS_DONE });
                  });
              }
              dispatch({ type: SAVE_PROCESS_DONE });
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: SAVE_PROCESS_DONE });
              toast.error("Failed to create question. Please try again.");
            });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to add the new question. Please try again.");
        });
    }
    else{
      updateItemWs(saveObject, getState)
        .then((result) => {
          mapLayerSaveFiles(result.data, mapLayer)
            .then(() => {
              dispatch({ type: QUESTION_EDIT_ACTION, payload: result.data });
              toast.success("The question has been updated successfully");
              if (question.isConditional && condition.id === undefined) {
                condition.PK = saveObject.SK;
                condition.questionaireId = question.questionaireId;
                condition.sectionId = question.sectionId;
                condition.questionId = result.data.id;
                condition.status = "active";
                const conditionObject = new ConditionDTO(condition , tenantId);
                createItemWs(conditionObject, getState)
                  .then((result) => {
                    dispatch({ type: CONDITION_EDIT_ACTION, payload: result.data });
                    dispatch({ type: SAVE_PROCESS_DONE });
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch({ type: SAVE_PROCESS_DONE });
                  });
              } else if (question.isConditional) {
                condition.sectionId = question.sectionId;
                condition.questionId = result.data.id;
                condition.status = "active";
                const conditionObject = new ConditionDTO(condition , tenantId);
                updateItemWs(conditionObject,  getState)
                  .then((result) => {
                    dispatch({ type: CONDITION_EDIT_ACTION, payload: result.data });
                    dispatch({ type: SAVE_PROCESS_DONE });
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch({ type: SAVE_PROCESS_DONE });
                  });
              }
              dispatch({ type: SAVE_PROCESS_DONE });
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: SAVE_PROCESS_DONE });
              toast.error("Failed to create question. Please try again.");
            });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to add the new question. Please try again.");
        });
    }
  };
};

export const questionDeleteAction = (question, condition, questionaireName) => {
  return (dispatch, getState) => {
    let saveObject = new QuestionDTO(question);
    saveObject.sequence = parseInt(saveObject.sequence);
    saveObject.status = "isDeleted";
    updateItemWs(saveObject, getState)
      .then((result) => {
        if (question.questionType === "map" ||question.questionType === "map_yes_no") {
          mapLayerDeleteFile(questionaireName, result.data);
        }
        if (condition !== undefined) {
          condition.sectionCondition = false;
          saveObject = new ConditionDTO(condition);
          saveObject.status = "isDeleted";
          updateItemWs(saveObject, getState)
            .then((result) => {
            dispatch({ payload: result.data.id });
          });
        }
        dispatch({ type: QUESTION_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success("The question has been deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed to delete the question. Please try again.");
      });
  };
};


export const FETCHING_BASE_QNAIRE_QUESTIONS_ACTION = "FETCHING_BASE_QNAIRE_QUESTIONS_ACTION";
export const FETCH_BASE_QNAIRE_QUESTIONS_SUCCESS_ACTION = "FETCH_BASE_QNAIRE_QUESTIONS_SUCCESS_ACTION";
export const FETCH_BASE_QNAIRE_QUESTIONS_ERROR_ACTION = "FETCH_BASE_QNAIRE_QUESTIONS_ERROR_ACTION";

export const fetchBaseQanireQuestionsActions = (baseQnaires) => {
  const queryParams = {
    GSI11PK: `2WWjarbwyZ6IvJpoQKfzVWkGWiK%23QUESTIONS`,
    GSI11SK: 'QUESTION%23',
  };

  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_BASE_QNAIRE_QUESTIONS_ACTION, payload: baseQnaires });

    try {
      const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const returnItems = result.Items.filter(item => item.status !== "isDeleted");

      // Assuming that each question has a property named 'questionnaireId'
      const baseQnaireIds = baseQnaires.map(qnaire => qnaire.id);

      const filteredQuestions = returnItems.filter(item => baseQnaireIds.includes(item.questionaireId));

      dispatch({
        type: FETCH_BASE_QNAIRE_QUESTIONS_SUCCESS_ACTION,
        payload: filteredQuestions,
      });

      return filteredQuestions;
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_BASE_QNAIRE_QUESTIONS_ERROR_ACTION, err });
      throw err;
    }
  };
};