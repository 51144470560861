import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Dropdown
} from "semantic-ui-react";

import * as BRTYPE_ACTIONS from "../../../actions/businessRuleTypeActions";
import * as BRULE_ACTIONS from "../../../actions/businessRuleActions";

export function BusinessRuleDetails(props) {
  const [state, setState] = useState({
    bRule: { ...props.bRule },
    bRuleAttrs: { ...props.bRuleAttrs},
    isChanged: false,
    showAggregationCount: false,
    showNotificationMsg: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });


useEffect(() => {
  // props.fetchBrTypesAction();

  const matchingBrType = props.brTypes.find(
    (brType) => brType.id === props.bRule.brTypeId
  );

  // Check if the matching brType has a microService of "Aggregation"
  if (matchingBrType && matchingBrType.microService === "Aggregation") {
    setState((state) => ({
      ...state,
      showAggregationCount: true,
    }));
  }
  if (matchingBrType && matchingBrType.microService === "Powerpoint Generation") {
    setState((state) => ({
      ...state,
      showNotificationMsg: true,
    }));
  }
}, []);
  const bRuleAttrsObjectsFromString = (json) => {
		if (!_.isEmpty(json)) {
			return JSON.parse(json);
		}
		else {
			return [];
		}
	}

	const bRuleAttrsObjectsToString = (objectArray) => {
		return JSON.stringify(objectArray);
	}

  const onSubmit = (data) => {
    let toCommit = state.bRule;

    if (!_.isEmpty(data.brtype_name)) {

      // const selectedBrType = brTypesList.filter((brtype) => brtype.key === data.brtype_name).map((brtype) => ({text: brtype.text}));
      const selectedBrType = props.brTypes.filter((brtype) => brtype.id === state.bRule.brTypeId);

      if (!_.isEmpty(selectedBrType))
        toCommit.brTypeName = selectedBrType[0].brType;
        toCommit.pptxType = selectedBrType[0]?.pptxType;
    }

    if (!_.isEmpty(data.notificationMsg)) {
        toCommit.notoficationMsg = data.notificationMsg;
    }

    if (!_.isEmpty(state.bRuleAttrs) && _.isEmpty(toCommit.bRuleAttrs)) {
      toCommit.bRuleAttrs = bRuleAttrsObjectsToString(state.bRuleAttrs);
    }
    props.saveBRuleAction(toCommit, state.bRuleAttrs, props.tenantId);

    setState((state) => ({ ...state, bRule: toCommit, bRuleAttrs: state.bRuleAttrs, isChanged: false}));

    if (_.isEmpty(props.onComplete)) {
      props.onComplete();
    }
  };

  const bRuleAttrsNew = (bRuleName) => {
    var newAttrs = [];

    if (!_.isEmpty(bRuleName)) {
      const selectedBrType = brTypesList.filter((brtype) => brtype.key === bRuleName).map((brtype) => ({attrs: brtype.attrs}));
      if (!_.isEmpty(selectedBrType)) {
        var selAttrs = bRuleAttrsObjectsFromString(selectedBrType[0].attrs);
        (selAttrs).forEach(attr => {
          var newAttr = {
            attrName: attr.attrName,
            qnaireId: '',
            qnaireName: '',
            qnaireSectionId: '',
            qnaireSectionName: '',
            qnaireQuestionId:'',
            qnaireQuestionName:'',
            isMandatory:attr.isMandatory,          
            isUpload:attr.isUpload,      
            uploadFilename: ''
          }
          newAttrs.push(newAttr);
        });
      }
    }
    return _.orderBy(newAttrs,['attrName']);
  }
  
  const brTypesList = _.orderBy(props.brTypes
    .map((brType) => ({
      key: brType.id,
      text: brType.brType,
      value: brType.id,
      attrs: brType.brTypeAttributes,
      service: brType.microService,
      targetQnaireId: brType.targetQnaireId === undefined?'':brType.targetQnaireId,
    })), ['text']);

    const brTypeDropdown = () => {
      var defaultValue = state.bRule.brTypeName ? state.bRule.brTypeName : "";
    
      return (
        <Controller
          name="brtype_name"
          control={control}
          rules={{ required: true }}
          setValue={setValue}
          defaultValue={defaultValue}
          render={({ field, fieldState, formState }) => {
            return (
              <div>
                <Dropdown
                  className={fieldState.invalid ? "invalid-input-field" : ""}
                  {...field}
                  placeholder="Select a Business Rule Type"
                  fluid
                  selection
                  search
                  options={brTypesList}
                  value={state.bRule.brTypeId}
                  onChange={(e, { value }) => {
                    const selectedBrType = brTypesList.find(
                      (brType) => brType.value === value
                    );
    
                    if (selectedBrType) {
                      const isAggregation =
                        selectedBrType.service === "Aggregation";
                      const isPPTGen =
                        selectedBrType.service === "Powerpoint Generation";
    
                      // Set microService and targetQnaireId based on selected BRType
                      const microService = selectedBrType.service;
                      const targetQnaireId = selectedBrType.targetQnaireId===undefined?'':selectedBrType.targetQnaireId;
    
                      setState((state) => ({
                        ...state,
                        bRule: {
                          ...state.bRule,
                          brTypeId: value,
                          microService, // Set microService
                          targetQnaireId, // Set targetQnaireId
                        },
                        bRuleAttrs: bRuleAttrsNew(value),
                        showAggregationCount: isAggregation,
                        showNotificationMsg: isPPTGen,
                      }));
    
                      setState((state) => ({ ...state, isChanged: true }));
                      field.onChange(value);
                    }
                  }}
                />
                {fieldState.invalid && (
                  <p className="validation-error">* Select a Business Rule Type</p>
                )}
              </div>
            );
          }}
        />
      );
    };
    
  
  
  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
    <Form.Field>
      <label>Business Rule</label>
      <input
        name="brule_name"
        className={errors.question_text ? "invalid-input-field" : ""}
        {...register("brule_name", { required: true, maxLength: 128 })}
        placeholder="Business Rule"
        value={state.bRule.bRule}
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            bRule: { ...prevState.bRule, bRule: e.target.value },
          }));
          setState((state) => ({...state, isChanged: true}));
        }}
      />
      {errors.brule_name && errors.brule_name.type === "required" && (
        <p className="validation-error">* Please enter a definition</p>
      )}
      {errors.question_text && errors.brule_name.type === "pattern" && (
        <p className="validation-error">
          * May only contain alphabetical characters
        </p>
      )}
      {errors.question_text && errors.brule_name.type === "maxLength" && (
        <p className="validation-error">
          * Name may not be more than 128 characters long
        </p>
      )}
    </Form.Field>
    <Form.Field>
      <label>Link to Business Type</label>
      {brTypeDropdown()}
    </Form.Field>
    {state.showAggregationCount && (
    <Form.Field>
      <label>Aggregation count</label>
      <input
        type="number"
        name="aggregation_count"
        placeholder="Enter aggregation count"
        value={state.bRule.brAggregationCount}
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            bRule: { ...prevState.bRule, brAggregationCount: e.target.value },
          }));
          setState((state) => ({ ...state, isChanged: true }));
        }}
      />
    </Form.Field>
  )}
    {state.showNotificationMsg && (
    <Form.Field>
      <label>Notification Msg</label>
      <input
        name="noti_msg"
        {...register("noti_msg", { required: false, maxLength: 256 })}
        placeholder="Enter Notification Message"
        value={state.bRule.notificationMsg}
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            bRule: { ...prevState.bRule, notificationMsg: e.target.value },
          }));
          setState((state) => ({ ...state, isChanged: true }));
        }}
      />
      {errors.question_text && errors.noti_msg.type === "maxLength" && (
        <p className="validation-error">
          * Name may not be more than 256 characters long
        </p>
      )}
    </Form.Field>
  )}
  <Divider />
    <Button.Group floated="right">
      <Button
        className="danger"
        onClick={() => {
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success" disabled={!state.isChanged}>
        <Icon name="checkmark" /> Ok
      </Button>
    </Button.Group>
  </Form>
  );
}

const mapStateToProps = (state, ownProps) => {
  var bRule = {};
  var isNew = false;
  var newBRule = {
    bRule: '',
    brType: '',
    brTypeName: '',
    brAggregationCount:'',
    brAttrs: []
  };

  if (ownProps.bdaction === 'add') {
    bRule = newBRule;
    isNew = true;
  } else {
    bRule = _.isEmpty(ownProps.BRule)
      ? newBRule
      : ownProps.BRule;
    isNew = false;
  }

  return {
    bRule: bRule,
    isNew: isNew,
		bRules: (_.isEmpty(state.bRules)) ? [] : Object.values(state.bRules),
		brTypes: (_.isEmpty(state.brTypes)) ? [] : Object.values(state.brTypes),
    bRuleAttrs: ownProps.bRuleAttrs, 
    tenantId: state.tenantManagement.activeTenant.tenantId 
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		fetchBrTypesAction: () => dispatch(BRTYPE_ACTIONS.fetchbrTypesAction()),
		saveBRuleAction: (bRule, bRuleAttrs, tenantId) => dispatch(BRULE_ACTIONS.bRuleSaveAction(bRule, bRuleAttrs, tenantId)),
  })
  )(BusinessRuleDetails);
