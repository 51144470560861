import { 
	FETCH_TENANT_ROLES_SUCCESS_ACTION, 
	FETCH_TENANT_ROLE_USERS_SUCCESS_ACTION, 
	FETCH_ALL_PERMISSIONS_SUCCESS_ACTION,
} from "../actions/tenantRolesActions";

export const tenantRolesReducer = (state = [], action) => {
    switch (action.type) {
			case FETCH_TENANT_ROLES_SUCCESS_ACTION:
				return action.payload;
			default:
				return state;
    }
};

export const tenantRoleUsersReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_TENANT_ROLE_USERS_SUCCESS_ACTION:
			return action.payload;
		default:
			return state;
	}
}


export const allPermissionsReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_ALL_PERMISSIONS_SUCCESS_ACTION:
			return action.payload;
		default:
			return state;
	}
}
