import {
    FETCH_BRULES_SUCCESS_ACTION,
    BRULE_ADD_ACTION,
    BRULE_EDIT_ACTION,
    BRULE_DELETE_ACTION,
} from '../actions/businessRuleActions';

export const businessRulesReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_BRULES_SUCCESS_ACTION:
            const newbRule= action.payload;
            const oldbRule = Object.values({...state}).filter(s => !newbRule.some(newS => newS.id === s.id));
            const newbRuleState = oldbRule.concat(newbRule); //Creates a copy...a new state
            return newbRuleState;
        case BRULE_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case BRULE_EDIT_ACTION:
            const newbRule1 = action.payload;
            const bRulesCopy = Object.values({...state}).filter(s => !(newbRule1.id === s.id)); //Creates a copy...a new state
            bRulesCopy.push(newbRule1);
            return bRulesCopy;
        case BRULE_DELETE_ACTION:
            return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
};