export class CVSearchlogDTO {
    PK;
    SK;
    createdAt;
    updatedAt;
    type;
    id;
    fileLocationType;
    incomeStreams;
    languages;
    locationRadius;
    qualifications;
    terms;
    searchResultCount;
    tenantId;

    constructor(cv, tenant, resultData) {
        const currentDate = new Date().toISOString().replace(/[-:.]/g, '').substring(0, 15);
        if (cv.id != null) {
            this.PK = cv.PK;
            this.SK = cv.SK;
            this.createdAt = cv.createdAt;
            this.updatedAt = cv.updatedAt;
        } else {
            this.PK = `${tenant.id}#CVSEARCH`;
            this.SK = `CVSEARCH#${currentDate}#`;
            this.createdAt = currentDate;
            this.updatedAt = currentDate;
        }
        this.id = cv.id;
        this.fileLocationType = cv.fileLocationType;
        this.incomeStreams = cv.incomeStreams;
        this.languages = cv.languages;
        this.locationRadius = cv.locationRadius;
        this.qualifications = cv.qualifications;
        this.terms = cv.terms;
        this.searchResultCount = resultData.data.length;
        this.type = "CVSEARCHLOG";
        this.tenantId = tenant.id;
    }
}
