export class ActivationRuleDTO{
    PK;
    Sk;
    id;
    createdAt;
    updatedAt;
    tenantId;
    type;
    name;
    description;
    timesToBeCompleted;
    // peopleToBeCompleted;
    completionLimitType;
    frequency;
    status;
    questionWithAnswers;
    userLocationQuestion;
    refKMLFile;
    ruleQuestionnaires;
    groupApplicable;
    // anonymisedData;
    ruleGroup;
    distanceOption;
    distanceValue;
    maxNumUsers;
    whatsAppTemplate;
    whatsAppTemplatePlaceholderValues;
    targetAudience;


    constructor(rule, tenant) {
        if (rule.id != null) {
            this.PK = rule.PK;
            this.SK = rule.SK;
        }
        else{
            this.PK = `${tenant.id}#ACTRULES`;
            this.SK = "ACTRULE#";
        }
        this.id = rule.id;
        this.type = "ACTRULE";
        this.tenantId = tenant.id;
        this.name = rule.name;
        this.description = rule.description;
        this.timesToBeCompleted = rule.timesToBeCompleted;
        // this.peopleToBeCompleted = rule.peopleToBeCompleted;
        this.completionLimitType = rule.completionLimitType;
        this.frequency = rule.frequency;
        this.status = rule.status;
        this.questionWithAnswers = rule.questionWithAnswers;
        this.userLocationQuestion = rule.userLocationQuestion;
        this.refKMLFile = rule.refKMLFile;
        this.ruleQuestionnaires = rule.ruleQuestionnaires;
        this.groupApplicable = rule.groupApplicable;
        // this.anonymisedData = rule.anonymisedData;
        this.ruleGroup = rule.ruleGroup;
        this.distanceOption = rule.distanceOption;
        this.distanceValue = rule.distanceValue;
        this.maxNumUsers = rule.maxNumUsers;
        this.targetAudience = rule.targetAudience;
        this.whatsAppTemplatePlaceholderValues = rule.whatsAppTemplatePlaceholderValues;
        this.whatsAppTemplate = rule.whatsAppTemplate;
    }
}