import { createItemWs, updateItemWs } from '../webservices/saveWS';
import { fetchItemsWS} from '../webservices/fetchWS';
import { TenantRoleDTO } from './DTOs/TenantRoleDTO';
import { TenantUserRoleDTO } from './DTOs/TenantUserRoleDTO';

export const FETCH_TENANT_ROLE_USERS_ACTION = 'FETCH_TENANT_ROLE_USERS_ACTION';
export const FETCH_TENANT_ROLE_USERS_SUCCESS_ACTION = 'FETCH_TENANT_ROLE_USERS_SUCCESS_ACTION';
export const FETCH_TENANT_ROLE_USERS_ERROR_ACTION = 'FETCH_TENANT_ROLE_USERS_ERROR_ACTION';

export const SAVE_TENANT_ROLE_USERS_ACTION = 'SAVE_TENANT_ROLE_USERS_ACTION';
export const SAVE_TENANT_ROLE_USERS_SUCCESS_ACTION = 'SAVE_TENANT_ROLE_USERS_SUCCESS_ACTION';
export const SAVE_TENANT_ROLE_USERS_ERROR_ACTION = 'SAVE_TENANT_ROLE_USERS_ERROR_ACTION';

// ================ FETCH ALL PERMISSIONS ================

export const FETCH_ALL_PERMISSIONS_ACTION = 'FETCH_ALL_PERMISSIONS_ACTION';
export const FETCH_ALL_PERMISSIONS_SUCCESS_ACTION = 'FETCH_ALL_PERMISSIONS_SUCCESS_ACTION';
export const FETCH_ALL_PERMISSIONS_ERROR_ACTION = 'FETCH_ALL_PERMISSIONS_ERROR_ACTION';

export const fetchAllPermissionAction = () => {
    return (dispatch, getState) => {
        dispatch({ type: FETCH_ALL_PERMISSIONS_ACTION, payload: {} });

        const queryParams = {
            PK: `PERMISSIONS`,
            SK: `PERMISSIONS`,
        };

        fetchItemsWS('get-dynamodb', queryParams, getState)
            .then((data) => {
                const items = data.Items;
                if (items.length < 1) throw "Full Permissions list was empty!";

                const allPermissions = items[0].permissions;

                const sortedPermissions = allPermissions.map(permission => {
                    const actionsArray = Object.keys(permission.actions);

                    const sortedActions = actionsArray.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

                    const sortedActionsObject = {};
                    sortedActions.forEach(action => {
                        sortedActionsObject[action] = permission.actions[action];
                    });

                    return { ...permission, actions: sortedActionsObject };
                }).sort((a, b) => {
                    const categoryA = a.category.toUpperCase();
                    const categoryB = b.category.toUpperCase();
                    return categoryA.localeCompare(categoryB);
                });

                dispatch({ type: FETCH_ALL_PERMISSIONS_SUCCESS_ACTION, payload: sortedPermissions });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_ALL_PERMISSIONS_ERROR_ACTION, payload: err });
            });
    };
};


// ================ FETCH ROLES ================

export const FETCH_TENANT_ROLES_ACTION = 'FETCH_TENANT_ROLES_ACTION';
export const FETCH_TENANT_ROLES_SUCCESS_ACTION = 'FETCH_TENANT_ROLES_SUCCESS_ACTION';
export const FETCH_TENANT_ROLES_ERROR_ACTION = 'FETCH_TENANT_ROLES_ERROR_ACTION';

export const fetchRolesAction = (tenant) => {

	const queryParams = {
    PK: `${tenant.tenantId}%23ROLES`,
    SK: `ROLE`,
  };

	return (dispatch, getState) => {
			dispatch({ type: FETCH_TENANT_ROLES_ACTION, payload: tenant });
			fetchItemsWS('get-dynamodb', queryParams, getState)
			.then((data) => {
					let items = data.Items;
					items = items.filter((item) => item.status !== 'isDeleted');
					dispatch({ type: FETCH_TENANT_ROLES_SUCCESS_ACTION, payload: items });
				})
				.catch((err) => {
					console.log(err);
					dispatch({ type: FETCH_TENANT_ROLES_ERROR_ACTION, payload: err })
				});
    }
}

// ================ CREATE ROLE ================

export const CREATE_TENANT_ROLE_USERS_ACTION = 'CREATE_TENANT_ROLE_USERS_ACTION';
export const CREATE_TENANT_ROLE_USERS_SUCCESS_ACTION = 'CREATE_TENANT_ROLE_USERS_SUCCESS_ACTION';
export const CREATE_TENANT_ROLE_USERS_ERROR_ACTION = 'CREATE_TENANT_ROLE_USERS_ERROR_ACTION';

export const createRoleAction = (tenant, role) => {
	return (dispatch, getState) => {
			dispatch({ type: CREATE_TENANT_ROLE_USERS_ACTION, payload: {tenant,role} });
			
			const qData = new TenantRoleDTO(role, tenant);
			qData.status = 'isEnabled';

			createItemWs(qData, getState).then(async (_) => {
				dispatch({ type: CREATE_TENANT_ROLE_USERS_SUCCESS_ACTION, payload:_ })
				dispatch(fetchRolesAction(tenant));
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: CREATE_TENANT_ROLE_USERS_ERROR_ACTION, payload: err })
			});
	}
}

// ================ DELETE ROLES ================

export const DELETE_TENANT_ROLES_ACTION = 'DELETE_TENANT_ROLES_ACTION';
export const DELETE_TENANT_ROLES_SUCCESS_ACTION = 'DELETE_TENANT_ROLES_SUCCESS_ACTION';
export const DELETE_TENANT_ROLES_ERROR_ACTION = 'DELETE_TENANT_ROLES_ERROR_ACTION';

export const deleteRoleAction = (tenant, role) => {
	return (dispatch, getState) => {
			dispatch({ type: DELETE_TENANT_ROLES_ACTION, payload: { tenant, role } });

			const qData = new TenantRoleDTO(role, tenant);
			qData.status = 'isDeleted';

			updateItemWs(qData, getState).then(async (_) => {
				updateAllUserRole(getState, tenant, role, true).then((_)=>{
					dispatch({ type: DELETE_TENANT_ROLES_SUCCESS_ACTION, payload:_ })
					dispatch(fetchRolesAction(tenant));
				})
				.catch((err) => {
					console.log(err);
					dispatch({ type: DELETE_TENANT_ROLES_ERROR_ACTION, payload: err })
				});
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: DELETE_TENANT_ROLES_ERROR_ACTION, payload: err })
			});
	}
}

// ================ UPDATE ROLES ================

export const UPDATE_TENANT_ROLES_ACTION = 'UPDATE_TENANT_ROLES_ACTION';
export const UPDATE_TENANT_ROLES_SUCCESS_ACTION = 'UPDATE_TENANT_ROLES_SUCCESS_ACTION';
export const UPDATE_TENANT_ROLES_ERROR_ACTION = 'UPDATE_TENANT_ROLES_ERROR_ACTION';

export const updateRoleAction = (tenant, role, isNew, isDeleting = false) => {
	return (dispatch, getState) => {
			dispatch({ type: UPDATE_TENANT_ROLES_ACTION, payload: { tenant, role } });

			const qData = new TenantRoleDTO(role, tenant);

			updateItemWs(qData, getState).then(async (_) => {
				updateAllUserRole(getState, tenant, role, false).then((_)=>{
					dispatch({ type: UPDATE_TENANT_ROLES_SUCCESS_ACTION, payload:_ })
					dispatch(fetchRolesAction(tenant));
				})
				.catch((err) => {
					console.log(err);
					dispatch({ type: UPDATE_TENANT_ROLES_ERROR_ACTION, payload: err })
				});
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: UPDATE_TENANT_ROLES_ERROR_ACTION, payload: err })
			});
	}
}

const updateAllUserRole = (getState, tenant, role, isDeleting = false) => {
	return new Promise((resolve,reject) => {
		const payload = { tenant, role, isDeleting};
		const {tenantId, tenantName } = tenant;

		const queryParams = {
			GSI8PK: `${tenant.tenantId}%23ROLE%23${role.id}`,
			GSI8SK: `USER%23`,
		};

		fetchItemsWS('get-dynamodb', queryParams, getState)
			.then((data) => {
				const userRoles = data.Items;
				userRoles.forEach((userRole) => {
					const newUserRole = {
						...userRole,
						roleName: role.roleName,
						roleDescription: role.roleDescription,
						permissions: role.permissions,
					};
					const qData = new TenantUserRoleDTO(newUserRole, tenant);
					if(isDeleting === true) qData.status = 'isDeleted';
					updateItemWs(qData, getState)
						.then((_) => {

						})
						.catch(reject);
				});
				resolve();
			})
			.catch(reject);
	});
}

// ================ FETCH ROLE USERS ================

export const fetchRoleUsersAction = (tenant, role) => {

	const queryParams = {
    GSI8PK: `${tenant.tenantId}%23ROLE%23${role.id}`,
    GSI8SK: `USER%23`,
  };

	return (dispatch, getState) => {
		dispatch({ type: FETCH_TENANT_ROLE_USERS_ACTION, payload: {tenant , role}});
		fetchItemsWS('get-dynamodb', queryParams, getState)
		.then((data) => {
				let items = data.Items;
				items = items.filter((item) => item.status !== 'isDeleted');
				dispatch({ type: FETCH_TENANT_ROLE_USERS_SUCCESS_ACTION, payload: items });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: FETCH_TENANT_ROLE_USERS_ERROR_ACTION, payload: err })
			});
	}
}

export const roleUserDeleteAction = (tenant, userRole, role) => {
	return (dispatch, getState) => {
	 dispatch(roleUserSaveAction(tenant, userRole, role, true));
	}
}

export const roleUserSaveAction = (tenant, userRole, role, isDeleting = false) => {
	return (dispatch, getState) => {
			dispatch({ type: SAVE_TENANT_ROLE_USERS_ACTION, payload: { tenant, userRole, isDeleting} });
			const qData = new TenantUserRoleDTO(userRole, tenant);

			let promise;
			if(isDeleting === true) {
				qData.status = 'isDeleted';
				promise= updateItemWs(qData, getState);
			} else {
				promise = createItemWs(qData, getState);
			}

			promise.then((_) => {
				dispatch({ type: SAVE_TENANT_ROLE_USERS_SUCCESS_ACTION, payload: _ })
				// const user = { username: userRole.userName };
				dispatch(fetchRoleUsersAction(tenant, role));
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: SAVE_TENANT_ROLE_USERS_ERROR_ACTION, payload: err })
			});
	}
}