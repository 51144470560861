import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { toast } from "react-toastify";
import { MessageDTO } from "./DTOs/MessageDTO";
import { createItemWs } from "../webservices/saveWS";
import { fetchItemsWS } from "../webservices/fetchWS";

export const FETCHING_MESSAGES_ACTION = "FETCHING_MESSAGES_ACTION";
export const FETCH_MESSAGES_SUCCESS_ACTION = "FETCH_MESSAGES_SUCCESS_ACTION";
export const FETCH_MESSAGES_ERROR_ACTION = "FETCH_MESSAGES_ERROR_ACTION";

export const MESSAGE_ADD_ACTION = "MESSAGE_ADD_ACTION";
export const MESSAGE_EDIT_ACTION = "MESSAGE_EDIT_ACTION";
export const MESSAGE_DELETE_ACTION = "MESSAGE_DELETE_ACTION";

export const USER_MESSAGE_LIST_ADD_ACTION = "USER_MESSAGE_LIST_ADD_ACTION";

export const fetchMessagesAction = () => {
  const queryParams = {
    GSI6PK: 'MESSAGES',
    GSI6SK: 'MESSAGE',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_MESSAGES_ACTION });
    fetchItemsWS('get-dynamodb', queryParams)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_MESSAGES_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_MESSAGES_ERROR_ACTION, err });
      });
  };
}

export const messageSaveAction = (message, recipients) => {
  return (dispatch, getState) => {
    try {
      for (const element of recipients) {
        let user = element;
        message.userName = user;
        const newMessage = new MessageDTO(message);
        newMessage.status = "active";
        const saveObject = newMessage;
        dispatch({ type: SAVE_IN_PROGRESS });
        createItemWs(saveObject, getState)
          .then((result) => {
            dispatch({ type: MESSAGE_ADD_ACTION, payload: result.data });
            dispatch({ type: SAVE_PROCESS_DONE });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: SAVE_PROCESS_DONE });
            toast.error("Failed to send message please try again");
          });
      }
      toast.success("The message has been dispatched");
    } catch (error) {
      console.log(error);
      dispatch({ type: SAVE_PROCESS_DONE });
      toast.error("Failed to send message please try again");
    }
  };
};
