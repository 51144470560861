import React, { useState, useEffect } from 'react';
import { Modal, Button, Loader } from 'semantic-ui-react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';  

const defaultIcon = new L.Icon({
    iconUrl: require("../../../media/marker.png"),
    iconSize: [38, 38],
    iconAnchor: [12, 41],
});

const PRETORIA_COORDS = [-25.746111, 28.188056]; // Pretoria, South Africa coordinates

export const MapModal = ({ open, onClose, onSelectCoordinates }) => {
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [userLocation, setUserLocation] = useState(null); 
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        let mounted = true;
        let watchId;
    
        const fetchLocation = () => {
            setLoading(true); 
            watchId = navigator.geolocation.watchPosition(
                (position) => {
                    if (mounted) {
                        const { latitude, longitude } = position.coords;
                        setUserLocation([latitude, longitude]);
                        setLoading(false);
                    }
                },
                (error) => {
                    if (mounted) {
                        setUserLocation(PRETORIA_COORDS);
                        setLoading(false);
                    }
                }
            );
        };
    
        if (open) {
            fetchLocation();
        } else {
            navigator.geolocation.clearWatch(watchId);
        }
    
        return () => {
            mounted = false;
            navigator.geolocation.clearWatch(watchId);
        };
    }, [open]);

    const LocationMarker = () => {
        useMapEvents({
            click: (e) => {
                const { lat, lng } = e.latlng;
                setSelectedPosition({ lat, lng });
            },
        });

        return selectedPosition ? (
            <Marker
                position={[selectedPosition.lat, selectedPosition.lng]}
                icon={defaultIcon} 
            >
                <Popup>
                    <div>Selected Position: {selectedPosition.lat.toFixed(6)}, {selectedPosition.lng.toFixed(6)}</div>
                </Popup>
            </Marker>
        ) : null;
    };

    const MapCenterUpdater = () => {
        const map = useMap();
        useEffect(() => {
            if (userLocation) {
                map.setView(userLocation, 13); 
            }
        }, [userLocation, map]);
        return null;
    };

    const handleSelect = () => {
        if (selectedPosition) {
            onSelectCoordinates(selectedPosition);
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            {loading && (
                <div style={{ textAlign: 'center', padding: '20px', }}>
                    <Loader style={{color:'black', fonstSize: '1.5em'}}>Fetching Location...</Loader>
                </div>
            )}
            <Modal.Header>Select a Location</Modal.Header>
                <Modal.Content>
                        <MapContainer
                            style={{ height: "500px", width: "100%" }}
                            center={userLocation}
                            zoom={13}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <LocationMarker />
                            <MapCenterUpdater />
                        </MapContainer>
                </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    primary
                    disabled={!selectedPosition}
                    onClick={handleSelect}
                >
                    Select
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
