export class BRQnaireDTO {
    PK;
    SK;
    id;
    type;
    status;
    questionaireId;
    brName;
    brNameId;
    brAttrs;
    brExecutionRule;
    brExecutionRuleVal;
    brScheduled;
    brSchedDaily;
    brSchedWeekly;
    brSchedDow;
    brSchedMonthly;
    brSchedDom;
    brSchedTime;
    brSchedDate;
    createdAt;
    updatedAt;
    tenantId;

    constructor(data,tenantId) {
      if (data.id != null) {
        this.PK = data.PK;
        this.SK = data.SK;
      } else {
        this.PK = `${tenantId}#QNAIRE#${data.qnaireId}`;
        this.SK = 'BRULE#';
      }
      this.id = data.id;
      this.type = 'BRULE';
      this.status = data.status;
      this.questionaireId = data.qnaireId;
      this.brName = data.brName;
      this.brNameId = data.brNameId;
      this.brAttrs = data.brAttrs;
      this.brExecutionRule = data.brExecutionRule;
      this.brExecutionRuleVal = data.brExecutionRuleVal;
      this.brScheduled = data.brScheduled;
      this.brSchedDaily = data.brSchedDaily;
      this.brSchedWeekly = data.brSchedWeekly;
      this.brSchedDow = data.brSchedDow;
      this.brSchedMonthly = data.brSchedMonthly;
      this.brSchedDom = data.brSchedDom;
      this.brSchedTime = data.brSchedTime;
      this.tenantId = tenantId;
    }
  }