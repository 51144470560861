import { FETCHING_QUESTIONAIRES_ACTION, FETCH_QUESTIONAIRES_ERROR_ACTION, FETCH_QUESTIONAIRES_SUCCESS_ACTION, FETCHING_BASE_QUESTIONAIRES_ACTION, FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION, FETCH_BASE_QUESTIONAIRES_ERROR_ACTION } from '../actions/questionaireActions';
import { FETCHING_LOOKUPS_ACTION, FETCH_LOOKUPS_ERROR_ACTION, FETCH_LOOKUPS_SUCCESS_ACTION } from "../actions/lookupsActions";
import { FETCHING_SECTIONS_ACTION, FETCH_SECTIONS_SUCCESS_ACTION, FETCH_SECTIONS_ERROR_ACTION } from '../actions/sectionActions';
import { FETCHING_QUESTIONS_ACTION, FETCH_QUESTIONS_SUCCESS_ACTION, FETCH_QUESTIONS_ERROR_ACTION } from '../actions/questionActions';
import { FETCHING_QUESTION_WHATSAPPTEMPLATES_ACTION, FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_SUCCESS_ACTION, FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_ERROR_ACTION } from '../actions/questionWhatsappTemplateActions';
import { FETCHING_CONDITIONS_ACTION, FETCH_CONDITIONS_SUCCESS_ACTION, FETCH_CONDITIONS_ERROR_ACTION } from '../actions/conditionActions';
import { FETCHING_MESSAGES_ACTION, FETCH_MESSAGES_SUCCESS_ACTION, FETCH_MESSAGES_ERROR_ACTION } from '../actions/messageActions';
import { FETCHING_GROUPS_ACTION, FETCH_GROUPS_SUCCESS_ACTION, FETCH_GROUPS_ERROR_ACTION } from '../actions/groupsActions';
import { FETCHING_USER_GROUPS_ACTION, FETCH_USER_GROUPS_SUCCESS_ACTION, FETCH_USER_GROUPS_ERROR_ACTION, FETCHING_GROUP_USERS_ACTION, FETCH_GROUP_USERS_SUCCESS_ACTION, FETCH_GROUP_USERS_ERROR_ACTION } from '../actions/userGroupsActions';
import { FETCHING_INBOXES_ACTION, FETCH_INBOXES_SUCCESS_ACTION, FETCH_INBOXES_ERROR_ACTION } from '../actions/inboxesActions';
import { FETCHING_LOOKUPSQNAIRE_ACTION, FETCH_LOOKUPSQNAIRE_SUCCESS_ACTION, FETCH_LOOKUPSQNAIRE_ERROR_ACTION } from "../actions/lookupsQnaireActions";
import { FETCHING_BRTYPES_ACTION, FETCH_BRTYPES_SUCCESS_ACTION, FETCH_BRTYPES_ERROR_ACTION } from '../actions/businessRuleTypeActions';
import { FETCHING_BRULES_ACTION, FETCH_BRULES_SUCCESS_ACTION, FETCH_BRULES_ERROR_ACTION } from '../actions/businessRuleActions';
import { FETCHING_BRQNAIRES_ACTION, FETCH_BRQNAIRES_SUCCESS_ACTION, FETCH_BRQNAIRES_ERROR_ACTION } from '../actions/brQnaireActions';
import { FETCHING_MICROSERVICES_ACTION, FETCH_MICROSERVICES_SUCCESS_ACTION, FETCH_MICROSERVICES_ERROR_ACTION } from '../actions/microServiceAction';
import { FETCHING_INTENTS_ACTION, FETCH_INTENTS_SUCCESS_ACTION, FETCH_INTENTS_ERROR_ACTION } from '../actions/intentsActions';
import { FETCHING_FREQUENCY_RULE_ACTION, FETCH_FREQUENCY_RULE_SUCCESS_ACTION, FETCH_FREQUENCY_RULE_ERROR_ACTION } from '../actions/frequencyRuleActions';
import { FETCHING_SOURCE_TENANT_ACTION, FETCH_SOURCE_TENANT_SUCCESS_ACTION, FETCH_SOURCE_TENANT_ERROR_ACTION, FETCHING_AUTH_USER_ROLES_ACTION, FETCH_AUTH_USER_ROLES_SUCCESS_ACTION, FETCH_AUTH_USER_ROLES_ERROR_ACTION } from '../actions/registrationActions';
import { FETCHING_KML_FILES_ACTION, FETCH_KML_FILES_SUCCESS_ACTION, FETCH_KML_FILES_ERROR_ACTION } from '../actions/kmlUploadActions'
import { FETCHING_BASE_QNAIRES_ACTION, FETCH_BASE_QNAIRES_SUCCESS_ACTION, FETCH_BASE_QNAIRES_ERROR_ACTION } from '../actions/tenantPermissionsActions'
import { FETCHING_REVIEW_QNAIRES_ACTION, FETCH_REVIEW_QNAIRES_SUCCESS_ACTION, FETCH_REVIEW_QNAIRES_ERROR_ACTION } from '../actions/reviewQuestionaireActions'
import { FETCHING_EXPORTS_ACTION, FETCH_EXPORTS_SUCCESS_ACTION, FETCH_EXPORTS_ERROR_ACTION, FETCHING_EXPORT_QUESTIONAIRES_ACTION, FETCH_EXPORT_QUESTIONAIRES_SUCCESS_ACTION, FETCH_EXPORT_QUESTIONAIRES_ERROR_ACTION, FETCHING_EXPORT_SECTIONS_ACTION, FETCH_EXPORT_SECTIONS_SUCCESS_ACTION, FETCH_EXPORT_SECTIONS_ERROR_ACTION, FETCHING_EXPORT_QUESTIONS_ACTION, FETCH_EXPORT_QUESTIONS_SUCCESS_ACTION, FETCH_EXPORT_QUESTIONS_ERROR_ACTION } from '../actions/exportsActions'
import { FETCHING_GROUP_MESSAGES_ACTION, FETCH_GROUP_MESSAGES_SUCCESS_ACTION, FETCH_GROUP_MESSAGES_ERROR_ACTION } from '../actions/userGroupsActions'
import { FETCHING_WHATSAPPTEMPLATES_ACTION, FETCH_WHATSAPPTEMPLATES_SUCCESS_ACTION, FETCH_WHATSAPPTEMPLATES_ERROR_ACTION } from '../actions/whatsappTemplateAction';
import { FETCHING_CVSEARCHLOG_ACTION, FETCH_CVSEARCHLOG_SUCCESS_ACTION, FETCH_CVSEARCHLOG_ERROR_ACTION, FETCHING_SELECTED_CV_ACTION, FETCH_SELECTED_CV_QNAIRE_SUCCESS_ACTION, FETCH_SELECTED_CV_ANSWERS_SUCCESS_ACTION, FETCH_SELECTED_CV_ERROR_ACTION,FETCHING_SELECTED_CV_STATUS_ACTION, FETCH_SELECTED_CV_STATUS_SUCCESS_ACTION, FETCH_SELECTED_CV_STATUS_ERROR_ACTION } from '../actions/cvSearchActions';
import { FETCHING_GLOBALPRICES_ACTION, FETCH_GLOBALPRICES_SUCCESS_ACTION, FETCH_GLOBALPRICES_ERROR_ACTION } from '../actions/globalPricingActions';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from '../actions/fetchingDataActions';

export const fetchingDataReducer = (state = {}, action) => {

    const fetchingData = () => {
        return true;
    };

    const dataFetchDone = () => {
        return false;
    }

    switch (action.type) {
        case FETCHING_QUESTIONAIRES_ACTION:
            return fetchingData();
        case FETCH_QUESTIONAIRES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_QUESTIONAIRES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_BASE_QUESTIONAIRES_ACTION:
            return fetchingData();
        case FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_BASE_QUESTIONAIRES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_LOOKUPS_ACTION:
            return fetchingData();
        case FETCH_LOOKUPS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_LOOKUPS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_LOOKUPSQNAIRE_ACTION:
            return fetchingData();
        case FETCH_LOOKUPSQNAIRE_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_LOOKUPSQNAIRE_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_SECTIONS_ACTION:
            return fetchingData();
        case FETCH_SECTIONS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_SECTIONS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_QUESTIONS_ACTION:
            return fetchingData();
        case FETCH_QUESTIONS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_QUESTIONS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_QUESTION_WHATSAPPTEMPLATES_ACTION:
            return fetchingData();
        case FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_CONDITIONS_ACTION:
            return fetchingData();
        case FETCH_CONDITIONS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_CONDITIONS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_MESSAGES_ACTION:
            return fetchingData();
        case FETCH_MESSAGES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_MESSAGES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_GROUPS_ACTION:
            return fetchingData();
        case FETCH_GROUPS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_GROUPS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_USER_GROUPS_ACTION:
            return fetchingData();
        case FETCH_USER_GROUPS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_USER_GROUPS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_GROUP_USERS_ACTION:
            return fetchingData();
        case FETCH_GROUP_USERS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_GROUP_USERS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_GROUP_MESSAGES_ACTION:
            return fetchingData();
        case FETCH_GROUP_MESSAGES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_GROUP_MESSAGES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_INBOXES_ACTION:
            return fetchingData();
        case FETCH_INBOXES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_INBOXES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_BRTYPES_ACTION:
            return fetchingData();
        case FETCH_BRTYPES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_BRTYPES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_BRULES_ACTION:
            return fetchingData();
        case FETCH_BRULES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_BRULES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_BRQNAIRES_ACTION:
            return fetchingData();
        case FETCH_BRQNAIRES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_BRQNAIRES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_MICROSERVICES_ACTION:
            return fetchingData();
        case FETCH_MICROSERVICES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_MICROSERVICES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_INTENTS_ACTION:
            return fetchingData();
        case FETCH_INTENTS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_INTENTS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_SOURCE_TENANT_ACTION:
            return fetchingData();
        case FETCH_SOURCE_TENANT_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_SOURCE_TENANT_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_AUTH_USER_ROLES_ACTION:
            return fetchingData();
        case FETCH_AUTH_USER_ROLES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_AUTH_USER_ROLES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_FREQUENCY_RULE_ACTION:
            return fetchingData();
        case FETCH_FREQUENCY_RULE_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_FREQUENCY_RULE_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_KML_FILES_ACTION:
            return fetchingData();
        case FETCH_KML_FILES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_KML_FILES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_BASE_QNAIRES_ACTION:
            return fetchingData();
        case FETCH_BASE_QNAIRES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_BASE_QNAIRES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_REVIEW_QNAIRES_ACTION:
            return fetchingData();
        case FETCH_REVIEW_QNAIRES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_REVIEW_QNAIRES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_EXPORTS_ACTION:
            return fetchingData();
        case FETCH_EXPORTS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_EXPORTS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_EXPORT_QUESTIONAIRES_ACTION:
            return fetchingData();
        case FETCH_EXPORT_QUESTIONAIRES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_EXPORT_QUESTIONAIRES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_EXPORT_SECTIONS_ACTION:
            return fetchingData();
        case FETCH_EXPORT_SECTIONS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_EXPORT_SECTIONS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_EXPORT_QUESTIONS_ACTION:
            return fetchingData();
        case FETCH_EXPORT_QUESTIONS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_EXPORT_QUESTIONS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_WHATSAPPTEMPLATES_ACTION:
            return fetchingData();
        case FETCH_WHATSAPPTEMPLATES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_WHATSAPPTEMPLATES_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_CVSEARCHLOG_ACTION:
            return fetchingData();
        case FETCH_CVSEARCHLOG_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_CVSEARCHLOG_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_SELECTED_CV_ACTION:
            return fetchingData();
        case FETCH_SELECTED_CV_QNAIRE_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_SELECTED_CV_ANSWERS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_SELECTED_CV_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_SELECTED_CV_STATUS_ACTION:
            return fetchingData();
        case FETCH_SELECTED_CV_STATUS_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_SELECTED_CV_STATUS_ERROR_ACTION:
            return dataFetchDone();
        case FETCHING_GLOBALPRICES_ACTION:
            return fetchingData();
        case FETCH_GLOBALPRICES_SUCCESS_ACTION:
            return dataFetchDone();
        case FETCH_GLOBALPRICES_ERROR_ACTION:
            return dataFetchDone();

        case SAVE_IN_PROGRESS:
            return fetchingData();
        case SAVE_PROCESS_DONE:
            return dataFetchDone();
        default:
            return state;
    }
};