import { FETCH_SELECTED_CV_QNAIRE_SUCCESS_ACTION, FETCH_SELECTED_CV_ANSWERS_SUCCESS_ACTION, FETCH_SELECTED_CV_STATUS_SUCCESS_ACTION,CLEAR_USER_CV } from "../actions/cvSearchActions";
import { FETCH_SELECTED_BOUGHT_CV_QNAIRE_SUCCESS_ACTION, FETCH_SELECTED_BOUGHT_CV_ANSWERS_SUCCESS_ACTION } from "../actions/boughtCVActions";

const initialState = {
    cvSearchResult: null,
    error: null
}

export const cvSearchReducer = (state = initialState, action) =>{
    switch (action.type){
        case "CV_SEARCH_ACTION":
            return action.payload;
        default:
            return state;
    }
}

export const selecetedCVReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_SELECTED_CV_QNAIRE_SUCCESS_ACTION:
            const selectedCV = action.payload;
            return selectedCV;
        case FETCH_SELECTED_BOUGHT_CV_QNAIRE_SUCCESS_ACTION:
            const boughtCV = action.payload;
            return boughtCV;
        case CLEAR_USER_CV:
            const userCV = null
            return userCV;
        default:
            return state;
    }
}

export const selecetedCVAnswerReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_SELECTED_CV_ANSWERS_SUCCESS_ACTION:
            const selectedCV = action.payload;
            return selectedCV;
        case FETCH_SELECTED_BOUGHT_CV_ANSWERS_SUCCESS_ACTION:
            const boughtCV = action.payload;
            return boughtCV;
        default:
            return state;
    }
}

export const boughtCVReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_SELECTED_CV_STATUS_SUCCESS_ACTION:
            const cv = action.payload;
            return cv;
        default:
            return state;
    }
}
