import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _, { isEmpty } from "lodash";
import { Storage } from 'aws-amplify';

import {
  Container,
  Form,
  Button,
  Icon,
  Divider,
} from "semantic-ui-react";
import { MapContainer, TileLayer, Marker, Polygon, Polyline } from "react-leaflet";
import L from "leaflet";

import * as KML_ACTIONS from '../../../actions/kmlUploadActions';
import { toast } from "react-toastify";

export function KmlDetails(props) {
  const [state, setState] = useState({
    kml: { ...props.kml },
    isChanged: false,
    markers: [],
    polygons: [],
    polylines: []
  });

  useEffect(() => {
    setState({
      kml: { ...props.kml },
      isChanged: false,
    });
  }, [props.kml]);

  useEffect(() => {
    const fetchKmlFileFromS3 = async () => {
      if (!props.isNew && !_.isEmpty(props.kml.fileName)) {
        try {
          const response = await fetch(await Storage.get(props.kml.filePath));
          
          if (!response.ok) {
            toast.error(`Failed to fetch KML file.`);
          }
  
          const kmlContent = await response.text();
  
          const kmlBlob = new Blob([kmlContent], { type: 'application/vnd.google-earth.kml+xml' });
  
          await extractFeaturesFromKml(kmlBlob);
  
        } catch (error) {
          console.error("Error fetching KML file from S3:", error.message);
        }
      }
    };
  
    fetchKmlFileFromS3();
  }, [props.isNew, props.kml.fileName]);
  
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  
  const onSubmit = (data) => {
    let toCommit = {};
    // if (data.kmlFile[0] && data.kmlFile[0].name.endsWith('.kml') && data.kmlFile[0].type === 'application/vnd.google-earth.kml+xml') {
      if (!_.isEmpty(data.name)) toCommit.name = data.name;
      if (!_.isEmpty(data.description)) toCommit.description = data.description;
      if(!_.isEmpty(data.kmlFile)){
        toCommit.fileName = data.kmlFile[0].name;;
      }
      else if(!_.isEmpty(props.kml)){
        toCommit.fileName = props.kml.fileName;
      }
      else{
        toCommit.fileName = '';
      }
  
      const kmlFile = data.kmlFile[0];
      
      if (props.isNew) {
        props.saveKmlFile(props.tenant, toCommit, kmlFile, props.isNew);
      } else {
        const combinedKml = { ...props.kml, ...toCommit };
        props.saveKmlFile(props.tenant, combinedKml, kmlFile, props.isNew);
      }
  
      setState((state) => ({ ...state, isChanged: false }));
      if (_.isEmpty(props.onComplete)) props.onComplete();

    // }
    // else{
    //   toast.error("Please upload a google earth genrated .kml file.");
    // }
  };

  const handleKmlFileSelection = async (e) => {
    const kmlFile = e.target.files[0];
    // if (kmlFile && kmlFile.name.endsWith('.kml') && kmlFile.type === 'application/vnd.google-earth.kml+xml') {
      try {
        await extractFeaturesFromKml(kmlFile);
      } catch (error) {
        console.error("Error extracting features from KML:", error.message);
      }
    // } else {
    //   toast.error("Please upload a google earth genrated .kml file.");
    // }
  };
  
  
  
  const extractFeaturesFromKml = async (kmlFile) => {
    try {
      const kmlContent = await readKmlFileContent(kmlFile);
  
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(kmlContent, "text/xml");
  
      const placemarks = xmlDoc.querySelectorAll("Placemark");
  
      const updatedState = {
        markers: [],
        polygons: [],
        polylines: [],
      };
  
      placemarks.forEach((placemark) => {
        const coordinates = placemark.querySelector("coordinates").textContent.split(",");
        const geocode = [parseFloat(coordinates[1]), parseFloat(coordinates[0])];
        const type = placemark.querySelector("Point")
          ? "Point"
          : placemark.querySelector("Polygon")
          ? "Polygon"
          : placemark.querySelector("LineString")
          ? "LineString"
          : null;
  
        if (type === "Point") {
          updatedState.markers.push({ geocode });
        } else if (type === "Polygon") {
          const polygonCoordinates = Array.from(placemark.querySelectorAll("coordinates"))
            .flatMap((coord) =>
              coord.textContent.trim().split(" ").map((point) => {
                const [longitude, latitude] = point.split(",").map(parseFloat);
                return [latitude, longitude];
              })
            );
  
          updatedState.polygons.push({ coordinates: polygonCoordinates });
        } else if (type === "LineString") {
          const polylineCoordinates = placemark.querySelector("coordinates").textContent
            .trim()
            .split(' ')
            .map(point => {
              const [longitude, latitude] = point.split(',').map(parseFloat);
              return [latitude, longitude];
            });
  
          updatedState.polylines.push({ coordinates: polylineCoordinates });
        }
      });
  
      // Use setState to update the state
      setState((prevState) => ({
        ...prevState,
        ...updatedState,
      }));
  
      return updatedState;
    } catch (error) {
      console.error("Error extracting features from KML:", error.message);
      throw error;
    }
  };
  
  
  const readKmlFileContent = (kmlFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        resolve(event.target.result);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsText(kmlFile);
    });
  };

  const _buildName = () => {
    return (
      <Form.Field>
        <label>Name</label>
        <input
          name="name"
          className={errors.name ? "invalid-input-field" : ""}
          {...register("name", { required: true, maxLength: 128 })}
          placeholder="Name"
          value={state.kml.name}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              kml: { ...prevState.kml, name: e.target.value },
            }));
            setState((state) => ({ ...state, isChanged: true }));
          }}
        />
        {errors.name && errors.name.type === "required" && (
          <p className="validation-error">* Please enter a name</p>
        )}
        {errors.name && errors.name.type === "maxLength" && (
          <p className="validation-error">
            * Name may not be more than 128 characters long
          </p>
        )}
      </Form.Field>
    );
  };

  const _buildDescription = () => {
    return (
      <Form.Field>
        <label>Description</label>
        <input
          name="description"
          className={errors.description ? "invalid-input-field" : ""}
          {...register("description", { maxLength: 255 })}
          placeholder="Description"
          value={state.kml.description}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              kml: { ...prevState.kml, description: e.target.value },
            }));
            setState((state) => ({ ...state, isChanged: true }));
          }}
        />
        {errors.description && errors.description.type === "maxLength" && (
          <p className="validation-error">
            * Description may not be more than 255 characters long
          </p>
        )}
      </Form.Field>
    );
  };

  const _buildKmlFileInput = () => {
    return (
      <Form.Field>
        <label>KML File</label>
        {state.kml && state.kml.fileName && (
          <label>Uploaded File: {state.kml.fileName}</label>
        )}
          <input
            type="file"
            accept=".kml"
            name="kmlFile"
            className={errors.kmlFile ? "invalid-input-field" : ""}
            {...register("kmlFile", { required: false })}
            onChange={(e) => {
              const kmlFile = e.target.files[0];
              if (kmlFile && kmlFile.name.endsWith('.kml')) {
                handleKmlFileSelection(e);
  
                setState((prevState) => ({
                  ...prevState,
                  kml: { ...prevState.kml, kmlFile: e.target.files[0] },
                }));
  
                setState((state) => ({ ...state, isChanged: true }));
              }else{
                toast.error("Please upload a google earth genrated .kml file.");
              }
            }}
          />
          {errors.kmlFile && errors.kmlFile.type === "required" && (
            <p className="validation-error">* Please select a KML file</p>
          )}
      </Form.Field>
    );
  };

  const position = [-25.731340, 28.218370]
  const blueOptions = { color: 'blue' }

  const defaultIcon = new L.Icon({
    iconUrl: require("../../../media/marker.png"),
    iconSize: [38, 38],
    iconAnchor: [12, 41],
  });

  const _buildMapContainer = () => {
    if (!state.markers && !state.polygons && !state.polylines) {
      return null; // Don't render the map if no features are available
    }
  
    // Calculate centerPosition only if features are present
    const centerPosition =
      state.markers?.length > 0
        ? state.markers[0].geocode
        : state.polygons?.length > 0
        ? state.polygons[0]?.coordinates[0]
        : state.polylines?.length > 0
        ? state.polylines[0]?.coordinates[0]
        : position;
  
    return (
      <MapContainer center={centerPosition} zoom={15} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
  
        {/* Display Markers */}
        {state.markers?.length > 0 &&
          state.markers.map((marker, index) => (
            <Marker key={`marker-${index}`} position={marker.geocode} icon={defaultIcon}></Marker>
          ))}
  
        {/* Display Polygons */}
        {state.polygons?.length > 0 &&
          state.polygons.map((polygon, index) => (
            <Polygon
              pathOptions={blueOptions}
              key={`polygon-${index}`}
              positions={polygon.coordinates}
              color="blue"
              fillOpacity={0.2}
            />
          ))}
  
        {/* Display Polylines */}
        {state.polylines?.length > 0 &&
          state.polylines.map((polyline, index) => (
            <Polyline key={`polyline-${index}`} positions={polyline.coordinates} color="red" />
          ))}
      </MapContainer>
    );
  };
  

  return (
    <Container>
      <Form
        size="small"
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: "50px" }}
      >
        {_buildName()}
        {_buildDescription()}
        {_buildKmlFileInput()}

        <Divider />

        <Button.Group floated="right" >
          <Button
            className="danger"
            onClick={() => {
              if (_.isEmpty(props.onComplete)) {
                props.onComplete();
              }
            }}
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button type="submit" className="success" disabled={!state.isChanged}>
            <Icon name="checkmark" /> Save
          </Button>
        </Button.Group>
      </Form>
      {_buildMapContainer()}
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  let kml = {};
  let isNew = false;
  let newKml = {
    name: '',
    description: '',
    fileName: '',
  };

  if (ownProps.bdaction === 'add') {
    kml = newKml;
    isNew = true;
  } else {
    kml = _.isEmpty(ownProps.kml)
      ? newKml
      : ownProps.kml;
  }

  return {
    tenant: state.tenantManagement.activeTenant,
    isNew: isNew,
    kml: kml,
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch) => ({
    saveKmlFile: (tenant, file, kmlFile, isNew) => dispatch(KML_ACTIONS.saveKmlFileAction(tenant, file, kmlFile, isNew)),
  })
)(KmlDetails);
