import React from 'react';
import { connect } from 'react-redux';
import { Grid, Segment, Button, Confirm, Header, Icon, Item, Dropdown, Label } from "semantic-ui-react";
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import moment from 'moment';

import * as QUESTIONAIRE_ACTIONS from '../../../actions/questionaireActions';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import QuestionaireDetails from '../questionairesPage/QuestionaireDetails';
import { toast } from "react-toastify";
class QuestionairesPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			questionaire: null,
			selectedQuestionaire: false,
			selectedQuestionaireMainSection: null,
			sections: { ...this.props.sections },
			fetchingData: false,
			currentPage: 0, 
			pageSize: 10,
			questionaireFilter: {
				key: "All",
				text: "Filter",
				value: -1
			},
		};
	}

	hasPermissions(category){
		return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category)
	}

	isAdmin(){
		return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin
	}

	canReadQuestionnaires(){
		return this.hasPermissions('Questionnaires') && this.props.userPermissions['Questionnaires'].actions.READ || this.isAdmin()
	}

	canCreateQuestionnaires(){
		return this.hasPermissions('Questionnaires') && this.canReadQuestionnaires() && this.props.userPermissions['Questionnaires'].actions.CREATE || this.isAdmin()
	}

	canUpdateQuestionnaires(){
		return this.hasPermissions('Questionnaires') && this.props.userPermissions['Questionnaires'].actions.UPDATE || this.isAdmin()
	}

	canDeleteQuestionnaires(){
		return this.hasPermissions('Questionnaires') && this.props.userPermissions['Questionnaires'].actions.DELETE || this.isAdmin()
	}
	
	componentDidUpdate(prevProps) {
		if (!_.isEqual(this.props.tenant.tenantId, prevProps.tenant.tenantId) ) {
			if(this.props.isPermissionsFetched){
				if (this.canReadQuestionnaires()) {
					this.props.fetchQuestionairesAction(this.props.tenant.tenantId);
				}
				else{
				toast.error('You do not have permission to view questionnaires');
				}
			}
		}
	  }
	  
	onPaginationPageChange = ({ selected: selectedPage }) => {
		this.setState({currentPage: selectedPage});
	}

	currentPaginationPageData = () => {	  
		const { pageSize } = this.state;
		const pageOffset = this.state.currentPage * pageSize;
	  
		const sortedQuestionaires = _.sortBy(this.props.questionaires, 'name');
	  
		return (
		  <div style={{ backgroundColor: "white", padding: "10px", boxShadow: "1px 2px 4px 1px #a9a9a9", borderRadius: "5px" }}>
	  
			<Item.Group divided>
			  {sortedQuestionaires.slice(pageOffset, pageOffset + pageSize).map((questionaire) => {
				const lastDateModifed = moment.utc((questionaire.updatedAt != null) ? questionaire.updatedAt : questionaire.createdAt);
				const lastModified = lastDateModifed.format("LL") + " at " + lastDateModifed.format("LTS");
	  
				if (this.state.questionaireFilter.value === -1 || questionaire.isActive === this.state.questionaireFilter.value) {
				  const isBaseQnaire = questionaire.isBaseQnaire;
				  const isRootTenant = this.props.tenant.isRoot === true || false;

					return (
						<Item key={questionaire.id} className="question-item">
							<Item.Content>
								{this.confirmDelete()}
								<Grid>
									<Grid.Row className="question-item">
										<Grid.Column width='8'>
											<Link to={{
												pathname: `/Questionaires/${questionaire.name.replaceAll(" ", "-").toLowerCase()}`,
												state: { id: questionaire.id }
											}}>
												<Item.Header style={{ padding: '0px 5px 5px 0px' }}>
													<span style={{ fontSize: '18px', textAlign: 'left' }}>{questionaire.name}</span>
												</Item.Header>
												<Item.Description style={{ color: 'grey', fontStyle: 'italic', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'light', fontSize: '14px', textAlign: 'left', padding: '0px 5px 0px 0px' }}>{questionaire.description}</Item.Description>
											</Link>
											<Item.Meta style={{ padding: '0px 5px 5px 0px', fontSize: '12px' }}>
												<span>Last Modified: {lastModified}</span>
											</Item.Meta>
											<Label
												color={questionaire.status === "active" ? 'green' : questionaire.status === "underReview" ? 'yellow' : questionaire.status === "denied" ? 'red' : 'red'}
												size='small'
											>
												{questionaire.status === "active" ? 'Active' : questionaire.status === "underReview" ? 'Under Review' : questionaire.status === "denied" ? 'Denied' : 'Inactive'}
											</Label>
											{(questionaire.defaultQuestionnaire === true) &&
												<label>{'     '}</label>
											}
											{(questionaire.defaultQuestionnaire === true) &&
												<Label size='small' style={{ color: 'white', backgroundColor: 'green'}}>Default</Label>
											}
										</Grid.Column>
										<Grid.Column width='8'>
											<Button.Group size="mini" basic floated="right">
												{this.canUpdateQuestionnaires() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
													<FuseContentModal
														header={`Edit ${questionaire.name} Questionnaire`}
														content={<QuestionaireDetails questionaire={questionaire} questionaires={this.props.questionaires} />}
														trigger={<Button className="edit-button"><Icon name='edit' /> Edit</Button>}
													/>
												)}

												{this.canDeleteQuestionnaires() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
													<Button className="delete-button" onClick={() => this.setState({ showConfirmDelete: true, questionaire })}><Icon name='delete' />Delete</Button>
												)}
											</Button.Group>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Item.Content>
						</Item>
				  );
				}
			  })}
			</Item.Group>
		  </div>
		);
	  }
	  

	confirmDelete() {
		if(this.state.questionaire !== null){
		return (
		  <Confirm
			content={`Are you sure you want to delete questionnaire: ${this.state.questionaire.name}?`}
			open={this.state.showConfirmDelete}
			onCancel={() => this.setState({ showConfirmDelete: false })}
			onConfirm={() => {
			  this.props.deleteQuestionaire(this.state.questionaire);
			  this.setState({ showConfirmDelete: false })
			}
			}
		  />
		);
		}
	  }

	questionaireList() {
		const filterOptions = [
			{ key: "All", text: "All", value: -1},
			{ key: "Active", text: "Active", value: true},
			{ key: "Not Active", text: "Not Active", value: false},
		];

		const handleDropDownSelect = (event, data) => {
			const filterOption = Object.values(data.options).filter(option => option.value === data.value)[0];
			this.setState({questionaireFilter: filterOption});
		};

		const actions = () => {
			return(
				<div style={{ marginBottom: "20px" }}>
					<Grid>
						<Grid.Row>
							<Grid.Column width={10} style={{padding: "0px !important"}}>
								<Dropdown
									placeholder="Filter"
									icon='filter'
									labeled
									button
									className='icon'
									options={filterOptions}
									onChange={handleDropDownSelect}
								>
								</Dropdown>
							</Grid.Column>
							<Grid.Column width={6}>
								{ this.canCreateQuestionnaires() && (
									<FuseContentModal
									header={`Add New Questionnaire`}
									content={<QuestionaireDetails />}
									trigger={<Button primary floated="right"><Icon name='plus' /> Add</Button>}
									/>
								)}
								</Grid.Column>
						</Grid.Row>
					</Grid>
				</div >
			);
		}

		if(!this.props.fetchingData) {
			if(!_.isEmpty(this.props.questionaires)) {
				const pageCount = Math.ceil(this.props.questionaires.length / this.state.pageSize);

				return (
					<div>
						{actions()}
						{this.currentPaginationPageData()}
						<div className="nectaPaginationBackground">
							<ReactPaginate
								previousLabel={"← Previous"}
								nextLabel={"Next →"}
								pageCount={pageCount}
								onPageChange={this.onPaginationPageChange}
								containerClassName={"nectaPagination"}
								previousLinkClassName={"nectaPagination_link"}
								nextLinkClassName={"nectaPagination_link"}
								activeLinkClassName={"nectaPagination_link_active"}
							/>
						</div>
					</div>
				);
			}
			else {
				return (
					<div>
					{actions()}
					<Segment>
						<Header icon>
							<Icon name='pdf file' color="black" />
								There are no questionnaires yet
						</Header>
					</Segment>
					</div>
				);
			}
		}
		else {
			return (
				<div>
					{actions()}
					<Segment secondary loading>
						<div style={{ height: "200px" }}>Loading</div>
					</Segment>
				</div>
			);
		}
	}

	render() {
		return (
			<div>
				{this.questionaireList()}
			</div>
		);
	}

}

const mapStateToProps = (state, ownProps) => {

	return {
		fetchingData: state.fetchingData === true,
		questionaires: _.isEmpty(state.questionaires) ? [] : Object.values(state.questionaires),//.filter(questionaires => questionaires.status.toLowerCase()!=state.questionaireFilter.text.toLowerCase())),
		// baseQuestionnaires: state.baseQuestionnaires,
		// questionaires: [...Object.values(state.questionaires), ...state.baseQuestionnaires],
		tenant: _.isEmpty(state.tenantManagement.activeTenant) ? {} : state.tenantManagement.activeTenant,
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchQuestionairesAction: (payload) => dispatch(QUESTIONAIRE_ACTIONS.fetchQuestionairesAction(payload)),
		// fetchBaseQuestionairesAction: (payload) => dispatch(QUESTIONAIRE_ACTIONS.fetchBaseQuestionairesAction(payload)),
		commitQuestionaire: (questionaire, isNewItem) => dispatch(QUESTIONAIRE_ACTIONS.questionaireSaveAction(questionaire, isNewItem)),
		deleteQuestionaire: (questionaire) => dispatch(QUESTIONAIRE_ACTIONS.questionaireDeleteAction(questionaire))
	})
)(QuestionairesPage);