import {
  FETCH_ACT_RULES_SUCCESS_ACTION,
  ACT_RULE_ADD_ACTION,
  ACT_RULE_EDIT_ACTION,
  ACT_RULE_DELETE_ACTION,
  FETCH_RULE_QUESTIONS_SUCCESS_ACTION,
  FETCH_RULE_SECTIONS_SUCCESS_ACTION,
  FETCH_RULE_QUESTIONNARES_SUCCESS_ACTION,
  FETCH_RULE_KML_FILES_SUCCESS_ACTION,
  FETCH_WHATSAPP_TEMPLATES_SUCCESS_ACTION,
  FETCH_RULE_USER_GROUPS_SUCCESS_ACTION,
  FETCH_RULE_LOGS_INVOCATION_SUCCESS_ACTION,
  FETCH_RULE_LOGS_SUCCESS_SUCCESS_ACTION,
} from "../actions/activationRulesActions";

export const ActivationRulesReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_ACT_RULES_SUCCESS_ACTION:
      const Rule = action.payload;
      return Rule;
    case ACT_RULE_ADD_ACTION:
      const clonedState = [...state];
      clonedState.push(action.payload);
      return clonedState;
    case ACT_RULE_EDIT_ACTION:
      const newRule = action.payload;
      const RuleCopy = Object.values({ ...state }).filter(
        (rule) => !(newRule.id === rule.id)
      );
      RuleCopy.push(newRule);
      return RuleCopy;
    case ACT_RULE_DELETE_ACTION:
      return state.filter((element) => element.id !== action.payload);
    default:
      return state;
  }
};

export const ActivationRulesQuestionsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_RULE_QUESTIONS_SUCCESS_ACTION:
      const Rule = action.payload;
      return Rule;
    default:
      return state;
  }
};

export const ActivationRulesSectionsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_RULE_SECTIONS_SUCCESS_ACTION:
      const Rule = action.payload;
      return Rule;
    default:
      return state;
  }
};

export const ActivationRulesQuestionnairesReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_RULE_QUESTIONNARES_SUCCESS_ACTION:
      const Rule = action.payload;
      return Rule;
    default:
      return state;
  }
};

export const ActivationRulesKMLFilesReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_RULE_KML_FILES_SUCCESS_ACTION:
      const Rule = action.payload;
      return Rule;
    default:
      return state;
  }
};

export const ActivationRulesWhatsAppTemplatesReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_WHATSAPP_TEMPLATES_SUCCESS_ACTION:
      const templates = action.payload;
      return templates;
    default:
      return state;
  }
};

export const ActivationRulesUserGroupsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_RULE_USER_GROUPS_SUCCESS_ACTION:
      const Rule = action.payload;
      return Rule;
    default:
      return state;
  }
};

export const ActivationRuleLogInvocationEventReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_RULE_LOGS_INVOCATION_SUCCESS_ACTION:
      const Logs = action.payload;
      return Logs;
    default:
      return state;
  }
};

export const ActivationRuleLogSuccessEventReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_RULE_LOGS_SUCCESS_SUCCESS_ACTION:
      const Logs = action.payload;
      return Logs;
    default:
      return state;
  }
};
