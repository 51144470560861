import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as ACTIVATION_RULES from '../../../actions/activationRulesActions';

const parseLookupValue = (lookup) => {
    const valuesArray = JSON.parse(lookup[0].values);
    const lookupValues = valuesArray.map((valueObj) => valueObj.value);
    return {
        ...lookup,
        values: lookupValues,
    };
};

const QuestionModal = (props) => {
    const { question, lookupValue, onClose, onSave, fetchLookups, tenant } = props;
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    // Conditionally parse lookup values based on question type
    const parsedLookupValue = question.questionType !== 'yes_no' ? parseLookupValue(lookupValue) : null;

    const handleSave = () => {
        const questionWithAnswers = {
            questionId: question.id,
            question: question.question,
            questionnaireId: question.questionaireId,
            tenantId: question.tenantId,
            answers: selectedAnswers.map(answer => ({ value: answer })),
        };
    
        onSave(JSON.stringify([questionWithAnswers]));
        onClose();
    };
    
    
    

    const handleAnswerClick = (answer) => {
        if (question.questionType === 'mc_sel_one') {
            setSelectedAnswers([answer]);
        } else if (question.questionType === 'multi_select') {
            setSelectedAnswers((prevAnswers) => {
                if (prevAnswers.includes(answer)) {
                    return prevAnswers.filter((selectedAnswer) => selectedAnswer !== answer);
                } else {
                    return [...prevAnswers, answer];
                }
            });
        } else if (question.questionType === 'yes_no') {
            setSelectedAnswers([answer]);
        }
    };

    return (
        <Modal open={true} size="small">
            <Modal.Header>
                <span style={{ fontWeight: 'bold' }}>Select an answer for:</span> "{question.question}"
            </Modal.Header>
            <Modal.Content>
                {question.questionType === 'yes_no' ? (
                    <>
                        <Button onClick={() => handleAnswerClick('Yes')} positive={selectedAnswers.includes('Yes')}>
                            Yes
                        </Button>
                        <Button onClick={() => handleAnswerClick('No')} negative={selectedAnswers.includes('No')}>
                            No
                        </Button>
                    </>
                ) : (
                    <div>
                        {parsedLookupValue &&
                            parsedLookupValue.values.map((value, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    {question.questionType === 'multi_select' ? (
                                        <Checkbox
                                            label={value}
                                            checked={selectedAnswers.includes(value)}
                                            onChange={() => handleAnswerClick(value)}
                                        />
                                    ) : (
                                        <Checkbox
                                            radio
                                            label={value}
                                            checked={selectedAnswers.includes(value)}
                                            onChange={() => handleAnswerClick(value)}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={onClose}>
                    Cancel
                </Button>
                <Button positive onClick={handleSave} disabled={!selectedAnswers.length}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const mapStateToProps = (state, ownProps) => ({
    tenant: state.tenantManagement.activeTenant,
});

export default connect(
    mapStateToProps,
    (dispatch) => ({
        fetchLookups: (question) => dispatch(ACTIVATION_RULES.fetchLookupsAction(question)),
    })
)(QuestionModal);
