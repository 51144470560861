import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { updateItemWs} from "../webservices/saveWS";

import { executeStepFunction } from '../webservices/testQnaireWS';


export const executeStepFunctionAction = (data, setLoading) => {
    return async (dispatch, getState) => {
        dispatch({ type: SAVE_IN_PROGRESS });
        try {
            setLoading(true);
            const result = await executeStepFunction(data, getState);

            const combinedData = {
                ...data,
                userqnaire_id: result.data.userqnaire_id
            };

            const firstQuestion = await getFirstQuestion(combinedData)(dispatch, getState);
            
            const finalData = {
                ...combinedData,
                next_question: firstQuestion.next_question,
                next_question_type: firstQuestion.next_question_type,
                mc_vals: firstQuestion.mc_vals
            };
            dispatch({ type: SAVE_PROCESS_DONE });
            setLoading(false);
            return finalData;
        } catch (err) {
            dispatch({ type: SAVE_PROCESS_DONE });
        }
    };
};

export const getFirstQuestion = (data) => {
    return async (dispatch, getState) => {
        try {
            const result = await executeStepFunction(data, getState);
            return result.data;
        } catch (err) {
            console.log(err)
            throw err;
        }
    };
};


export const getNextQuestionAction = (data, setLoading) => {
    return async (dispatch, getState) => {
        try {
            setLoading(true);
            const result = await executeStepFunction(data, getState);
            setLoading(false);
            return result.data;
        } catch (err) {
            console.log(err)
            throw err;
        }
    };
}


export const deleteTestQnaireAction = (qnaireData) => {
    return (dispatch, getState) => {
        updateItemWs(qnaireData, getState)
            .then((result) => {
                return result.data
            })
            .catch((err) => {
                console.log(err);
            });
    }
}