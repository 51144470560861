import React, { Component, useState } from 'react';
import { Modal, Form, Button } from "semantic-ui-react";
import { useForm, Controller } from 'react-hook-form';

export function FuseInputModal(props) {
	const [state, setState] = useState({
		value: props.initialValue,
		valueChanged: false,
		showModal: true
	});

	const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    control
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur'
  });

	const onSubmit = (data) => {
    closeModal(true);
  }

	const closeModal = (isOk) => {
		setState((state) => ({
			...state,
			showModal: null
		}));

		if (isOk) {
			props.onOk(state.valueChanged, state.value);
		}
		else {
			props.onCancel();
		}
	}

	const openModal = () => {
		setState((state) => ({
			...state,
			showModal: true
		}));
	}

	//{React.cloneElement(this.props.children, { loggedIn: this.state.loggedIn })
	return (
		<Modal
			size='mini'
			className='fuseAlignedModal'
			open={state.showModal}
		>
			<Modal.Content>
				<Form size='small' onSubmit={handleSubmit(onSubmit)}>
					<Form.Field>
						<label>{props.label}</label>
						<input name="input_value" className={(errors.input_value) ? "invalid-input-field" : ""} {...register('input_value', { required: true, maxLength: 250 })} value={state.value} onChange={(e) => { setState((state) => ({ ...state, value: e.target.value, valueChanged: true })); }} />
						{errors.input_value && errors.input_value.type === "required" && <p className="validation-error">* Please enter a {props.label}</p>}
						{errors.input_value && errors.input_value.type === "pattern" && <p className="validation-error">* May only contain alphabetical characters</p>}
						{errors.input_value && errors.input_value.type === "maxLength" && <p className="validation-error">* {props.label} may not be more than 250 characters long</p>}
					</Form.Field>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button className='danger' size='mini' onClick={(e) => { closeModal(false); }}>
					Cancel
				</Button>
				<Button className='success' type="submit" size='mini' disabled={errors.input_value}  onClick={(e) => {closeModal(true)}} positive>
					Ok
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

FuseInputModal.defaultProps = {
	label: 'Input:',
	initialValue: '',
	onOk: (valueChanged, returnValue) => { },
	onCancel: () => { }
}

export default FuseInputModal;