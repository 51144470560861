export class TenantRoleDTO{
    PK;
    SK;
    name;
    createdAt;
    UpdatedAt;
    type;
    description;
    tenantId;
    permissions;
    id;

    constructor(role, tenant) {
      if (role.id != null) {
          this.PK = role.PK;
          this.SK = role.SK;
        }
        else {
          this.PK = `${tenant.tenantId}#ROLES`;
          this.SK = `ROLE#`;
        }
        this.id = role.id;
        this.name = role.roleName;
        this.description = role.roleDescription;
        this.type = "TENANTROLE";
        this.tenantId = tenant.tenantId;
        this.permissions = role.permissions;
    }
}