export class GroupUserDTO{
    PK;
    SK;
    GSI4PK;
    GSI4SK;
    id;
    createdAt;
    updatedAt;
    tenantId;
    type;
    name;
    surename;
    email;
    contact_nr;

    constructor(user, group) {
        if (user.id != null) {
            this.PK = user.PK;
            this.SK = user.SK;
            this.GSI4PK = user.GSI4PK;
            this.GSI4SK = user.GSI4SK;
        }
        else{
            this.PK = `${group.tenantId}#GROUP#${group.id}`;
            this.SK = `GROUPUSER#IN#${user.contact_nr}`;
            this.GSI4PK = `GROUPUSER#${user.contact_nr}`;
            this.GSI4SK = `${group.tenantId}#GROUP#${group.id}`;
        }
        this.id = user.id;
        this.type = "GROUPUSER";
        this.tenantId = group.tenantId;
        this.name = user.name;
        this.surname = user.surname;
        this.email = user.email;
        this.contact_nr = user.contact_nr;
    }
}