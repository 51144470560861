import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_QUESTIONAIRE_TYPES_ACTION = 'FETCHING_QUESTIONAIRE_TYPES_ACTION';
export const FETCH_QUESTIONAIRE_TYPES_SUCCESS_ACTION = 'FETCH_QUESTIONAIRE_TYPES_SUCCESS_ACTION';
export const FETCH_QUESTIONAIRE_TYPES_ERROR_ACTION = 'FETCH_QUESTIONAIRE_TYPES_ERROR_ACTION';

export const QUESTIONAIRE_TYPE_ADD_ACTION = 'QUESTIONAIRE_TYPE_ADD_ACTION';
export const QUESTIONAIRE_TYPE_EDIT_ACTION = 'QUESTIONAIRE_TYPE_EDIT_ACTION';
export const QUESTIONAIRE_TYPE_DELETE_ACTION = 'QUESTIONAIRE_TYPE_DELETE_ACTION';

export const fetchQuestionaireTypesAction = () => {
  const queryParams = {
    PK: 'QNAIRETYPES',
    SK: 'QNAIRETYPES',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_QUESTIONAIRE_TYPES_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items[0].qnairetypes;
        dispatch({ type: FETCH_QUESTIONAIRE_TYPES_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_QUESTIONAIRE_TYPES_ERROR_ACTION, err });
      });
  };
}
