export class GroupMessageDTO{
    PK;
    SK;
    id;
    createdAt;
    updatedAt;
    tenantId;
    type;
    message;
    senderData;
    notificationType;
    whatsAppTemplate;
    whatsAppTemplatePlaceholderValues;

    constructor(message, userGroup, senderData) {
        if (message.id != null) {
            this.PK = message.PK;
            this.SK = message.SK;
        }
        else{
            this.PK = `${userGroup.tenantId}#GROUP#${userGroup.id}`;
            this.SK = `NOTIFICATION#`;
        }
        this.id = message.id;
        this.type = "GROUPNOTIFICATION";
        this.notificationType = "groupMessage";
        this.tenantId = userGroup.tenantId;
        this.message = message;
        this.senderData = senderData;
        this.whatsAppTemplate = message.whatsAppTemplate;
        this.whatsAppTemplatePlaceholderValues = message.whatsAppTemplatePlaceholderValues;

    }
}