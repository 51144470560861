import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Container,
  Icon,
  Segment,
  Header,
  Table,
  Label,
  Grid,
  Dimmer,
  Loader,
  Dropdown,
  Input,
  Button,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as AUDITTRAIL_ACTIONS from "../../../actions/auditTrailActions";
import AuditTrailItemDetails from "./AuditTrailItemDetails";
import FuseContentModal from "../../../components/generic/FuseContentModal";

const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
  <Input
    icon="calendar"
    iconPosition="left"
    placeholder="Select Date"
    onClick={onClick}
    value={value}
    ref={ref}
    style={{ borderRadius: "8px", borderColor: "#ccc" }}
  />
));

const AuditTrailPage = (props) => {
  const [state, setState] = useState({
    loading: false,
    denied: false,
    auditTrailItems: [],
    selectedUsers: [],
    selectedActions: [],
    selectedTypes: [],
    startDate: null,
    endDate: null,
    isViewAuditItemOpen: false,
    currentPage: 1,
    itemsPerPage: 25,
  });

  const hasPermissions = (category) => {
    return (
      !_.isEmpty(props.userPermissions) &&
      props.userPermissions.hasOwnProperty(category)
    );
  };

  const isAdmin = () => {
    return (
      hasPermissions("Other") && props.userPermissions["Other"].actions.admin
    );
  };

  const canReadAuditTrailData = () => {
    return (
      (hasPermissions("Audit Trail") &&
        props.userPermissions["Audit Trail"].actions.READ) ||
      isAdmin()
    );
  };

  useEffect(() => {
    if (!_.isEmpty(props.tenant)) {
      if (props.isPermissionsFetched) {
        if (canReadAuditTrailData()) {
          setState({ ...state, loading: true });
          const query = {
            usergroup: props.tenant.name,
          };
          props
            .fetchTenantAuditTrail(query)
            .then(() => {
              props.fetchTenantUsers(props.tenant.name);
              props.fetchTenantQuestionaires(props.tenant);
              setState({ ...state, loading: false });
            })
            .catch(() => {
              setState({
                loading: false,
              });
              toast.error("Failed to fetch tenant audit trail");
            });
        } else {
          setState({
            denied: true,
            loading: false,
          });
          toast.error(
            "You do not have permission to view the tenant audit trail"
          );
        }
      }
    }
  }, [props.tenant, props.isPermissionsFetched]);

  const handleDateChange = (type, date) => {
    setState({ ...state, [type]: date });
  };

  const clearFilters = () => {
    setState({ ...state, loading: true, auditTrailItems: [] });
    const query = {
      usergroup: props.tenant.name,
    };
    props
      .fetchTenantAuditTrail(query)
      .then(() => {
        setState({
          ...state,
          selectedUsers: [],
          selectedQuestionnaire: [],
          selectedActions: [],
          selectedTypes: [],
          startDate: null,
          endDate: null,
          loading: false,
        });
      })
      .catch(() => {
        setState({
          loading: false,
        });
        toast.error("Failed to fetch tenant audit trail");
      });
  };

  const search = () => {
    const query = {
      usergroup: props.tenant.name,
      user: state.selectedUsers,
      type: state.selectedTypes,
      operation: state.selectedActions,
      startDate: state.startDate,
      endDate: state.endDate,
    };
    setState({ ...state, loading: true });
    props
      .fetchTenantAuditTrail(query)
      .then(() => {
        setState({
          ...state,
          loading: false,
        });
      })
      .catch(() => {
        setState({
          loading: false,
        });
        toast.error("Failed to fetch tenant audit trail");
      });
  };

  const opMapping = {
    i: "Create",
    u: "Update",
    d: "Delete",
  };

  const actionsOptions = [
    { key: 1, text: "Create", value: "i" },
    { key: 2, text: "Update", value: "u" },
    { key: 3, text: "Delete", value: "d" },
  ];

  let typeOptions = [
    { key: 1, text: "Business Rule", value: "BRULE" },
    { key: 2, text: "Business Type", value: "BRTYPE" },
    { key: 3, text: "Condition", value: "CONDITION" },
    { key: 4, text: "CV Search Log", value: "CVSEARCHLOG" },
    { key: 5, text: "Distribution Rule", value: "ACTRULE" },
    { key: 6, text: "Export", value: "EXPORT" },
    { key: 7, text: "Group Notification", value: "GROUPNOTIFICATION" },
    { key: 8, text: "Group User", value: "GROUPUSER" },
    { key: 9, text: "KML File", value: "KMLFILE" },
    { key: 10, text: "Lookup", value: "LOOKUP" },
    { key: 11, text: "Question", value: "QUESTION" },
    { key: 12, text: "Questionnaire", value: "QNAIRE" },
    { key: 13, text: "Questionnaire Lookup", value: "LOOKUPQNAIRE" },
    { key: 14, text: "Role", value: "TENANTROLE" },
    { key: 15, text: "Section", value: "SECTION" },
    { key: 16, text: "Tenant", value: "TENANT" },
    { key: 17, text: "User", value: "TENANTUSER" },
    { key: 18, text: "User Role", value: "TENANTUSERROLE" },
  ];

  typeOptions.sort((a, b) => a.text.localeCompare(b.text));

  const reverseTypeMapping = {
    "BRULE": "Business Rule",
    "BRTYPE": "Business Type",
    "CONDITION": "Condition",
    "ACTRULE": "Distribution Rule",
    "EXPORT": "Export",
    "GROUPNOTIFICATION": "Group Notification",
    "GROUPUSER": "Group User",
    "KMLFILE": "KML File",
    "LOOKUP": "Lookup",
    "QUESTION": "Question",
    "QNAIRE": "Questionnaire",
    "LOOKUPQNAIRE": "Questionnaire Lookup",
    "TENANTROLE": "Role",
    "SECTION": "Section",
    "TENANT": "Tenant",
    "TENANTUSER": "User",
    "TENANTUSERROLE": "User Role",
    "CVSEARCHLOG": "CV Search Log"
  };

  const handleTenantUserChange = (event, data) => {
    setState({ ...state, selectedUsers: data.value });
  };

  const handleActionChange = (event, data) => {
    setState({ ...state, selectedActions: data.value });
  };

  const handleTypeChange = (event, data) => {
    setState({ ...state, selectedTypes: data.value });
  };

  const _buildAuitTrailGroup = () => {
    let { auditTrailItems, currentPage, itemsPerPage } = state;

    auditTrailItems = props.auditTrailItems.map((item) => ({
      ...item,
      date: moment(item.date, "YYYYMMDDTHHmmss").toDate(),
    }));

    auditTrailItems.sort((a, b) => b.date - a.date);

    // Pagination calculations
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = auditTrailItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Label style={{ marginBottom: "8px" }}>
                Select A Tenant User:
              </Label>
              <Dropdown
                placeholder="Select User"
                fluid
                search
                selection
                multiple
                options={props.tenantUsers.map((user) => ({
                  key: user.id,
                  text: user.userName,
                  value: user.userName,
                }))}
                onChange={handleTenantUserChange}
                value={state.selectedUsers}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Label style={{ marginBottom: "8px" }}>Action Perfromed:</Label>
              <Dropdown
                placeholder="Select Action"
                fluid
                search
                selection
                multiple
                options={actionsOptions}
                onChange={handleActionChange}
                value={state.selectedActions}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Label style={{ marginBottom: "8px" }}>Item Type:</Label>
              <Dropdown
                placeholder="Select Type"
                fluid
                search
                selection
                multiple
                options={typeOptions}
                onChange={handleTypeChange}
                value={state.selectedTypes}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Label style={{ marginBottom: "8px" }}>Start Date:</Label>
              <DatePicker
                selected={state.startDate}
                onChange={(date) => handleDateChange("startDate", date)}
                endDate={state.endDate}
                maxDate={state.endDate}
                dateFormat="dd-MM-yyyy"
                isClearable
                customInput={<CustomDatePickerInput />}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Label style={{ marginBottom: "8px" }}>End Date:</Label>
              <DatePicker
                selected={state.endDate}
                onChange={(date) => handleDateChange("endDate", date)}
                startDate={state.startDate}
                minDate={state.startDate}
                dateFormat="dd-MM-yyyy"
                isClearable
                customInput={<CustomDatePickerInput />}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Label style={{ marginBottom: "8px" }}>Clear Filters:</Label>
              <div>
                <Button compact className="warning" onClick={clearFilters}>
                  Clear All
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column width={2}>
              <Label style={{ marginBottom: "8px" }}>Search:</Label>
              <div>
                <Button compact className="success" onClick={search}>
                  Search
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {state.loading ? (
          <Dimmer active inverted>
            <Loader>Loading...</Loader>
          </Dimmer>
        ) : currentItems.length > 0 ? (
          <Table compact size="small" striped celled equal width>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="nectaPrimaryBg">
                  Date Modified(DD-MM-YYYY):
                </Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg">
                  Item type:
                </Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg">
                  Action:
                </Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg">
                  User:
                </Table.HeaderCell>
                <Table.HeaderCell className="nectaPrimaryBg">
                  Details:
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {currentItems.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    {moment.utc(item.date).format("DD-MM-YYYY hh:mm:ss a")}
                  </Table.Cell>
                  <Table.Cell>{reverseTypeMapping[item.type] || item.type}</Table.Cell>
                  <Table.Cell>{opMapping[item.operation]}</Table.Cell>
                  <Table.Cell>{item.user}</Table.Cell>
                  <Table.Cell>
                    <FuseContentModal
                      header={`Item: ${item.id}`}
                      content={
                        <AuditTrailItemDetails
                          auditTrailItem={item}
                          bdaction={"add"}
                        />
                      }
                      trigger={
                        <Button
                          compact
                          className="primary"
                          icon="eye"
                          size="mini"
                        >
                          View Item
                        </Button>
                      }
                      size={"fullscreen"}
                      onOpen={() => {
                        setState((state) => ({
                          ...state,
                          isViewAuditItemOpen: true,
                          selectedAuditItem: item,
                        }));
                      }}
                      onClose={() => {
                        setState((state) => ({
                          ...state,
                          isViewAuditItemOpen: false,
                          selectedAuditItem: {},
                        }));
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Label style={{ marginTop: "10px" }}>
            Audit Trail History Unavailable
          </Label>
        )}
        {auditTrailItems.length > itemsPerPage && (
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <Button.Group>
              <Button
                icon
                onClick={() =>
                  setState({ ...state, currentPage: currentPage - 1 })
                }
                disabled={currentPage === 1}
              >
                <Icon name="chevron left" />
              </Button>
              <Button>{currentPage}</Button>
              <Button
                icon
                onClick={() =>
                  setState({ ...state, currentPage: currentPage + 1 })
                }
                disabled={indexOfLastItem >= auditTrailItems.length}
              >
                <Icon name="chevron right" />
              </Button>
            </Button.Group>
          </div>
        )}
      </div>
    );
  };

  if (state.denied) {
    return (
      <Container>
        <Segment>
          <Header icon>
            <Icon name="ban" color="red" />
            You do not have permissions to view the tenant audit trail
          </Header>
        </Segment>
      </Container>
    );
  }

  return (
    // state.loading ? (
    //     <Dimmer active inverted>
    //         <Loader>Loading...</Loader>
    //     </Dimmer>
    // ) : (
    <Container fluid>
      <h3>Audit Trail:</h3>
      <Segment raised>{_buildAuitTrailGroup()}</Segment>
    </Container>
    // )
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenantManagement.activeTenant,
    userPermissions: _.isEmpty(state.authUserRoles.permissions)
      ? {}
      : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,
    tenantUsers: state.userManagement.users,
    tenantQnaires: state.questionaires,
    auditTrailItems: state.auditTrailItems,
  };
};

export default connect(
  mapStateToProps,
  // map dispatch to props
  (dispatch) => ({
    fetchTenantAuditTrail: (query) =>
      dispatch(AUDITTRAIL_ACTIONS.fetchTenantAuditTrail(query)),
    fetchTenantUsers: (tenant) =>
      dispatch(AUDITTRAIL_ACTIONS.fetchTenantUsers(tenant)),
    fetchTenantQuestionaires: (tenant) =>
      dispatch(AUDITTRAIL_ACTIONS.fetchTenantQuestionaires(tenant)),
    fetchTenantQuestionaires: (tenant) =>
      dispatch(AUDITTRAIL_ACTIONS.fetchTenantQuestionaires(tenant)),
  })
)(AuditTrailPage);
