import { AUTHDATA_ADD_ACTION } from "../actions/registrationActions";

export const authDataReducer = (state = [], action) => {
    switch (action.type) {
        case AUTHDATA_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        default:
            return state;
    }
};