import React from "react";
import { Container, Form, Label, Segment } from "semantic-ui-react";
import moment from "moment";

function TransactionDetails(props) {
  const { transaction, currencies } = props;

  const isSuccess = transaction.status === "SUCCESS";
  const isCredit = transaction.operation === "CREDIT";

  const currencyData = currencies[transaction.currency];
  const nextConversion =
    currencyData.denominations.find((denom) => denom.next_conversion)
      ?.next_conversion || 1;

  const CustomLabel = ({ text, className }) => (
    <label className={`custom-label ${className}`}>{text}</label>
  );
  const payerReceiverText = isCredit ? "Payer" : "Receiver";

  const tagMappings = {
    "MESSAGES": "message",
    "QUESTIONNAIRE DELIVERED": "delivery",
    "USER QUESTIONNAIRE REWARD": "user#reward",
    "QUESTIONNAIRE COMPLETED": "qnaireCompleted",
    "SUBSCRIPTION FEES": "subscriptionFee",
    "CV PURCHASES": "cvPurchase",
    "AI ANALYSIS": "aiAnalysis",
  };

  const reverseTagMappings = Object.fromEntries(
    Object.entries(tagMappings).map(([key, value]) => [value, key])
  );

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const amountInBaseCurrency = formatter.format(Math.abs(transaction.amount / nextConversion));

  //   let amountInBaseCurrency = transaction.amount / nextConversion;
  //   amountInBaseCurrency = Math.abs(amountInBaseCurrency);
  //   amountInBaseCurrency = amountInBaseCurrency.toFixed(2);


  return (
    <Container>
      <Segment raised>
        <Form>
          <CustomLabel text="Transaction Details:" className="nectaPrimaryBg" />
          <Form.Group widths={2}>
            <Form.Field>
              <label>Transaction ID:</label>
              <input
                type="text"
                name="transactionId"
                value={transaction.transaction_id}
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label>Date:</label>
              <input
                type="text"
                name="dateReceived"
                value={moment
                  .utc(transaction.date_received)
                  .format("DD-MM-YYYY - hh:mm:ss a")}
                readOnly
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>Currency:</label>
              <input
                type="text"
                name="currency"
                value={transaction.currency}
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label>Amount:</label>
              <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <input
                  type="text"
                  name="amount"
                  value={
                    (transaction.operation === 'DEBIT' ? '- ' : '+ ') +
                    amountInBaseCurrency
                  }
                  readOnly
                  style={{ width: '100%' }}
                />
              </div>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>Description:</label>
              <input
                type="text"
                name="description"
                value={transaction.description || "N/A"}
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label>Operation:</label>
              <input
                type="text"
                name="operation"
                value={transaction.operation}
                readOnly
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>Transaction Type:</label>
              <input
                type="text"
                name="type"
                value={transaction.tag ? (reverseTagMappings[transaction.tag] || transaction.tag) : "N/A"}
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label>Transaction Status:</label>
              <input
                type="text"
                name="status"
                value={isSuccess ? "Successful" : "Failed"}
                readOnly
              />
            </Form.Field>
          </Form.Group>
          <CustomLabel
            text={`${payerReceiverText} Details:`}
            className="nectaPrimaryBg"
          />
          <Form.Group widths={2}>
            <Form.Field>
              <label> Wallet ID:</label>
              <input
                type="text"
                name="targetWalletId"
                value={
                  isCredit
                    ? transaction.source?.wallet_id || "N/A"
                    : transaction.target?.wallet_id || "N/A"
                }
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label> User ID:</label>
              <input
                type="text"
                name="targetUserId"
                value={
                  isCredit
                    ? transaction.source?.user_id || "N/A"
                    : transaction.target?.user_id || "N/A"
                }
                readOnly
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    </Container>
  );
}

export default TransactionDetails;