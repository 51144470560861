import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  Segment,
  Container,
  Button,
  Icon,
  Modal,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import moment from "moment";
import { Storage } from "aws-amplify";
import ImageLink from "./ImageLink";
import FuseCollapsiblePanel from "../../../components/generic/FuseCollapsiblePanel";
import FuseContentModal from '../../../components/generic/FuseContentModal';
import CVSearchLogDetails from './CVSearchLogDetails';

import * as CV_SEARCH_ACTIONS from "../../../actions/cvSearchActions";
import * as BOUGHT_CV_SEARCH_ACTIONS from "../../../actions/boughtCVActions";

export function OwnedCVsPage(props) {
  const [state, setState] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCV, setSelectedCV] = useState(null);
  const [downloadingCV, setDownloadingCV] = useState(null);

  const hasPermissions = (category) => {
    return (
      !_.isEmpty(props.userPermissions) &&
      props.userPermissions.hasOwnProperty(category)
    );
  };

  const isAdmin = () => {
    return (
      hasPermissions("Other") && props.userPermissions["Other"].actions.admin
    );
  };

  const canReadOwnedCVs = () => {
    return (
      (hasPermissions("Owned CVs") &&
        props.userPermissions["Owned CVs"].actions.READ) ||
      isAdmin()
    );
  };

  const canViewOwnedCV = () => {
    return (
      (hasPermissions("Owned CVs") &&
        props.userPermissions["Owned CVs"].actions["View CVs"]) ||
      isAdmin()
    );
  };

  const canDownloadOwnedCV = () => {
    return (
      (hasPermissions("Owned CVs") &&
        props.userPermissions["Owned CVs"].actions["Download CVs"]) ||
      isAdmin()
    );
  };

  useEffect(() => {
    if (!_.isEmpty(props.tenant)) {
      if (props.isPermissionsFetched) {
        if (canReadOwnedCVs()) {
          props.fetchBoughtCVsAction(props.tenant);
        } else {
          toast.error("You do not have permission to view uploaded Kml Files");
        }
      }
    }
  }, [props.tenant]);

  const handleDownload = async (cv) => {
    try {
      setDownloadingCV(cv.id);
      const userQnaire = await props.downloadSelectedCVAction(cv);
      // const userDoc = await props.downloadSelectedCVAction(cv);
      if(userQnaire === null){
        toast.warning("User CV details unavailable. The user might have chosen to delete his personal information.");
        return
      }
      // if (!_.isEmpty(userDoc) && userDoc.key) {
      if (!_.isEmpty(cv) && cv.id) {
        const key = `${process.env.REACT_APP_ENV}/presentations/${cv.number}/${userQnaire.id}.pptx`

        // const s3Key = userDoc.key.replace(/^public\//, "");
        // const signedUrl = await Storage.get(s3Key);
        const signedUrl = await Storage.get(key);
        const response = await fetch(signedUrl);

        if (!response.ok) {
          throw new Error("Failed to fetch the file");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${cv.number}_CV.pptx`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast.success("CV downloaded successfully");
      } else {
        toast.error("No document found for download");
      }
    } catch (error) {
      console.error("Error downloading CV:", error.message);
      toast.error(`Failed to download CV: ${error.message}`);
    } finally {
      setDownloadingCV(null);
    }
  };

  const _buildCVsTable = () => {
    const sortedCVs = _.isEmpty(props.boughtCVs)
      ? []
      : props.boughtCVs.sort((a, b) => {
          const dateA = moment
            .utc(a.createdAt, "YYYYMMDDTHHmmss")
            .format("YYYY-MM-DD");
          const dateB = moment
            .utc(b.createdAt, "YYYYMMDDTHHmmss")
            .format("YYYY-MM-DD");
          return moment(dateB).diff(moment(dateA), "days");
        });

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Number</Table.HeaderCell>
            <Table.HeaderCell>Date Bought</Table.HeaderCell>
            <Table.HeaderCell>Match to Search Query</Table.HeaderCell>
            <Table.HeaderCell>Query Used to Find CV</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedCVs.map((cv) => {
            let headerColor;
            if (cv.matchPercentage == 100) {
              headerColor = '#3A95A4';
            } else if (cv.matchPercentage > 80) {
              headerColor = 'green';
            } else if (cv.matchPercentage > 50) {
              headerColor = '#FFA500';
            } else {
              headerColor = 'red';
            }

            return (
              <Table.Row key={cv.id}>
                <Table.Cell>+{cv.number}</Table.Cell>
                <Table.Cell>
                  {moment.utc(cv.createdAt).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell style={{ paddingBottom: "10px" }}>
                  <span
                    style={{
                      backgroundColor: headerColor,
                      color: "white",
                      borderRadius: "50%",
                      padding: "8px",
                    }}
                  >
                    {cv.matchPercentage}%{" "}
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <FuseContentModal
                    header={`View Search Log: ${moment
                      .utc(cv.createdAt)
                      .format("DD-MM-YYYY hh:mm:ss a")}`}
                    content={
                      <CVSearchLogDetails
                        cvSearchLog={state.selectedSearchLog}
                        bdaction={"view"}
                      />
                    }
                    trigger={
                      <Button className="primary" size="medium">
                        <Icon name="clipboard" /> View Search Query
                      </Button>
                    }
                    onOpen={() => {
                      setState((state) => ({
                        ...state,
                        isViewSearchLogOpen: true,
                        selectedSearchLog: cv.cvSearchQuery,
                      }));
                    }}
                    onClose={() => {
                      setState((state) => ({
                        ...state,
                        isViewSearchLogOpen: false,
                        selectedSearchLog: {},
                      }));
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button icon labelPosition="left" onClick={() => openModal(cv)}>
                    <Icon name="eye" />
                    View User Details
                  </Button>
                  {canDownloadOwnedCV() && (
                    <Button
                      icon
                      labelPosition="left"
                      onClick={() => handleDownload(cv)}
                      loading={downloadingCV === cv.id}
                      disabled={downloadingCV === cv.id}
                    >
                      <Icon name="download" />
                      {downloadingCV === cv.id ? "Downloading..." : "Download CV"}
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };


  const openModal = (cv) => {
    props.fetchSelectedBoughtCVAction(cv);
    // props.checkCVOwnStatusAction(props.tenant, cv);
    setSelectedCV(cv);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCV(null);
    setModalOpen(false);
  };

  const renderCVModal = () => {
    if (!selectedCV || !props.userCV.length > 0) return null;

    const { cvMainSections, cvQuestions, userAnswers } = props;

    // Filter out sections where all questions do not have answers
    const filteredSections = cvMainSections.filter((section) =>
        cvQuestions.some((question) => 
            question.sectionId === section.id && 
            userAnswers.some((answer) => answer.questionId === question.id)
        )
    );

    return (
      <Modal open={modalOpen} onClose={closeModal} size="large">
        <Modal.Header>User Details:</Modal.Header>
        <Modal.Content>
          {filteredSections
            .sort((a, b) => a.sequence - b.sequence)
            .map((section, index) => (
              <div key={section.id}>
                <FuseCollapsiblePanel
                  content={_buildQuestionList(section, section.name)}
                  isCollapsed={true}
                  leadingIcon="book"
                  trailingIcon="angle down"
                  header={section.name}
                />
                {index !== filteredSections.length - 1 && (
                  <div style={{ marginBottom: "10px" }}></div>
                )}
              </div>
            ))}
        </Modal.Content>
        <Modal.Actions>
          {canDownloadOwnedCV() && (
            <Button
              icon
              labelPosition="left"
              onClick={() => handleDownload(selectedCV)}
              loading={downloadingCV === selectedCV.id}
              disabled={downloadingCV === selectedCV.id}
            >
              <Icon name="download" />
              {downloadingCV === selectedCV.id
                ? "Downloading..."
                : "Download CV"}
            </Button>
          )}
          <Button onClick={closeModal} className="warning">
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const _buildQuestionList = (section, sectionName) => {
    const { cvQuestions, cvSubSections, userAnswers } = props;

    const subsections = cvSubSections.filter(
      (subsection) =>
        subsection.parentSectionId === section.id &&
        cvQuestions.some(
          (question) =>
            question.sectionId === subsection.id &&
            userAnswers.some((answer) => answer.questionId === question.id)
        )
    );

    const sectionQuestions = cvQuestions.filter(
      (question) =>
        question.sectionId === section.id &&
        userAnswers.some((answer) => answer.questionId === question.id)
    );

    let combinedQuestionAnswers = sectionQuestions.flatMap((question) => {
      const relatedAnswers = userAnswers.filter(
        (answer) => answer.questionId === question.id && answer.values
      );
    
      return relatedAnswers.length > 0
        ? relatedAnswers.map((answer) => ({
            question,
            answer,
          }))
        : [{ question, answer: null }]; 
    });
    
    // Updated sorting logic
    combinedQuestionAnswers.sort((a, b) => {
      const repeatGroupA = a.answer?.repeatGroup;
      const repeatGroupB = b.answer?.repeatGroup;
    
      // If both items have repeatGroup, sort by repeatGroup first
      if (repeatGroupA && repeatGroupB) {
        if (repeatGroupA !== repeatGroupB) {
          return repeatGroupA - repeatGroupB;
        }
        return a.question.sequence - b.question.sequence;
      }
    
      // If only one of the items has repeatGroup, place the one without repeatGroup later
      if (!repeatGroupA && repeatGroupB) {
        return 1;  // Place b before a
      } else if (repeatGroupA && !repeatGroupB) {
        return -1; // Place a before b
      }
    
      // If neither has repeatGroup, just sort by sequence
      return a.question.sequence - b.question.sequence;
    });
    

    if (sectionQuestions.length === 0 && subsections.length === 0) {
      return null;
    }

    return (
      <div>
          {combinedQuestionAnswers.map(({ question, answer }, index) => (
                    <div key={`${question.id}-${index}`}>
                    <Table celled>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell style={{ width: "90%" }}>
                            Q: {question.question}
                          </Table.Cell>
                        </Table.Row>
                        {answer ? (
                          <Table.Row>
                            <Table.Cell
                              style={{
                                width: "90%",
                                backgroundColor:
                                  question.questionType !== "upload_image"
                                    ? "#b7e0b5"
                                    : "transparent",
                              }}
                            >
                              {question.questionType !== "upload_image" ? (
                                `A: ${answer.values}`
                              ) : (
                                <ImageLink
                                  answer={answer}
                                  questionnaireId={answer.questionaireId}
                                  userQuestionnaireId={answer.userQuestionaireId}
                                />
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row>
                            <Table.Cell
                              style={{ width: "90%", backgroundColor: "#e0e0e0" }}
                            >
                              No answer provided.
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                    {index !== combinedQuestionAnswers.length - 1 && (
                      <div style={{ marginBottom: "10px" }}></div>
                    )}
                  </div>
          ))}

        <div style={{ marginBottom: "10px" }}></div>

        {subsections.map((subsection, index) => (
          <div key={subsection.id}>
            <FuseCollapsiblePanel
              key={subsection.id}
              content={_buildQuestionList(subsection, subsection.name)}
              isCollapsed={true}
              leadingIcon="book"
              trailingIcon="angle down"
              header={subsection.name}
            />
            {index !== subsections.length - 1 && (
              <div style={{ marginBottom: "10px" }}></div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Container fluid>
      <h3>Bought CVs:</h3>
      <Segment>{_buildCVsTable()}</Segment>
      {renderCVModal()}
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    userPermissions: _.isEmpty(state.authUserRoles.permissions)
      ? {}
      : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,
    tenant: state.tenantManagement.activeTenant,
    boughtCVs: state.boughtCVs,
    userCV: state.cvSearch.userCV,
    cvMainSections: state.cvSearch.userCV.filter((item) => item.type === "SECTION" && !item.parentSectionId),
    cvSubSections: state.cvSearch.userCV.filter((item) => item.type === "SECTION" && item.parentSectionId),
    cvQuestions: state.cvSearch.userCV.filter((item) => item.type === "QUESTION" && item.questionType != "no_answer" && item.whatsAppOnly != true),
    userAnswers: state.cvSearch.userCVAnswers.filter((item) => item.type === "ANSWER"),
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch) => ({
    fetchBoughtCVsAction: (tenant) =>
      dispatch(CV_SEARCH_ACTIONS.fetchBoughtCVsAction(tenant)),
    fetchSelectedBoughtCVAction: (cv) =>
      dispatch(BOUGHT_CV_SEARCH_ACTIONS.fetchSelectedBoughtCVAction(cv)),
    downloadSelectedCVAction: (cv) =>
      dispatch(BOUGHT_CV_SEARCH_ACTIONS.downloadSelectedCVAction(cv)),
  })
)(OwnedCVsPage);
