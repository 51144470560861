import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  Form,
  Button,
  Icon,
  Checkbox,
  Divider,
  Dropdown,
  Table,
  Container,
  Dimmer,
  Loader,
  Confirm
} from "semantic-ui-react";
import * as QUESTIONAIRE_ACTIONS from "../../../actions/questionaireActions";
import * as QUESTIONAIRE_TYPE_ACTIONS from "../../../actions/questionaireTypeActions";
import * as QNAIREBR_ACTIONS from "../../../actions/brQnaireActions";
import * as COUNTRY_CODES_ACTION from "../../../actions/countryCodesActions"

export function QuestionaireDetails(props) {
  const [state, setState] = useState({
    chatbotQuestionaireType: { ...props.chatbotQuestionaireType },
    questionaire: { ...props.questionaire },
    showCondition: !_.isEmpty(props.condition),
    condition: {},
    qnaireBRs: { ...props.qnaireBRs },
    showConfirmIsActive: false,
    showConfirmEditIsActive: false,
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = async (data) => {

    const isQuestionnaireActive = props.questionaire.status === "active" || props.questionaire.status === "underReview";

    if (isQuestionnaireActive) {
      setState((state) => ({ ...state, showConfirmEditIsActive: true }));
    } else {
      // Check if the current questionnaire is both active and default
      if (state.questionaire.isActive && state.questionaire.defaultQuestionnaire) {
        // Fetch all active and default questionnaires to check for duplicates
        const activeDefaultQuestionnaires = props.allQuestionaires.filter(
          (questionaire) =>
            (questionaire.isActive || questionaire.status === "underReview") &&
            questionaire.defaultQuestionnaire &&
            questionaire.id !== state.questionaire.id  // Exclude the current questionnaire being edited
        );

        // Check if any of the existing active/default questionnaires have the same activation phrase
        const duplicateActivationPhrase = activeDefaultQuestionnaires.some(
          (questionaire) => questionaire.activationPhrase === state.questionaire.activationPhrase
        );

        if (duplicateActivationPhrase) {
          // Display an error message or handle the duplicate activation phrase scenario
          toast.error("Activation Phrase: '" + state.questionaire.activationPhrase + "' already in use. Please use a different phrase.");
          return;
        }
      }

      // If no duplicate activation phrase, proceed with saving the questionnaire
      props.commitQuestionaire(state.questionaire, props.isNew);
      props.onComplete();
    }
  };

  useEffect(() => {
    props.fetchQuestionaireTypes();
    props.fetchCountryCodes();
    props.fetchQnaireBRs(props.questionaire);
    props.fetchAllQnaires();
  }, []);

  let codeslist = [];

  let codeNone = {
    key: 1,
    value: '',
    text: ' None',
  };
  codeslist.push(codeNone);

  props.countryCodes.forEach((code, index) => {
    code = {
      key: code.key,
      value: code.name,
      text: code.name,
    };
    codeslist.push(code);
  });

  const rootQanireTypes = [
    { key: 1, text: "My Profile", value: "My Profile", Description: "My Profile" },
    { key: 2, text: "My Home and Properties", value: "My Home and Properties", Description: "My Home and Properties" },
    { key: 3, text: "My Skills and Work Experience", value: "My Skills and Work Experience", Description: "My Skills and Work Experience" },
    { key: 4, text: "My Will and Testament", value: "My Will and Testament", Description: "My Will and Testament" },
    { key: 5, text: "POPIA", value: "POPIA", Description: "POPIA" },
  ];

  const onSelectedQuestionaireTypeOption = (value) => {
    setState((prevState) => ({
      ...prevState,
      questionaire: { ...prevState.questionaire, questionaireType: value },
    }));
  };

  const handleWheel = (e) => {
    e.target.blur();
  };

  const questionaireTypesOptions = () => {
  
    let questionaireTypes = [];
  
    if (!_.isEmpty(props.questionaireTypes)) {
      if (props.tenant.isRoot) {
        // Combine rootQanireTypes and props.questionaireTypes based on Name property
        questionaireTypes = [
          ...rootQanireTypes,
          ...props.questionaireTypes.map((item, index) => ({
            key: index + rootQanireTypes.length,
            value: item.Name,
            text: item.Name,
          })),
        ];
      } else {
        // Use only props.questionaireTypes
        questionaireTypes = props.questionaireTypes.map((item, index) => ({
          key: index,
          value: item.Name,
          text: item.Name,
        }));
      }
    }
  
    const defaultValue = state.questionaire.questionaireType
    ? state.questionaire.questionaireType
    : "";
  
    return (
      <Controller
        name="questionaire_type"
        control={control}
        //rules={{ required: "Select a type" }}
        setValue={setValue}
        defaultValue={defaultValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <Dropdown
                className={fieldState.error ? "invalid-input-field" : ""}
                {...field}
                fluid
                placeholder="Select questionnaire type"
                defaultValue={defaultValue}
                search
                selection
                loading={props.fetchingData}
                options={questionaireTypes}
                value={defaultValue}
                onChange={(e, { value }) => {
                  field.onChange(value);
                  // onSelectedQuestionaireTypeOption(value);
                  setState((prevState) => ({
                    ...prevState,
                    questionaire: {
                      ...prevState.questionaire,
                      isActive: false,
                      questionaireType: value
                    },
                  }));
                }}
              />
              {fieldState.invalid && (
                <p className="validation-error">* Select a type</p>
              )}
            </div>
          );
        }}
      />
    );
  };

  const questionaireReward = () => {
    return (
      <Form.Field>
        <input
          name="questionaire_reward"
          className={errors.questionaire_reward ? "invalid-input-field" : ""}
          {...register("questionaire_reward", {
            min: { value: 0, message: "* Reward amount cannot be less than 0" }
          })}
          type="number"
          placeholder="Enter a reward amount"
          value={state.questionaire.qnaireReward / 100}
          min={0}
          onChange={(e) => {
            const inputValue = e.target.value;
            const inputInRand = inputValue * 100;
            setState((prevState) => ({
              ...prevState,
              questionaire: { ...prevState.questionaire, qnaireReward: inputInRand },
            }));
          }}
          onWheel={handleWheel}
        />
        {errors.questionaire_reward && (
          <p className="validation-error">{errors.questionaire_reward.message}</p>
        )}
      </Form.Field>
    );
  };
  
  

  // const countryCodeDropDown =() => {
  //   const defaultValue = state.questionaire.countryCode
  //     ? state.questionaire.countryCode
  //     : "";

  //     return (
  //       <Controller
  //         name="questionnaire_countrycode"
  //         control={control}
  //         rules={{ required: false }}
  //         setValue={setValue}
  //         defaultValue={defaultValue}
  //         render={({ field, fieldState, formState }) => {
  //           return (
  //             <div>
  //               <Dropdown
  //                 className={fieldState.invalid ? "invalid-input-field" : ""}
  //                 {...field}
  //                 placeholder="Select country code"
  //                 fluid
  //                 selection
  //                 search
  //                 options={codeslist}
  //                 value={defaultValue}
  //                 onChange={(e, { value }) => {
  //                   setState((state) => ({
  //                     ...state,
  //                     questionaire: {
  //                       ...state.questionaire,
  //                       countryCode: value,
  //                     },
  //                   }));
  //                   field.onChange(value);
  //                 }}
  //               />
  //             </div>
  //           );
  //         }}
  //       />
  //     );
  // }

  const QnaireBRItems = () => {
    if (!props.fetchingData) {
      if (!_.isEmpty(props.qnaireBRs)) {
        return (
          <Table compact selectable size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className='nectaPrimaryBg'>Business Rules linked to Questionnaire</Table.HeaderCell>
                <Table.HeaderCell className='nectaPrimaryBg' />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.qnaireBRs.map(qnaireBR => {
                return (
                  <Table.Row key={qnaireBR.id}>
                    <Table.Cell>{qnaireBR.brName}</Table.Cell>
                  </Table.Row>
                );
              })
              }
            </Table.Body>
          </Table>
        );
      }
    }
    else {
      return (
        <div style={{ height: "200px", margin: "10px 0px" }}>
          <Dimmer inverted active>
            <Loader content='Loading' />
          </Dimmer>
        </div>
      );
    }
  }

  const confirmEditActivate = () => {
    let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";

    return (
      <Confirm
        header='Warning:'
        content={contentMessage}
        open={state.showConfirmEditIsActive}
        onCancel={() => setState((state) => ({ ...state, showConfirmEditIsActive: false }))}
        onConfirm={() => {
          // Check if the current questionnaire is both active and default
          if (state.questionaire.isActive && state.questionaire.defaultQuestionnaire) {
            // Fetch all active and default questionnaires to check for duplicates
            const activeDefaultQuestionnaires = props.questionaires.filter(
              (questionaire) =>
                questionaire.isActive &&
                questionaire.defaultQuestionnaire &&
                questionaire.id !== state.questionaire.id  // Exclude the current questionnaire being edited
            );

            // Check if any of the existing active/default questionnaires have the same activation phrase
            const duplicateActivationPhrase = activeDefaultQuestionnaires.some(
              (questionaire) => questionaire.activationPhrase === state.questionaire.activationPhrase
            );

            if (duplicateActivationPhrase) {
              // Display an error message or handle the duplicate activation phrase scenario
              toast.error("Activation Phrase: '" + state.questionaire.activationPhrase + "' already in use. Please use a different phrase.");
              return;
            }
          }
          state.questionaire.status = "inActive"
          // If no duplicate activation phrase, proceed with saving the questionnaire
          props.commitQuestionaire(state.questionaire, props.isNew);
          props.onComplete();
        }}
      />
    );
  }

  const confirmActivate = () => {
  let contentMessage = "Once the questionnaire is activated and saved, it will undergo a review process. If the review is successful, the questionnaire will become active.";

  if (state.questionaire.isActive) {
    contentMessage = "Are you sure you want to deactivate the questionnaire? The questionnaire will need to undergo the review process again if re-activated.";
  }
    
  return (
    <Confirm
      header='Warning:'
      content={contentMessage}
      open={state.showConfirmIsActive}
      onCancel={() => setState((state) => ({ ...state, showConfirmIsActive: false }))}
      onConfirm={() => {
        setState((prevState) => ({
          ...prevState,
          questionaire: {
            ...prevState.questionaire,
            isActive: !prevState.questionaire.isActive,
          },
          showConfirmIsActive: false
        }));
      }}
    />
  );
}


  return (
    <Form size="small" onSubmit={handleSubmit(onSubmit)}>
			{confirmActivate()}
			{confirmEditActivate()}
      <Form.Field>
        <label>Questionnaire Name:</label>
        <input
          name="questionaire_name"
          className={errors.questionaire_name ? "invalid-input-field" : ""}
          {...register("questionaire_name", { required: true, pattern: /^[A-Za-z0-9-\s]*$/, })}
          placeholder="Enter questionnaire name"
          value={state.questionaire.name}
          onChange={(e) => {
            const inputValue = e.target.value
            if (inputValue.length <= 50) {
              setState((prevState) => ({
                ...prevState,
                questionaire: { ...prevState.questionaire, name: inputValue },
              }));
            }
          }}
        />
        {errors.questionaire_name &&
          errors.questionaire_name.type === "required" && (
            <p className="validation-error">* Please enter a name</p>
          )}
        {errors.questionaire_name &&
          errors.questionaire_name.type === "pattern" && (
            <p className="validation-error">
              * May only contain alphanumerical characters and "-"
            </p>
          )}
        {errors.questionaire_name &&
          errors.questionaire_name.type === "maxLength" && (
            <p className="validation-error">
              * Name may not be more than 50 characters long
            </p>
          )}
      </Form.Field>
      {!props.isNew && (
        <Form.Field>
          <label>Powerpoint Reference:</label>
          <input
            className="visible-input"
            value={state.questionaire.pptRef}
            disabled
          />
        </Form.Field>
      )}
      <Form.Field>
        <label>Description:</label>
        <textarea
          name="questionaire_description"
          className={
            errors.questionaire_description ? "invalid-input-field" : ""
          }
          {...register("questionaire_description", {
            required: true,
            maxLength: 512,
          })}
          placeholder="Enter a description for the questionnaire"
          value={state.questionaire.description}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              questionaire: {
                ...prevState.questionaire,
                description: e.target.value,
              },
            }));
          }}
        />
        {errors.questionaire_description &&
          errors.questionaire_description.type === "required" && (
            <p className="validation-error">* Please enter a description</p>
          )}
        {errors.questionaire_description &&
          errors.questionaire_description.type === "pattern" && (
            <p className="validation-error">
              * May only contain alphanumerical characters
            </p>
          )}
        {errors.questionaire_description &&
          errors.questionaire_description.type === "maxLength" && (
            <p className="validation-error">
              * Description may not be more than 512 characters long
            </p>
          )}
      </Form.Field>
      <Form.Field>
        <label>Questionnaire Category:</label>
        {questionaireTypesOptions()}
      </Form.Field>
      <Form.Field>
        <label>Questionnaire Completion Reward(R):</label>
        {questionaireReward()}
      </Form.Field>
      <Form.Field>
        <Checkbox
          toggle
          label="Users can have more than one"
          checked={!!state.questionaire.multiple}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              questionaire: {
                ...prevState.questionaire,
                multiple: !prevState.questionaire.multiple,
              },
            }));
          }}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          toggle
          label="Default WhatsApp Questionnaire"
          checked={!!state.questionaire.defaultQuestionnaire}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              questionaire: {
                ...prevState.questionaire,
                defaultQuestionnaire:
                  !prevState.questionaire.defaultQuestionnaire,
              },
            }));
          }}
        />
      </Form.Field>
      {state.questionaire.defaultQuestionnaire && (
        <>
          {/* <Form.Field>
            <label>Select a Country Code:</label>
            {countryCodeDropDown()}
          </Form.Field> */}

          <Form.Field>
            <label>Activation Phrase:</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '5px' }}>Hello</div>
              <input
                name="activationPhrase"
                className={errors.activationPhrase ? "invalid-input-field" : ""}
                {...register("activationPhrase", {
                  required: true,
                  maxLength: 45,
                })}
                placeholder="Enter the rest of the activation phrase"
                value={state.questionaire.activationPhrase?.replace(/^Hello /i, '')} // Remove "Hello " case-insensitive
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const cleanedValue = inputValue.toLowerCase() === 'hello' ? '' : inputValue; // Remove "Hello" if entered
                  if (cleanedValue.length <= 50) {
                    setState((prevState) => ({
                      ...prevState,
                      questionaire: {
                        ...prevState.questionaire,
                        activationPhrase: `Hello ${cleanedValue}`,
                      },
                    }));
                  }
                }}
              />
            </div>
            {errors.activationPhrase &&
              errors.activationPhrase.type === "required" && (
                <p className="validation-error">* Please enter an activation phrase</p>
              )}
            {errors.activationPhrase &&
              errors.activationPhrase.type === "maxLength" && (
                <p className="validation-error">
                  * Activation phrase may not be more than 50 characters long
                </p>
              )}
          </Form.Field>
        </>
      )}
      <Form.Field>
      <Checkbox
        toggle
        label="Is Active"
        disabled={props.isNew}
        checked={state.questionaire.isActive || state.questionaire.status === "underReview"}
        onChange={() => {
          // Check if there is already an active questionnaire for the current type in rootQanireTypes
          const isExistingActive = rootQanireTypes.some(
            (rootQuestionaire) =>
              rootQuestionaire.value === state.questionaire.questionaireType &&
              props.questionaires.some(
                (questionaire) =>
                  questionaire.isActive &&
                  questionaire.questionaireType === state.questionaire.questionaireType
              )
          );

          if (isExistingActive) {
            // Deactivate the toggle and show an error message
            setState((prevState) => ({
              ...prevState,
              questionaire: {
                ...prevState.questionaire,
                isActive: false,
              },
            }));
            toast.error("There is already an active questionnaire of type: " + state.questionaire.questionaireType);
          } else if (state.questionaire.status === "underReview") {
            // Toggle isActive
            setState((prevState) => ({
              ...prevState,
              questionaire: {
                ...prevState.questionaire,
                status: 'inActive'
              },
            }));
          } else {
             setState((state) => ({ ...state, showConfirmIsActive: true }));
          }
        }}
      />
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Anonymise Data"
          toggle
          checked={!!state.questionaire.anonymisedData}
          disabled={state.questionaire.alwaysAnonymise}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              questionaire: {
                ...prevState.questionaire,
                anonymisedData: !prevState.questionaire.anonymisedData,
              },
            }));
          }}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          toggle
          label="Can Edit Questionnaire Once Completed"
          checked={!!state.questionaire.canEditQnaire}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              questionaire: {
                ...prevState.questionaire,
                canEditQnaire: !prevState.questionaire.canEditQnaire,
              },
            }));
          }}
        />
      </Form.Field>
      <Container>
        {QnaireBRItems()}
      </Container>
      {props.tenant.isRoot && (
        <Form.Field>
          <Checkbox
            toggle
            label="Is Base Questionnaire"
            checked={!!state.questionaire.isBaseQnaire}
            onChange={() => {
              setState((prevState) => ({
                ...prevState,
                questionaire: {
                  ...prevState.questionaire,
                  isBaseQnaire: !prevState.questionaire.isBaseQnaire,
                },
              }));
            }}
          />
        </Form.Field>
      )}
      <Divider />
      <Button.Group floated="right">
        <Button
          className="danger"
          onClick={() => {
            if (_.isEmpty(props.onComplete)) {
              props.onComplete();
            }
          }}
        >
          <Icon name="remove" /> Cancel
        </Button>
        <Button type="submit" className="success">
          <Icon name="checkmark" /> Ok
        </Button>
      </Button.Group>
      <br/>
    </Form>
  );
}

const mapStateToProps = (state, ownProps) => {
  let questionaire = {};
  let isNew = false;
  let newQuestionaire = {
    name: "",
    description: "",
    countryCode: "",
    multiple: false,
    defaultQuestionnaire: false,
    pptRef: "",
    isActive: false,
    isBaseQnaire: false,
    canEditQnaire: false,
    hasPptTemplates: false,
    // anonymisedData: false,
    questionaireType: "",
    status: "inActive",
    activationPhrase: "",
    // qnaireReward: 0,
    tenantId: state.tenantManagement.activeTenant.tenantId,
  };

  if (ownProps.questionaire === undefined) {
    questionaire = newQuestionaire;
    isNew = true;
  } else {
    questionaire = _.isEmpty(ownProps.questionaires)
      ? newQuestionaire
      : Object.values(
        ownProps.questionaires.filter(
          (questionaire) => questionaire.id === ownProps.questionaire.id
        )
      )[0];
  }
  let questionaireTypes = _.isEmpty(state.questionaireTypes)?[]:Object.values(state.questionaireTypes);
  let chatbotQuestionaireType = {};

  if (!_.isEmpty(questionaireTypes)) {
    chatbotQuestionaireType = questionaireTypes.filter(
      (questionaireType) => questionaireType.Name.toLowerCase() === "chatbot"
    )[0];
   }
  

  let qnaireBRs = _.isEmpty(state.qnaireBRs) ? [] : Object.values(state.qnaireBRs.filter((qnaireBR) => qnaireBR.qnaireId === questionaire.id));

  return {
    fetchingData: state.fetchingData === true,
    questionaire: questionaire,
    questionaireTypes: state.questionaireTypes,
    chatbotQuestionaireType: chatbotQuestionaireType,
    questionaires: state.questionaires,
    allQuestionaires: state.allQuestionnaires,
    isNew: isNew,
    qnaireBRs: qnaireBRs,
    countryCodes: _.isEmpty(state.countryCodes) ? [] : _.sortBy(state.countryCodes,'countryCode'),
    tenant: state.tenantManagement.activeTenant,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    fetchQuestionaireTypes: () => dispatch(QUESTIONAIRE_TYPE_ACTIONS.fetchQuestionaireTypesAction()),
    fetchCountryCodes: () => dispatch(COUNTRY_CODES_ACTION.fetchCountryCodesAction()),
    commitQuestionaire: (questionaire, isNewItem) => dispatch(QUESTIONAIRE_ACTIONS.questionaireSaveAction(questionaire, isNewItem)),
    fetchAllQnaires: () => dispatch(QUESTIONAIRE_ACTIONS.fetchAllQnairesAction()),
    fetchQnaireBRs: (questionaire) => dispatch(QNAIREBR_ACTIONS.fetchbRQnairesAction(questionaire)),
  })
)(QuestionaireDetails);
