import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Container,
  Form,
  Button,
  Icon,
  Segment,
  Checkbox,
  Header,
  Divider,
} from "semantic-ui-react";

import * as TOS_ACTIONS from "../../../actions/tosActions"
import _ from "lodash";

export function TosPage(props) {
  const [tenant, setTenant] = useState({
    ...props.tenant,
    subAgreement: false,
  });

  const hasPermissions = (category) => {
    return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
  };

  const isAdmin = () => {
    return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
  };

  useEffect(() => {
    if (props.tenant) {
      if (props.isPermissionsFetched) {
        if (isAdmin()) {
          setTenant({
            ...props.tenant,
            subAgreement: false,
          });
        } else {
          setTenant({
            denied: true,
            loading: false,
          });
        }
      }
    }
  }, [props.tenant]);

  const {
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = () => {
    const now = new Date();
    const formattedDate = now.getFullYear() +
      String(now.getMonth() + 1).padStart(2, '0') +
      String(now.getDate()).padStart(2, '0') + 'T' +
      String(now.getHours()).padStart(2, '0') +
      String(now.getMinutes()).padStart(2, '0') +
      String(now.getSeconds()).padStart(2, '0');
  
    const updatedTenant = {
      ...tenant,
      subAgreeDate: formattedDate
    };
    props.saveTenantAction(updatedTenant);
  };

  const handleAgreementChange = () => {
    setTenant((prevTenant) => ({
      ...prevTenant,
      subAgreement: !prevTenant.subAgreement,
    }));
  };

  const _buildTOSGroup = () => {
    if (!props.tenant) {
      return null;
    }
  
    return (
      <Segment>
        <Header as='h4' className="nectaPrimaryBg" textAlign='center' style={{ padding: '10px', color: 'white' }}>
          Subscriber Agreement
        </Header>
        <Form.Field style={{ margin: '20px 0' }}>
          <p style={{ fontSize: '1.1em', marginBottom: '15px' }}>
            Please read and agree to the Subscriber Agreement before proceeding. The full agreement can be found at{" "}
            
            <a
              href="https://wakamoso.africa/subscriber-agreement/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              Subscriber Agreement - Wakamoso Africa
            </a>
          </p>
          <Divider hidden/>
          <Checkbox
            label={
              <label style={{ fontSize: '1.1em' }}>
                I have read and agree to the Subscriber Agreement
              </label>
            }
            onChange={handleAgreementChange}
            checked={tenant.subAgreement}
          />
        </Form.Field>
      </Segment>
    );
  };

  const isSaveDisabled = !(tenant.subAgreement);

  if (tenant.denied) {
    return (
      <Container>
        <Segment placeholder>
          <Header icon textAlign='center'>
            <Icon name='exclamation triangle' color="orange" />
            <Header.Content>
              Access Restricted
              <Header.Subheader>
                The Wakamoso Subscriber Agreement has been updated.
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Segment.Inline>
            <p>
              The system administrator has not yet accepted the Subscriber Agreement.
              Access to the system will be restored once the administrator has reviewed and
              agreed to the new terms.
            </p>
          </Segment.Inline>
        </Segment>
      </Container>
    );
  }

  return (
    <Container>
      <h3>Wakamoso Usage Terms:</h3>
      <Segment raised>
        <Form
          size="medium"
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingBottom: "25px" }}
        >
          {_buildTOSGroup()}
          <Button.Group floated="right">
            <Button
              type="submit"
              className="success"
              disabled={isSaveDisabled}
            >
              <Icon name="checkmark" /> Accept and Continue
            </Button>
          </Button.Group>
        </Form>
      </Segment>
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: state.tenantManagement.activeTenant,
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,
  };
};

export default connect(
  mapStateToProps,
  (dispatch) => ({
    saveTenantAction: (tenant) => dispatch(TOS_ACTIONS.saveTenantAction(tenant)),
    resetState: () => dispatch(TOS_ACTIONS.resetState()),
  })
)(TosPage);
