import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Table,
  Grid,
  Button,
  Container,
  Confirm,
  Dimmer,
  Loader,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import FuseContentModal from "../../../components/generic/FuseContentModal";
import _ from "lodash";

import * as ACTIVATION_RULES from "../../../actions/activationRulesActions";
import ActivationRuleDetails from "./ActivationRuleDetails";
import ActivationRuleLogDetails from "./ActivationRuleLogDetails";

export function ActivationRulesPage(props) {
  const [state, setState] = useState({
    selectedActivationRule: {},
    isEditActivationRuleOpen: false,
    deleteActRuleCandidate: {},
    showConfirmDeleteActRule: false,
  });

  const hasPermissions = (category) => {
    return (
      !_.isEmpty(props.userPermissions) &&
      props.userPermissions.hasOwnProperty(category)
    );
  };

  const isAdmin = () => {
    return (
      hasPermissions("Other") && props.userPermissions["Other"].actions.admin
    );
  };

  const canReadActivationRule = () => {
    return (
      (hasPermissions("Distribution Rules") &&
        props.userPermissions["Distribution Rules"].actions.READ) ||
      isAdmin()
    );
  };

  const canCreateActivationRule = () => {
    return (
      (hasPermissions("Distribution Rules") &&
        canReadActivationRule() &&
        props.userPermissions["Distribution Rules"].actions.CREATE) ||
      isAdmin()
    );
  };

  const canUpdateActivationRule = () => {
    return (
      (hasPermissions("Distribution Rules") &&
        canReadActivationRule() &&
        props.userPermissions["Distribution Rules"].actions.UPDATE) ||
      isAdmin()
    );
  };

  const canDeleteActivationRule = () => {
    return (
      (hasPermissions("Distribution Rules") &&
        canReadActivationRule() &&
        props.userPermissions["Distribution Rules"].actions.DELETE) ||
      isAdmin()
    );
  };

  const canExecuteActivationRule = () => {
    return (
      (hasPermissions("Distribution Rules") &&
        canReadActivationRule() &&
        props.userPermissions["Distribution Rules"].actions.EXECUTE) ||
      isAdmin()
    );
  };

  useEffect(() => {
    if (!_.isEmpty(props.tenant)) {
      if (props.isPermissionsFetched) {
        if (canReadActivationRule()) {
          props.fetchActivationRulesAction(props.tenant);
        } else {
          toast.error("You do not have permission to view distribution rules.");
        }
      }
    }
  }, [props.tenant]);

  const confirmDeleteActRule = () => {
    return (
      <Confirm
        content={`Are you sure you want to delete the following distribution rule: ${state.deleteActRuleCandidate.name}?`}
        open={state.showConfirmDeleteActRule}
        onCancel={() =>
          setState((state) => ({ ...state, showConfirmDeleteActRule: false }))
        }
        onConfirm={() => {
          props.deleteActivationRuleAction(
            state.deleteActRuleCandidate,
            props.tenant
          );
          setState((state) => ({
            ...state,
            showConfirmDeleteActRule: false,
            deleteActRuleCandidate: {},
          }));
        }}
      />
    );
  };

  const openConfirmModal = (activationRule) => {
    setState((prevState) => ({
      ...prevState,
      activationRule: activationRule,
      showExecuteActivationRule: true,
    }));
  };

  const closeConfirmModal = () => {
    setState((prevState) => ({
      ...prevState,
      showExecuteActivationRule: false,
    }));
  };

  const executeActivationRule = () => {
    return (
      <Confirm
        content={`Do you wish to push this questionnaire to you selected audience now? *Note this is a once off execution`}
        open={state.showExecuteActivationRule}
        onCancel={closeConfirmModal}
        onConfirm={() => {
          props.executeActivartionRuleAction(state.activationRule);
          setState((state) => ({ ...state, showExecuteActivationRule: false }));
        }}
      />
    );
  };

  const activationRulesRender = () => {
    if (!props.fetchingData) {
      if (!props.activationRules || props.activationRules.length === 0) {
        return (
          <Segment>
            <Header icon>
              <Icon name="file" color="black" />
              There Aren't Any Distribution Rules
            </Header>
          </Segment>
        );
      }

      const sortedActivationRules = [...props.activationRules].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      return (
        <Table size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                className="nectaPrimaryBg"
                style={{ width: "10%" }}
              >
                Rule Name
              </Table.HeaderCell>
              <Table.HeaderCell
                className="nectaPrimaryBg"
                style={{ width: "20%" }}
              >
                Rule Description
              </Table.HeaderCell>
              <Table.HeaderCell
                className="nectaPrimaryBg"
                style={{ width: "10%" }}
              >
                Rule Status
              </Table.HeaderCell>
              <Table.HeaderCell
                className="nectaPrimaryBg"
                style={{ width: "10%" }}
              >
                Execute (Once)
              </Table.HeaderCell>
              <Table.HeaderCell
                className="nectaPrimaryBg"
                style={{ width: "10%" }}
              >
                Rule Logs
              </Table.HeaderCell>
              <Table.HeaderCell
                className="nectaPrimaryBg"
                style={{ width: "20%" }}
              >
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedActivationRules.map((activationRule, index) => (
              <Table.Row
                key={`${activationRule.id}.${index}`}
                className="clickable"
                active={activationRule.id === state.selectedActivationRule.id}
              >
                <Table.Cell>{activationRule.name}</Table.Cell>
                <Table.Cell>{activationRule.description}</Table.Cell>
                <Table.Cell>
                  {activationRule.status === "active" ? "Active" : "Inactive"}
                </Table.Cell>
                <Table.Cell>
                  {canExecuteActivationRule() && (
                    <Button
                      compact
                      className="warning"
                      size="small"
                      icon="trash"
                      onClick={() => openConfirmModal(activationRule)}
                    >
                      Run Now
                    </Button>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <FuseContentModal
                    header={`Logs For Distribution Rule: ${activationRule.name}`}
                    content={
                      <ActivationRuleLogDetails
                        activationRule={activationRule}
                        bdaction={"view"}
                      />
                    }
                    trigger={
                      <Button
                        compact
                        className="primary"
                        icon="eye"
                        size="small"
                      >
                        View Logs
                      </Button>
                    }
                    onOpen={() => {
                      setState((state) => ({
                        ...state,
                        isEditActivationRuleOpen: true,
                        selectedActivationRule: activationRule,
                      }));
                    }}
                    onClose={() => {
                      setState((state) => ({
                        ...state,
                        isEditActivationRuleOpen: false,
                        selectedActivationRule: {},
                      }));
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  {canUpdateActivationRule() && (
                    <FuseContentModal
                      header={`Edit Distribution Rule: ${activationRule.name}`}
                      content={
                        <ActivationRuleDetails
                          activationRule={activationRule}
                          bdaction={"edit"}
                        />
                      }
                      trigger={
                        <Button
                          compact
                          className="warning"
                          icon="edit"
                          size="small"
                        >
                          Edit Rule
                        </Button>
                      }
                      onOpen={() => {
                        setState((state) => ({
                          ...state,
                          isEditActivationRuleOpen: true,
                          selectedActivationRule: activationRule,
                        }));
                      }}
                      onClose={() => {
                        setState((state) => ({
                          ...state,
                          isEditActivationRuleOpen: false,
                          selectedActivationRule: {},
                        }));
                      }}
                    />
                  )}
                  {canDeleteActivationRule() && (
                    <Button
                      compact
                      className="danger"
                      icon="trash"
                      size="small"
                      onClick={(e) => deleteActivationRule(e, activationRule)}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <div style={{ height: "200px", margin: "10px 0px" }}>
          <Dimmer inverted active>
            <Loader content="Loading" />
          </Dimmer>
        </div>
      );
    }
  };

  const deleteActivationRule = (e, rule) => {
    e.stopPropagation();
    setState((state) => ({
      ...state,
      deleteActRuleCandidate: rule,
      showConfirmDeleteActRule: true,
    }));
  };

  return (
    <Container fluid>
      {confirmDeleteActRule()}
      <Grid>
        <Grid.Row>
          <Grid.Column width="8">
            <h3>Distribution Rules:</h3>
          </Grid.Column>
          <Grid.Column width="8">
            {canCreateActivationRule() && (
              <FuseContentModal
                header={`Create Distribution Rule`}
                content={<ActivationRuleDetails bdaction={"add"} />}
                trigger={
                  <Button
                    compact
                    className="success"
                    icon="add"
                    size="mini"
                    floated="right"
                    content="Create Rule"
                  />
                }
                onClose={() => {
                  setState((state) => ({
                    ...state,
                    selectedActivationRule: state.selectedActivationRule,
                  }));
                }}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {activationRulesRender()}
      {executeActivationRule()}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  activationRules: state.activationRules.rules,
  fetchingData: state.fetchingData === true,
  tenant: state.tenantManagement.activeTenant,
  userPermissions: _.isEmpty(state.authUserRoles.permissions)
    ? {}
    : state.authUserRoles.permissions,
  isPermissionsFetched: state.isPermissionsFetched === true,
});

export default connect(mapStateToProps, (dispatch) => ({
  fetchActivationRulesAction: (tenant) =>
    dispatch(ACTIVATION_RULES.fetchActivationRulesAction(tenant)),
  deleteActivationRuleAction: (rule, tenant) =>
    dispatch(ACTIVATION_RULES.deleteActivationRuleAction(rule, tenant)),
  executeActivartionRuleAction: (rule) =>
    dispatch(ACTIVATION_RULES.executeActivartionRuleAction(rule)),
}))(ActivationRulesPage);
