import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Checkbox,
  Dropdown
} from "semantic-ui-react";

import * as BRULE_ACTIONS from "../../../actions/businessRuleActions";
import * as QNAIRE_ACTIONS from "../../../actions/questionaireActions";
import * as SECTION_ACTIONS from "../../../actions/sectionActions";
import * as QUESTION_ACTIONS from "../../../actions/questionActions";
import { toast } from "react-toastify";

export function BusinessRuleAttrDetails1(props) {
  const [state, setState] = useState({
    bRule: { ...props.bRule },    
    bRuleAttr: { ...props.bRuleAttr },
    bRuleAttrs: { ...props.bRuleAttrs},
    isChanged: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  
  useEffect(() => {
    props.fetchQnairesAction(props.tenantId);
  }, []);

  useEffect(() => {
    props.fetchSectionsAction(state.bRuleAttr.qnaireId, props.tenantId);
  }, [state.bRuleAttr.qnaireId]);

  useEffect(() => {
    props.fetchQuestionsAction(state.bRuleAttr.qnaireId, state.bRuleAttr.qnaireSectionId, props.tenantId);
  }, [state.bRuleAttr.qnaireSectionId]);

  const bRuleAttrsObjectsFromString = (json) => {
		if (!_.isEmpty(json)) {
			return JSON.parse(json);
		}
		else {
			return [];
		}
	}

	const bRuleAttrsObjectsToString = (valueQnaireObjectArray) => {
		return JSON.stringify(valueQnaireObjectArray);
	}

  const onSubmit = (data) => {
    let values = [];
    let newVal = [];

    let toCommit = props.bRule;
    if (!_.isEmpty(toCommit.bRuleAttrs))
      values = toCommit.bRuleAttrs;

    if (!_.isEmpty(values))
      newVal = bRuleAttrsObjectsFromString(values);

    let newBruleAttr = state.bRuleAttr;

    if (!_.isEmpty(data.questionnaire_name)) {
      const selectedQnaire = questionaireList.filter((qnaire) => qnaire.key === data.questionnaire_name).map((qnaire) => ({text: qnaire.text}));
      if (!_.isEmpty(selectedQnaire))
        newBruleAttr.qnaireName = selectedQnaire[0].text;
    }
    if (!_.isEmpty(data.section_name)) {
      const selectedSection = sectionList.filter((section) => section.key === data.section_name).map((section) => ({text: section.text}));
      if (!_.isEmpty(selectedSection))
        newBruleAttr.qnaireSectionName = selectedSection[0].text;
    }
    if (!_.isEmpty(data.question_name)) {
      const selectedQuest = questionList.filter((quest) => quest.key === data.question_name).map((quest) => ({text: quest.text}));
      if (!_.isEmpty(selectedQuest))
        newBruleAttr.qnaireQuestionName = selectedQuest[0].text;
    }
  
    if (!_.isEmpty(newBruleAttr)) {
      if (newBruleAttr.isUpload && newBruleAttr.uploadFile !== undefined) {
        newBruleAttr.uploadFilename = newBruleAttr.uploadFile.name;
      }
    }

    newVal[props.valIndex] = newBruleAttr;
      
    let strVals = bRuleAttrsObjectsToString(_.orderBy(newVal,['attrName']));
    toCommit.bRuleAttrs = strVals;
    setState((state) => ({ ...state, bRuleAttrs: newVal, isChanged: false}));

    props.saveBRuleAction(toCommit, newBruleAttr, props.tenantId);
    if (_.isEmpty(props.onComplete)) {
      props.onComplete();
    }
  };

  const questionaireList = _.orderBy(props.questionaires
    .filter((qnaire) => qnaire.status === "active")
    .map((qnaire) => ({
      key: qnaire.id,
      text: qnaire.name,
      value: qnaire.id,
    })), ['text']);

  const questionnaireDropdown = () => {
    var defaultValue = state.bRuleAttr.qnaireId ? state.bRuleAttr.qnaireId : "";

    return (
      <Controller
        name="questionnaire_name"
        control={control}
        rules={{ required: false }}
        setValue={setValue}
        defaultValue={defaultValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <Dropdown
                className={fieldState.invalid ? "invalid-input-field" : ""}
                {...field}
                placeholder="Select a questionnaire"
                fluid
                selection
                search
                options={questionaireList}
                value={state.bRuleAttr.qnaireId}
                onChange={(e, { value }) => {
                  setState((state) => ({
                    ...state,
                    bRuleAttr: {
                      ...state.bRuleAttr,
                      qnaireId: value,
                    },
                    isChanged: true,                  
                  }));
                  field.onChange(value);
                }}
              />
              {fieldState.invalid && (
                <p className="validation-error">* Select a questionnaire</p>
              )}
            </div>
          );
        }}
      />
    );
  };

  const sectionList = _.orderBy(props.sections
    .filter((section) => section.questionaireId === state.bRuleAttr.qnaireId)
    .map((section) => ({
      key: section.id,
      text: section.name,
      value: section.id,
    })), ['text']);

  const sectionDropdown = () => {
    var defaultValue = state.bRuleAttr.qnaireSectionId ? state.bRuleAttr.qnaireSectionId : "";

    return (
      <Controller
        name="section_name"
        control={control}
        rules={{ required: false }}
        setValue={setValue}
        defaultValue={defaultValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <Dropdown
                className={fieldState.invalid ? "invalid-input-field" : ""}
                {...field}
                placeholder="Select a section"
                fluid
                selection
                search
                options={sectionList}
                value={state.bRuleAttr.qnaireSectionId}
                onChange={(e, { value }) => {
                  setState((state) => ({
                    ...state,
                    bRuleAttr: {
                      ...state.bRuleAttr,
                      qnaireSectionId: value,
                    },
                    isChanged: true,                  
                  }));
                  field.onChange(value);
                }}
              />
              {fieldState.invalid && (
                <p className="validation-error">* Select a section</p>
              )}
            </div>
          );
        }}
      />
    );
  };

  const questionList = _.orderBy(props.questions
    .filter((question) => question.questionaireId === state.bRuleAttr.qnaireId && question.sectionId === state.bRuleAttr.qnaireSectionId)
    .map((question) => ({
      key: question.id,
      text: question.question,
      value: question.id,
    })), ['text']);

  const questionDropdown = () => {
    var defaultValue = state.bRuleAttr.qnaireQuestionId ? state.bRuleAttr.qnaireQuestionId : "";

    return (
      <Controller
        name="question_name"
        control={control}
        rules={{ required: false }}
        setValue={setValue}
        defaultValue={defaultValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <Dropdown
                className={fieldState.invalid ? "invalid-input-field" : ""}
                {...field}
                placeholder="Select a question"
                fluid
                selection
                search
                options={questionList}
                value={state.bRuleAttr.qnaireQuestionId}
                onChange={(e, { value }) => {
                  setState((state) => ({
                    ...state,
                    bRuleAttr: {
                      ...state.bRuleAttr,
                      qnaireQuestionId: value,
                    },
                    isChanged: true,                  
                  }));
                  field.onChange(value);
                }}
              />
              {fieldState.invalid && (
                <p className="validation-error">* Select a question</p>
              )}
            </div>
          );
        }}
      />
    );
  };

  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
    <Form.Field>
      <label>Attribute Name</label>
      <input
        name="brAttrName"
        className={errors.question_text ? "invalid-input-field" : ""}
        {...register("brAttrName")}
        placeholder="Attribute Name"
        value={state.bRuleAttr.attrName}
      />
    </Form.Field>
    <Form.Field>
    <Checkbox
          toggle
          label="Is Mandatory?"
          checked={!!state.bRuleAttr.isMandatory}
          disabled
        />
    </Form.Field>
    {!state.bRuleAttr.isUpload && (
      <Form.Field> 
        <label>Questionnaire</label>
        {questionnaireDropdown()}
      </Form.Field>)}
    {!state.bRuleAttr.isUpload && (
      <Form.Field>
        <label>Section</label>
        {sectionDropdown()}
      </Form.Field>)}
    {!state.bRuleAttr.isUpload && (
      <Form.Field>
        <label>Question</label>
        {questionDropdown()}
      </Form.Field>)}
    {state.bRuleAttr.isUpload && (
      <Form.Field>
        <Checkbox
            toggle
            label="Is Upload?"
            checked={!!state.bRuleAttr.isUpload}
            disabled
          />
      </Form.Field>)}
    {state.bRuleAttr.isUpload && (
      <Form.Field>
          {state.bRuleAttr && state.bRuleAttr.uploadFilename && (
            <label>Uploaded File: {state.bRuleAttr.uploadFilename}</label>
          )}
        <input
          className={errors.uploadFile ? "invalid-input-field" : ""}
          {...register("uploadFile", { required: false })}
          id="hiddenFileInput"
          name="uploadFile"
          type="file"
          accept=".pptx"
          // style={{ display: "none" }}
          onChange={(e) =>{
            const pptFile = e.target.files[0];
            if (pptFile && pptFile.name.endsWith('.pptx') && pptFile.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
              setState((state) => ({
                ...state,
                bRuleAttr: {
                  ...state.bRuleAttr,
                  uploadFile: e.target.files[0],
                },
                isChanged: true,                  
              }))
            }else{
              toast.error("Please upload a powerpoint file.");
            }
          }}
        />
        {/* <button
          type="button"
          onClick={() => {
            document.getElementById("hiddenFileInput").click();
          }}
        >
          Choose File
        </button> */}
        {errors.uploadFile && errors.uploadFile.type === "required" && (
          <p className="validation-error">* Please select a .pptx file</p>
        )}
      </Form.Field>
   )}

    <Divider />
    <Button.Group floated="right">
      <Button 
        className="danger"
        onClick={() => {
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success" disabled={!state.isChanged}>
        <Icon name="checkmark" /> Ok
      </Button>
    </Button.Group>
  </Form>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    bRule: ownProps.bRule,
		bRules: (_.isEmpty(state.bRules)) ? [] : Object.values(state.bRules),
    bRuleAttrs: ownProps.bRuleAttrs,  
    bRuleAttr: ownProps.bRuleAttrs[ownProps.valIndex],
    isNew: false,
    valIndex: ownProps.valIndex,
    questionaires: _.isEmpty(state.questionaires) ? [] : Object.values(state.questionaires),
    sections: _.isEmpty(state.sections) ? [] : Object.values(state.sections),
    questions: _.isEmpty(state.questions) ? [] : Object.values(state.questions),
    tenantId: state.tenantManagement.activeTenant.tenantId
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    fetchQnairesAction: (tenantId) => dispatch(QNAIRE_ACTIONS.fetchQuestionairesAction(tenantId)),
    fetchSectionsAction: (qnaireId, tenantId) => dispatch(SECTION_ACTIONS.fetchSectionListWsAction(qnaireId, tenantId)),
    fetchQuestionsAction: (qnaireId, sectionId, tenantId) => dispatch(QUESTION_ACTIONS.fetchQuestionsAction(qnaireId, sectionId, tenantId)),
		saveBRuleAction: (bRule, bRuleAttr, tenantId) => dispatch(BRULE_ACTIONS.bRuleSaveAction(bRule, bRuleAttr, tenantId)),
  })
  )(BusinessRuleAttrDetails1);
