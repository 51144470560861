import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import {
  Form,
  Button,
  Icon,
  Divider,
  Dropdown,
} from "semantic-ui-react";

import * as BRTYPE_ACTIONS from "../../../actions/businessRuleTypeActions";
import * as MICROSERVICE_ACTION from "../../../actions/microServiceAction"

export function BrTypeDetails(props) {
  const [state, setState] = useState({
    brType: { ...props.brType },
    isChanged: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  // useEffect(() => {
  //   props.fetchMircoServices();
  // }, [])

  const onSubmit = (data) => {
    let toCommit = state.brType;
  
    // Check if the microService is not "Validation"
    if (toCommit.microService !== "Validation") {
      toCommit.targetQnaireId = ""; // Set targetQnaireId to an empty string
      toCommit.targetQnaireName = "";
    } else if (!_.isEmpty(data.questionnaire_name)) {
      const selectedqnaire = questionaireList.filter(
        (qnaire) => qnaire.key === data.questionnaire_name
      ).map((qnaire) => ({ text: qnaire.text }));
  
      if (!_.isEmpty(selectedqnaire)) {
        toCommit.targetQnaireName = selectedqnaire[0].text;
      } else {
        toCommit.targetQnaireName = "";
      }
    } else {
      toCommit.targetQnaireName = "";
    }
  
    setState((state) => ({ ...state, isChanged: false }));
  
    props.saveBrTypeAction(toCommit, props.tenantId);
    if (_.isEmpty(props.onComplete)) {
      props.onComplete();
    }
  };
  
  var qnaireNone = {
    key: '1',
    value: '',
    text: ' None',
  };

  var questionaireList = []
  questionaireList.push(qnaireNone);
  questionaireList = questionaireList.concat(_.orderBy(props.questionaires
    .map((qnaire) => ({
      key: qnaire.id,
      text: qnaire.name,
      value: qnaire.id,
    })), ['text']));

  const pptxTypes = [
    { key: uuidv4(), text: "CV", value: "CV" },
    { key: uuidv4(), text: "Will", value: "Will" },
  ];

    const questionnaireDropdown = () => {
      var defaultValue = state.brType.targetQnaireId ? state.brType.targetQnaireId : "";
    
      // Show the Form.Field (including label and dropdown) only when Micro Service is "Validation"
      if (state.brType.microService === "Validation") {
        return (
          <Form.Field>
            <label>Link to Questionnaire Name</label>
            <Controller
              name="questionnaire_name"
              control={control}
              rules={{ required: true }}
              setValue={setValue}
              defaultValue={defaultValue}
              render={({ field, fieldState, formState }) => {
                return (
                  <div>
                    <Dropdown
                      className={fieldState.invalid ? "invalid-input-field" : ""}
                      {...field}
                      placeholder="Select a questionnaire"
                      fluid
                      selection
                      search
                      options={questionaireList}
                      value={state.brType.targetQnaireId}
                      onChange={(e, { value }) => {
                        setState((state) => ({
                          ...state,
                          brType: {
                            ...state.brType,
                            targetQnaireId: value,
                          },
                        }));
                        setState((state) => ({ ...state, isChanged: true }));
                        field.onChange(value);
                      }}
                    />
                    {fieldState.invalid && (
                      <p className="validation-error">* Select a questionnaire</p>
                    )}
                  </div>
                );
              }}
            />
          </Form.Field>
        );
      }else if(state.brType.microService === "Powerpoint Generation"){
        return (
          <Form.Field>
            <label>Select Powerpoint Type</label>
            <Controller
              name="pptx_type"
              control={control}
              rules={{ required: true }}
              setValue={setValue}
              defaultValue={defaultValue}
              render={({ field, fieldState, formState }) => {
                return (
                  <div>
                    <Dropdown
                      // className={fieldState.invalid ? "invalid-input-field" : ""}
                      {...field}
                      placeholder="Powerpoint type"
                      fluid
                      selection
                      search
                      options={pptxTypes}
                      value={state.brType.pptxType}
                      onChange={(e, { value }) => {
                        setState((state) => ({
                          ...state,
                          brType: {
                            ...state.brType,
                            pptxType: value,
                          },
                        }));
                        setState((state) => ({ ...state, isChanged: true }));
                        field.onChange(value);
                      }}
                    />
                    {/* {fieldState.invalid && (
                      <p className="validation-error">* Select a pptx type</p>
                    )} */}
                  </div>
                );
              }}
            />
          </Form.Field>
        )
      }
      return null; // If Micro Service is not "Validation," return null to hide the Form.Field
    };
    

  const microcServiceDorpDown = () => {
    if (!props.microservices || props.microservices.length === 0) {
      return null;
    }
  
    const defaultValue = state.brType.microService ? state.brType.microService : "";
  
    const microServicesList = [
      // Add a "None" option with an empty string value
      {
        key: 'none',
        text: 'None',
        value: '',
      },
      ..._.orderBy(
        props.microservices[0].microservices.map((service) => ({
          key: service.id,
          text: service.name,
          value: service.name,
        })),
        ['text']
      )
    ];
  
    return (
      <Controller
        name="micro_service"
        control={control}
        rules={{ required: true }}
        setValue={setValue}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <div>
            <Dropdown
              className={fieldState.invalid ? "invalid-input-field" : ""}
              {...field}
              placeholder="Select a micro service"
              fluid
              selection
              search
              options={microServicesList}
              onChange={(e, { value }) => {
                setState((prevState) => ({
                  ...prevState,
                  brType: {
                    ...prevState.brType,
                    microService: value,
                  },
                  isChanged: true,
                }));
                field.onChange(value);
              }}
            />
            {fieldState.invalid && (
              <p className="validation-error">* Select a micro service</p>
            )}
          </div>
        )}
      />
    );
  };
  
  
  
  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
      <Form.Field>
        <label>Business Rule Type</label>
        <input
          name="bRuleType"
          className={errors.question_text ? "invalid-input-field" : ""}
          {...register("bRuleType", { required: true, maxLength: 128 })}
          placeholder="Business Rule Type"
          value={state.brType.brType}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              brType: { ...prevState.brType, brType: e.target.value },
            }));
            setState((state) => ({ ...state, isChanged: true }));
          }}
        />
        {errors.bRuleType && errors.bRuleType.type === "required" && (
          <p className="validation-error">* Please enter a type</p>
        )}
        {errors.question_text && errors.bRuleType.type === "pattern" && (
          <p className="validation-error">
            * May only contain alphabetical characters
          </p>
        )}
        {errors.question_text && errors.bRuleType.type === "maxLength" && (
          <p className="validation-error">
            * Name may not be more than 128 characters long
          </p>
        )}
      </Form.Field>
      <Form.Field>
        <label>Business Rule Type Description</label>
        <input
          name="bRuleTypeDesc"
          className={errors.question_text ? "invalid-input-field" : ""}
          {...register("bRuleTypeDesc", { required: false, maxLength: 256 })}
          placeholder="Business Rule Type Description"
          value={state.brType.brTypeDesc}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              brType: { ...prevState.brType, brTypeDesc: e.target.value },
            }));
            setState((state) => ({ ...state, isChanged: true }));
          }}
        />
        {errors.bRuleTypeDesc && errors.bRuleTypeDesc.type === "required" && (
          <p className="validation-error">* Please enter a type</p>
        )}
        {errors.question_text && errors.bRuleTypeDesc.type === "pattern" && (
          <p className="validation-error">
            * May only contain alphabetical characters
          </p>
        )}
        {errors.question_text && errors.bRuleTypeDesc.type === "maxLength" && (
          <p className="validation-error">
            * Name may not be more than 256 characters long
          </p>
        )}
      </Form.Field>
      <Form.Field>
        <label>Select Micro Service</label>
        {microcServiceDorpDown()}
      </Form.Field>
      <Form.Field>
        {questionnaireDropdown()}
      </Form.Field>
      <Divider />
      <Button.Group floated="right">
        <Button
          className="danger"
          onClick={() => {
            console.log(props.onComplete);
            if (_.isEmpty(props.onComplete)) {
              props.onComplete();
            }
          }}
        >
          <Icon name="remove" /> Cancel
        </Button>
        <Button type="submit" className="success" disabled={!state.isChanged}>
          <Icon name="checkmark" /> Ok
        </Button>
      </Button.Group>
    </Form>
  );
}


const mapStateToProps = (state, ownProps) => {
  var brType = {};
  var isNew = false;
  var newBrType = {
    brType: '',
    brTypeDesc: '',
    targetQnaireId: '',
    targetQnaireName: '',
    microService: '',
    brTypeAttributes: []
  };

  if (ownProps.braction === 'add') {
    brType = newBrType;
    isNew = true;
  } else {
    brType = _.isEmpty(ownProps.brType)
      ? newBrType
      : ownProps.brType;
    isNew = false;
  }

  return {
    brType: brType,
    isNew: isNew,
    questionaires: (_.isEmpty(state.questionaires)) ? [] : Object.values(state.questionaires),
    microservices: (_.isEmpty(state.microServices)) ? [] : Object.values(state.microServices),
    tenantId: state.tenantManagement.activeTenant.tenantId
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    saveBrTypeAction: (brType, tenantId) => dispatch(BRTYPE_ACTIONS.brTypeSaveAction(brType , tenantId)),
    // fetchMircoServices: () => dispatch(MICROSERVICE_ACTION.fetchMicroServiceAction()),
  })
)(BrTypeDetails);
