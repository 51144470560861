import {
	FETCH_ITEMS_SUCCESS_ACTION,CLEAR_LASTEVALUATEDKEY
} from '../actions/userGroupsActions';

export const LastEvaluatedKeyReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_ITEMS_SUCCESS_ACTION:
			const newKey = action.payload;
			return newKey;
        case CLEAR_LASTEVALUATEDKEY:
            return null;
		default:
			return state;
	}
};