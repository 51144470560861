import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { LookUpQnaireDTO } from './DTOs/LookUpQnaireDTO';
import { createItemWs,updateItemWs } from '../webservices/saveWS';
import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_LOOKUPSQNAIRE_ACTION = 'FETCHING_LOOKUPSQNAIRE_ACTION';
export const FETCH_LOOKUPSQNAIRE_SUCCESS_ACTION = 'FETCH_LOOKUPSQNAIRE_SUCCESS_ACTION';
export const FETCH_LOOKUPSQNAIRE_ERROR_ACTION = 'FETCH_LOOKUPSQNAIRE_ERROR_ACTION';

export const LOOKUPQNAIRE_ADD_ACTION = 'LOOKUPQNAIRE_ADD_ACTION';
export const LOOKUPQNAIRE_EDIT_ACTION = 'LOOKUPQNAIRE_EDIT_ACTION';
export const LOOKUPQNAIRE_DELETE_ACTION = 'LOOKUPQNAIRE_DELETE_ACTION';

export const fetchLookupsQnaireAction = (questionaireId, tenantId) => {
  const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${questionaireId}`,
    SK: 'LOOKUPQNAIRE%23',
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_LOOKUPSQNAIRE_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_LOOKUPSQNAIRE_ERROR_ACTION, err });
      });
  };
}

export const lookupQnaireSaveAction = (lookupQnaire, tenantId) => {
  return (dispatch, getState) => {
    const saveObject = new LookUpQnaireDTO(lookupQnaire, tenantId);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (lookupQnaire.id === undefined) {
      saveObject.status = 'active'
      saveObject.values = [];
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: LOOKUPQNAIRE_ADD_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The lookup questionnaire item has been added successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to add lookup questionnaire item please try again');
        })
    }
    else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: LOOKUPQNAIRE_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The lookup questionnaire item has been updated successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to update lookup questionnaire item please try again');
        })
    }
  }
}

export const lookupQnaireDeleteAction = (lookup) => {
  return (dispatch, getState) => {
    const saveObject = new LookUpQnaireDTO(lookup);
    saveObject.status = 'isDeleted';
    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: LOOKUPQNAIRE_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success('The lookup questionnaire item has been deleted successfully');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error('Failed to delete lookup questionnaire item please try again');
      })
  }
}
