import React, { useState, useEffect, useRef } from 'react';
import { Button, Divider, Form, TextArea } from 'semantic-ui-react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import * as TEST_QNAIRE_ACTIONS from "../../../../actions/testQuestionnaireActions"

export function TestQuestionnaireModal(props) {
    const [state, setState] = useState({
        questionaire: { ...props.questionaire },
        userResponse: '', 
        sentMessages: [],
        questions: [],
        currentQuestionType: '',
        isDisabled: false
    });

    const scrollableContainerRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setState({ ...state, userResponse: props.questionaire.activationPhrase || '' });
    }, [props.questionaire.activationPhrase]);

    useEffect(() => {
        if (state.currentQuestionType === "no_answer") {
            noAnswerQuestion();
        }else if(state.currentQuestionType === "endofqnaire"){
            let qnaireData = {
                PK: `USER#${props.userData[0].userName}`,
                SK: `QNAIRE#${props.questionaire.id}`,
                status: "isDeleted"
            }

            props.deleteTestQnaireAction(qnaireData);
            
            setState(prevState => ({
                ...prevState,
                userqnaire_id: '', 
                userResponse: '',
                currentQuestionType: '',
                isDisabled: true
            }));
        }
    }, [state.currentQuestionType, state.questions]);

    useEffect(() => {
        scrollToBottom();
    }, [state.questions]);

    const scrollToBottom = () => {
        scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
    };

    const handleInputChange = (e, { value }) => {
        setState({ ...state, userResponse: value });
    };

    const handleSubmit = async () => {    
        if (state.userResponse.trim() === '') {
            toast.error('Please enter a message.')
            return;
        }

        setState(prevState => ({
            ...prevState,
            disabled: true
        }));

        await fetchQuestion();
    };

    // Function to fetch the initial question
    const fetchQuestion = async () => {
        setState({ ...state, isDisabled: true });
        let questionData;
        if( !_.isEmpty(state.userqnaire_id) && state.userResponse !== ""){
            const qnaireData = {
                "from_value": props.userData[0].userName,
                "tenant_id": props.tenantId,
                "invoke_environ": "webapp",
                "qnaire_id": state.questionaire.id,
                "userqnaire_id": state.userqnaire_id,
                "answer": state.userResponse
            };
            questionData = await props.getNextQuestionAction(qnaireData, setLoading);
        } else {
            const qnaireData = {
                "from_value": props.userData[0].userName,
                "tenant_id": props.tenantId,
                "invoke_environ": "webapp",
                "qnaire_id": state.questionaire.id,
            };
            questionData = await props.executeStepFunction(qnaireData, setLoading);
        }

        if(questionData == undefined){
            toast.error("Error simulation the questionnaire. Please ensure all questions that are marked as conditional have conditions.")
        }
        else{
            setState(prevState => ({
                ...prevState,
                sentMessages: [...prevState.sentMessages, state.userResponse],
                questions: [
                    ...prevState.questions, 
                    { 
                        text: questionData.next_question, 
                        type: questionData.next_question_type,
                        mcVals:  questionData.mc_vals || []
                    }
                ],
                userqnaire_id: questionData.userqnaire_id || state.userqnaire_id, 
                userResponse: '',
                currentQuestionType: questionData.next_question_type,
                isDisabled: false
            }));
        }
    };

    const noAnswerQuestion = async () => {
        setState({ ...state, isDisabled: true });

        const qnaireData = {
            "from_value": props.userData[0].userName,
            "tenant_id": props.tenantId,
            "invoke_environ": "webapp",
            "qnaire_id": state.questionaire.id,
            "userqnaire_id": state.userqnaire_id,
            "answer": ""
        };

        const questionData = await props.getNextQuestionAction(qnaireData, setLoading);

        if(questionData == undefined){
            toast.error("Error simulation the questionnaire. Please try again or contact support.")
        }
        else{
            setState(prevState => ({
                ...prevState,
                sentMessages: [...prevState.sentMessages, "..."],
                questions: [
                    ...prevState.questions, 
                    { 
                        text: questionData.next_question, 
                        type: questionData.next_question_type,
                        mcVals:  questionData.mc_vals || []
                    }
                ],
                userqnaire_id: state.userqnaire_id, 
                userResponse: '',
                currentQuestionType: questionData.next_question_type,
                isDisabled: false
            }));
        }
    }

    return (
        <div>
            <div>
                <div ref={scrollableContainerRef} style={{ height: '300px', overflowY: 'auto', padding: '10px' }}>
                    {state.sentMessages.map((message, index) => (
                        <React.Fragment key={index}>
                            <div className="chat-bubble user-bubble">{message}</div>
                            {state.questions[index] && (
                                <div className="chat-bubble bot-bubble">
                                    {state.questions[index].text === "endofqnaire" ? "End of Questionnaire" : state.questions[index].text}
                                </div>
                            )}
                            {state.questions[index] && (state.questions[index].type === "mc_sel_one" || state.questions[index].type === "multi_select" || state.questions[index].type === "link_to_qnaire") && (
                                <div className="chat-bubble bot-bubble">
                                    {state.questions[index].mcVals.map((option, optionIndex) => (
                                        <div key={optionIndex}>{optionIndex + 1}: {option}</div>
                                    ))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <Form reply>
                <TextArea
                    value={state.userResponse}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit();
                        }
                    }}
                    placeholder="Type your response..."
                    style={{ minHeight: 15, marginBottom: "10px" }}
                />
                <Button loading={loading} floated='right' content='Send' labelPosition='left' icon='envelope' primary onClick={handleSubmit} disabled={state.userResponse.trim() === '' || state.disabled} />
            </Form>
        </div>
    );  
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.authUserRoles.userRoles,
        tenantId: state.tenantManagement.activeTenant.tenantId,
    }
}

export default connect(
    mapStateToProps,
    (dispatch, ownProps) => ({
        executeStepFunction: (data, setLoading) => dispatch(TEST_QNAIRE_ACTIONS.executeStepFunctionAction(data, setLoading)),
        getNextQuestionAction: (data, setLoading) => dispatch(TEST_QNAIRE_ACTIONS.getNextQuestionAction(data, setLoading)),
        deleteTestQnaireAction: (qnaireData, userQnaireData) => dispatch(TEST_QNAIRE_ACTIONS.deleteTestQnaireAction(qnaireData, userQnaireData)),
    })
)(TestQuestionnaireModal);
