import { fetchAuditTrailItemsWS } from "../webservices/auditTrailWS";
import { fetchItemsWS } from "../webservices/fetchWS";
import { SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { toast } from "react-toastify";

export const FETCHING_AUDITTRAIL_ACTION = "FETCHING_AUDITTRAIL_ACTION";
export const FETCH_AUDITTRAIL_SUCCESS_ACTION = "FETCH_AUDITTRAIL_SUCCESS_ACTION";
export const FETCH_AUDITTRAIL_ERROR_ACTION = "FETCH_AUDITTRAIL_ERROR_ACTION";

export const fetchTenantAuditTrail = (query) => {
    const queryParams = {};

    if (query.usergroup) queryParams.usergroup = query.usergroup;
    if (query.user) queryParams.user = query.user;
    if (query.type) queryParams.type = query.type;
    if (query.operation) queryParams.operation = query.operation;
    if (query.startDate) queryParams.startDate = query.startDate.toISOString();
    if (query.endDate) queryParams.endDate = query.endDate.toISOString();

    return (dispatch, getState) => {
        dispatch({ type: FETCHING_AUDITTRAIL_ACTION });
        return fetchAuditTrailItemsWS('get-auditTrail', queryParams, getState)
            .then((result) => {
                let items = result;
                dispatch({ type: FETCH_AUDITTRAIL_SUCCESS_ACTION, payload: items });
                return items;
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_AUDITTRAIL_ERROR_ACTION, err });
                throw err;
            });
    };
};

export const FETCHING_TENANT_USERS_ACTION = 'FETCHING_TENANT_USERS_ACTION';
export const FETCH_TENANT_USERS_SUCCESS_ACTION = 'FETCH_TENANT_USERS_SUCCESS_ACTION';
export const FETCH_TENANT_USERS_ERROR_ACTION = 'FETCH_TENANT_USERS_ERROR_ACTION';

export const fetchTenantUsers = (tenant) => {
    const queryParams = {
        PK: `TENANT%23${tenant}`,
        SK: `USER`,
    };
    
    return (dispatch, getState) => {
			dispatch({ type: FETCHING_TENANT_USERS_ACTION });
			fetchItemsWS('get-dynamodb', queryParams, getState)
			.then((result) => {
					let items = result.Items.filter((item) => item.status !== 'isDeleted');
					dispatch({ type: FETCH_TENANT_USERS_SUCCESS_ACTION, payload: items });
                    return items
				})
				.catch((err) => {
					console.log(err);
					dispatch({ type: FETCH_TENANT_USERS_ERROR_ACTION, payload: err })
				});
    }
}

export const FETCHING_QUESTIONAIRES_ACTION = 'FETCHING_QUESTIONAIRES_ACTION';
export const FETCH_QUESTIONAIRES_SUCCESS_ACTION = 'FETCH_QUESTIONAIRES_SUCCESS_ACTION';
export const FETCH_QUESTIONAIRES_ERROR_ACTION = 'FETCH_QUESTIONAIRES_ERROR_ACTION';

export const fetchTenantQuestionaires = (tenant) => {
    const queryParams = {
      GSI2PK: `${tenant.id}%23QNAIRES`,
      GSI2SK: 'QNAIRE',
    };
  
    return async (dispatch, getState) => {
      dispatch({ type: FETCHING_QUESTIONAIRES_ACTION});
  
      try {
        const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_QUESTIONAIRES_SUCCESS_ACTION, payload: returnItems });

      } catch (err) {
        console.log(err);
        dispatch({ type: FETCH_QUESTIONAIRES_ERROR_ACTION, err });
      }
    };
  };