import {
  FETCH_QUESTIONAIRES_SUCCESS_ACTION,
  QUESTIONAIRE_ADD_ACTION,
  QUESTIONAIRE_EDIT_ACTION,
  QUESTIONAIRE_DELETE_ACTION,
  FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION,
  FETCH_ALL_QUESTIONAIRES_SUCCESS_ACTION,
} from '../actions/questionaireActions';

import {
  FETCH_BASE_QNAIRES_SUCCESS_ACTION,
} from '../actions/tenantPermissionsActions';

import{
  FETCH_REVIEW_QNAIRES_SUCCESS_ACTION
} from '../actions/reviewQuestionaireActions';

export const quesionairesResolver = (state = [], action) => {
  switch(action.type){
      case FETCH_QUESTIONAIRES_SUCCESS_ACTION:
          const newQuestionaires = action.payload;
          return newQuestionaires;
      case QUESTIONAIRE_ADD_ACTION:
          const clonedState = [...state];
          clonedState.push(action.payload);
          return clonedState;
      case QUESTIONAIRE_EDIT_ACTION:
          const newQuestionaire = action.payload;
          const questionairesCopy = Object.values({...state}).filter(questionaire => !(newQuestionaire.id === questionaire.id)); //Creates a copy...a new state
          questionairesCopy.push(newQuestionaire);
          return questionairesCopy;
      case QUESTIONAIRE_DELETE_ACTION:
          return state.filter(element => element.id !== action.payload);
      default:
          return state;
  }
};

export const tenZeroBaseQuestionnaireReducer = (state = [], action) => {
  switch(action.type){
    case FETCH_BASE_QNAIRES_SUCCESS_ACTION:
      const newQuestionaires = action.payload;
      return newQuestionaires;
    default:
      return state;
  }
};

export const tenantReviewQuestionnaireReducer = (state = [], action) => {
  switch(action.type){
    case FETCH_REVIEW_QNAIRES_SUCCESS_ACTION:
      const newQuestionaires = action.payload;
      return newQuestionaires;
    default:
      return state;
  }
};

export const baseQuestionnaireReducer = (state = [], action) => {
  switch(action.type){
    case FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION:
      const newQuestionaires = action.payload;
      return newQuestionaires;
    default:
      return state;
  }
};

export const allQuestionnairesReducer = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_QUESTIONAIRES_SUCCESS_ACTION:
      const newQuestionaires = action.payload;
      return newQuestionaires;
    default:
      return state;
  }
};