import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Table, Button, Container, Grid, Confirm, Dimmer, Loader, Divider, Checkbox, Tab } from 'semantic-ui-react';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import PlaceholderSegment from '../../../components/generic/PlaceholderSegment';
import RoleDetails from './RoleDetails';
import RoleUserDetails from './RoleUserDetails';
import RolePermissionDetails from './RolePermissionDetails';
import * as ROLE_ACTIONS from '../../../actions/tenantRolesActions';
import { toast } from 'react-toastify';

export function ManageRolesPage(props) {
	const [state, setState] = useState({
		selectedRole: {},
		selectedPermission: {},
		isEditRoleOpen: false,
		//DELETE ROLE
		deleteRoleCandidate: {},
		showConfirmDeleteRole: false,
		//DELETE ROLE USER
		deleteRoleUserCandidate: {},
		showConfirmDeleteRoleUser: false,
		//DELETE ROLE PERMISSION
		deleteRolePermissionCandidate: {},
		showConfirmDeleteRolePermission: false,
	});

	const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

	//Roles
	const canReadRoles = () => {
		return hasPermissions('Manage Roles') && props.userPermissions['Manage Roles'].actions.READ || isAdmin()
	}

	const canCreateRoles = () => {
		return hasPermissions('Manage Roles') && canReadRoles() && props.userPermissions['Manage Roles'].actions.CREATE ||isAdmin()
	}

	const canUpdateRoles = () => {
		return hasPermissions('Manage Roles') && canReadRoles() && props.userPermissions['Manage Roles'].actions.UPDATE || isAdmin()
	}

	const canDeleteRoles = () => {
		return hasPermissions('Manage Roles') && canReadRoles() && props.userPermissions['Manage Roles'].actions.DELETE || isAdmin()
	}

	//Users assigned to role
	const canReadRolesUsers = () => {
		return hasPermissions('Manage Roles') && canReadRoles() && props.userPermissions['Manage Roles'].actions['VIEW USERS'] || isAdmin()
	}

	const canCreateRolesUsers = () => {
		return hasPermissions('Manage Roles') && canReadRolesUsers() && props.userPermissions['Manage Roles'].actions['ADD USER'] || isAdmin()
	}

	const canDeleteRolesUsers = () => {
		return hasPermissions('Manage Roles') && canReadRolesUsers() && props.userPermissions['Manage Roles'].actions['DELETE USER'] || isAdmin()
	}

	//Permissions assigned to role
	const canReadRolesPermissions = () => {
		return hasPermissions('Manage Roles') && canReadRoles() && props.userPermissions['Manage Roles'].actions['VIEW PERMISSIONS'] || isAdmin()
	}

	const canCreateRolesPermissions = () => {
		return hasPermissions('Manage Roles') &&  props.userPermissions['Manage Roles'].actions['ADD PERMISSIONS'] || isAdmin()
	}

	const canUpdateRolesPermissions = () => {
		return hasPermissions('Manage Roles') &&  props.userPermissions['Manage Roles'].actions['UPDATE PERMISSIONS'] || isAdmin()
	}

	const canDeleteRolesPermissions = () => {
		return hasPermissions('Manage Roles') &&  props.userPermissions['Manage Roles'].actions['DELETE PERMISSIONS'] || isAdmin()
	}

	
	useEffect(() => {
		if(!_.isEmpty(props.tenant)){
			if(props.isPermissionsFetched){
				if (canReadRoles()) {
					props.fetchRolesAction(props.tenant);
				}
				else{
					toast.error('You do not have permission to view roles');
				}
			}
		}
	}, [props.tenant])

	
	useEffect(() => {
		let updatedSelectedRole = {};
		const selectedRoles = props.roles.filter((role) => role.id === state.selectedRole.id);
		if(!_.isEmpty(selectedRoles)) updatedSelectedRole = selectedRoles[0];
		setState((state) => ({ ...state, selectedRole: updatedSelectedRole}));		
	}, [props.roles, props.roles.length])

		
	useEffect(() => {
		if(!_.isEmpty(state.selectedRole)){
			if(props.isPermissionsFetched){
				if (canReadRolesUsers()) {
					props.fetchRoleUsersAction(props.tenant, state.selectedRole);
				}
				else{
					toast.error('You do not have permission to view a roles users');
				}
			}
		}
	}, [state.selectedRole?.id])
	
	
	const onRoleSelectionChanged = (rowId) => {
		if(state.selectedRole?.id == rowId){
			setState((state) => ({
				...state,
				selectedRole: {},
			}));
		} else if (rowId !== 'new') {
			const roles = props.roles.filter(x => x.id === rowId);
			if (roles.length > 0) {
				const role = roles[0];
				setState((state) => ({
					...state,
					selectedRole: role,
				}));
			}
			else {
				setState((state) => ({
					...state,
					selectedRole: {},
				}));
			}
		}
		else {
			setState((state) => ({
				...state,
				selectedRole: {
					id: 0,
					name: '',
					description: '',
				},
			}));
		}
	}

	const onPermissionSelectionChanged = (category) => {
		if(state.selectedPermission?.category == category){
			setState((state) => ({
				...state,
				selectedPermission: {},
			}));
		} else if (category !== 'new') {
			const permissions = state.selectedRole.permissions.filter(x => x.category === category);
			if (permissions.length > 0) {
				const permission = permissions[0];
				setState((state) => ({
					...state,
					selectedPermission: permission,
				}));
			}
			else {
				setState((state) => ({
					...state,
					selectedPermission: {},
				}));
			}
		}
		else {
			setState((state) => ({
				...state,
				selectedPermission: {
					category: '',
					displayName: '',
					description: '',
					policyName: '',
					action: [],
				},
			}));
		}
	}


	const confirmDeleteRole = () => {
		return (
			<Confirm
				content={`Are you sure you want to delete the following role: ${state.deleteRoleCandidate.name}?`}
				open={state.showConfirmDeleteRole}
				onCancel={() => setState((state) => ({ ...state, showConfirmDeleteRole: false }))}
				onConfirm={() => {
					props.deleteRole(props.tenant, state.deleteRoleCandidate);
					onRoleSelectionChanged(0);
					setState((state) => ({ ...state, showConfirmDeleteRole: false, deleteRoleCandidate: {} }));
				}}
			/>
		);
	}

	const confirmDeleteRoleUser = () => {
		return (
			<Confirm
				content={`Are you sure you want to unassigned the following user from the role: ${state.deleteRoleUserCandidate.roleName}?`}
				open={state.showConfirmDeleteRoleUser}
				onCancel={() => setState((state) => ({ ...state, showConfirmDeleteRoleUser: false }))}
				onConfirm={() => {
					props.deleteRoleUserAction(props.tenant, state.deleteRoleUserCandidate, state.selectedRole);
					setState((state) => ({ ...state, showConfirmDeleteRoleUser: false, deleteRoleUserCandidate: {} }));
				}}
			/>
		);
	}

	const confirmDeleteRolePermission = () => {
		return (
			<Confirm
				content={`Are you sure you want to unassigned the following user from the role: ${state.deleteRolePermissionCandidate.displayName}?`}
				open={state.showConfirmDeleteRolePermission}
				onCancel={() => setState((state) => ({ ...state, showConfirmDeleteRolePermission: false }))}
				onConfirm={() => {
					const permission = state.deleteRolePermissionCandidate;

					const role = state.selectedRole;
					const oldPermissionsList = _.isArray(role.permissions) ? role.permissions : []; 

					const permissionCategoryMap = {};

					oldPermissionsList.forEach((oldPerm) => {
						permissionCategoryMap[oldPerm.category] = oldPerm;
					});

					delete permissionCategoryMap[permission.category];

					const newPermissionList = Object.keys(permissionCategoryMap).map((key) => permissionCategoryMap[key]);

					const updatedRole = {
						...role,
						permissions: newPermissionList,
					};

					props.updateRoleAction(props.tenant, updatedRole);
					setState((state) => ({ ...state, showConfirmDeleteRolePermission: false, deleteRolePermissionCandidate: {} }));
				}}
			/>
		);
	}

	const roleItems = () => {
		if (!props.fetchingData) {
			if (!_.isEmpty(props.roles)) {
				const sortedRoles = _.orderBy(props.roles, ['roleName', 'name']); // Sort by role name
				return (
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Role Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Role Description</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg' />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{sortedRoles.map((role, index) => {
								return (
									<Table.Row 
										key={`${role.id}.${index}`} 
										className='clickable'
										active={role.id === state.selectedRole.id}
										onClick={() => {
											if(state.isEditRoleOpen !== true)  onRoleSelectionChanged(role.id)
										}} 
									>
										<Table.Cell>{role.roleName ?? role.name}</Table.Cell>
										<Table.Cell>{role.roleDescription ?? role.description}</Table.Cell>
										<Table.Cell>
										{canUpdateRoles() && (
										<FuseContentModal
											header={`Edit Role: ${state.selectedRole.name}`}
											content={<RoleDetails role={state.selectedRole} bdaction={'edit'}/>}
											trigger={<Button compact className="warning" icon='edit' size='mini' />}
											onOpen={() => {
												setState((state) => ({ ...state, isEditRoleOpen: true }));
											}}
											onClose={() => {
												setState((state) => ({ ...state, isEditRoleOpen: false }));
												onRoleSelectionChanged(role.id);
											}}					
										/>)}
										{canDeleteRoles() && (
										<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
											e.stopPropagation();
											setState((state) => ({ ...state, deleteRoleCandidate: role, showConfirmDeleteRole: true }));
										}} />)}</Table.Cell>
									</Table.Row>
								);
							})
							}
						</Table.Body>
					</Table>
				);
			}
			else {
				return (
					<PlaceholderSegment text="There are no Roles" />
				);
			}
		}
		else {
			return (
				<div style={{ height: "200px", margin: "10px 0px" }}>
					<Dimmer inverted active>
						<Loader content='Loading' />
					</Dimmer>
				</div>
			);
		}
	}

	const roleUsersRender = () => {
		if (_.isEmpty(state.selectedRole) || (state.selectedRole.id < 1)) {
			return;
		}
		else {
			
		const roleUsers = _.orderBy(props.roleUsers,["name"])

			return (
				<div>
					<Grid>
						<Grid.Row>
							<Grid.Column width='8'>
								<h3>{`Users assigned to role: ${state.selectedRole.name}`}</h3>
							</Grid.Column>
							<Grid.Column width='8'>
								{canCreateRolesUsers() &&(
								<FuseContentModal
									header={`Assign User to role: ${state.selectedRole.name}`}
									content={<RoleUserDetails role={state.selectedRole}/>}
									trigger={<Button compact className="success" icon='add' size='mini' floated='right'/>}
									onClose={() => {
										setState((state) => ({ ...state, role: state.role, roleUsers: props.roleUsers}));
									}}									
								/>)}
								{canReadRolesUsers() &&(
								<Button compact className='primary' icon='refresh' size='mini' floated='right' onClick={(e) => {
									e.stopPropagation();
									props.fetchRoleUsersAction(props.tenant, state.selectedRole);
									setState({
										selectedRole: state.selectedRole,
										isEditRoleOpen: false,
										selectedPermission:{},
										//DELETE ROLE
										deleteRoleCandidate: {},
										showConfirmDeleteRole: false,
										//DELETE ROLE USER
										deleteRoleUserCandidate: {},
										showConfirmDeleteRoleUser: false,
										//DELETE ROLE PERMISSION
										deleteRolePermissionCandidate: {},
										showConfirmDeleteRolePermission: false,
									});
								}}/>)}
							</Grid.Column> 
						</Grid.Row>
					</Grid>
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Surname</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Username</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Email Address</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{roleUsers.map((user, index) =>
								<Table.Row key={`${user.id}.${index}`}>
									<Table.Cell>{user.firstname}</Table.Cell>
									<Table.Cell>{user.lastname}</Table.Cell>
									<Table.Cell>{user.userName ?? user.username}</Table.Cell>
									<Table.Cell>{user.email}</Table.Cell>
									<Table.Cell textAlign='right'>
										{canDeleteRolesUsers() &&(
										<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
												e.stopPropagation();
												setState((state) => ({ ...state, deleteRoleUserCandidate: user, showConfirmDeleteRoleUser: true }));
											}}/>)}
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
				</div>
			);
		}
	}

	const rolePermissionsRender = () => {
		if (_.isEmpty(state.selectedRole) || (state.selectedRole.id < 1)) {
			return;
		}
		else {
			
		const rolePermissions = _.orderBy(state.selectedRole.permissions,["displayName"])

			return (
				<div>
					<Grid>
						<Grid.Row>
							<Grid.Column width='8'>
								<h3>{`Permissions granted by role: ${state.selectedRole.name}`}</h3>
							</Grid.Column>
							<Grid.Column width='8'>
								{canCreateRolesPermissions() && (
								<FuseContentModal
									header={`Grant permissions to role ${state.selectedRole.name}`}
									content={<RolePermissionDetails role={state.selectedRole} isNew={true}/>}
									trigger={<Button compact className="success" icon='add' size='mini' floated='right'/>}
									onClose={() => {
										setState((state) => ({ ...state, role: state.role, roleUsers: props.roleUsers}));
									}}									
								/>)}
							</Grid.Column> 
						</Grid.Row>
					</Grid>
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Permission</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Description</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{rolePermissions.map((permission, index) => {
								const isSelected = state.selectedPermission.category === permission.category;
								return (
									<Table.Row 
											key={`${permission.category}.${index}`}
											onClick={() => {
												onPermissionSelectionChanged(permission.category)
											}} 
										>
										<Table.Cell>{permission.displayName}</Table.Cell>
										<Table.Cell>{permission.description}</Table.Cell>
										<Table.Cell textAlign='right'>
											{canDeleteRolesPermissions() &&(
											<Button compact className='danger' icon='trash' size='mini'  floated='right' onClick={(e) => {
												e.stopPropagation();
												setState((state) => ({ ...state, deleteRolePermissionCandidate: permission, showConfirmDeleteRolePermission: true }));
											}}/>)}
											{canUpdateRolesPermissions() &&(
											<FuseContentModal
												header={`Edit permission: ${permission.displayName}`}
												content={<RolePermissionDetails role={state.selectedRole} permission={permission} isNew={false}/>}
												trigger={<Button compact className="warning" icon='edit' size='mini' floated='right'/>}
												onClose={() => {
													setState((state) => ({ ...state, role: state.role, roleUsers: props.roleUsers}));
												}}									
											/>)}
										</Table.Cell>
									</Table.Row>
									);
								}
							)}
						</Table.Body>
					</Table>
				</div>
			);
		}
	}

	const tabListRenderer = () =>{
		if (_.isEmpty(state.selectedRole) || (state.selectedRole.id < 1)) {
			return;
		}

		const tabs = [];


		let allowedToViewUsers = true;
		let allowedToViewPermissions = true;

		if(allowedToViewUsers){
			tabs.push({ 
				menuItem: "Users", 
				render: () => <Tab.Pane>{roleUsersRender()}</Tab.Pane>, 
			});
		}

		if(allowedToViewPermissions){
			tabs.push({ 
				menuItem: "Permissions", 
				render: () => <Tab.Pane>{rolePermissionsRender()}</Tab.Pane>, 
			});
		}
		

		return <Tab 
			menuPosition='left'
			defaultActiveIndex={0} 
			renderActiveOnly={true}
			panes={tabs}
		/>;
	}

	return (
		<Container fluid>
			{confirmDeleteRole()}
			{confirmDeleteRoleUser()}
			{confirmDeleteRolePermission()}
			<Grid>
				<Grid.Row>
					<Grid.Column width='8'>
						<h3>Manage Roles</h3>
					</Grid.Column>
					<Grid.Column width='8'>
						{canCreateRoles() && (
						<FuseContentModal
							header={`Add New Role`}
							content={<RoleDetails role={state.selectedRole} roleUsers = {props.roleUsers} bdaction={'add'}/>}
							trigger={<Button compact className="success" icon='add' size='mini' floated='right'/>}
							onClose={() => {
								setState((state) => ({ ...state, role: state.role, roleUsers: props.roleUsers}));
							}}									
						/>)}
						{canReadRoles() && (
						<Button compact className='primary' icon='refresh' size='mini' floated='right' onClick={(e) => {
							e.stopPropagation();
							props.fetchRolesAction(props.tenant);
							setState({
								selectedRole: {},
								isEditRoleOpen: false,
								//DELETE ROLE
								deleteRoleCandidate: {},
								showConfirmDeleteRole: false,
								//DELETE ROLE USER
								deleteRoleUserCandidate: {},
								showConfirmDeleteRoleUser: false,
								//DELETE ROLE PERMISSION
								deleteRolePermissionCandidate: {},
								showConfirmDeleteRolePermission: false,
							});
						}}/>)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{roleItems()}
			<br/>
			{tabListRenderer()}
		</Container>
	);
}

const mapStateToProps = (state, ownProps) => {

	const tenantRoles = state.userManagement.roles;
	const tenantRoleUsers = state.userManagement.selectedRoleUsers;

	return {
		fetchingData: state.fetchingData === true,
		tenant: state.tenantManagement.activeTenant,
		roles: _.isEmpty(tenantRoles) ? [] : Object.values(tenantRoles),		
		roleUsers: _.isEmpty(tenantRoleUsers) ? [] : Object.values(tenantRoleUsers),
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchRolesAction: (tenant) => dispatch(ROLE_ACTIONS.fetchRolesAction(tenant)),
		deleteRole: (tenant, role) => dispatch(ROLE_ACTIONS.deleteRoleAction(tenant, role)),
		updateRoleAction: (tenant, role) => dispatch(ROLE_ACTIONS.updateRoleAction(tenant, role)),
		fetchRoleUsersAction: (tenant, role) => dispatch(ROLE_ACTIONS.fetchRoleUsersAction(tenant, role)),
		deleteRoleUserAction: (tenant, userRole, role) => dispatch(ROLE_ACTIONS.roleUserDeleteAction(tenant, userRole, role)),
	})
)(ManageRolesPage);