import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Grid, Button, Container, Dropdown, Dimmer, Loader, Header, Icon, Segment, Input, Pagination } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import _ from 'lodash';
import moment from 'moment';

import CVSearchLogDetails from './CVSearchLogDetails';

import * as CV_SEARCH_ACTIONS from "../../../actions/cvSearchActions";


export function CVSearchLogPage(props) {
    const [state, setState] = useState({
        selectedSearchLog: {},
        isViewSearchLogOpen: false,
        searchQuery: "",
        filterBy: "all",
        activePage: 1,
        pageSize: 15,
    });

    const hasPermissions = (category) => {
        return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
    }

    const isAdmin = () => {
        return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
    }

    const canReadCVSearchLog = () => {
        return hasPermissions('CV Search') && props.userPermissions['CV Search'].actions.READ || isAdmin()
    }

    useEffect(() => {
        if (!_.isEmpty(props.tenant)) {
            if (props.isPermissionsFetched) {
                if (canReadCVSearchLog()) {
                    props.fetchCVSearchLogsAction(props.tenant);
                }
                else {
                    toast.error('You do not have permission to view the cv search logs!');
                }
            }
        }
    }, [props.tenant]);

    const onSearchInputChange = (event) => {
        const searchQuery = event.target.value;
        setState((prevState) => ({ ...prevState, searchQuery }));
    };


    const handlePaginationChange = (e, { activePage }) => {
        setState((prevState) => ({ ...prevState, activePage }));
    };

    const _buildSearchLogs = () => {
        const { searchQuery, activePage, pageSize, filterBy } = state;

        const startIndex = (activePage - 1) * pageSize;
        const endIndex = startIndex + pageSize;

        let filteredCVSearchLogs = props.cvSearchLogs;

        // Filter based on search query
        if (searchQuery) {
            filteredCVSearchLogs = filteredCVSearchLogs.filter((cvSearchLog) => {
                const termsMatch = !_.isEmpty(cvSearchLog.terms) && cvSearchLog.terms.some(term => term.toLowerCase().includes(searchQuery.toLowerCase()));
                const qualificationsMatch = !_.isEmpty(cvSearchLog.qualifications) && cvSearchLog.qualifications.some(qualification => qualification.toLowerCase().includes(searchQuery.toLowerCase()));
                const languagesMatch = !_.isEmpty(cvSearchLog.languages) && cvSearchLog.languages.some(language => language.toLowerCase().includes(searchQuery.toLowerCase()));
                return termsMatch || qualificationsMatch || languagesMatch;
            });
        }

        // Apply secondary filter based on searchResultCount
        if (filterBy && filterBy !== 'all') {
            filteredCVSearchLogs = filteredCVSearchLogs.filter((cvSearchLog) => {
                const searchResultCount = parseInt(cvSearchLog.searchResultCount);
                switch (filterBy) {
                    case 'zero':
                        return searchResultCount === 0;
                    case 'one':
                        return searchResultCount === 1;
                    case 'fiveLess':
                        return searchResultCount < 5;
                    case 'fivePlus':
                        return searchResultCount >= 5;
                    default:
                        return true;
                }
            });
        }

        if (!props.fetchingData) {
            if (!filteredCVSearchLogs || filteredCVSearchLogs.length === 0) {
                return (
                    <Segment>
                        <Header icon>
                            <Icon name='file' color="black" />
                            No Search Logs To View
                        </Header>
                    </Segment>
                )
            }

            return (
                <React.Fragment>
                    <Table compact selectable size='small' celled>
                        <Table.Header>
                            <Table.Row >
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '20%' }}>Search Terms</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '20%' }}>Qualifications</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '20%' }}>Languages</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '20%' }}>Date Searched</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}>Number of Search Results</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {filteredCVSearchLogs.slice(startIndex, endIndex).map((cvSearchLog, index) => (
                                <Table.Row
                                    key={`${cvSearchLog.id}.${index}`}
                                    active={cvSearchLog.id === state.selectedSearchLog.id}
                                >
                                    <Table.Cell>{cvSearchLog.terms?.join(', ')}</Table.Cell>
                                    <Table.Cell>{cvSearchLog.qualifications?.join(', ')}</Table.Cell>
                                    <Table.Cell>{cvSearchLog.languages?.join(', ')}</Table.Cell>
                                    <Table.Cell>{moment.utc(cvSearchLog.createdAt).format("DD-MM-YYYY hh:mm:ss a")}</Table.Cell>
                                    <Table.Cell>{cvSearchLog.searchResultCount}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        <FuseContentModal
                                            header={`View Search Log: ${moment.utc(cvSearchLog.createdAt).format("DD-MM-YYYY hh:mm:ss a")}`}
                                            content={<CVSearchLogDetails cvSearchLog={state.selectedSearchLog} bdaction={'view'} />}
                                            trigger={<Button compact className="primary" content="View" icon='eye' size='medium' />}
                                            onOpen={() => {
                                                setState((state) => ({ ...state, isViewSearchLogOpen: true, selectedSearchLog: cvSearchLog }));
                                            }}
                                            onClose={() => {
                                                setState((state) => ({ ...state, isViewSearchLogOpen: false, selectedSearchLog: {} }));
                                            }}
                                        />
                                    </Table.Cell>

                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    <Pagination
                        activePage={activePage}
                        totalPages={Math.ceil(props.cvSearchLogs.length / pageSize)}
                        onPageChange={handlePaginationChange}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <div style={{ height: '200px', margin: '10px 0px' }}>
                    <Dimmer inverted active>
                        <Loader content='Loading' />
                    </Dimmer>
                </div>
            );
        }
    };

    return (
        <Container fluid>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <h3>CV Search Logs:</h3>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Input
                            placeholder="Search Term, Language or Qualification..."
                            icon={"search"}
                            value={state.searchQuery}
                            onChange={onSearchInputChange}
                            style={{ width: '100%', borderRadius: '5px' }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Dropdown
                            placeholder='Results...'
                            fluid
                            selection
                            options={[
                                { key: 'all', text: 'All', value: 'all' },
                                { key: 'zero', text: '0', value: 'zero' },
                                { key: 'one', text: '1', value: 'one' },
                                { key: 'fiveLess', text: 'Less than 5', value: 'fiveLess' },
                                { key: 'fivePlus', text: 'More Than 5', value: 'fivePlus' },
                            ]}
                            onChange={(e, { value }) => setState((prevState) => ({ ...prevState, filterBy: value, activePage: 1 }))}
                            />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {_buildSearchLogs()}
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenant: state.tenantManagement.activeTenant,
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        cvSearchLogs: state.cvSearchLogs
    };
}

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch) => ({
        fetchCVSearchLogsAction: (tenant) => dispatch(CV_SEARCH_ACTIONS.fetchCVSearchLogsAction(tenant)),
    })
)(CVSearchLogPage);
