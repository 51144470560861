import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Table, Button, Container, Grid, Confirm, Dimmer, Loader, Checkbox } from 'semantic-ui-react';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import PlaceholderSegment from '../../../components/generic/PlaceholderSegment';
import BusinessRuleDetails from './BusinessRuleDetails';
import BusinessRuleAttrDetails from './BusinessRuleAttrDetails';
import * as BRULE_ACTIONS from '../../../actions/businessRuleActions';
import * as BRTYPE_ACTIONS from '../../../actions/businessRuleTypeActions';



export function BusinessRulePage(props) {
	const [state, setState] = useState({
		bRule: null,
		selectedBRule: {},
		fetchingData: false,
		showConfirmDelete: false,
		deleteCandidate: {}
	});

	const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

	const canReadBusinessRule = () => {
		return hasPermissions('Business Rules') && props.userPermissions['Business Rules'].actions.READ || isAdmin()
	}

	const canCreateBusinessRule = () => {
		return hasPermissions('Business Rules') && canReadBusinessRule() && props.userPermissions['Business Rules'].actions.CREATE ||isAdmin()
	}

	const canUpdateBusinessRule = () => {
		return hasPermissions('Business Rules') && canReadBusinessRule() && props.userPermissions['Business Rules'].actions.UPDATE || isAdmin()
	}

	const canDeleteBusinessRule = () => {
		return hasPermissions('Business Rules') && canReadBusinessRule() && props.userPermissions['Business Rules'].actions.DELETE || isAdmin()
	}

	useEffect(() => {
		if (props.authData.length > 0){
			if(props.isPermissionsFetched){
				if (canReadBusinessRule()) {
					props.fetchbRulesAction(props.tenantId);
					props.fetchBrTypesAction(props.tenantId);
				}
				else{
					toast.error('You do not have permission to view business rules');
				}
			}
		}
	}, [props.tenantId])

	
	const onBRuleSelectionChanged = (rowId) => {
		if (rowId !== 'new') {
			const bRules = props.bRules.filter(x => x.id === rowId);
			if (bRules.length > 0) {
				const bRule = bRules[0];
				setState((state) => ({
					...state,
					selectedBRule: bRule,
					bRuleAttrs: brTypeAttrsObjectsFromString(bRule.bRuleAttrs)
				}));
			}
			else {
				setState((state) => ({
					...state,
					selectedBRule: {},
					bRuleAttrs: []
				}));
			}
		}
		else {
			setState((state) => ({
				...state,
				selectedBRule: {
					id: 0,
					keyName: ''
				},
				bRuleAttrs: []
			}));
		}
	}

	const onEditBRuleRow = (rowId) => {
		onBRuleSelectionChanged(rowId);
		setState((state) => ({ ...state, isEditBRuleOpen: true }));
	}

	const brTypeAttrsObjectsFromString = (json) => {
		if (!_.isEmpty(json)) {
			return JSON.parse(json);
		}
		else {
			return [];
		}
	}

	const brTypeAttrsObjectsToString = (valueQnaireObjectArray) => {
		return JSON.stringify(valueQnaireObjectArray);
	}

	const confirmDelete = () => {
		return (
			<Confirm
				content={`Are you sure you want to delete the following Business Rule: ${state.deleteCandidate.bRule}?`}
				open={state.showConfirmDelete}
				onCancel={() => setState((state) => ({ ...state, showConfirmDelete: false }))}
				onConfirm={() => {
					let deleteBRule = state.deleteCandidate;
					props.deleteBRule(deleteBRule);
					onBRuleSelectionChanged(0);
					setState((state) => ({ ...state, showConfirmDelete: false }));
				}
				}
			/>
		);
	}

	const getMicroServiceForBrType = (brTypeId) => {

		  const matchingBrType = props.brTypes.find((brType) => brType.id === brTypeId);
		  return matchingBrType ? matchingBrType.microService : "";
	  };
	  

	const BRuleItems = () => {
		if (!props.fetchingData) {
			if (!_.isEmpty(props.bRules)) {
				const filteredBRules = props.bRules
				  .filter((BRule) => BRule.tenantId === props.tenantId)
				  .sort((a, b) => a.bRule.localeCompare(b.bRule));
				return (
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Business Rule</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Business Rule Type</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Micro Service</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Aggregation Count</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Notification Msg</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}/>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{filteredBRules.map((BRule) => {
								const microService = getMicroServiceForBrType(BRule.brTypeId);
								return (
									<Table.Row key={BRule.id} className='clickable' onClick={() => onEditBRuleRow(BRule.id)} active={BRule.id === state.selectedBRule.id}>
										<Table.Cell>{BRule.bRule}</Table.Cell>
										<Table.Cell>{BRule.brTypeName}</Table.Cell>
										<Table.Cell>{microService}</Table.Cell>
										<Table.Cell>
											{microService === 'Aggregation' ? BRule.brAggregationCount : ''}
										</Table.Cell>
										<Table.Cell>{BRule.notificationMsg}</Table.Cell>
										<Table.Cell textAlign='right'>
										{ canUpdateBusinessRule() && (
										<FuseContentModal
											header={`Edit Business Rule: ${state.selectedBRule.bRule}`}
											content={<BusinessRuleDetails BRule={state.selectedBRule} bRuleAttrs = {state.bRuleAttrs} bdaction={'edit'}/>}
											trigger={<Button compact className="warning" icon='edit' size='small' />}
											// onClose={() => {
											// 	onBRuleSelectionChanged(BRule.id);
											// 	setState((state) => ({ ...state, bRuleAttrs: state.bRuleAttrs}));;
											// }}									
										/>)}
										{ canDeleteBusinessRule() && (
										<Button compact className='danger' icon='trash' size='small' onClick={(e) => {
											e.stopPropagation();
											setState((state) => ({ ...state, deleteCandidate: BRule, showConfirmDelete: true }));
										}} />)}</Table.Cell>
									</Table.Row>
								);
							})
							}
						</Table.Body>
					</Table>
				);
			}
			else {
				return (
					<PlaceholderSegment text="There are no Business Rules" />
				);
			}
		}
		else {
			return (
				<div style={{ height: "200px", margin: "10px 0px" }}>
					<Dimmer inverted active>
						<Loader content='Loading' />
					</Dimmer>
				</div>
			);
		}
	}

	const bRuleAttrs = () => {
		state.bRuleAttrs = _.orderBy(state.bRuleAttrs,["attrName"])
		return state.bRuleAttrs
			.map((bRuleAttr, index) =>
				<Table.Row key={`${bRuleAttr.id}.${index}`}>
					<Table.Cell>{bRuleAttr.attrName}</Table.Cell>
					<Table.Cell><Checkbox disabled checked={bRuleAttr.isMandatory}/></Table.Cell>
					<Table.Cell>{bRuleAttr.qnaireName}</Table.Cell>
					<Table.Cell>{bRuleAttr.qnaireSectionName}</Table.Cell>
					<Table.Cell>{bRuleAttr.qnaireQuestionName}</Table.Cell>
					<Table.Cell><Checkbox disabled checked={bRuleAttr.isUpload==undefined?false:bRuleAttr.isUpload}/></Table.Cell>
					<Table.Cell>{bRuleAttr.uploadFilename}</Table.Cell>
					<Table.Cell textAlign='right'>
					{ canUpdateBusinessRule() && (
						<FuseContentModal
							header={`Edit Business Rule Attribute: ${bRuleAttr.attrName}`}
							content={<BusinessRuleAttrDetails bRule={state.selectedBRule} bRuleAttrs = {state.bRuleAttrs} bdaction={'edit'} valIndex={index}/>}
							trigger={<Button compact className="warning" icon='edit' size='mini' />}
							onClose={() => {setState((state) => ({ ...state, bRuleAttrs: _.orderBy(brTypeAttrsObjectsFromString(state.selectedBRule.bRuleAttrs),["attrName"]) }));;}}									
						/>)}
					</Table.Cell>
				</Table.Row>
			);
	}
	const bRuleTypeAttrsRender = () => {
		if (_.isEmpty(state.selectedBRule) || (state.selectedBRule.id < 1)) {
			return;
		}
		else {
			return (
				<div>
					<Grid>
						<Grid.Row>
							<Grid.Column width='8'>
								<h3>{`${state.selectedBRule.bRule} Attributes:`}</h3>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Attribute Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Is Mandatory?</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Questionnaire</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Section</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Question</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Is Upload?</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Uploaded Filename</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'></Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{bRuleAttrs()}
						</Table.Body>
					</Table>
				</div>
			);
		}
	}

	return (
		<Container fluid>
			{confirmDelete()}
			<Grid>
				<Grid.Row>
					<Grid.Column width='8'>
						<h3>Business Rules</h3>
					</Grid.Column>
					<Grid.Column width='8'>
					{ canCreateBusinessRule() && (
					<FuseContentModal
						header={`Add New Business Rule`}
						content={<BusinessRuleDetails BRule={state.selectedBRule} bRuleAttrs = {state.bRuleAttrs} bdaction={'add'}/>}
						trigger={<Button compact className="success" icon='add' size='mini' floated='right'/>}
						onClose={() => {
							setState((state) => ({ ...state, bRule: state.bRule, bRuleAttrs: state.bRuleAttrs}));
						}}									
					/>)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{BRuleItems()}
			{bRuleTypeAttrsRender()}
		</Container>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		fetchingData: state.fetchingData === true,
		bRules: _.isEmpty(state.bRules) ? [] : Object.values(state.bRules),		
		bRuleAttrs: _.isEmpty(state.bRuleAttrs) ? [] : Object.values(state.bRuleAttrs),
		microservices: (_.isEmpty(state.microServices)) ? [] : Object.values(state.microServices),
		brTypes: _.isEmpty(state.brTypes) ? [] : Object.values(state.brTypes),
		tenantId: state.tenantManagement.activeTenant.tenantId,
		authData: _.isEmpty(state.authData) ? [] : Object.values(state.authData),
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchbRulesAction: (tenantId) => dispatch(BRULE_ACTIONS.fetchbRulesAction(tenantId)),
		deleteBRule: (bRule) => dispatch(BRULE_ACTIONS.bRuleDeleteAction(bRule)),
		savebRulesAction: (bRule) => dispatch(BRULE_ACTIONS.bRuleSaveAction(bRule)),
		fetchBrTypesAction: (tenantId) => dispatch(BRTYPE_ACTIONS.fetchbrTypesAction(tenantId)),
	})
)(BusinessRulePage);