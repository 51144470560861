/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "a609a59078854bf085355c3ea612d355",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "a609a59078854bf085355c3ea612d355",
            "region": "eu-west-1"
        }
    },
    "aws_cognito_identity_pool_id": "eu-west-1:ef887f91-3b63-425b-8dee-bf35a4c923d9",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_fNFgXUzTl",
    "aws_user_pools_web_client_id": "3l6vbq12c41klho7uk59l2p8a7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "infusion-whatsapp-webdev-storage-09ce1a0a95047-webuat",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
