export class UserGroupDTO{
    PK;
    Sk;
    id;
    createdAt;
    updatedAt;
    tenantId;
    name;
    description;
    type;

    constructor(group, tenant) {
        if (group.id != null) {
            this.PK = group.PK;
            this.SK = group.SK;
        }
        else{
            this.PK = `${tenant.id}#GROUPS`;
            this.SK = "GROUP#";
        }
        this.id = group.id;
        this.type = "USERGROUP";
        this.tenantId = tenant.id;
        this.name = group.name;
        this.description = group.description;
    }
}