import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Table, Button, Container, Grid, Confirm, Dimmer, Loader, Divider, Checkbox } from 'semantic-ui-react';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import PlaceholderSegment from '../../../components/generic/PlaceholderSegment';
import UserDetails from './UserDetails';
import UserRoleDetails from './UserRoleDetails';
import * as USER_ACTIONS from '../../../actions/tenantUsersActions';

export function ManageUsersPage(props) {
	const [state, setState] = useState({
		selectedUser: {},
		isEditUserOpen: false,
		//DELETE USER ROLE
		deleteUserRoleCandidate: {},
		showConfirmDeleteUserRole: false,
	});


	const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

	const canManageUsers = () => {
		return hasPermissions('Manage Users') && props.userPermissions['Manage Users'].actions.READ || isAdmin()
	}

	const canCreateUsers = () => {
		return hasPermissions('Manage Users') && canManageUsers() && props.userPermissions['Manage Users'].actions.CREATE ||isAdmin()
	}

	const canUpdateUsers = () => {
		return hasPermissions('Manage Users') && canManageUsers() && props.userPermissions['Manage Users'].actions.UPDATE || isAdmin()
	}

	const canReadUserRoles = () => {
		return hasPermissions('Manage Users') && canManageUsers() && props.userPermissions['Manage Users'].actions['VIEW ROLES'] || isAdmin()
	}

	const canCreateUserRoles = () => {
		return hasPermissions('Manage Users') && canReadUserRoles() && props.userPermissions['Manage Users'].actions['ADD ROLES'] || isAdmin()
	}

	const canDeleteUserRoles = () => {
		return hasPermissions('Manage Users') && canReadUserRoles() && props.userPermissions['Manage Users'].actions['DELETE ROLE'] || isAdmin()
	}
	useEffect(() => {
		if(!_.isEmpty(props.tenant)){
			if(props.isPermissionsFetched){
				if (canManageUsers()) {
					props.fetchUsersAction(props.tenant);
				}
				else{
					toast.error('You do not have permission to view users');
				}
			}
		}
	}, [props.tenant])

		
	useEffect(() => {
		if(!_.isEmpty(state.selectedUser)){
			if(props.isPermissionsFetched){
				if (canReadUserRoles()) {
					props.fetchUserRolesAction(props.tenant, state.selectedUser);
				}
				else{
					toast.error('You do not have permission to view a users roles');
				}
			}
		}
	}, [state.selectedUser?.userName])


	
	const onUserSelectionChanged = (rowId) => {
		if(state.selectedUser?.id == rowId){
			setState((state) => ({
				...state,
				selectedUser: {},
			}));
		} else if (rowId !== 'new') {
			const users = props.users.filter(x => x.id === rowId);
			if (users.length > 0) {
				const user = users[0];
				setState((state) => ({
					...state,
					selectedUser: user,
				}));
			}
			else {
				setState((state) => ({
					...state,
					selectedUser: {},
				}));
			}
		}
		else {
			setState((state) => ({
				...state,
				selectedUser: {
					id: 0,
					firstname: '',
					lastname: '',
					userName: '',
					email: '',
				},
			}));
		}
	}

	const confirmDeleteUserRole = () => {
		return (
			<Confirm
				content={`Are you sure you want to unassign the following role from the user: ${state.deleteUserRoleCandidate.userName}?`}
				open={state.showConfirmDeleteUserRole}
				onCancel={() => setState((state) => ({ ...state, showConfirmDeleteUserRole: false }))}
				onConfirm={() => {
					props.deleteUserRoleAction(props.tenant, state.deleteUserRoleCandidate, state.selectedUser);
					setState((state) => ({ ...state, showConfirmDeleteUserRole: false, deleteUserRoleCandidate: {} }));
				}}
			/>
		);
	}

	const userItems = () => {
		if (!props.fetchingData) {
			if (!_.isEmpty(props.users)) {
				// props.users.sort((a, b) => a..localeCompare(b.user));
				return (
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Surname</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Username</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Email Address</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Status</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg' />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{props.users.map((user, index) => {

								const isDisabled = user.status == 'isDisabled';

								let header = `Edit User: ${user.firstname} ${user.lastname}`;
								if(isDisabled) header = `Edit User: ${user.firstname} ${user.lastname} [DISABLED]`;

								let className = '';
								if(isDisabled) className = 'disabled';

								return (
									<Table.Row 
										key={`${user.id}.${index}`} 
										className='clickable'
										active={user.id === state.selectedUser.id}
										onClick={() => {
											if(state.isEditUserOpen !== true)  onUserSelectionChanged(user.id)
										}} 
									>
										<Table.Cell className={className}>{user.firstname}</Table.Cell>
										<Table.Cell className={className}>{user.lastname}</Table.Cell>
										<Table.Cell className={className}>{user.userName}</Table.Cell>
										<Table.Cell className={className}>{user.email}</Table.Cell>
										<Table.Cell className={className}>{(isDisabled? "Disabled" : "Active")}</Table.Cell>
										<Table.Cell>
											{canUpdateUsers() && (
											<FuseContentModal
												header={header}
												content={<UserDetails user={user} bdaction={'edit'}/>}
												trigger={<Button compact className="warning" icon='edit' size='mini' />}
												onOpen={() => {
													setState((state) => ({ ...state, isEditUserOpen: true }));
												}}
												onClose={() => {
													setState((state) => ({ ...state, isEditUserOpen: false }));
													onUserSelectionChanged(user.id);
												}}					
											/>)}
										</Table.Cell>
									</Table.Row>
								);
							})
							}
						</Table.Body>
					</Table>
				);
			}
			else {
				return (
					<PlaceholderSegment text="There are no Users" />
				);
			}
		}
		else {
			return (
				<div style={{ height: "200px", margin: "10px 0px" }}>
					<Dimmer inverted active>
						<Loader content='Loading' />
					</Dimmer>
				</div>
			);
		}
	}

	const userRolesRender = () => {
		if (_.isEmpty(state.selectedUser) || (state.selectedUser.id < 1)) {
			return;
		}
		else {
			
		const userRoles = _.orderBy(props.userRoles,["name"])

			return (
				<div>
					<Grid>
						<Grid.Row>
							<Grid.Column width='8'>
								<h3>{`Roles assigned to user: ${state.selectedUser.firstname} ${state.selectedUser.lastname}`}</h3>
							</Grid.Column>
							<Grid.Column width='8'>
							{canCreateUserRoles() && (
								<FuseContentModal
									header={`Assign Role to ${state.selectedUser.firstname} ${state.selectedUser.lastname}`}
									content={<UserRoleDetails user={state.selectedUser}/>}
									trigger={<Button compact className="success" icon='add' size='mini' floated='right'/>}
									onClose={() => {
										setState((state) => ({ ...state, user: state.user, userRoles: props.userRoles}));
									}}									
								/>)}
								{canManageUsers() && (
								<Button compact className='primary' icon='refresh' size='mini' floated='right' onClick={(e) => {
									e.stopPropagation();
									props.fetchUserRolesAction(props.tenant, state.selectedUser);
									setState({
										selectedUser: state.selectedUser,
										isEditUserOpen: false,
										//DELETE USER
										deleteUserCandidate: {},
										showConfirmDeleteUser: false,
										//DELETE USER ROLE
										deleteUserRoleCandidate: {},
										showConfirmDeleteUserRole: false,
									});
								}}/>)}
							</Grid.Column> 
						</Grid.Row>
					</Grid>
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Role Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Role Description</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{userRoles.map((role, index) =>
								<Table.Row key={`${role.id}.${index}`}>
									<Table.Cell>{role.roleName ?? role.name}</Table.Cell>
									<Table.Cell>{role.roleDescription ?? role.description}</Table.Cell>
									<Table.Cell textAlign='right'>
										{canDeleteUserRoles() &&(
										<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
												e.stopPropagation();
												setState((state) => ({ ...state, deleteUserRoleCandidate: role, showConfirmDeleteUserRole: true }));
											}}/>)}
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
				</div>
			);
		}
	}

	return (
		<Container fluid>
			{confirmDeleteUserRole()}
			<Grid>
				<Grid.Row>
					<Grid.Column width='8'>
						<h3>Manage Users</h3>
					</Grid.Column>
					<Grid.Column width='8'>
					{canCreateUsers() && (
						<FuseContentModal
							header={`Add New User`}
							content={<UserDetails user={state.selectedUser} userRoles = {props.userRoles} bdaction={'add'}/>}
							trigger={<Button compact className="success" icon='add' size='mini' floated='right'/>}
							onClose={() => {
								setState((state) => ({ ...state, user: state.user, userRoles: props.userRoles}));
							}}									
						/>)}
						{canManageUsers() && (
						<Button compact className='primary' icon='refresh' size='mini' floated='right' onClick={(e) => {
							e.stopPropagation();
							props.fetchUsersAction(props.tenant);
							setState({
								selectedUser: {},
								isEditUserOpen: false,
								//DELETE USER
								deleteUserCandidate: {},
								showConfirmDeleteUser: false,
								//DELETE USER ROLE
								deleteUserRoleCandidate: {},
								showConfirmDeleteUserRole: false,
							});
						}}/>)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{userItems()}
			<br/>
			{userRolesRender()}
		</Container>
	);
}

const mapStateToProps = (state, ownProps) => {

	const tenantUsers = state.userManagement.users;
	const tenantUserRoles = state.userManagement.selectedUserRoles;

	const users = _.isEmpty(tenantUsers) ? [] : Object.values(tenantUsers);

	users.sort((a,b) => {
		if(a.status === b.status) return 0;
		if(a.status === "isDisabled") return 1;
		if(b.status === "isDisabled") return -1;
		return 0;
	});

	return {
		fetchingData: state.fetchingData === true,
		tenant: state.tenantManagement.activeTenant,
		users: users,		
		userRoles: _.isEmpty(tenantUserRoles) ? [] : Object.values(tenantUserRoles),
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchUsersAction: (tenant) => dispatch(USER_ACTIONS.fetchUsersAction(tenant)),
		fetchUserRolesAction: (tenant, user) => dispatch(USER_ACTIONS.fetchUserRolesAction(tenant, user)),
		deleteUserRoleAction: (tenant, userRole, user) => dispatch(USER_ACTIONS.userRoleDeleteAction(tenant, userRole, user)),
	})
)(ManageUsersPage);