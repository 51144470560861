import { FETCH_TENANT_USERS_SUCCESS_ACTION, 
	FETCH_TENANT_USER_ROLES_SUCCESS_ACTION, } from "../actions/tenantUsersActions";

export const tenantUsersReducer = (state = [], action) => {
    switch (action.type) {
			case FETCH_TENANT_USERS_SUCCESS_ACTION:
				return action.payload;
			default:
				return state;
    }
};

export const tenantUserRolesReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_TENANT_USER_ROLES_SUCCESS_ACTION:
			return action.payload;
		default:
			return state;
	}
}
