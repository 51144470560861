import {
    FETCH_BOUGHT_CVS_SUCCESS_ACTION
}from "../actions/cvSearchActions";

export const boughtCVsReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_BOUGHT_CVS_SUCCESS_ACTION:
            const cvs = action.payload;
            return cvs;
        default:
            return state;
    }
}