import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Checkbox
} from "semantic-ui-react";

import * as BRTYPE_ACTIONS from "../../../actions/businessRuleTypeActions";

export function BrTypeAttrDetails(props) {
  const [state, setState] = useState({
    brType: { ...props.brType },    
    brTypeAttr: { ...props.brTypeAttr },
    brTypeAttributes: { ...props.brTypeAttributes},
    isChanged: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  
  useEffect(() => {
  }, []);

  const attrValueObjectsFromString = (json) => {
		if (!_.isEmpty(json)) {
			return JSON.parse(json);
		}
		else {
			return [];
		}
	}

	const attrValueObjectsToString = (attrValueObjectArray) => {
		return JSON.stringify(attrValueObjectArray);
	}

  const onSubmit = (data) => {
    let values = [];
    let newVal = [];

    let toCommit = props.brType;
    if (!_.isEmpty(toCommit.brTypeAttributes))
      values = toCommit.brTypeAttributes;

    if (!_.isEmpty(values))
      newVal = attrValueObjectsFromString(values);

    if (props.isNew === true)
      newVal.push(state.brTypeAttr);
    else
      newVal[props.valIndex] = state.brTypeAttr;
      
    let strVals = attrValueObjectsToString(_.orderBy(newVal,['attrName']));

    toCommit.brTypeAttributes = strVals;
    setState((state) => ({ ...state, brTypeAttributes: newVal, isChanged: false}));

    props.saveBrTypeAction(toCommit, props.tenantId);
    if (_.isEmpty(props.onComplete)) {
      props.onComplete();
    }
  };

  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
    <Form.Field>
      <label>Attribute Name</label>
      <input
        name="brAttrName"
        className={errors.question_text ? "invalid-input-field" : ""}
        {...register("brAttrName", { required: true, maxLength: 128 })}
        placeholder="Attribute Name"
        value={state.brTypeAttr.attrName}
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            brTypeAttr: { ...prevState.brTypeAttr, attrName: e.target.value },
          }));
          setState((state) => ({...state, isChanged: true}));
        }}
      />
      {errors.brAttrName && errors.brAttrName.type === "required" && (
        <p className="validation-error">* Please enter a name</p>
      )}
      {errors.question_text && errors.brAttrName.type === "pattern" && (
        <p className="validation-error">
          * May only contain alphabetical characters
        </p>
      )}
      {errors.question_text && errors.brAttrName.type === "maxLength" && (
        <p className="validation-error">
          * Name may not be more than 128 characters long
        </p>
      )}
    </Form.Field>
    <Form.Field>
      <Checkbox
          toggle
          label="Is Mandatory?"
          checked={!!state.brTypeAttr.isMandatory}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              brTypeAttr: { ...prevState.brTypeAttr, isMandatory: !prevState.brTypeAttr.isMandatory },
            }));
            setState((state) => ({...state, isChanged: true}));
          }}
        />
    </Form.Field>
    <Form.Field>
      <Checkbox
          toggle
          label="Is Upload?"
          checked={!!state.brTypeAttr.isUpload}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              brTypeAttr: { ...prevState.brTypeAttr, isUpload: !prevState.brTypeAttr.isUpload },
            }));
            setState((state) => ({...state, isChanged: true}));
          }}
        />
    </Form.Field>
    <Divider />
    <Button.Group floated="right">
      <Button
        className="danger"
        onClick={() => {
          console.log(props.onComplete);
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success" disabled={!state.isChanged}>
        <Icon name="checkmark" /> Ok
      </Button>
    </Button.Group>
  </Form>
  );
}


const mapStateToProps = (state, ownProps) => {
  var brTypeAttr = {};
  var isNew = false;
  var newBrTypeAttr = {
    attrName: "",
    isMandatory: false
  };

  if (ownProps.braction === 'add') {
    brTypeAttr = newBrTypeAttr;
    isNew = true;
  } else {
    brTypeAttr = _.isEmpty(ownProps.BrTypeValues)
      ? newBrTypeAttr
      : ownProps.BrTypeValues[ownProps.valIndex];
    isNew = false;
  }

  return {
    brType: ownProps.BrType,
    brTypeAttr: brTypeAttr,
    brTypeAttributes: ownProps.brTypeAttributes,
    isNew: isNew,
    valIndex: ownProps.valIndex,
    tenantId: state.tenantManagement.activeTenant.tenantId
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		saveBrTypeAction: (brType, tenantId) => dispatch(BRTYPE_ACTIONS.brTypeSaveAction(brType, tenantId)),
  })
  )(BrTypeAttrDetails);
