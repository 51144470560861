import {
    FETCH_LOOKUPSQNAIRE_SUCCESS_ACTION,
    LOOKUPQNAIRE_ADD_ACTION,
    LOOKUPQNAIRE_EDIT_ACTION,
    LOOKUPQNAIRE_DELETE_ACTION
} from '../actions/lookupsQnaireActions';

export const lookupsQnairesReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_LOOKUPSQNAIRE_SUCCESS_ACTION:
            const newLookupsQnaires = action.payload;
            const oldLookupsQnaires = Object.values({...state}).filter(s => !newLookupsQnaires.some(newS => newS.id === s.id));
            const newLookupQnaireState = oldLookupsQnaires.concat(newLookupsQnaires); //Creates a copy...a new state
            return newLookupQnaireState;
        case LOOKUPQNAIRE_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case LOOKUPQNAIRE_EDIT_ACTION:
            const newLookupQnaire = action.payload;
            const lookupsQnairesCopy = Object.values({...state}).filter(s => !(newLookupQnaire.id === s.id)); //Creates a copy...a new state
            lookupsQnairesCopy.push(newLookupQnaire);
            return lookupsQnairesCopy;
        case LOOKUPQNAIRE_DELETE_ACTION:
            return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
};