import { Storage } from 'aws-amplify';

export const FETCHING_MAPLAYER_ACTION = 'FETCHING_MAPLAYER_ACTION';
export const FETCH_MAPLAYER_SUCCESS_ACTION = 'FETCH_MAPLAYER_SUCCESS_ACTION';
export const FETCH_MAPLAYER_ERROR_ACTION = 'FETCH_MAPLAYER_ERROR_ACTION';

export const MAPLAYER_ADD_ACTION = 'MAPLAYER_ADD_ACTION';
export const MAPLAYER_EDIT_ACTION = 'MAPLAYER_EDIT_ACTION';
export const MAPLAYER_DELETE_ACTION = 'MAPLAYER_DELETE_ACTION';

// Used when creating a question with a question type of 'map'
export async function mapLayerSaveFiles(question, mapLayer) {
  try {
    if(mapLayer !== undefined){
      if (mapLayer.name.includes('.kml')) {
        await Storage.put(`questionaires/${question.questionaireId}/maplayers/${question.id}.kml`, mapLayer, {});
      } 
      else{
        // Handle unsupported file types
        throw new Error(`Unsupported file type: ${mapLayer.type}`);
      }
    }
    else{
      console.log("no file to upload!")
    }
  } catch (error) {
    throw error;
  }
}


// Used when deleting a question with a question type of 'map'
export async function mapLayerDeleteFile(questionaire, question) {
  try {
    // Remove KML file
    await Storage.remove(`questionaires/${question.questionaireId}/maplayers/${question.id}.kml`, {});

    // Remove JSON file
    await Storage.remove(`questionaires/${question.questionaireId}/maplayers/${question.id}.json`, {});
  } catch (error) {
    throw error;
  }
}


