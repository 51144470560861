import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_INTENTS_ACTION = 'FETCHING_INTENTS_ACTION';
export const FETCH_INTENTS_SUCCESS_ACTION = 'FETCH_INTENTS_SUCCESS_ACTION';
export const FETCH_INTENTS_ERROR_ACTION = 'FETCH_INTENTS_ERROR_ACTION';

export const INTENT_ADD_ACTION = 'INTENT_ADD_ACTION';
export const INTENT_EDIT_ACTION = 'INTENT_EDIT_ACTION';
export const INTENT_DELETE_ACTION = 'INTENT_DELETE_ACTION';

export const fetchIntentsAction = () => {
  const queryParams = {
    PK: 'INTENTS',
    SK: 'INTENTS',
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = JSON.parse(result.Items[0].intentNames);
        dispatch({ type: FETCH_INTENTS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        dispatch({ type: FETCH_INTENTS_ERROR_ACTION, err });
      });
  };
}
