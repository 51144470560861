import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Container, Form, Button, Icon, Divider } from "semantic-ui-react";

import * as USER_GROUPS from '../../../actions/userGroupsActions';

export function UserGroupDetails(props) {
    const [state, setState] = useState({
        userGroup: props.userGroup
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = () => {
        props.saveUserGroupAction(props.tenant, state.userGroup, props.isNew)

        setState((state) => ({ ...state, }));
        if (_.isEmpty(props.onComplete)) props.onComplete();
    };

    const _buildName = () => {
        return (
            <Form.Field className="full-width-field">
                <label>Group Name</label>
                <input
                    name="name"
                    className={errors.name ? "invalid-input-field" : ""}
                    {...register("name", { required: true, maxLength: 128 })}
                    placeholder="Name"
                    value={state.userGroup.name}
                    onChange={(e) => {
                        setState((prevState) => ({
                            ...prevState,
                            userGroup: { ...prevState.userGroup, name: e.target.value },
                        }));
                    }}
                />
                {errors.name && errors.name.type === "required" && (
                    <p className="validation-error">* Please enter a name</p>
                )}
                {errors.name && errors.name.type === "maxLength" && (
                    <p className="validation-error">
                        * Name may not be more than 128 characters long
                    </p>
                )}
            </Form.Field>
        );
    };


      const _buildDescription = () => {
        return (
          <Form.Field>
            <label>Group Description</label>
            <textarea
              name="description"
              className={errors.description ? "invalid-input-field" : ""}
              {...register("description", { maxLength: 500 })}
              placeholder="Description"
              value={state.userGroup.description}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  userGroup: { ...prevState.userGroup, description: e.target.value },
                }));
              }}
            />
            {errors.description && errors.description.type === "maxLength" && (
              <p className="validation-error">
                * Description may not be more than 500 characters long
              </p>
            )}
          </Form.Field>
        );
      };

      return (
        <Container>
            <Form
                  size="small"
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ paddingBottom: "20px" }}
              >
                  {_buildName()}
                  <br></br>
                  {_buildDescription()}
                  <Divider />
                  <Button.Group floated="right">
                      <Button
                          className="danger"
                          onClick={() => {
                              if (_.isEmpty(props.onComplete)) {
                                  props.onComplete();
                              }
                          }}
                      >
                          <Icon name="remove" /> Cancel
                      </Button>
                      <Button type="submit" className="success">
                          <Icon name="checkmark" /> Ok
                      </Button>
                  </Button.Group>
              </Form>
        </Container>
      );
}

const mapStateToProps = (state, ownProps) => {
    let userGroup = {};
    let isNew = false;
    let newGroup = {
        name: '',
        description: '',
    };

    if (ownProps.bdaction === 'add') {
        userGroup = newGroup;
        isNew = true;
    } else {
        userGroup = _.isEmpty(ownProps.group)
            ? newGroup
            : ownProps.group;
        isNew = false;
    }

    return{
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        tenant: state.tenantManagement.activeTenant,
        isNew: isNew,
        userGroup: userGroup,
    }
}

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch) => ({
        saveUserGroupAction: (tenant, group, isNew) => dispatch(USER_GROUPS.saveUserGroupAction(tenant, group, isNew)),
    })
)(UserGroupDetails)