import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Dropdown,
  Checkbox,
  Confirm
} from "semantic-ui-react";

import * as QUESTIONNAIRE_ACTIONS from "../../../actions/questionaireActions";
import * as QNAIREBR_ACTIONS from "../../../actions/brQnaireActions";
import * as BRULE_ACTIONS from "../../../actions/businessRuleActions";

export function QuestionaireBRDetails(props) {
  const [state, setState] = useState({
    brQnaire: { ...props.brQnaire },
    isChanged: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    props.fetchBRulesAction(props.qnaire.tenantId);
  }, []);

  const onSubmit = (data) => {

    const isQuestionnaireActive = props.qnaire.status == "active" || props.qnaire.status == "underReview";

		if(isQuestionnaireActive){
			setState((state) => ({ ...state, showConfirmEditBRQnaire: true, data: data }));
    }else{
      let toCommit = state.brQnaire;

      if (!_.isEmpty(data.brule_name)) {

        const selectedBRule = bRulesList.filter((brule) => brule.key === data.brule_name).map((brule) => ({ text: brule.text }));

        if (!_.isEmpty(selectedBRule))
          toCommit.brName = selectedBRule[0].text;
      }

      if (!_.isEmpty(data.execution_rule)) {

        const selectedExeRule = brExecutionRules.filter((exeRule) => exeRule.value === data.execution_rule).map((exeRule) => ({ text: exeRule.text }));

        if (!_.isEmpty(selectedExeRule))
          toCommit.brExecutionRuleVal = selectedExeRule[0].text;
      }
      else if (!_.isEmpty(toCommit.brExecutionRuleVal)) {
        toCommit.brExecutionRuleVal = "";
      }

      setState((state) => ({ ...state, isChanged: false }));

      props.saveBRQnaireAction(toCommit, props.tenantId);
      if (_.isEmpty(props.onComplete)) {
        props.onComplete();
      }
    }
  };

  const confirmEditBRQnaire = () => {
    let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";

    return (
      <Confirm
        header='Warning:'
        content={contentMessage}
        open={state.showConfirmEditBRQnaire}
        onCancel={() => setState((state) => ({ ...state, showConfirmEditBRQnaire: false }))}
        onConfirm={() => {
          setState((state) => ({ ...state, showConfirmEditBRQnaire: false }));
          let toCommit = state.brQnaire;

          if (!_.isEmpty(state.data.brule_name)) {

            const selectedBRule = bRulesList.filter((brule) => brule.key === state.data.brule_name).map((brule) => ({ text: brule.text }));

            if (!_.isEmpty(selectedBRule))
              toCommit.brName = selectedBRule[0].text;
          }

          if (!_.isEmpty(state.data.execution_rule)) {

            const selectedExeRule = brExecutionRules.filter((exeRule) => exeRule.value === state.data.execution_rule).map((exeRule) => ({ text: exeRule.text }));

            if (!_.isEmpty(selectedExeRule))
              toCommit.brExecutionRuleVal = selectedExeRule[0].text;
          }
          else if (!_.isEmpty(toCommit.brExecutionRuleVal)) {
            toCommit.brExecutionRuleVal = "";
          }

          setState((state) => ({ ...state, isChanged: false }));
          props.deactiveQuestionaireAction(props.qnaire);
          props.saveBRQnaireAction(toCommit, props.tenantId);
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      />
    );
  }


  const brExecutionRules = [
    { key: 0, text: "None", value: "" },
    { key: 1, text: "On Questionnaire Started", value: "uqnaire_started" },
    { key: 2, text: "On Questionnaire Completed", value: "uqnaire_completed" },
  ];

  const bRulesList = _.orderBy(props.bRules
    .map((brRule) => ({
      key: brRule.id,
      text: brRule.bRule,
      value: brRule.id,
    })), ['text']);

    const bRuleDropdown = () => {
      var defaultValue = state.brQnaire.brNameId ? state.brQnaire.brNameId : "";
  
      return (
        <Controller
          name="brule_name"
          control={control}
          rules={{ required: true }}
          setValue={setValue}
          defaultValue={defaultValue}
          render={({ field, fieldState, formState }) => {
            return (
              <div>
                <Dropdown
                  className={fieldState.invalid ? "invalid-input-field" : ""}
                  {...field}
                  placeholder="Select a Business Rule"
                  fluid
                  selection
                  search
                  options={bRulesList}
                  value={state.brQnaire.brNameId}
                  onChange={(e, { value }) => {
                    setState((state) => ({
                      ...state,
                      brQnaire: {
                        ...state.brQnaire,
                        brNameId: value,
                      },
                      isChanged: true,
                    }));
                    field.onChange(value);
                  }}
                />
                {fieldState.invalid && (
                  <p className="validation-error">* Select a Business Rule</p>
                )}
              </div>
            );
          }}
        />
      );
    };
  
    const brExecutionRuleDropdown = () => {
      var defaultValue = state.brQnaire.brExecutionRule
        ? state.brQnaire.brExecutionRule
        : "";
    
      return (
        <Controller
          name="execution_rule"
          control={control}
          rules={{ required: false }}
          setValue={setValue}
          defaultValue={defaultValue}
          render={({ field, fieldState, formState }) => {
            return (
              <div>
                <Dropdown
                  className={fieldState.invalid ? "invalid-input-field" : ""}
                  {...field}
                  placeholder="Select an execution rule"
                  fluid
                  selection
                  search
                  options={brExecutionRules}
                  value={state.brQnaire.brExecutionRule}
                  onChange={(e, { value }) => {
                    if (state.brQnaire.brScheduled && (_.isEmpty(value) && !["None"].includes(value))) {
                      // Display an error message with the name of the active default questionnaire
                      toast.error(
                        `Please provide Execution Rule or Schedule.`
                      );
                      return;
                    }

                    setState((state) => ({
                      ...state,
                      brQnaire: {
                        ...state.brQnaire,
                        brExecutionRule: value,
                      },
                      isChanged: true,
                    }));
                    field.onChange(value);
                  }}
                />
              </div>
            );
          }}
        />
      );
    };

  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
      {confirmEditBRQnaire()}
    <Form.Field>
      <label>Link to Business Rule</label>
      {bRuleDropdown()}
    </Form.Field>
    <Form.Field>
      <label>Execution Rule</label>
      {brExecutionRuleDropdown()}
    </Form.Field>
    <Form.Field>
        <Checkbox
          toggle
          label="Scheduled"
          checked={!!state.brQnaire.brScheduled}
          onChange={() => {
            if (!state.brQnaire.brScheduled && (!_.isEmpty(state.brQnaire.brExecutionRule) && !["Nonde"].includes(state.brQnaire.brExecutionRule))) {
              // Display an error message with the name of the active default questionnaire
              toast.error(
                `Please provide Execution Rule or Schedule.`
              );
              return;
            }

            setState((prevState) => ({
              ...prevState,
              brQnaire: {
                ...prevState.brQnaire,
                brScheduled: !prevState.brQnaire.brScheduled,
              },
            }));
            setState((state) => ({ ...state, isChanged: true}));
          }}
        />
      </Form.Field>
    {state.brQnaire.brScheduled && (
    <>
    <Form.Field>
        <Checkbox
          toggle
          label="Daily"
          checked={!!state.brQnaire.brSchedDaily}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              brQnaire: {
                ...prevState.brQnaire,
                brSchedDaily: !prevState.brQnaire.brSchedDaily,
              },
            }));
            setState((state) => ({ ...state, isChanged: true}));
          }}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          toggle
          label="Weekly"
          checked={!!state.brQnaire.brSchedWeekly}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              brQnaire: {
                ...prevState.brQnaire,
                brSchedWeekly: !prevState.brQnaire.brSchedWeekly,
              },
            }));
            setState((state) => ({ ...state, isChanged: true}));
          }}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          toggle
          label="Monthly"
          checked={!!state.brQnaire.brSchedMonthly}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              brQnaire: {
                ...prevState.brQnaire,
                brSchedMonthly: !prevState.brQnaire.brSchedMonthly,
              },
            }));
            setState((state) => ({ ...state, isChanged: true}));
          }}
        />
      </Form.Field>
      {/* <Form.Field>
      <label>Scheduled Time</label>
      <TimePicker
        value="state.brQnaire.brSchedTime"
        onChange={() => {
          setState((prevState) => ({
            ...prevState,
            brQnaire: {
              ...prevState.brQnaire,
              brSchedTime: !prevState.brQnaire.brSchedTime,
            },
          }));
        }}
      />
      </Form.Field> */}
      </>
    )}

    <Divider />
    <Button.Group floated="right">
      <Button
        className="danger"
        onClick={() => {
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success" disabled={!state.isChanged}>
        <Icon name="checkmark" /> Ok
      </Button>
    </Button.Group>
  </Form>
  );
}

const mapStateToProps = (state, ownProps) => {
  var brQnaire = {};
  var isNew = false;
  var newBRQnaire = {
    qnairdId: '',
    brName: '',
    brNameId: '',
    brExecutionRule: '',
    brExecutionRuleVal: '',
    brAttrs: null,
  };

  if (ownProps.braction === 'add') {
    brQnaire = newBRQnaire;
    brQnaire.qnaireId = _.isEmpty(ownProps.qnaire) ? '' : ownProps.qnaire.id;
    isNew = true;
  } else {
    brQnaire = _.isEmpty(ownProps.brQnaire) ? newBRQnaire : ownProps.brQnaire;
    isNew = false;
  }

  return {
    brQnaire: brQnaire,
    isNew: isNew,
		brQnaires: (_.isEmpty(state.brQnaires)) ? [] : Object.values(state.brQnaires),
		bRules: (_.isEmpty(state.bRules)) ? [] : Object.values(state.bRules),
    tenantId: state.tenantManagement.activeTenant.tenantId
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		fetchBRulesAction: (bRule) => dispatch(BRULE_ACTIONS.fetchbRulesAction(bRule)),
		deactiveQuestionaireAction: (questionaire) => dispatch(QUESTIONNAIRE_ACTIONS.deactiveQuestionaireAction(questionaire)),
		saveBRQnaireAction: (brQnaire, tenantId) => dispatch(QNAIREBR_ACTIONS.bRQnaireSaveAction(brQnaire, tenantId)),
  })
  )(QuestionaireBRDetails);
