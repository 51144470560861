import React from 'react';
import { Segment, Header } from 'semantic-ui-react';

function PlaceholderSegment(props) {
  return (
    <Segment>
      <Header color={props.textColor}>
        <div style={{textAlign: "center"}}>{props.text}</div>
      </Header>
    </Segment>
  );
}

export default PlaceholderSegment