import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { Table, Button } from "semantic-ui-react";

const ImageLink = ({ answer, questionnaireId, userQuestionnaireId }) => {
  const [signedUrl, setSignedUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSignedUrl = async () => {
      try {
        setLoading(true);
        const imageUrl = `${process.env.REACT_APP_ENV}/questionnaireFiles/${questionnaireId}/${userQuestionnaireId}/${answer.values}.jpg`;
        const url = await Storage.get(imageUrl);
        setSignedUrl(url);
      } catch (error) {
        console.error("Error getting signed URL:", error);
      } finally {
        setLoading(false);
      }
    };

    getSignedUrl();
  }, [answer, questionnaireId, userQuestionnaireId]);

  const openImageInNewTab = () => {
    if (signedUrl) {
      window.open(signedUrl, "_blank");
    }
  };

  return (
    <Table.Row>
      <Table.Cell style={{ width: "90%" }}>
        {loading ? (
          "Loading image..."
        ) : signedUrl ? (
          <Button
            onClick={openImageInNewTab}
            style={{ backgroundColor: "#3A95A4", color: "white" }}
          >
            View Image
          </Button>
        ) : (
          "Image not available"
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default ImageLink;
