import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_MICROSERVICES_ACTION = 'FETCHING_MICROSERVICES_ACTION';
export const FETCH_MICROSERVICES_SUCCESS_ACTION = 'FETCH_MICROSERVICES_SUCCESS_ACTION';
export const FETCH_MICROSERVICES_ERROR_ACTION = 'FETCH_MICROSERVICES_ERROR_ACTION';

export const fetchMicroServiceAction = () => {
    const queryParams = {
      PK: 'MICROSERVICES',
      SK: 'MICROSERVICES',
    };
  
    return (dispatch, getState) => {
      dispatch({ type: FETCHING_MICROSERVICES_ACTION });
      fetchItemsWS('get-dynamodb', queryParams, getState)
        .then((result) => {
          const returnItems = result.Items;
          dispatch({ type: FETCH_MICROSERVICES_SUCCESS_ACTION, payload: returnItems });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: FETCH_MICROSERVICES_ERROR_ACTION, err });
        });
    };
  }