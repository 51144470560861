import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Dropdown
} from "semantic-ui-react";

import * as USER_ACTIONS from '../../../actions/tenantUsersActions';
import * as ROLE_ACTIONS from '../../../actions/tenantRolesActions';

export function RoleUserDetails(props) {
  const [state, setState] = useState({
    isChanged: true,
		selectedUserId: '',
  });

	const getSelectedUser = () => {
		const user = props.users.filter((user) => user.id === state.selectedUserId);
		if(_.isEmpty(user)) return {id: "", username:"", firstname: "", lastname: "", email:""};
		return user[0];
	}

	
	useEffect(() => {
		props.fetchTenantUsersAction(props.tenant);
	}, [props.tenant, props.tenant.id])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
		const user = getSelectedUser();
		if(!_.isEmpty(user.id)){

			const role = props.role;

			const {id: userId, ...userDetails} = user;
			const userRole = {
				...userDetails,
				roleId: role.id,
				roleName: role.name,
				roleDescription: role.description,
				permissions: role.permissions,
			};

			props.saveRoleUsersAction(props.tenant, userRole, role);
			if (_.isEmpty(props.onComplete)) {
				props.onComplete();
			}
		}
  };

	
	const _buildUserDropdown = () => {
		return <Form.Field>
		<label>User</label>
		<select
		name="user"
		className={errors.question_text ? "invalid-input-field" : ""}
		{...register("user", { required: true, maxLength: 128 })}
		placeholder="Select a user"
		value={state.selectedUserId}

		onChange={(e) => {
			setState((prevState) => ({
				...prevState,
				isChanged: true,
				selectedUserId: e.target.value,
			}));
		}}
		>
			<option value="" disabled selected>Select a user</option>
			{props.users.map((user) => {
				return (<option key={`${user.id}`} value={`${user.id}`} >{`${user.firstname} ${user.lastname}`}</option>);
			})}
		</select>
		{errors.user && errors.user.type === "required" && (
			<p className="validation-error">* Please enter a definition</p>
		)}
		{errors.question_text && errors.user.type === "pattern" && (
			<p className="validation-error">
				* May only contain alphabetical characters
			</p>
		)}
		{errors.question_text && errors.user.type === "maxLength" && (
			<p className="validation-error">
				* Name may not be more than 128 characters long
			</p>
		)}
	</Form.Field>;
	}
    
  
  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
		{_buildUserDropdown()}

  <Divider />
    <Button.Group floated="right">
      <Button
        className="danger"
        onClick={() => {
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success" disabled={!state.isChanged}>
        <Icon name="checkmark" /> Add
      </Button>
    </Button.Group>
  </Form>
  );
}

const mapStateToProps = (state, ownProps) => {

	const tenantUsers = state.userManagement.users;
	const allUsers = _.isEmpty(tenantUsers) ? [] : Object.values(tenantUsers);


	const selectedRoleUsers = state.userManagement.selectedRoleUsers;
	const roleUsers = _.isEmpty(selectedRoleUsers) ? [] : Object.values(selectedRoleUsers);

	const users = allUsers.filter((tUser) => {
		const doesInclude = roleUsers.filter((rUser) => {
			if(rUser.id === tUser.id) return true;
			return rUser.userName === tUser.userName;
		}).length > 0;
		return !doesInclude;
	});

  return {
    tenant: state.tenantManagement.activeTenant,
    user: ownProps.user,
		users: users,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		fetchTenantUsersAction: (tenant) => dispatch(USER_ACTIONS.fetchUsersAction(tenant)),
		saveRoleUsersAction: (tenant, userRole, role) => dispatch(ROLE_ACTIONS.roleUserSaveAction(tenant, userRole, role)),
  })
  )(RoleUserDetails);
