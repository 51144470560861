import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Container, Form, Button, Icon, Segment, Header, Popup } from "semantic-ui-react";
import { toast } from 'react-toastify';
import * as PRICES_ACTIONS from '../../../actions/globalPricingActions';

// Conversion functions
const convertPricesToRand = (prices) => {

    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    
    if (!prices || !prices.prices) return prices;
    return {
        ...prices,
        prices: {
            ...prices.prices,
            qnaireCost: formatter.format(Math.abs(prices.prices.qnaireCost /100)),
            qnaireCompletionCost: formatter.format(Math.abs(prices.prices.qnaireCompletionCost /100)),
            appMessaging: formatter.format(Math.abs(prices.prices.appMessaging /100)),
            whatsAppMessaging: formatter.format(Math.abs(prices.prices.whatsAppMessaging /100)),
            aiAnalysis: formatter.format(Math.abs(prices.prices.aiAnalysis /100)),
            subscriptionFee: formatter.format(Math.abs(prices.prices.subscriptionFee /100)),
            cvSearch: formatter.format(Math.abs(prices.prices.cvSearch /100)),
            minimumBalance: formatter.format(Math.abs(prices.prices.minimumBalance /100)),
        },
    };
};

const convertPricesToCents = (prices) => {
    if (!prices || !prices.prices) return prices;
    return {
        ...prices,
        prices: {
            ...prices.prices,
            qnaireCost: prices.prices.qnaireCost * 100,
            qnaireCompletionCost: prices.prices.qnaireCompletionCost * 100,
            appMessaging: prices.prices.appMessaging * 100,
            whatsAppMessaging: prices.prices.whatsAppMessaging * 100,
            aiAnalysis: prices.prices.aiAnalysis * 100,
            subscriptionFee: prices.prices.subscriptionFee * 100,
            cvSearch: prices.prices.cvSearch * 100,
            minimumBalance: prices.prices.minimumBalance * 100,
        },
    };
};

export function ManageGlobalPaymentValuesPage(props) {
    const [state, setState] = useState({
        globalPrices: {},
        originalPrices: {},
        isChanged: false,
        isEditing: false,
        denied: false,
    });

    const hasPermissions = (category) => {
        return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
    };

    const isAdmin = () => {
        return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
    };

    const canReadGlobalPrices = () => {
        return hasPermissions('Manage Global Pricing') && props.userPermissions['Manage Global Pricing'].actions.READ || isAdmin();
    };

    const canUpdateGlobalPrices = () => {
        return hasPermissions('Manage Global Pricing') && canReadGlobalPrices() && props.userPermissions['Manage Global Pricing'].actions.UPDATE || isAdmin();
    };

    useEffect(() => {
        if (!_.isEmpty(props.tenant)) {
            if (props.isPermissionsFetched) {
                if (canReadGlobalPrices()) {
                    props.fetchGlobalPrices();
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        denied: true,
                    }));
                    toast.error('You do not have permission to view global prices');
                }
            }
        }
    }, [props.tenant, props.isPermissionsFetched]);

    useEffect(() => {
        if (!state.isEditing && props.globalPrices) {
            const globalPricesInRand = convertPricesToRand(props.globalPrices);
            setState((prevState) => ({
                ...prevState,
                globalPrices: globalPricesInRand,
                originalPrices: globalPricesInRand,
                isChanged: false,
            }));
        }
    }, [props.globalPrices, state.isEditing]);

    const {
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = async (data) => {
        const parsedPrices = parsePrices(state.globalPrices.prices);
        const convertedPrices = convertPricesToCents({ ...state.globalPrices, prices: parsedPrices });
        await props.saveGlobalPrices(convertedPrices);
    
        if (_.isFunction(props.onComplete)) {
            props.onComplete();
        }
    
        toggleEdit();
    };

    const parsePrices = (prices) => {
        const parsedPrices = {};
        for (const key in prices) {
            if (prices.hasOwnProperty(key)) {
                const value = prices[key];
                parsedPrices[key] = typeof value === 'string' ? parseFloat(value.replace(",", ".")) : value;
            }
        }
        return parsedPrices;
    };

    const onCancel = () => {
        setState((prevState) => ({
            ...prevState,
            globalPrices: { ...prevState.originalPrices },
            isChanged: false,
            isEditing: false,
        }));
    };

    const toggleEdit = () => {
        setState((prevState) => ({
            ...prevState,
            isEditing: !prevState.isEditing,
        }));
    };

    const handleChange = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            globalPrices: { ...prevState.globalPrices, [field]: value },
            isChanged: true,
        }));
    };

    const handleWheel = (e) => {
        e.target.blur();
    };

    const CustomLabel = ({ text, className }) => (
        <label className={`custom-label ${className}`}>
            {text}
        </label>
    );

    const _buildPricesGroup = () => {
        return (
            <>
                <CustomLabel text="Operating Costs" className="nectaPrimaryBg" />
                <Form.Group widths="equal">
                    <Popup
                        content="The cost of every completed questionnaire sent to users from your dashboard."
                        trigger={
                            <Form.Field>
                                <label>Questionnaire Completion Costs (R):</label>
                                <input
                                    type="text"
                                    name="qnaireCost"
                                    placeholder="Questionnaire delivery cost"
                                    value={state.globalPrices.prices?.qnaireCost || ""}
                                    onChange={(e) => handleChange("prices", { ...state.globalPrices.prices, qnaireCost: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                                    onWheel={handleWheel}
                                    readOnly={!state.isEditing}
                                />
                            </Form.Field>
                        }
                    />
                    <Popup
                        content="The cost to send a message or questionnaire to the Wakamoso Mobile App."
                        trigger={
                            <Form.Field>
                                <label>App Messaging (R):</label>
                                <input
                                    type="number"
                                    name="appMessaging"
                                    placeholder="App messaging"
                                    value={state.globalPrices.prices?.appMessaging || ""}
                                    onChange={(e) => handleChange("prices", { ...state.globalPrices.prices, appMessaging: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                                    onWheel={handleWheel}
                                    readOnly={!state.isEditing}
                                />
                            </Form.Field>
                        }
                    />
                    <Popup
                        content="The cost to send a message or questionnaire via WhatsApp (User does not have Mobile App installed)"
                        trigger={
                            <Form.Field>
                                <label>WhatsApp Messaging (R):</label>
                                <input
                                    type="number"
                                    name="whatsAppMessaging"
                                    placeholder="WhatsApp Messaging"
                                    value={state.globalPrices.prices?.whatsAppMessaging || ""}
                                    onChange={(e) => handleChange("prices", { ...state.globalPrices.prices, whatsAppMessaging: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                                    onWheel={handleWheel}
                                    readOnly={!state.isEditing}
                                />
                            </Form.Field>
                        }
                    />
                </Form.Group>
                <CustomLabel text="Usage Fees" className="nectaPrimaryBg" />
                <Form.Group widths="equal">
                    <Popup
                        content="The fee to run an AI analysis on a data set within your ecosystem."
                        trigger={
                            <Form.Field>
                                <label>AI Analysis (R):</label>
                                <input
                                    type="number"
                                    name="aiAnalysis"
                                    placeholder="AI analysis"
                                    value={state.globalPrices.prices?.aiAnalysis || ""}
                                    onChange={(e) => handleChange("prices", { ...state.globalPrices.prices, aiAnalysis: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                                    onWheel={handleWheel}
                                    readOnly={!state.isEditing}
                                />
                            </Form.Field>
                        }
                    />
                    <Popup
                        content="The monthly cost per user linked to your tenant dashboard. "
                        trigger={
                            <Form.Field>
                                <label>Subscription Fee (R):</label>
                                <input
                                    type="number"
                                    name="subscriptionFee"
                                    placeholder="Subscription Fee"
                                    value={state.globalPrices.prices?.subscriptionFee || ""}
                                    onChange={(e) => handleChange("prices", { ...state.globalPrices.prices, subscriptionFee: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                                    onWheel={handleWheel}
                                    readOnly={!state.isEditing}
                                />
                            </Form.Field>
                        }
                    />
                    <Popup
                        content="The cost to access the personal information linked to a CV that you wish to access."
                        trigger={
                            <Form.Field>
                                <label>CV Purchase Fee (R):</label>
                                <input
                                    type="number"
                                    name="cvSearch"
                                    placeholder="CV Search"
                                    value={state.globalPrices.prices?.cvSearch || ""}
                                    onChange={(e) => handleChange("prices", { ...state.globalPrices.prices, cvSearch: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                                    onWheel={handleWheel}
                                    readOnly={!state.isEditing}
                                />
                            </Form.Field>
                        }
                    />
                    <Popup
                        content="The % of the CV Purchase Fee paid to the data subject (person whose personal information we share with you)"
                        trigger={
                            <Form.Field>
                                <label>CV Owner Fee Split (%):</label>
                                <input
                                    type="number"
                                    name="cvOwnerSplit"
                                    placeholder="CV Owner Split"
                                    value={state.globalPrices.prices?.cvOwnerSplit || ""}
                                    onChange={(e) => handleChange("prices", { ...state.globalPrices.prices, cvOwnerSplit: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                                    onWheel={handleWheel}
                                    readOnly={!state.isEditing}
                                    min="0"
                                    max="100"
                                />
                            </Form.Field>
                        }
                    />
                </Form.Group>
                <CustomLabel text="Minimum Cash Balances" className="nectaPrimaryBg" />
                <Form.Group widths="equal">
                    <Popup
                        content="Enter the minimum cash balance required."
                        trigger={
                            <Form.Field>
                                <label> Minimum Cash Balance (R):</label>
                                <input
                                    type="text"
                                    name="minimumBalance"
                                    placeholder="Minimum Cash Balance"
                                    value={state.globalPrices.prices?.minimumBalance || ""}
                                    onChange={(e) => handleChange("prices", { ...state.globalPrices.prices, minimumBalance: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                                    onWheel={handleWheel}
                                    readOnly={!state.isEditing}
                                />
                            </Form.Field>
                        }
                    />
                </Form.Group>
            </>
        );
    };


    if (state.denied) {
        return (
            <Container>
                <Segment>
                    <Header icon>
                        <Icon name='ban' color="red" />
                        You do not have permissions to view the global prices
                    </Header>
                </Segment>
            </Container>
        );
    }

    return (
        <Container fluid>
            <h3>Manage Global Prices:</h3>
            <Segment raised>
                <Form
                    size="medium"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ paddingBottom: "25px" }}
                >
                    {_buildPricesGroup()}
                    {(canUpdateGlobalPrices() &&
                        <Button.Group floated="right">
                            {!state.isEditing && (
                                <Button.Group floated="right">
                                    <Button className="warning" onClick={toggleEdit}>
                                        <Icon name="pencil" /> Edit
                                    </Button>
                                </Button.Group>
                            )}
                            {state.isEditing && (
                                <>
                                    <Button type="button" className="danger" onClick={onCancel}>
                                        <Icon name="cancel" /> Cancel
                                    </Button>
                                    <Button type="submit" className="success">
                                        <Icon name="checkmark" /> Ok
                                    </Button>
                                </>
                            )}
                        </Button.Group>
                    )}
                </Form>
            </Segment>
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenant: state.tenantManagement.activeTenant,
        globalPrices: state.globalPrices[0],
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
    };
};

export default connect(
    mapStateToProps,
    (dispatch) => ({
        fetchGlobalPrices: () => dispatch(PRICES_ACTIONS.fetchGlobalPricesAction()),
        saveGlobalPrices: (prices) => dispatch(PRICES_ACTIONS.saveGlobalPricesAction(prices)),
    })
)(ManageGlobalPaymentValuesPage);
