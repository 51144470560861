import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { LookUpDTO } from './DTOs/LookUpDTO';
import { createItemWs,updateItemWs } from '../webservices/saveWS';
import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_LOOKUPS_ACTION = 'FETCHING_LOOKUPS_ACTION';
export const FETCH_LOOKUPS_SUCCESS_ACTION = 'FETCH_LOOKUPS_SUCCESS_ACTION';
export const FETCH_LOOKUPS_ERROR_ACTION = 'FETCH_LOOKUPS_ERROR_ACTION';

export const LOOKUP_ADD_ACTION = 'LOOKUP_ADD_ACTION';
export const LOOKUP_EDIT_ACTION = 'LOOKUP_EDIT_ACTION';
export const LOOKUP_DELETE_ACTION = 'LOOKUP_DELETE_ACTION';

export const fetchLookupsAction = (questionaireId, tenantId) => {
  const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${questionaireId}`,
    SK: 'LOOKUP%23',
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_LOOKUPS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_LOOKUPS_ERROR_ACTION, err });
      });
  };
}

export const lookupSaveAction = (lookup,tenantId) => {
  return (dispatch, getState) => {
    const saveObject = new LookUpDTO(lookup,tenantId);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (lookup.id === undefined) {
      saveObject.status = 'active'
      saveObject.values = [];
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: LOOKUP_ADD_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The lookup item has been added successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to add lookup item please try again');
        })
    }
    else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: LOOKUP_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The lookup item has been updated successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to update lookup item please try again');
        })
    }
  }
}

export const lookupDeleteAction = (lookup) => {
  return (dispatch, getState) => {
    const saveObject = new LookUpDTO(lookup);
    saveObject.status = 'isDeleted';
    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: LOOKUP_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success('The lookup item has been deleted successfully');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error('Failed to delete lookup item please try again');
      })
  }
}
