export class TenantUserDTO {
    PK;
    SK;
    createdAt;
    updatedAt;
    tenantName;
    email;
    userName;
    firstname;
    lastname;
    type;
    tenantId;
		op;

    constructor(user, tenant){
        if (user.id != null) {
            this.PK = user.PK;
            this.SK = user.SK;
        }
        else {
            this.PK = `TENANT#${tenant.tenantName}`;
            this.SK = `USER#${user.userName.toLowerCase()}`;
        } 
        this.tenantName = tenant.tenantName;
        this.userName = user.userName;
        this.tenantId = tenant.tenantId;
        this.email = user.email;
        this.type = "TENANTUSER";
        this.firstname = user.firstname;
        this.lastname = user.lastname;
    }
}