import {
  FETCH_INTENTS_SUCCESS_ACTION,
} from '../actions/intentsActions';

export const intentsReducer= (state = [], action) => {
  switch (action.type) {
    case FETCH_INTENTS_SUCCESS_ACTION:
      const newIntents = action.payload;
      const oldIntents = Object.values({...state}).filter(s => !newIntents(newS => newS.id === s.id));
      const newIntentState = oldIntents.concat(newIntents); //Creates a copy...a new state
      return newIntentState;
    default:
      return state;
  }
};