import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  Segment,
  Grid,
  Dropdown,
  Input,
  Table,
  Icon,
  Pagination // Import Pagination
} from "semantic-ui-react";
import * as QUESTION_ACTIONS from "../../../actions/questionActions";
import * as QUESTIONAIRE_ACTIONS from "../../../actions/questionaireActions";

class PDFTemplatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      selectedQuestionnaires: [],
      pptRefSortOrder: "asc",
      currentPage: 1,
      itemsPerPage: 50
    };
  }

  hasPermissions(category) {
    return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category);
  }

  isAdmin() {
    return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin;
  }

  canReadPPTReferences() {
    return this.hasPermissions('PTT References') && this.props.userPermissions['PTT References'].actions.READ || this.isAdmin();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.tenantId, prevProps.tenantId)) {
      if (this.props.isPermissionsFetched) {
        if (this.canReadPPTReferences()) {
          this.props.fetchQuestionairesAction(this.props.tenantId);
          this.props.fetchAllQuestionsAction(this.props.tenantId);
        } else {
          toast.error('You do not have permission to view power point references');
        }
      }
    }
  }

  onSearchInputChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  onDropdownChange = (event, { value }) => {
    this.setState({ selectedQuestionnaires: value });
  };

  copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        toast.success('Reference copied to clipboard!', { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .catch(() => {
        toast.error('Error copying to clipboard!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  onPptRefHeaderClick = () => {
    this.setState((prevState) => ({
      pptRefSortOrder: prevState.pptRefSortOrder === "asc" ? "desc" : "asc",
    }));
  };

  render() {
    const { searchQuery, selectedQuestionnaires, currentPage, itemsPerPage } = this.state;
    const { questionaires, questions } = this.props;

    const filteredQuestions = questions.filter(
      (question) =>
        (selectedQuestionnaires.length === 0 || selectedQuestionnaires.includes(question.questionaireId)) &&
        (question.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (question.questionaireName && question.questionaireName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (question.pptRef && question.pptRef.toLowerCase().includes(searchQuery.toLowerCase())))
    );

    filteredQuestions.sort((a, b) => {
      const nameComparison = a.questionaireName.localeCompare(b.questionaireName);
      if (nameComparison !== 0) return nameComparison;

      const pptRefA = a.pptRef.toLowerCase();
      const pptRefB = b.pptRef.toLowerCase();
      return this.state.pptRefSortOrder === "asc" ? pptRefA.localeCompare(pptRefB) : pptRefB.localeCompare(pptRefA);
    });

    // Pagination logic
    const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage);
    const paginatedQuestions = filteredQuestions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const dropdownOptions = questionaires.map((questionaire) => ({
      key: questionaire.id,
      text: questionaire.name,
      value: questionaire.id,
    }));

    return (
      <div>
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <h3><Icon name="file powerpoint outline" /> Powerpoint References</h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                <Dropdown
                  placeholder="Select questionnaire"
                  value={selectedQuestionnaires}
                  onChange={this.onDropdownChange}
                  options={dropdownOptions}
                  selection
                  multiple
                  search
                  style={{ width: '100%', borderRadius: '5px' }}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Input
                  placeholder="Search..."
                  icon="search"
                  value={searchQuery}
                  onChange={this.onSearchInputChange}
                  style={{ width: '100%', borderRadius: '5px' }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Questionnaire</Table.HeaderCell>
                <Table.HeaderCell>Question</Table.HeaderCell>
                <Table.HeaderCell onClick={this.onPptRefHeaderClick} style={{ cursor: "pointer" }}>
                  PowerPoint Reference
                  {this.state.pptRefSortOrder === "asc" ? <Icon name="caret down" /> : <Icon name="caret up" />}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {paginatedQuestions.map((question) => (
                <Table.Row key={question.id} className="table-cell" onClick={() => this.copyToClipboard(question.pptRef)}>
                  <Table.Cell>{question.questionaireName}</Table.Cell>
                  <Table.Cell>{question.question}</Table.Cell>
                  <Table.Cell>{question.pptRef}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Pagination
            activePage={currentPage}
            totalPages={totalPages}
            onPageChange={(_, data) => this.setState({ currentPage: data.activePage })}
          />
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionaires: _.isEmpty(state.questionaires) ? [] : Object.values(state.questionaires),
  baseQnaires: _.isEmpty(state.baseQuestionnaires) ? [] : Object.values(state.baseQuestionnaires),
  questions: _.isEmpty(state.questions) ? [] : Object.values(state.questions),
  tenantId: _.isEmpty(state.tenantManagement.activeTenant.tenantId) ? {} : state.tenantManagement.activeTenant.tenantId,
  userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
  isPermissionsFetched: state.isPermissionsFetched === true,
});

export default connect(mapStateToProps, {
  fetchQuestionairesAction: QUESTIONAIRE_ACTIONS.fetchQuestionairesAction,
  fetchAllQuestionsAction: QUESTION_ACTIONS.fetchAllQuestionsAction,
  fetchBaseQanireQuestionsActions: QUESTION_ACTIONS.fetchBaseQanireQuestionsActions,
})(PDFTemplatePage);
