import { combineReducers } from 'redux';
import { quesionairesResolver, tenZeroBaseQuestionnaireReducer, baseQuestionnaireReducer, tenantReviewQuestionnaireReducer, allQuestionnairesReducer } from '../reducers/questionairesReducer';
import { questionaireTypesReducer } from './questionaireTypesReducer';
import { SectionReducer } from './sectionsReducer';
import { QuestionsReducer } from './questionsReducer';
import { ConditionsReducer } from './conditionsReducer';
import { userGroupsReducer } from './userGroupsReducer';
import { lookupsReducer } from './lookupsReducer';
import { lookupsQnairesReducer } from './lookupsQnairesReducer';
import { fetchingDataReducer } from './fetchingDataReducer';
import { groupsReducer } from './groupsReducer';
import { businessRuleTypeReducer } from './businessRuleTypeReducer';
import { businessRulesReducer } from './businessRulesReducer';
import { brQnaireReducer } from './brQnaireReducer';
import { microServicesReducer } from './microServiceReducer';
import { intentsReducer } from './IntentsReducer';
import { countryCodesReducer } from './countryCodesReducer';
import { sourceTenantReducer, activeTenantReducer, allTenantsReducer, permissionsFetchedReducer } from './tenantsReducer';
import { authDataReducer } from './authDataReducer';
import { tenantUsersReducer, tenantUserRolesReducer} from './tenantUsersReducer';
import { allPermissionsReducer, tenantRolesReducer, tenantRoleUsersReducer } from './tenantRolesReducer';
import { authUserRolesReducer } from './authUserRolesReducer';
import { ActivationRulesReducer, ActivationRulesQuestionsReducer,ActivationRulesSectionsReducer, ActivationRulesQuestionnairesReducer, ActivationRulesKMLFilesReducer, ActivationRulesUserGroupsReducer, ActivationRulesWhatsAppTemplatesReducer, ActivationRuleLogInvocationEventReducer, ActivationRuleLogSuccessEventReducer } from './activationRulesReducer';
import { kmlFileReducer } from './kmlFilesReducer';
import { exportsReducer, exportQuestionnaireReducer, exportUserGroupsReducer, exportQuestionnaireSectionsReducer, exportQuestionnaireQuestionsReducer } from './exportsReducer';
import { cvSearchReducer, selecetedCVReducer, selecetedCVAnswerReducer, boughtCVReducer } from './cvSearchReducer';
import { groupUserReducer } from './groupUsersReducer';
import { whatsAppTemplateReducer } from './whatsAppTemplatesReducer';
import { questionWhatsAppTemplateReducer } from './questionWhatsAppTemplateReducer';
import { GlobalPricesreducer } from './globalPricesreducer';
import { groupMessagesReducer } from './groupsMessagesReducer';
import { cvSearchLogReducer } from './cvSearchLogReducer';
import { boughtCVsReducer } from './boughtCvsReducer';
import { LastEvaluatedKeyReducer } from './lastEvaluatedkeyReducer';
import { searchGroupUsersReducer } from './groupUsersReducer';
import { AuditTrailreducer } from './auditTrailReducer';
import { TenantBalancesReducer, TenantTransactionsReducer, CurrenciesReducer } from './financesReducer';

import { RESET_STATE_ACTION } from '../actions/registrationActions';

const INITIAL_STATE = {};

const appReducer = combineReducers({
  questionaires: quesionairesResolver,
  baseQuestionnaires: baseQuestionnaireReducer,
  tenZeroBaseQuestionnaires: tenZeroBaseQuestionnaireReducer,
  underReviewQuestionnaires: tenantReviewQuestionnaireReducer,
  allQuestionnaires: allQuestionnairesReducer,
  questionaireTypes: questionaireTypesReducer,
  sections: SectionReducer,
  questions: QuestionsReducer,
  conditions: ConditionsReducer,
  lookups: lookupsReducer,
  lookupsQnaires: lookupsQnairesReducer,
  fetchingData: fetchingDataReducer,
  groups: groupsReducer,
  brTypes: businessRuleTypeReducer,
  bRules: businessRulesReducer,
  brQnaires: brQnaireReducer,
  userGroups: userGroupsReducer,
  microServices: microServicesReducer,
  intents: intentsReducer,
  countryCodes: countryCodesReducer,
  authData: authDataReducer,
  authUserRoles: authUserRolesReducer,
  lastEvaluatedKey: LastEvaluatedKeyReducer,
  searchGroupUsers: searchGroupUsersReducer,
  auditTrailItems : AuditTrailreducer,
  tenantManagement: combineReducers({
    sourceTenant: sourceTenantReducer,
    activeTenant: activeTenantReducer,
    allTenants: allTenantsReducer,
  }),
  userManagement: combineReducers({
    users: tenantUsersReducer,
    selectedUserRoles: tenantUserRolesReducer,
    roles: tenantRolesReducer,
    selectedRoleUsers: tenantRoleUsersReducer,
    allPermissions: allPermissionsReducer,
  }),
  isPermissionsFetched: permissionsFetchedReducer,
  activationRules: combineReducers({
    rules: ActivationRulesReducer,
    questions: ActivationRulesQuestionsReducer,
    sections: ActivationRulesSectionsReducer,
    questionnaires: ActivationRulesQuestionnairesReducer,
    kmlFiles: ActivationRulesKMLFilesReducer,
    userGroups: ActivationRulesUserGroupsReducer,
    whatsAppTemplates: ActivationRulesWhatsAppTemplatesReducer,
    ruleLogs: combineReducers({
      ruleLogsInvocations: ActivationRuleLogInvocationEventReducer,
      ruleLogsSuccesses: ActivationRuleLogSuccessEventReducer,
    })
  }),
  exports: combineReducers({
    exports: exportsReducer,
    exportQnaires: exportQuestionnaireReducer,
    exportUserGroups: exportUserGroupsReducer,
    exportSections: exportQuestionnaireSectionsReducer,
    exportQuestions: exportQuestionnaireQuestionsReducer,
  }),
  kmlFiles: kmlFileReducer,
  cvSearch: combineReducers({
    cvSearch: cvSearchReducer,
    userCV: selecetedCVReducer,
    userCVAnswers: selecetedCVAnswerReducer,
    boughtCV: boughtCVReducer
  }),
  groupUsers: groupUserReducer,
  groupMessages: groupMessagesReducer,
  whatsAppTemplates : whatsAppTemplateReducer,
  cvSearchLogs : cvSearchLogReducer,
  boughtCVs: boughtCVsReducer,
  questionWhatsAppTemplates : questionWhatsAppTemplateReducer,
  globalPrices : GlobalPricesreducer,
  tenantFinances: combineReducers({
    tenantBalances: TenantBalancesReducer,
    tenantTransactions: TenantTransactionsReducer,
    currencies : CurrenciesReducer,
  }),
});

const rootReducer = (state = INITIAL_STATE, action) => {
  if (action.type === RESET_STATE_ACTION) {
    return appReducer(INITIAL_STATE, action);
  }
  return appReducer(state, action);
}


export default rootReducer;