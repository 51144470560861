import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Dropdown,
	Container,
	Checkbox,
} from "semantic-ui-react";

import * as USER_ACTIONS from '../../../actions/tenantUsersActions';
import * as ROLE_ACTIONS from '../../../actions/tenantRolesActions';

export function RolePermissionDetails(props) {

  const [state, setState] = useState({
		role: {...props.role},
    isChanged: true,
		selectedPermissionCategory: _.isEmpty(props.permission?.category) ? '' : props.permission.category,
		selectedPermissionActions: _.isEmpty(props.permission?.actions) ? {} : props.permission.actions,
  });

	const getSelectedPermission = () => {
		if(!props.isNew) return props.permission;
		const permissions = props.allPermissions.filter((permission) => permission.category === state.selectedPermissionCategory);
		if(_.isEmpty(permissions)) return {category: "", displayName:"", description: "", policyName: "", actions:[]};
		return permissions[0];
	}

	
	useEffect(() => {
		props.fetchAllPermissionAction();
	}, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
		const permission = getSelectedPermission();
		
		if(!_.isEmpty(permission.category)){

			const actions = state.selectedPermissionActions;

			const newPermission = {
				...permission,
				actions: actions,
			};

			const role = state.role;
			const oldPermissionsList = _.isArray(role.permissions) ? role.permissions : []; 

			const permissionCategoryMap = {};

			oldPermissionsList.forEach((oldPerm) => {
				permissionCategoryMap[oldPerm.category] = oldPerm;
			});

			permissionCategoryMap[newPermission.category] = newPermission;

			const newPermissionList = Object.keys(permissionCategoryMap).map((key) => permissionCategoryMap[key]);

			const updatedRole = {
				...role,
				permissions: newPermissionList,
			};

			props.updateRoleAction(props.tenant, updatedRole);
			setState((state) => ({ ...state, isChanged: false}));

			if (_.isEmpty(props.onComplete)) {
				props.onComplete();
			}
		}


		
  };

	
	const _buildPermissionDropdown = () => {
		if(!props.isNew) return;
		return <Form.Field>
			<label>Permission</label>
			<select
				name="permission"
				className={errors.question_text ? "invalid-input-field" : ""}
				{...register("permission", { required: true, maxLength: 128 })}
				placeholder="Select a permission"
				value={state.selectedPermissionCategory}

				onChange={(e) => {
					const permissionCategory = e.target.value;
					const permission = props.allPermissions.filter((permission) => permission.category === permissionCategory);

					let permissionActions =  {};
					if(!_.isEmpty(permission)) {
						const actions = permission[0].actions;
						permissionActions = {...actions};
					}
				
					setState((prevState) => ({
						...prevState,
						isChanged: true,
						selectedPermissionCategory: permissionCategory,
						selectedPermissionActions: permissionActions,
					}));
				}}
			>
				<option value="" disabled selected={_.isEmpty(state.selectedPermissionCategory)}>Select a permission</option>
				{props.allPermissions.map((permission, index) => {
					return (<option key={`${permission.category}.${index}`} value={`${permission.category}`} >{`${permission.displayName}`}</option>);
				})}
			</select>
			{errors.permission && errors.permission.type === "required" && (
				<p className="validation-error">* Please enter a definition</p>
			)}
			{errors.question_text && errors.permission.type === "pattern" && (
				<p className="validation-error">
					* May only contain alphabetical characters
				</p>
			)}
			{errors.question_text && errors.permission.type === "maxLength" && (
				<p className="validation-error">
					* Name may not be more than 128 characters long
				</p>
			)}
		</Form.Field>;
	}

	const _buildPermissionActionsList = () => {
		const permission = getSelectedPermission();
		if (_.isEmpty(state.selectedPermissionCategory)) return;
		if (_.isEmpty(state.selectedPermissionActions)) return;
	
		const sortedActions = Object.keys(state.selectedPermissionActions).sort();
	
		return (
			<Container>
				{sortedActions.map((key, index) => {
					const action = state.selectedPermissionActions[key];
					return (
						<Form.Field key={`${key}.${index}`}>
							<Checkbox
								label={key}
								checked={action}
								name={`${permission.category}.${key}`}
								onChange={(e) => {
									const checked = !action;
									setState((prevState) => ({
										...prevState,
										isChanged: true,
										selectedPermissionActions: {
											...state.selectedPermissionActions,
											[key]: checked,
										},
									}));
								}}
							/>
						</Form.Field>
					);
				})}
			</Container>
		);
	};
	
  
  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
		{_buildPermissionDropdown()}
		{_buildPermissionActionsList()}

  <Divider />
    <Button.Group floated="right">
      <Button
        className="danger"
        onClick={() => {
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success" disabled={!state.isChanged}>
        <Icon name="checkmark" /> {props.isNew? "Add" : "Save"}
      </Button>
    </Button.Group>
  </Form>
  );
}

const mapStateToProps = (state, ownProps) => {
	const tenantPermissions = state.userManagement.allPermissions;
	const allPermissions = _.isEmpty(tenantPermissions) ? [] : Object.values(tenantPermissions);
  
	const selectedRolePermissions = ownProps.role.permissions;
	const rolePermissions = _.isEmpty(selectedRolePermissions) ? [] : Object.values(selectedRolePermissions);
  
	const isRootTenant = state.tenantManagement.activeTenant.isRoot; // Assuming the isRoot property indicates whether the tenant is a root tenant
  
	const permissions = allPermissions.filter((tPermission) => {
	  const doesInclude = rolePermissions.some((rPermission) => rPermission.category === tPermission.category);
	  const isRootTenantOnly = tPermission.rootTenantOnly;
  
	  return !doesInclude && !(isRootTenantOnly && !isRootTenant);
	});
  
	return {
	  tenant: state.tenantManagement.activeTenant,
	  permission: ownProps.permission,
	  allPermissions: permissions,
	  role: ownProps.role,
	};
  };
  
  

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		fetchAllPermissionAction: (tenant) => dispatch(ROLE_ACTIONS.fetchAllPermissionAction(tenant)),
		updateRoleAction: (tenant, role) => dispatch(ROLE_ACTIONS.updateRoleAction(tenant, role)),
  })
  )(RolePermissionDetails);
