import { FETCH_MICROSERVICES_SUCCESS_ACTION  } from "../actions/microServiceAction";

export const microServicesReducer= (state = [], action) => {
    switch (action.type) {
        case FETCH_MICROSERVICES_SUCCESS_ACTION:
            const newMicorServices = action.payload;
            const oldMicorServices = Object.values({ ...state }).filter(type => !newMicorServices.some(newMicorService => newMicorService.id === type.id));
            const newMicorServicesState = oldMicorServices.concat(newMicorServices); //Creates a copy...a new state
            //const newQuestionaireTypesState = newQuestionaireTypes; //Creates a copy...a new state
            return newMicorServicesState;
        default:
            return state;
    }
}