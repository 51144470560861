export class TenantUserRoleDTO{
    PK;
    SK;
    GSI8PK;
    GSI8SK;
    id;
    tenantId;
    createdAt;
    UpdatedAt;
    type;
    roleId;
    roleName;
    permissions;
    roleDescription;
		userName;
		firstname;
		lastname;
		email;

    constructor(userRole, tenant){
        this.GSI8PK = `${tenant.tenantId}#ROLE#${userRole.roleId}`;
        if (userRole.id != null) {
            this.PK = userRole.PK;
            this.SK = userRole.SK;
            this.GSI8SK = userRole.GSI8SK;
        }
        else{
            this.GSI8SK = `USER#${userRole.userName}`;
            this.PK = `USER#${userRole.userName}`;
            this.SK = `ROLE#${userRole.roleId}`;
        }
        this.id = userRole.id;
        this.tenantId = tenant.tenantId;
        this.type = "TENANTUSERROLE";
        this.roleId = userRole.roleId;
        this.permissions = userRole.permissions;
        this.roleName = userRole.roleName;
        this.roleDescription = userRole.roleDescription;
        this.userName = userRole.userName;
        this.firstname = userRole.firstname;
        this.lastname = userRole.lastname;
        this.email = userRole.email;
    }
}
