import _ from 'lodash'
import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { BusinessRuleTypeDTO } from './DTOs/BusinessRuleTypeDTO';
import { createItemWs,updateItemWs } from '../webservices/saveWS';
import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_BRTYPES_ACTION = 'FETCHING_BRTYPES_ACTION';
export const FETCH_BRTYPES_SUCCESS_ACTION = 'FETCH_BRTYPES_SUCCESS_ACTION';
export const FETCH_BRTYPES_ERROR_ACTION = 'FETCH_BRTYPES_ERROR_ACTION';

export const BRTYPE_ADD_ACTION = 'BRTYPE_ADD_ACTION';
export const BRTYPE_EDIT_ACTION = 'BRTYPE_EDIT_ACTION';
export const BRTYPE_DELETE_ACTION = 'BRTYPE_DELETE_ACTION';


export const fetchbrTypesAction = (qData) => {
  const queryParams = {
    PK: `${qData}%23BRTYPES`,
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_BRTYPES_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_BRTYPES_ERROR_ACTION, err });
      });
  };
};

export const brTypeSaveAction = (brType, tenantId) => {
  return (dispatch, getState) => {
    const saveObject = new BusinessRuleTypeDTO(brType, tenantId);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (brType.id === undefined) {
      saveObject.status = 'active'
      saveObject.values = [];
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: BRTYPE_ADD_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The Business Rule Type item has been added successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to add Business Rule Type item please try again');
        })
    }
    else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: BRTYPE_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The Business Rule Type item has been updated successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to update Business Rule Type item please try again');
        })
    }
  }
}

export const brTypeDeleteAction = (brType) => {
  return (dispatch, getState) => {
    const saveObject = new BusinessRuleTypeDTO(brType);
    saveObject.status = 'isDeleted';
    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: BRTYPE_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success('The Business Rule Type item has been deleted successfully');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error('Failed to delete Business Rule Type item please try again');
      })
  }
}
