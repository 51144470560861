import {
    FETCH_COUNTRY_CODES_SUCCESS_ACTION
} from '../actions/countryCodesActions'

  export const countryCodesReducer= (state = [], action) => {
  switch (action.type) {
    case FETCH_COUNTRY_CODES_SUCCESS_ACTION:
      const newCountryCodes = action.payload;
      const oldCountryCodes = Object.values({ ...state }).filter(type => !newCountryCodes.some(newCountryCode => newCountryCode.id === type.id));
      const newCountryCodesState = oldCountryCodes.concat(newCountryCodes); //Creates a copy...a new state
      //const newQuestionaireTypesState = newQuestionaireTypes; //Creates a copy...a new state
      return newCountryCodesState;
    default:
      return state;
  }
};