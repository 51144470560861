import React, { Component } from 'react';
import { Segment, Header, Icon, Grid } from "semantic-ui-react";

class FuseCollapsiblePanel extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isCollapsed: this.props.isCollapsed,
			trailingIcon: this.props.trailingIcon,
			leadingIcon: this.props.leadingIcon
		};
	}

	static defaultProps = {
		content: null,
		isCollapsed: true,
		leadingIcon: '',
		trailingIcon: '',
		differentiate: false,
		onEdit: null,
		onDelete: null,
		actionObject: null
	}

	render() {
		return (
			<div>
				<Header attached='top' inverted className={`clickable ${this.props.differentiate ? 'nectaPrimaryBg' : 'nectaDarkGreyBg'}`} onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed})}>
					<Grid>
						{(this.props.leadingIcon !== '' && this.props.leadingIcon !== null) ?
							<Grid.Column width='1' verticalAlign='middle' className='nectaPrimaryBg'>
								<Icon name={this.props.leadingIcon} />
							</Grid.Column>
							: null}
						<Grid.Column width={(this.props.leadingIcon !== '') ? 14 : 15} verticalAlign='middle'>
							{this.props.header}
						</Grid.Column>
						{(this.props.trailingIcon !== '' && this.props.trailingIcon !== null) ?
							<Grid.Column width='1' verticalAlign='middle'>
								{(!this.state.isCollapsed) ? <Icon name={'angle up'} /> : <Icon name={'angle down'} />}
							</Grid.Column>
							: null}

						{/* <Grid.Column width='1'>
                    <Button.Group size='tiny' floated='right'>
                        {(typeof (this.props.onEdit) === 'function') && <Button primary icon='edit' size='tiny' onClick={(e) => {e.stopPropagation(); this.props.onEdit(this.props.actionObject)}}/>}
                        {(typeof (this.props.onDelete) === 'function') && <Button primary icon='trash' size='tiny' onClick={(e) => {e.stopPropagation(); this.props.onDelete(this.props.actionObject)}}/>}
                    </Button.Group>
                </Grid.Column> */}
					</Grid>
				</Header>
				<Segment attached className={this.state.isCollapsed ? 'isCollapsed' : ''}>
					{this.props.content}
				</Segment>
			</div>
		);
	}
}

// why

export default FuseCollapsiblePanel;