import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import _, { template } from "lodash";
import { toast } from "react-toastify";
import { Container, Form, Button, Icon, Divider, Grid, Dropdown, Input, Modal } from "semantic-ui-react";

import * as USER_GROUPS from '../../../actions/userGroupsActions';
import * as ACTIVATION_RULES from '../../../actions/activationRulesActions';

export function GroupMessageDetails(props) {
    const [state, setState] = useState({
        groupMessage: props.groupMessage,
        userGroup: props.group,
        isTemplateModalOpen: false,

    });
    useEffect(() => {
        props.fetchWhatsAppTemplatesAction(props.tenant);
      }, [props.tenant]);

    const {
        control,    
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = async (data) => {

        let message = { 
            title: data.title,
            body: data.body,
            whatsAppTemplate: state.groupMessage.whatsAppTemplate,
            whatsAppTemplatePlaceholderValues: state.groupMessage.whatsAppTemplatePlaceholderValues,
        }

        await props.sendMessageAction(message, state.userGroup, props.tenant);

        if (_.isFunction(props.onComplete)) {
            props.onComplete();
        }
    };

    const handleChange = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            groupMessage: { ...prevState.groupMessage, [field]: value },
        }));
    };

    const [localTemplate, setLocalTemplate] = useState(null);
    const [localInputValues, setLocalInputValues] = useState([]);
  
    const closeTemplateModal = () => {
      setState((prevState) => ({
        ...prevState,
        isTemplateModalOpen: false,
      }))
    };
  
    const handlePlaceholderChange = (index, value) => {
      const newLocalInputValues = [...localInputValues];
      newLocalInputValues[index] = { placeholder: `${index + 1}`, value: value };
      setLocalInputValues(newLocalInputValues);
    };
  
    const extractUniquePlaceholders = (text) => {
      const regex = /{{(\d+)}}/g;
      const placeholders = [];
      let match;
      while ((match = regex.exec(text)) !== null) {
        if (!placeholders.includes(match[1])) {
          placeholders.push(match[1]);
        }
      }
      return placeholders;
    };
  
    const renderInputFields = (text) => {
      const placeholders = extractUniquePlaceholders(text);
      return placeholders.map((placeholder, index) => (
        <div key={index}>
          <Input className="full-width-field"
            placeholder={`Enter value for placeholder ${placeholder}`}
            onChange={(event) => handlePlaceholderChange(index, event.target.value)}
          />
          <br />
        </div>
      ));
    };
  
    const setInputValuesButton = () => {
      setState(state => ({
        ...state,
        groupMessage: {
          ...state.groupMessage,
          whatsAppTemplate: localTemplate,
          whatsAppTemplatePlaceholderValues: localInputValues
        },
        isTemplateModalOpen: false,
      }));
    
      setLocalInputValues([]);
    };
  
    const handleTemplateSelection = (event, { value }) => {
      if (value == null) {
          setState((state) => ({
              ...state,
              groupMessage: {
                  ...state.groupMessage,
                  whatsAppTemplatePlaceholderValues: {}
              },
          }));
      } else {
        setLocalTemplate(value);
        setState(state => ({
          ...state,
          isTemplateModalOpen: true,
        }));
      }
    }; 

    const templatesOptions = [
        { key: 'none', value: null, text: 'Select WhatsApp Template' },
        ...props.whatsAppTemplates
          .map((template) => ({
            key: template.id,
            value: template,
            text: template.name,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      ];

    const _buildWhatsAppTemplate = () => {
        const defaultValue = state.groupMessage.whatsAppTemplate
            ? state.groupMessage.whatsAppTemplate
            : "";

        return (
            <Form.Field className="full-width-field">
                <label>WhatsApp Template</label>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Controller
                                name="template"
                                control={control}
                                rules={{ required: "* Please select a template" }}
                                defaultValue={defaultValue}
                                render={({ field, fieldState }) => (
                                    <div>
                                        <Dropdown
                                            className={fieldState.invalid ? "invalid-input-field" : ""}
                                            {...field}
                                            placeholder="Select WhatsApp Template"
                                            fluid
                                            search
                                            selection
                                            options={templatesOptions}
                                            value={defaultValue}
                                            onChange={(e, { value }) => {
                                                field.onChange(value);
                                                handleTemplateSelection(e, { value });
                                            }}
                                        />
                                        {fieldState.invalid && (
                                            <p className="validation-error">{fieldState.error.message}</p>
                                        )}
                                    </div>
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {state.groupMessage.whatsAppTemplate && state.groupMessage.whatsAppTemplatePlaceholderValues && (
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Placeholder Values For Selected Template:</label>
                                </Form.Field>
                                {state.groupMessage.whatsAppTemplatePlaceholderValues.map((inputValue, index) => (
                                    <div key={index} style={{ marginBottom: '10px' }}>
                                        <Input
                                            label={`Placeholder: ${inputValue.placeholder}`}
                                            value={inputValue.value}
                                            placeholder={inputValue.placeholder}
                                            onChange={event => handlePlaceholderChange(index, event.target.value)}
                                        />
                                    </div>
                                ))}
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
                {localTemplate && (
                    <Modal open={state.isTemplateModalOpen} onClose={closeTemplateModal} size="large">
                        <Modal.Header>WhatsApp Template: {localTemplate.name}</Modal.Header>
                        <Modal.Content>
                            {localTemplate.components.map((component, index) => (
                                component.type === 'BODY' && (
                                    <div key={index}>
                                        <textarea rows={10} style={{ width: '100%' }} readOnly>
                                            {component.text}
                                        </textarea>
                                        {renderInputFields(component.text)}
                                    </div>
                                )
                            ))}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button.Group floated="right" style={{ paddingBottom: "10px" }}>
                                <Button onClick={closeTemplateModal} className="danger">
                                    <Icon name="remove" />
                                    Close
                                </Button>
                                <Button onClick={setInputValuesButton} className="success">
                                    <Icon name="checkmark" />
                                    Select Template
                                </Button>
                            </Button.Group>
                        </Modal.Actions>
                    </Modal>
                )}
            </Form.Field>
        );
    };

    const _buildMessageTitle = () => {
        return (
            <Form.Field className="full-width-field">
                <label>Message Title: </label>
                <input
                    name="title"
                    className={errors.title ? "invalid-input-field" : ""}
                    {...register("title", { required: true, maxLength: 128 })}
                    placeholder="Message Title"
                    value={state.groupMessage.message?.title || ""}
                    readOnly={!props.isNew}

                    onChange={(e) => handleChange("message", { ...state.groupMessage.message, title: e.target.value })}

                />
                {errors.title && errors.title.type === "required" && (
                    <p className="validation-error">* Please enter a message title</p>
                )}
                {errors.title && errors.title.type === "maxLength" && (
                    <p className="validation-error">
                        * Message title may not be more than 128 characters long
                    </p>
                )}
            </Form.Field>
        );
    };

    const _buildMessageBody = () => {
        return (
            <Form.Field className="full-width-field">
                <label>Message Body: </label>
                <textarea
                    style={{ color: "DarkGrey" }}
                    name="body"
                    className={errors.body ? "invalid-input-field" : ""}
                    {...register("body", { required: true })}
                    placeholder="Message Body"
                    value={state.groupMessage?.message.body || ""}
                    readOnly={!props.isNew}
                    onChange={(e) => handleChange("message", { ...state.groupMessage.message, body: e.target.value })}

                />
                {errors.body && errors.body.type === "required" && (
                    <p className="validation-error">* Please enter a message body</p>
                )}
            </Form.Field>
        );
    };

    return (
        <Container>
            <Form
                size="small"
                onSubmit={handleSubmit(onSubmit)}
                style={{ paddingBottom: "20px" }}
            >
                {_buildWhatsAppTemplate()}
                {_buildMessageTitle()}
                {_buildMessageBody()}
                <Divider />
                <Button.Group floated="right">
                    <Button
                        className="danger"
                        onClick={() => {
                            if (_.isEmpty(props.onComplete)) {
                                props.onComplete();
                            }
                        }}
                    >
                        <Icon name="remove" /> Cancel
                    </Button>
                    {props.isNew && ( // Render send button only if isNew is true
                        <Button type="submit" className="success">
                            <Icon name="checkmark" /> Send
                        </Button>
                    )}
                    </Button.Group>
            </Form>
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    let groupMessage = {};
    let isNew = false;
    let newMessage = {
        message: {
            title: "",
            body: "",
        },
        // whatsAppTemplate: {},
        // whatsAppTemplatePlaceholderValues: {},
    };

    if (ownProps.bdaction === "add") {
        groupMessage = newMessage;
        isNew = true;
    } else {
        groupMessage = _.isEmpty(ownProps.message) ? newMessage : ownProps.message;
        isNew = false;
    }

    return {
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        tenant: state.tenantManagement.activeTenant,
        isNew: isNew,
        groupMessage: groupMessage,
        whatsAppTemplates: _.isEmpty(state.activationRules.whatsAppTemplates) ? [] : Object.values(state.activationRules.whatsAppTemplates),
    };
};

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch) => ({
        sendMessageAction: (message, userGroup ,tenant) => dispatch(USER_GROUPS.sendMessageAction(message, userGroup ,tenant)),
        fetchWhatsAppTemplatesAction: (tenant) => dispatch(ACTIVATION_RULES.fetchWhatsAppTemplatesAction(tenant)),
    })
)(GroupMessageDetails);
