import { FETCH_GLOBALPRICES_SUCCESS_ACTION, GLOBALPRICES_EDIT_ACTION} from '../actions/globalPricingActions';

export const GlobalPricesreducer = (state = [], action) => {
    switch(action.type){
        case FETCH_GLOBALPRICES_SUCCESS_ACTION:
            const newPrices = action.payload;
            const oldPrices = Object.values({...state}).filter(s => !newPrices.some(newS => newS.id === s.id));
            const newPricesState = oldPrices.concat(newPrices); 
            return newPricesState;
        // case QUESTION_ADD_ACTION:
        //     const clonedState = [...state];
        //     clonedState.push(action.payload);
        //     return clonedState;
        case GLOBALPRICES_EDIT_ACTION:
            const newPrice = action.payload;
            const pricesCopy = Object.values({...state}).filter(s => !(newPrice.id === s.id)); //Creates a copy...a new state
            pricesCopy.push(newPrice);
            return pricesCopy;
        // case QUESTION_DELETE_ACTION:
        //     return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
};