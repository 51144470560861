import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { toast } from "react-toastify";
import { Container, Form, Button, Icon, Divider, Grid, Input, Label, FormField, Dropdown, Checkbox, Segment } from "semantic-ui-react";

export function CVSearchLogDetails(props) {
    const [state, setState] = useState({
        cvSearchLog: props.cvSearchLog,
    });

    const _buildSearchLog = () => {
        const { cvSearchLog } = state;

        // Extracting data
        const { terms, languages, qualifications, locationRadius } = cvSearchLog;
        const { radius, location } = locationRadius;

        // Formatting data into readable format
        const formattedLanguages = languages.join(', ');
        const formattedQualifications = qualifications.join(', ');
        const formattedLocation = `${location.latitude}, ${location.longitude}`;

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Form.Field>
                            <Label>Search Terms:</Label>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {state.cvSearchLog.terms.map((term, index) => (
                                    <div key={index} style={{ marginRight: '10px', marginBottom: '5px' }}>
                                        <Label>
                                            {/* {term.operator && `(${term.operator})`} {term.value}  */}
                                            {term}
                                            {/* <Icon name='delete' onClick={() => handleDeleteSearchTerm(index)} /> */}
                                        </Label>
                                    </div>
                                ))}
                            </div>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Label>Languages:</Label> {formattedLanguages}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Label>Qualifications:</Label> {formattedQualifications}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Label>Radius (km):</Label>
                        <Label>{radius}</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <FormField>
                        <label>Location:</label>
                    </FormField>
                    <Grid.Column width={16}>
                        <FormField>
                            <Label>Latitude:</Label>
                            <Label>{location.latitude}</Label>
                        </FormField>
                        <FormField>
                            <Label>Longitude:</Label>
                            <Label>{location.longitude}</Label>
                        </FormField>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    const _buildSearch = () => {

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <Form.Field>
                            <label>Qualifications:</label>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {state.cvSearchLog?.qualifications?.map((qualification, index) => (
                                    <div key={index} style={{ marginRight: '10px', marginBottom: '5px', marginTop: '5px' }}>
                                        <Label>
                                            {qualification}
                                        </Label>
                                    </div>
                                ))}
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <label>Languages:</label>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {state.cvSearchLog?.languages?.map((language, index) => (
                                    <div key={index} style={{ marginRight: '10px', marginBottom: '5px', marginTop: '5px' }}>
                                        <Label>
                                            {language}
                                        </Label>
                                    </div>
                                ))}
                            </div>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Form.Field>
                            <label>Search Terms:</label>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {state.cvSearchLog?.terms?.map((term, index) => (
                                    <div key={index} style={{ marginRight: '10px', marginBottom: '5px' }}>
                                        <Label>
                                            {term}
                                        </Label>
                                    </div>
                                ))}
                            </div>
                        </Form.Field>
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Form.Field>
                            <label >Coordinate Or File:</label>
                            <Checkbox
                                style={{ paddingTop: '10px' }}
                                label="Select File"
                                toggle
                                checked={state.cvSearchLog?.fileLocationType}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        {state.cvSearchLog?.fileLocationType == false ? (
                            <Form.Field>
                                <label>Location:</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        style={{ paddingRight: '10px' }}
                                        readOnly
                                        value={state.cvSearchLog?.locationRadius?.location?.latitude}
                                    />
                                    <Input
                                        icon="map marker"
                                        readOnly
                                        value={state.cvSearchLog?.locationRadius?.location?.longitude}
                                    />
                                </div>
                            </Form.Field>
                        ) : (
                            <Form.Field>
                                <label>File:</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        icon="file"
                                        readOnly
                                        value={state.cvSearchLog?.locationRadius?.location?.fileName}
                                    />
                                </div>
                            </Form.Field>
                        )}
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Form.Field>
                            <label>Radius (KM):</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    icon="map"
                                    readOnly
                                    value={state.cvSearchLog?.locationRadius?.radius}
                                />
                            </div>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    return (
        <Container>
            <Form>
                {_buildSearch()}
                <Divider />

                <Button.Group floated="right">
                    <Button
                        className="danger"
                        onClick={() => {
                            if (_.isEmpty(props.onComplete)) {
                                props.onComplete();
                            }
                        }}
                    >
                        <Icon name="remove" /> Cancel
                    </Button>
                </Button.Group>
            </Form>
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        tenant: state.tenantManagement.activeTenant,
    }
}

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch) => ({
    })
)(CVSearchLogDetails);
