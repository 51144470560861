import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { updateItemWs } from "../webservices/saveWS";
import { TenantDTO } from './DTOs/TenantDTO';
import { toast } from "react-toastify";
import { fetchSourceTenantAction } from "./registrationActions";
import { Auth } from 'aws-amplify';

export const TENANT_EDIT_ACTION = "TENANT_EDIT_ACTION";

export const saveTenantAction = (tenant) => {
    return (dispatch, getState) => {
        const saveObject = new TenantDTO(tenant);
        dispatch({ type: SAVE_IN_PROGRESS });

        updateItemWs(saveObject, getState)
            .then((result) => {
                dispatch({ type: TENANT_EDIT_ACTION, payload: result.data });
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.success("Successfully updated tenant");
                const state = getState();
                const groupName = state.authData[0]?.signInUserSession?.accessToken?.payload['cognito:groups'][0]
                dispatch(fetchSourceTenantAction({
                    groupName: groupName
                }));
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.error("Failed to update the tenant information please try again");
            });
    };
}

export const TENANT_DELETE_ACTION = "TENANT_DELETE_ACTION";

export const deleteTenantAction = (tenant) => {
    return async (dispatch, getState) => {
      try {
        const saveObject = new TenantDTO(tenant);
        saveObject.status = "disabled";
  
        const result = await updateItemWs(saveObject, getState);
        dispatch({ type: TENANT_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        
        await Auth.signOut();
        window.location.reload();
      } catch (err) {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed to delete the Tenant, please try again");
      }
    };
  };
  