import React from "react";
import { connect } from "react-redux";
import { Container, Label, Table } from "semantic-ui-react";
import _ from "lodash";
import JSON5 from "json5";

export function AuditTrailItemDetails(props) {
  const { auditItem } = props;

  const parseItem = (itemString) => {
    try {
      if (!itemString) {
        return {};
      }
      itemString = itemString.replace(/^"(.*)"$/, '$1');
      itemString = itemString.replace(/\\"/g, '"');
      itemString = itemString.replace(/Decimal\('([0-9.]+)'\)/g, "$1");
      itemString = itemString
        .replace(/True/g, "true")
        .replace(/False/g, "false");

      return JSON5.parse(itemString);
    } catch (error) {
      console.error("Failed to parse item string:", itemString, error);
      return {};
    }
  };

  const formatNestedObject = (obj, indent = 0) => {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    const indentString = " ".repeat(indent * 2);
    const entries = Object.entries(obj);

    return entries
      .map(([key, value]) => {
        if (typeof value === "object" && value !== null) {
          return `${indentString}${key}: ${formatNestedObject(
            value,
            indent + 1
          )}`;
        }
        return `${indentString}${key}: ${value}`;
      })
      .join("\n");
  };

  const renderItem = (item) => {
    return (
      <Table celled className="responsive-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Field</Table.HeaderCell>
            <Table.HeaderCell width={12}>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.entries(item).map(([key, value]) => (
            <Table.Row key={key}>
              <Table.Cell>{key}</Table.Cell>
              <Table.Cell>
                {typeof value === "object" && value !== null ? (
                  <pre className="pre-wrapper">{formatNestedObject(value)}</pre>
                ) : (
                  value.toString()
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  const renderOriginalAndNew = (originalItem, newItem) => {
    const keys = _.union(Object.keys(originalItem), Object.keys(newItem));

    return (
      <Table celled className="responsive-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Field</Table.HeaderCell>
            <Table.HeaderCell width={6}>Original</Table.HeaderCell>
            <Table.HeaderCell width={6}>New</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {keys.map((key) => {
            const originalValue = originalItem[key];
            const newValue = newItem[key];

            const difference = !_.isEqual(originalValue, newValue);
            const originalContent =
              typeof originalValue === "object" && originalValue !== null
                ? formatNestedObject(originalValue)
                : originalValue?.toString();
            const newContent =
              typeof newValue === "object" && newValue !== null
                ? formatNestedObject(newValue)
                : newValue?.toString();

            return (
              <Table.Row key={key} positive={difference} negative={difference}>
                <Table.Cell>{key}</Table.Cell>
                <Table.Cell>
                  <pre className="pre-wrapper">{originalContent || "-"}</pre>
                </Table.Cell>
                <Table.Cell>
                  <pre className="pre-wrapper">{newContent || "-"}</pre>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  const operation = auditItem.operation;
  const originalItem = parseItem(auditItem.originalitem);
  const newItem = parseItem(auditItem.newitem);

  return (
    <Container className="responsive-container">
      {operation === "i" && (
        <div>
          <Label color="green">Item Created</Label>
          {renderItem(newItem)}
        </div>
      )}
      {operation === "d" && (
        <div>
          <Label color="red">Item Deleted</Label>
          {renderItem(newItem)}
        </div>
      )}
      {operation === "u" && (
        <div>
          <Label color="blue">Item Updated</Label>
          {renderOriginalAndNew(originalItem, newItem)}
        </div>
      )}
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  let auditItem = {};
  auditItem = ownProps.auditTrailItem;
  return {
    tenant: state.tenantManagement.activeTenant,
    auditItem: auditItem,
  };
};

export default connect(mapStateToProps, null)(AuditTrailItemDetails);
