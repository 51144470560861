import axios from 'axios';

export function fetchAuditTrailItemsWS(endpoint, queryParams, getState) {

  const state = getState();
  const jwtToken = state.authData[0]?.signInUserSession.accessToken.jwtToken;

  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };

  const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');

  const apiUrl = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ENV}/get-auditTrail?${queryString}`;
  return axios.get(apiUrl, {
    headers: headers,
  })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) {
        console.error("Error response from server:", err.response.data);
        console.error("Status code:", err.response.status);
        console.error("Headers:", err.response.headers);
      } else if (err.request) {
        console.error("No response received:", err.request);
      } else {
        console.error("Error setting up request:", err.message);
      }

      console.error("Error getting distribution rule logs:", err);

      throw err;
    });
}