import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { fetchItemsWS } from '../webservices/fetchWS';
import { createItemWs, updateItemWs } from "../webservices/saveWS";
import { KmlFileDTO } from "./DTOs/KmlFileDTO";
import { toast } from "react-toastify";
import { Storage } from 'aws-amplify';

export const FETCHING_KML_FILES_ACTION = 'FETCHING_KML_FILES_ACTION';
export const FETCH_KML_FILES_SUCCESS_ACTION = 'FETCH_KML_FILES_SUCCESS_ACTION';
export const FETCH_KML_FILES_ERROR_ACTION = 'FETCH_KML_FILES_ERROR_ACTION';

export const fetchKmlFilesAction = (tenant) => {
  const queryParams = {
    PK: `${tenant.id}%23KMLFILES`,
    SK: `KMLFILE%23`,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_KML_FILES_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        let items = result.Items;
        items = items.filter((item) => item.status !== 'isDeleted');
        dispatch({ type: FETCH_KML_FILES_SUCCESS_ACTION, payload: items });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_KML_FILES_ERROR_ACTION, err });
      });
  };
}

export const KML_FILES_ADD_ACTION = "KML_FILES_ADD_ACTION";
export const KML_FILES_EDIT_ACTION = "KML_FILES_EDIT_ACTION";

export const saveKmlFileAction = (tenant, kml, kmlFile, isNew) => {
  return (dispatch, getState) => {
    const saveObject = new KmlFileDTO(kml, tenant);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (isNew === true) {
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: KML_FILES_ADD_ACTION, payload: result.data });
          uploadKMLFile(result.data, kmlFile);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success("The new kml file has been uploaded successfully");
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to upload new kml file please try again");
        });
    }
    else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: KML_FILES_EDIT_ACTION, payload: result.data });
          uploadKMLFile(result.data, kmlFile);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success("Successfully updated the kml file");
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to update the kml file please try again");
        });
    }
  }
}

async function uploadKMLFile(file, kmlFile) {
  const path = file.filePath;
  try {
    if (kmlFile !== undefined) {
      if (kmlFile.name.includes('.kml')) {
        await Storage.put(path, kmlFile, {
          level: 'public',
        });
      }
      else {
        toast.error(`Unsupported file type: ${kmlFile.type}`);
      }
    }
    else {
      console.log("no file to upload!")
    }
  } catch (error) {
    throw error;
  }
}

export const DELETE_KML_FILES_ACTION = 'DELETING_KML_FILES_ACTION';

export const deleteKmlFileAction = (file, tenant) => {
  return (dispatch, getState) => {
    const saveObject = new KmlFileDTO(file, tenant);
    saveObject.status = "isDeleted";

    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: DELETE_KML_FILES_ACTION, payload: result.data.id });
        deleteKMLFile(tenant, file);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success("The kml file has been deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed to delete the kml file please try again");
      });
  }
}


// Used when deleting a question with a question type of 'map'
export async function deleteKMLFile(tenant, file) {
  const path = file.filePath;
  try {
    // Remove KML file
    await Storage.remove(path, {
      level: 'public',
    });

  } catch (error) {
    throw error;
  }
}
