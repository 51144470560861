import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Menu, Button, Confirm } from 'semantic-ui-react'
import _ from 'lodash';
import { toast } from "react-toastify";
import FuseContentModal from '../../../../components/generic/FuseContentModal'

import * as QUESTIONNAIRE_ACTIONS from "../../../../actions/questionaireActions";
import * as SECTION_ACTIONS from '../../../../actions/sectionActions'

import SectionDetails from './SectionDetails'

class QuestionaireSideMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeItem: '',
      activeSection: {},
      showConfirmDelete: false,
    };
  }

  static defaultProps = {
    onSelectionChanged: (value) => { }
  }

  hasPermissions(category){
		return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category)
	}

	isAdmin(){
		return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin
	}

  canReadSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.READ || this.isAdmin()
	}

  canCreateSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.CREATE || this.isAdmin()
	}

	canUpdateSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.UPDATE || this.isAdmin()
	}

	canDeleteSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.DELETE || this.isAdmin()
	}

  componentDidMount() {
    if(this.props.sections === null || _.isEmpty(this.props.sections)) {
      if(this.props.isPermissionsFetched){
        if (this.canReadSections()) {
          this.props.fetchSectionsAction(this.props.questionaire.id, this.props.questionaire.tenantId);
        }
        else{
          toast.error('You do not have permission to view sections');
        }
      }
    }
  }

  handleItemClick = (id) => {
    const selectedSection = this.props.sections.filter(section => section.id === id)[0];
    this.setState({ activeItem: id })
    this.setState({ activeSection: selectedSection })
    this.props.onSelectionChanged(selectedSection);
  }

  confirmDeactivate(){
    let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";

    return (
      <Confirm
        header='Warning:'
        content={contentMessage}
        open={this.state.showConfirmIsActive}
        onCancel={() => this.setState((state) => ({ ...state, showConfirmIsActive: false }))}
        onConfirm={() => {
          this.setState({ showConfirmDelete: true })
          this.setState({ showConfirmIsActive: false })
        }}
      />
    );
  }

  confirmDelete() {
    return (
      <Confirm
        content={`Are you sure you want to delete ${this.state.activeSection.displayName}?`}
        open={this.state.showConfirmDelete}
        onCancel={() => this.setState({ showConfirmDelete: false })}
        onConfirm={() => {
          this.props.deactiveQuestionaireAction(this.props.questionaire);
          this.props.deleteSection(this.state.activeSection);
          this.setState({ activeSection: {}, activeItem: '', showConfirmDelete: false });
          this.props.onSelectionChanged({});
        }
        }
      />
    );
  }

  render() {
    const { activeItem } = this.state

    const mainSections = () => {

      return this.props.sections
        .map(section =>
          <Menu.Item
            vertical="true"
            key={section.id}
            name={section.displayName}
            active={activeItem === section.id}
            onClick={this.handleItemClick.bind(this,section.id)}
          >
            {section.displayName}
          </Menu.Item>);
    }

    const isBaseQnaire = this.props.questionaire.isBaseQnaire;
    const isRootTenant = this.props.tenant.isRoot === true || false;

    return (
      <div>
        {this.confirmDelete()}
        {this.confirmDeactivate()}
        <div style={{marginBottom: "10px"}}>
          <Menu vertical secondary pointing compact fluid>
            {mainSections()}
          </Menu>
        </div>
        <div style={{display: "table", margin: "auto"}}>
          <Button.Group>
            {this.canCreateSections() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
            <FuseContentModal
              header={`Add main section`}
              content={<SectionDetails questionaire={this.props.questionaire} section={{ sectionId: null }} sections={this.props.sections}/>}
              trigger={<Button icon='add' className='success' />}
              tooltip={`Add main section`}
            />)}
             {this.canUpdateSections() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
            <FuseContentModal
              header={`Edit ${this.state.activeSection.displayName}`}
              content={<SectionDetails questionaire={this.props.questionaire} section={this.state.activeSection} sections={this.props.sections}/>}
              trigger={<Button disabled={_.isEmpty(this.state.activeSection)} className='warning' icon='edit' />}
              tooltip={`Edit ${this.state.activeSection.displayName}`}
            />)}

            {this.canDeleteSections() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
              <Button disabled={!this.state.activeSection.isUserCreated} className='danger' icon='trash'
                onClick={() => {
                  const isQuestionnaireActive = this.props.questionaire.status == "active" || this.props.questionaire.status == "underReview";

                  if(isQuestionnaireActive){
                    this.setState((state) => ({ ...state, showConfirmIsActive: true }));
                  }else{
                    this.setState({ showConfirmDelete: true })
                  }
                }}>

              </Button>
            )}
          </Button.Group>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownprops) => {
  return {
    sections: _.isEmpty(state.sections) ? [] : _.orderBy(Object.values(state.sections).filter(s => s.questionaireId === ownprops.questionaire.id && s.status !== 'isDeleted' &&s.parentSectionId === undefined),['sequence']),
    tenant: state.tenantManagement.activeTenant,
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,
  };
}

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    fetchSectionsAction: (questionaireId, tenantId) => dispatch(SECTION_ACTIONS.fetchSectionsAction(questionaireId, tenantId)),
    deactiveQuestionaireAction: (questionaire) => dispatch(QUESTIONNAIRE_ACTIONS.deactiveQuestionaireAction(questionaire)),
    deleteSection: (section) => dispatch(SECTION_ACTIONS.sectionDeleteAction(section))
  })
)(QuestionaireSideMenu);