import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { updateItemWs } from "../webservices/saveWS";
import { toast } from "react-toastify";
import { fetchItemsWS } from "../webservices/fetchWS";
import { GlobalPricesDTO } from "./DTOs/globalPricesDTO";


export const FETCHING_GLOBALPRICES_ACTION = "FETCHING_GLOBALPRICES_ACTION";
export const FETCH_GLOBALPRICES_SUCCESS_ACTION = "FETCH_GLOBALPRICES_SUCCESS_ACTION";
export const FETCH_GLOBALPRICES_ERROR_ACTION = "FETCH_GLOBALPRICES_ERROR_ACTION";

export const fetchGlobalPricesAction = () => {
    const queryParams = {
      PK: `GLOBALPRICES`,
      SK: 'GLOBALPRICES',
    };
  
    return (dispatch, getState) => {
        dispatch({ type: FETCHING_GLOBALPRICES_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
            .then((result) => {
                const returnItems = result.Items.filter(item => item.status !== "isDeleted");
                dispatch({type: FETCH_GLOBALPRICES_SUCCESS_ACTION,payload: returnItems});
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_GLOBALPRICES_ERROR_ACTION, err });
            });
    };
  };

export const GLOBALPRICES_EDIT_ACTION = "GLOBALPRICES_EDIT_ACTION";

export const saveGlobalPricesAction = (prices) => {
    return (dispatch, getState) => {
        const saveObject = new GlobalPricesDTO(prices);
        dispatch({ type: SAVE_IN_PROGRESS });

        updateItemWs(saveObject, getState)
            .then((result) => {
                dispatch({ type: GLOBALPRICES_EDIT_ACTION, payload: result.data });
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.success("Successfully updated prices");
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.error("Failed to update the prices information please try again");
            });
    };
}