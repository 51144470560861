import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";

import {
	Container,
	Form,
	Button,
	Icon,
	Divider,
	Confirm,
} from "semantic-ui-react";

import * as USER_ACTIONS from '../../../actions/tenantUsersActions';

export function UserDetails(props) {
  const [state, setState] = useState({
    user: { ...props.user },
    isChanged: false,
		showConfirmDeleteUser: false,
		showConfirmResetPassword: false,
		showConfirmChangeUserEnabled: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  	const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

	const canReadUsers = () => {
	return (hasPermissions('Manage Users') && props.userPermissions['Manage Users'].actions.READ || isAdmin());
	};

	const canUpdateUsers = () => {
	return (hasPermissions('Manage Users') && canReadUsers() && props.userPermissions['Manage Users'].actions.UPDATE || isAdmin());
	};

	const canDisableUsers = () => {
	return hasPermissions('Manage Users') && canUpdateUsers() && props.userPermissions['Manage Users'].actions['DISABLE USER'] || isAdmin()
	}

	const canDeleteUsers = () => {
	return hasPermissions('Manage Users') && canDisableUsers() && props.userPermissions['Manage Users'].actions.DELETE || isAdmin()
	}
	
	const canResetUserPassword = () => {
	return (hasPermissions('Manage Users') && canUpdateUsers() && props.userPermissions['Manage Users'].actions['RESET PASSWORD'] || isAdmin());
	};

	const onSubmit = (data) => {
		let toCommit = { ...state.user };
	
		if (!_.isEmpty(data.userName)) toCommit.userName = data.userName.toLowerCase();
		if (!_.isEmpty(data.firstname)) toCommit.firstname = data.firstname;
		if (!_.isEmpty(data.lastname)) toCommit.lastname = data.lastname;
		if (!_.isEmpty(data.email)) toCommit.email = data.email;
	
		if (props.isNew) {
			props.createUser(props.tenant, toCommit)
				.then(() => {
					setState((prevState) => ({ ...prevState, isChanged: false }));
					if (_.isEmpty(props.onComplete)) props.onComplete();
				})
				.catch((err) => {
					// Handle error here, if needed
				});
		} else {
			props.updateUser(props.tenant, toCommit)
				.then(() => {
					setState((prevState) => ({ ...prevState, isChanged: false }));
					if (_.isEmpty(props.onComplete)) props.onComplete();
				})
				.catch((err) => {
					// Handle error here, if needed
				});
		}
	};
    
  const _buildUsername = () => {
    return (
        <Form.Field>
            <label>Username</label>
            <input
                name="userName"
                className={errors.userName ? "invalid-input-field" : ""}
                {...register("userName", {
                    required: props.isNew,
                    maxLength: 128,
                    pattern: /^[a-zA-Z]+$/, // Allow alphabetical characters and spaces
                })}
                placeholder="Username"
                value={state.user.userName}
                onChange={(e) => {
                    setState((prevState) => ({
                        ...prevState,
                        user: { ...prevState.user, userName: e.target.value },
                    }));
                    setState((state) => ({ ...state, isChanged: true }));
                }}
                disabled={!props.isNew}
            />
            {errors.userName && errors.userName.type === "required" && (
                <p className="validation-error">* Please enter a username</p>
            )}
            {errors.userName && errors.userName.type === "pattern" && (
                <p className="validation-error">
                    * May only contain alphabetical characters not including spaces.
                </p>
            )}
            {errors.userName && errors.userName.type === "maxLength" && (
                <p className="validation-error">
                    * Username may not be more than 128 characters long
                </p>
            )}
        </Form.Field>
    );
};


	const _buildName = () => {
		return <Form.Field>
		<label>First Name</label>
		<input
			name="firstname"
			className={errors.question_text ? "invalid-input-field" : ""}
			{...register("firstname", { required: true, maxLength: 128 })}
			placeholder="firstname"
			value={state.user.firstname}
			onChange={(e) => {
				setState((prevState) => ({
					...prevState,
					user: { ...prevState.user, firstname: e.target.value },
				}));
				setState((state) => ({...state, isChanged: true}));
			}}
		/>
		{errors.firstname && errors.firstname.type === "required" && (
			<p className="validation-error">* Please enter a definition</p>
		)}
		{errors.question_text && errors.firstname.type === "pattern" && (
			<p className="validation-error">
				* May only contain alphabetical characters
			</p>
		)}
		{errors.question_text && errors.firstname.type === "maxLength" && (
			<p className="validation-error">
				* Name may not be more than 128 characters long
			</p>
		)}
	</Form.Field>;
	}

	
	const _buildSurname = () => {
		return <Form.Field>
		<label>Last Name</label>
		<input
			name="lastname"
			className={errors.question_text ? "invalid-input-field" : ""}
			{...register("lastname", { required: true, maxLength: 128 })}
			placeholder="lastname"
			value={state.user.lastname}
			onChange={(e) => {
				setState((prevState) => ({
					...prevState,
					user: { ...prevState.user, lastname: e.target.value },
				}));
				setState((state) => ({...state, isChanged: true}));
			}}
		/>
		{errors.lastname && errors.lastname.type === "required" && (
			<p className="validation-error">* Please enter a definition</p>
		)}
		{errors.question_text && errors.lastname.type === "pattern" && (
			<p className="validation-error">
				* May only contain alphabetical characters
			</p>
		)}
		{errors.question_text && errors.lastname.type === "maxLength" && (
			<p className="validation-error">
				* Name may not be more than 128 characters long
			</p>
		)}
	</Form.Field>;
	}

	const _buildEmailAddress = () => {
		return <Form.Field>
		<label>Email Address</label>
		<input
			name="email"
			className={errors.question_text ? "invalid-input-field" : ""}
			{...register("email", { required: true, maxLength: 128 })}
			placeholder="Email Address"
			value={state.user.email}
			onChange={(e) => {
				setState((prevState) => ({
					...prevState,
					user: { ...prevState.user, email: e.target.value },
				}));
				setState((state) => ({...state, isChanged: true}));
			}}
		/>
		{errors.email && errors.email.type === "required" && (
			<p className="validation-error">* Please enter a definition</p>
		)}
		{errors.question_text && errors.email.type === "pattern" && (
			<p className="validation-error">
				* May only contain alphabetical characters
			</p>
		)}
		{errors.question_text && errors.email.type === "maxLength" && (
			<p className="validation-error">
				* Name may not be more than 128 characters long
			</p>
		)}
	</Form.Field>;
	}

	
	
	const confirmDeleteUser = () => {
		return (
			<Confirm
				content={`Are you sure you want to delete the following user: ${props.user.firstname} ${props.user.lastname}?`}
				open={state.showConfirmDeleteUser}
				onCancel={() => setState((state) => ({ ...state, showConfirmDeleteUser: false }))}
				onConfirm={() => {
					props.deleteUser(props.tenant, props.user);
					setState((state) => ({ ...state, showConfirmDeleteUser: false }));
				}}
			/>
		);
	}
  
	const confirmResetPasswordUser = () => {
		return (
			<Confirm
				content={`Are you sure you want to reset the password of following user: ${props.user.firstname} ${props.user.lastname}?`}
				open={state.showConfirmResetPassword}
				onCancel={() => setState((state) => ({ ...state, showConfirmResetPassword: false }))}
				onConfirm={() => {
					props.resetUserPassword(props.tenant, props.user);
					setState((state) => ({ ...state, showConfirmResetPassword: false }));
				}
				}
			/>
		);
	}

	
  
	const confirmChangeUserEnabled = () => {

		const fullName = `${props.user.firstname} ${props.user.lastname}`;
		let title = `Are you sure you want to disable the user: ${fullName}`;
		
		if(props.isDisabled) title = `Are you sure you want to enable the user: ${fullName}`;
		
		return (
			<Confirm
				content={title}
				open={state.showConfirmChangeUserEnabled}
				onCancel={() => setState((state) => ({ ...state, showConfirmChangeUserEnabled: false }))}
				onConfirm={() => {
					if(props.isDisabled) props.enableUser(props.tenant, props.user);
					else  props.disableUser(props.tenant, props.user);
					setState((state) => ({ ...state, showConfirmChangeUserEnabled: false }));
				}
				}
			/>
		);
	}


  const _buildUserControls = () => {
		if(props.isNew) return null;
		if(props.isDisabled === true){
			return (
				<Button.Group floated="left">
					<Button 
						type="button"
						className="success"
						onClick={(e) => {
							e.stopPropagation();
							setState((state) => ({ ...state, showConfirmChangeUserEnabled: true }));
						}} >
						<Icon name="unlock" />Enable User
					</Button>
					{canDeleteUsers() &&(
					<Button 
						type="button"
						className="danger"
						onClick={(e) => {
							e.stopPropagation();
							setState((state) => ({ ...state,  showConfirmDeleteUser: true }));
						}} >
						<Icon name="trash" /> Delete User
					</Button>)}
				</Button.Group>
			);
		} else {
			return (
				<Button.Group floated="left">
					{canDisableUsers() &&(
					<Button 
						type="button"
						className="danger"
						onClick={(e) => {
							e.stopPropagation();
							setState((state) => ({ ...state, showConfirmChangeUserEnabled: true }));
						}} >
						<Icon name="lock" />Disable User
					</Button>)}
					{canResetUserPassword() &&(
					<Button 
						type="button"
						className="primary"
						onClick={(e) => {
							e.stopPropagation();
							setState((state) => ({ ...state, showConfirmResetPassword: true }));
						}} >
						Reset Password
					</Button>)}
				</Button.Group>
			);
		}
	}
  
  return (
		<Container>
			{confirmDeleteUser()}
			{confirmChangeUserEnabled()}
			{confirmResetPasswordUser()}
			<Form
				size="small"
				onSubmit={handleSubmit(onSubmit)}
				style={{ paddingBottom: "20px" }}
			>	

				{_buildUsername()}
				{_buildName()}
				{_buildSurname()}
				{_buildEmailAddress()}

			<Divider />
				{_buildUserControls()}
				<Button.Group floated="right">
					<Button
						className="danger"
						onClick={() => {
							if (_.isEmpty(props.onComplete)) {
								props.onComplete();
							}
						}}
					>
						<Icon name="remove" /> Cancel
					</Button>
					<Button type="submit" className="success" disabled={!state.isChanged}>
						<Icon name="checkmark" /> Save
					</Button>
				</Button.Group>
			</Form>
		</Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  var user = {};
  var isNew = false;
  var newUser = {
    userName: '',
    firstname: '',
    lastname: '',
    email:'',
		status: 'new',
  };

  if (ownProps.bdaction === 'add') {
    user = newUser;
    isNew = true;
  } else {
    user = _.isEmpty(ownProps.user)
      ? newUser
      : ownProps.user;
    isNew = false;
  }

	
	const isDisabled = user.status == 'isDisabled';

  return {
    tenant: state.tenantManagement.activeTenant,
    isNew: isNew,
    user: user,
	isDisabled: isDisabled,
	userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
	isPermissionsFetched: state.isPermissionsFetched === true,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		createUser: (tenant, user) => dispatch(USER_ACTIONS.createUserAction(tenant, user)),
		updateUser: (tenant, user) => dispatch(USER_ACTIONS.updateUserAction(tenant, user)),
		resetUserPassword: (tenant, user) => dispatch(USER_ACTIONS.resetUserPasswordAction(tenant, user)),
		enableUser: (tenant, user) => dispatch(USER_ACTIONS.enableUserAction(tenant, user)),
		disableUser: (tenant, user) => dispatch(USER_ACTIONS.disableUserAction(tenant, user)),
		deleteUser: (tenant, user) => dispatch(USER_ACTIONS.deleteUserAction(tenant, user)),
  })
  )(UserDetails);
