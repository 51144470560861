export class KmlFileDTO {
    PK;
    Sk;
    id;
    createdAt;
    updatedAt;
    tenantId;
    fileName;
    filePath;
    type;
    name;
    description;


    constructor(file, tenant) {
        if (file.id != null) {
            this.PK = file.PK;
            this.SK = file.SK;
        }
        else {
            this.PK = `${tenant.id}#KMLFILES`;
            this.SK = "KMLFILE#";
        }
        this.id = file.id;
        this.type = "KMLFILE";
        this.tenantId = tenant.id;
        this.name = file.name;
        this.description = file.description;
        this.fileName = file.fileName;
        this.filePath = file.filePath || `${process.env.REACT_APP_ENV}/KmlFiles/${tenant.id}/${file.name} ${file.fileName}`;
    }
}