import React, { Component } from 'react';
import _ from 'lodash'

import { Modal, Popup } from "semantic-ui-react";

class FuseContentModal extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	static defaultProps = {
		content: {},
		onClose: (event, data) => { },
		onOpen: (event, data) => { },
		trigger: {},
		header: {},
		tooltip: ''
	}

	closeModal = (event, data) => {
		this.setState({ showModal: null })

		this.props.onClose(event, data);
	}

	openModal = (event, data) => {
		this.setState({ showModal: true })
		this.props.onOpen(event, data);
	}

	getTrigger = () => {
		if (_.isEmpty(this.props.tooltip)) {
			return React.cloneElement(this.props.trigger, { onClick: () => { this.openModal() } })
		}
		else return <Popup content={this.props.tooltip} trigger={React.cloneElement(this.props.trigger, { onClick: () => { this.openModal() } })} />
	}

	render() {
		//{React.cloneElement(this.props.children, { loggedIn: this.state.loggedIn })
		return (
			<Modal
				className='fuseAlignedModal'
				size='large'
				closeIcon
				onClose={(e, data) => { this.closeModal(); this.props.onClose(e, data) }}
				onOpen={(e, data) => { this.openModal(); this.props.onOpen(e, data) }}
				open={this.state.showModal}
				trigger={this.getTrigger()}
			>
				<Modal.Header>{this.props.header}</Modal.Header>
				<Modal.Content scrolling>{React.cloneElement(this.props.content, { onComplete: this.closeModal })}</Modal.Content>
			</Modal>
		);
	}

}

export default FuseContentModal;