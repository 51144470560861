export class QuestionDTO {
  GSI11PK;
  GSI11SK;
  PK;
  SK;
  id;
  type;
  createdAt;
  updatedAt;
  sectionId;
  sequence;
  extraInfo;
  isMandatory;
  canRepeat;
  isUserCreated;
  question;
  questionType;
  questionaireName;
  whatsAppCompatible;
  isConditional;
  pptRef;
  pptRefPrefix;
  conditionId;
  isActive;
  questionaireId;
  qnairePptRef;
  lookupId;
  redirectQuestionId;
  redirectSectionId;
  canRedirect;
  kmlName;
  oldPptRefPrefix;
  intentName;
  status;
  tenantId;
  isPersonalInfo;
  whatsAppTemplate;
  whatsAppOnly;

  constructor(data) {
    this.GSI11PK = `${data.tenantId}#QUESTIONS`;
    if (data.id != null) {
      this.GSI11SK = data.GSI11SK;
      this.PK = data.PK;
      this.SK = data.SK;
    } else {
      this.GSI11SK = "QUESTION#"
      this.PK = `${data.tenantId}#QNAIRE#${data.questionaireId}`;
      this.SK = "QUESTION#";
    }
    this.isActive = data.isActive;
    this.id = data.id;
    this.type = 'QUESTION';
    this.sectionId = data.sectionId;
    this.sequence = data.sequence;
    this.extraInfo = data.extraInfo;
    this.isMandatory = data.isMandatory;
    this.canRepeat = data.canRepeat;
    this.isUserCreated = data.isUserCreated;
    this.question = data.question;
    this.questionType = data.questionType;
    this.questionaireName = data.questionaireName;
    this.whatsAppCompatible = data.whatsAppCompatible;
    this.isConditional = data.isConditional;
    this.pptRef = data.pptRef;
    this.pptRefPrefix = data.pptRefPrefix;
    this.qnairePptRef = data.qnairePptRef;
    this.conditionId = data.conditionId;
    this.questionaireId = data.questionaireId;
    this.lookupId = data.lookupId;
    this.conditionId = data.conditionId;
    this.redirectQuestionId = data.redirectQuestionId;
    this.redirectSectionId = data.redirectSectionId;
    this.canRedirect = data.canRedirect;
    this.kmlName = data.kmlName;
    this.oldPptRefPrefix = data.oldPptRefPrefix;
    this.intentName = data.intentName;
    this.status = "active";
    this.tenantId = data.tenantId;
    this.isPersonalInfo = data.isPersonalInfo;
    this.whatsAppOnly = data.whatsAppOnly;
    this.whatsAppTemplate = data.whatsAppTemplate;
  }
}
