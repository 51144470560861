import _ from 'lodash'
import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { BusinessRuleDTO } from './DTOs/BusinessRuleDTO';
import { createItemWs,updateItemWs } from '../webservices/saveWS';
import { Storage } from 'aws-amplify';
import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_BRULES_ACTION = 'FETCHING_BRULES_ACTION';
export const FETCH_BRULES_SUCCESS_ACTION = 'FETCH_BRULES_SUCCESS_ACTION';
export const FETCH_BRULES_ERROR_ACTION = 'FETCH_BRULES_ERROR_ACTION';

export const BRULE_ADD_ACTION = 'BRULE_ADD_ACTION';
export const BRULE_EDIT_ACTION = 'BRULE_EDIT_ACTION';
export const BRULE_DELETE_ACTION = 'BRULE_DELETE_ACTION';
export const BRULE_UPLOAD_TEMPLATE_ACTION = 'BRULE_UPLOAD_TEMPLATE_ACTION';


export const fetchbRulesAction = (payload) => {
  const queryParams = {
    PK: `${payload}%23BRULES`,
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_BRULES_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_BRULES_ERROR_ACTION, err });
      });
  };
};

export const bRuleSaveAction = (brDef, brAttr, tenantId) => {
  return (dispatch, getState) => {
    const saveObject = new BusinessRuleDTO(brDef,tenantId);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (brDef.id === undefined) {
      saveObject.status = 'active'
      saveObject.values = [];
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: BRULE_ADD_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The Business Rule has been added successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to add Business Rule please try again');
        })
    }
    else {
      updateItemWs(saveObject, getState)
      .then((result) => {
        if (brAttr.isUpload) {
          savePPTTemplateFile(brDef.id, brAttr)
          .then((uploadresult) => {
            dispatch({ type: BRULE_EDIT_ACTION, payload: result.data });
            dispatch({ type: SAVE_PROCESS_DONE });
            toast.success('The Business Rule has been updated successfully');
            })
          .catch((err) => {
            console.log(err);
            dispatch({ type: SAVE_PROCESS_DONE });
            toast.error('Failed to upload PPT Template please try again');
          })
        }
        else {
          dispatch({ type: BRULE_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The Business Rule has been updated successfully');
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error('Failed to update Business Rule please try again');
      })
    }
  }
}

export async function savePPTTemplateFile (bRuleId, brAttr) {
  let result;

  //add to S3
  //CHANGE - bucket should be private
  try{
    await Storage.put(`${process.env.REACT_APP_ENV}/PPT Templates/${brAttr.attrName}_${bRuleId}.pptx`, brAttr.uploadFile, {})
    console.log(`${process.env.REACT_APP_ENV}/PPT Templates/${brAttr.attrName}_${bRuleId}.pptx`);
  }
  catch (error) {
    console.log('Error uploading template file: ' + error.message);
  }

  return result;
}

export const bRuleDeleteAction = (brDef) => {
  return (dispatch, getState) => {
    const saveObject = new BusinessRuleDTO(brDef);
    saveObject.status = 'isDeleted';
    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: BRULE_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success('The Business Rule has been deleted successfully');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error('Failed to delete Business Rule please try again');
      })
  }
}
