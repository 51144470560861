import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_WHATSAPPTEMPLATES_ACTION = 'FETCHING_WHATSAPPTEMPLATES_ACTION';
export const FETCH_WHATSAPPTEMPLATES_SUCCESS_ACTION = 'FETCH_WHATSAPPTEMPLATES_SUCCESS_ACTION';
export const FETCH_WHATSAPPTEMPLATES_ERROR_ACTION = 'FETCH_WHATSAPPTEMPLATES_ERROR_ACTION';

export const fetchWhatsappTemplatesAction = () => {
    const queryParams = {
        PK: 'WHATSAPPTEMPLATES',
        SK: 'WHATSAPPTEMPLATES'
    };

    return (dispatch, getState) => {
        dispatch({ type: FETCHING_WHATSAPPTEMPLATES_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
          .then((result) => {
            const returnItems = result.Items[0].whatsappTemplates;
            dispatch({ type: FETCH_WHATSAPPTEMPLATES_SUCCESS_ACTION, payload: returnItems });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_WHATSAPPTEMPLATES_ERROR_ACTION, err });
          });
      };
}