export class UserDTO {
  PK;
  Sk;
  id;
  createdAt;
  updatedAt;
  type;
  name;
  surename;
  email;
  contact_nr;
  userId;
  status;
  walletUserId;
  walletId;

  constructor(user) {
    // if (user.userId != null) {
    //   this.PK = user.PK;
    //   this.SK = user.SK;
    // } else {
      this.PK = "USERS";
      this.SK = `USER#${user.contact_nr}`;
      this.GSI7PK = "USER#IN";
      this.GSI7SK = `USER#${user.contact_nr}`;

    // }
    this.id = user.contact_nr;
    this.type = "USER";
    this.name = user.name;
    this.surname = user.surname;
    this.email = user.email;
    this.contact_nr = user.contact_nr;
    this.userId = user.contact_nr;
    this.status = "active";
    this.walletUserId = user.walletUserId;
    this.walletId = user.walletId;

  }
}
