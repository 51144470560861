import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Table, Button, Container, Divider, Grid, Confirm, Dimmer, Loader } from 'semantic-ui-react'
import FuseInputModal from '../../../components/generic/FuseInputModal';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import PlaceholderSegment from '../../../components/generic/PlaceholderSegment';
import LookupsQnaireItem from './LookupsQnaireItem';

import * as QUESTIONNAIRE_ACTIONS from "../../../actions/questionaireActions";
import * as LOOKUPQNAIRE_ACTIONS from '../../../actions/lookupsQnaireActions';

export function LookupsQnairePage(props) {
	const [state, setState] = useState({
		isEditLookupQnaireOpen: false,
		isEditValueQnaireOpen: false,
		selectedLookupQnaire: {},
		lookupQnaireValues: [],
		selectedValueQnaire: {},
		showConfirmDelete: false,
		deleteCandidate: {}
	});

	const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

	const canReadLookup = () => {
		return hasPermissions('Questionnaire Lookup Menu') && props.userPermissions['Questionnaire Lookup Menu'].actions.READ || isAdmin()
	}

	const canCreateLookup = () => {
		return hasPermissions('Questionnaire Lookup Menu') && canReadLookup() && props.userPermissions['Questionnaire Lookup Menu'].actions.CREATE ||isAdmin()
	}

	const canUpdateLookup = () => {
		return hasPermissions('Questionnaire Lookup Menu') && canReadLookup() && props.userPermissions['Questionnaire Lookup Menu'].actions.UPDATE || isAdmin()
	}

	const canDeleteLookup = () => {
		return hasPermissions('Questionnaire Lookup Menu') && canReadLookup() && props.userPermissions['Questionnaire Lookup Menu'].actions.DELETE || isAdmin()
	}

	useEffect(() => {
		if(props.isPermissionsFetched){
			if (canReadLookup()) {
				props.fetchLookupsQnaireAction(props.questionaire.id, props.questionaire.tenantId);
				// props.fetchQuestionairesAction(props.questionaire.tenantId);
			}
			else{
				toast.error('You do not have permission to view lookup menu items');
			}
		}
	}, [])

	const isBaseQnaire = props.questionaire.isBaseQnaire;
    const isRootTenant = props.tenant.isRoot === true || false;

	const onLookupQnaireSelectionChanged = (rowId) => {
		if (rowId !== 'new') {
			const lookups = props.lookupsQnaires.filter(x => x.id === rowId);
			if (lookups.length > 0) {
				const lookupQnaire = lookups[0];
				setState((state) => ({
					...state,
					selectedLookupQnaire: lookupQnaire,
					lookupQnaireValues: _.orderBy(valueQnaireObjectsFromString(lookupQnaire.values), ['seq'])
				}));
			}
			else {
				setState((state) => ({
					...state,
					selectedLookupQnaire: {},
					lookupQnaireValues: []
				}));
			}
		}
		else {
			setState((state) => ({
				...state,
				selectedLookupQnaire: {
					id: 0,
					keyName: ''
				},
				lookupQnaireValues: []
			}));
		}
	}

	const onValueQnaireSelectionChanged = (valueId) => {
		if (valueId !== 'new') {
			const values = state.lookupQnaireValues;
			const value = values[valueId];
			setState((state) => ({
				...state,
				selectedQnaireValue: {
					id: valueId,
					value: value.value,
					questionaireId: value.questionaireId
				}
			}));
		}
		else {
			setState((state) => ({
				...state,
				selectedQnaireValue: {
					id: valueId,
					value: ''
				}
			}));
		}
	}

	const onEditLookupQnaireRow = (rowId) => {
		onLookupQnaireSelectionChanged(rowId)
		setState((state) => ({ ...state, isEditLookupQnaireOpen: true }));
	}

	const valueQnaireObjectsFromString = (json) => {
		if (!_.isEmpty(json)) {
			return JSON.parse(json);
		}
		else {
			return [];
		}
	}

	const valueQnaireObjectsToString = (valueQnaireObjectArray) => {
		return JSON.stringify(valueQnaireObjectArray);
	}

	const confirmDeleteValueDeactivate = () => {
		let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";
	
		return (
		  <Confirm
			header='Warning:'
			content={contentMessage}
			open={state.showconfirmDeleteValueDeactivate}
			onCancel={() => setState((state) => ({ ...state, showconfirmDeleteValueDeactivate: false }))}
			onConfirm={() => {
				setState((state) => ({ ...state, showconfirmDeleteValueDeactivate: false,}))
				var toCommit = state.selectedLookupQnaire;
				//Note that the intention here is not to update the old state, we just use it have an object to send to our action creator. State objects should only ever be modified using setState and not directly...
				toCommit.values = valueQnaireObjectsToString(state.values);
				props.deactiveQuestionaireAction(props.questionaire);
				props.saveLookupsQnaireAction(toCommit);
			}}
		  />
		);
	  }

	const updateValuesToLookupQnaire = (values) => {

		const isQuestionnaireActive = props.questionaire.status == "active" || props.questionaire.status == "underReview";

		if (isQuestionnaireActive) {
			setState((state) => ({ ...state, showconfirmDeleteValueDeactivate: true, values: values }))
		}
		else{
			var toCommit = state.selectedLookupQnaire;
			//Note that the intention here is not to update the old state, we just use it have an object to send to our action creator. State objects should only ever be modified using setState and not directly...
			toCommit.values = valueQnaireObjectsToString(values);
			props.saveLookupsQnaireAction(toCommit);
		}
	}

	const confirmEditDeactivate = () => {
		let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";
	
		return (
		  <Confirm
			header='Warning:'
			content={contentMessage}
			open={state.showConfirmIsActive}
			onCancel={() => setState((state) => ({ ...state, showConfirmIsActive: false, isEditLookupQnaireOpen: false, }))}
			onConfirm={() => {
				setState((state) => ({ ...state, isEditLookupQnaireOpen: false, showConfirmIsActive: false }));
				if (state.valueChanged === true) {
					if (state.selectedLookupQnaire.id === 0) {
						var toCommit = _.omit({ ...state.selectedLookupQnaire }, ['id'])
						toCommit.questionaireId = props.questionaire.id;
						toCommit.name = state.returnValue;
						toCommit.status = 'active'
						props.deactiveQuestionaireAction(props.questionaire);
						props.saveLookupsQnaireAction(toCommit, props.questionaire.tenantId);
					} else {
						var toUpdate = state.selectedLookupQnaire
						toUpdate.name = state.returnValue
						props.deactiveQuestionaireAction(props.questionaire);
						props.saveLookupsQnaireAction(toUpdate, props.questionaire.tenantId);
					}
				}
			}}
		  />
		);
	  }

	const confirmDeletelookupQnaire = () => {
		let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";
		return (
			<Confirm
				content={contentMessage}
				open={state.showConfirmDeletelookupQnaire}
				onCancel={() => setState((state) => ({ ...state, showConfirmDeletelookupQnaire: false, }))}
				onConfirm={() => {
					setState((state) => ({ ...state, showConfirmDeletelookupQnaire: false, confirmDeletelookupQnaire: true }));
				}
				}
			/>
		);
	}

	const deletelookupQnaire = () => {
		return (
			<Confirm
				content={`Are you sure you want to delete the following Menu/Questionnaire lookup with all its values: ${state.deleteCandidate.name}?`}
				open={state.confirmDeletelookupQnaire}
				onCancel={() => setState((state) => ({ ...state, confirmDeletelookupQnaire: false, }))}
				onConfirm={() => {
					var deleteLookupQnaire = state.deleteCandidate;
					props.deactiveQuestionaireAction(props.questionaire);
					props.deleteLookupQnaire(deleteLookupQnaire);
					onLookupQnaireSelectionChanged(0);
					setState((state) => ({ ...state, confirmDeletelookupQnaire: false }));
				}
				}
			/>
		);
	}

	const confirmDelete = () => {
		return (
			<Confirm
				content={`Are you sure you want to delete the following Menu/Questionnaire lookup with all its values: ${state.deleteCandidate.name}?`}
				open={state.showConfirmDelete}
				onCancel={() => setState((state) => ({ ...state, showConfirmDelete: false, }))}
				onConfirm={() => {
					var deleteLookupQnaire = state.deleteCandidate;
					props.deleteLookupQnaire(deleteLookupQnaire);
					onLookupQnaireSelectionChanged(0);
					setState((state) => ({ ...state, showConfirmDelete: false }));
				}
				}
			/>
		);
	}

	const lookupQnaireItems = () => {
		if (!props.fetchingData) {
			if (!_.isEmpty(props.lookupsQnaires)) {
				// console.log(props.lookupsQnaires);
				props.lookupsQnaires.sort((a, b) => a.name.localeCompare(b.name));

				return (
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Lookup Menu/Link to Questionnaire Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg' />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{props.lookupsQnaires.map(lookupQnaire => {
								return (
									<Table.Row key={lookupQnaire.id} className='clickable' onClick={() => onLookupQnaireSelectionChanged(lookupQnaire.id)} active={lookupQnaire.id === state.selectedLookupQnaire.id}>
										<Table.Cell>{lookupQnaire.name}</Table.Cell>
										<Table.Cell textAlign='right'>
										{ canUpdateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
											<Button compact className='warning' icon='edit' size='mini' onClick={(e) => { e.stopPropagation(); onEditLookupQnaireRow(lookupQnaire.id) }} />
										)}	
										{ canDeleteLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
											<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
												e.stopPropagation();
												const isQuestionnaireActive = props.questionaire.status == "active" || props.questionaire.status == "underReview";
												if(isQuestionnaireActive){
													setState((state) => ({ ...state, deleteCandidate: lookupQnaire, showConfirmDeletelookupQnaire: true }));
												}else{
													setState((state) => ({ ...state, deleteCandidate: lookupQnaire, showConfirmDelete: true }));
												}
											}} />)}
										</Table.Cell>
									</Table.Row>
								);
							})
							}
						</Table.Body>
					</Table>
				);
			}
			else {
				return (
					<PlaceholderSegment text="There are no lookup questionnaire items yet" />
				);
			}
		}
		else {
			return (
				<div style={{ height: "200px", margin: "10px 0px" }}>
					<Dimmer inverted active>
						<Loader content='Loading' />
					</Dimmer>
				</div>
			);
		}
	}

	const lookupQnaireValues = () => {
		return state.lookupQnaireValues
			.map((lookupQnaireValue, index) =>
				<Table.Row key={`${lookupQnaireValue.id}.${index}`}>
					<Table.Cell>{lookupQnaireValue.name}</Table.Cell>
					<Table.Cell>{lookupQnaireValue.seq}</Table.Cell>
					<Table.Cell>{lookupQnaireValue.value}</Table.Cell>
					<Table.Cell textAlign='right'>
					{ canUpdateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
						<FuseContentModal
							header={`Edit menu/questionnaire link in: ${state.selectedLookupQnaire.name}`}
							content={<LookupsQnaireItem lookupQnaire={state.selectedLookupQnaire} lookupQnaireValues={state.lookupQnaireValues} questionaire={props.questionaire} qnaction={'edit'} valIndex={index}/>}
							trigger={<Button compact className="warning" icon='edit' size='mini' />}
							tooltip={`Edit menu/questionnaire link in: ${state.selectedLookupQnaire.name}`}
							onClose={() => {setState((state) => ({ ...state, lookupQnaireValues: _.orderBy(valueQnaireObjectsFromString(state.selectedLookupQnaire.values),["seq"]) }));;}}									
						/>)}
						{ canDeleteLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
						<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
							e.stopPropagation();
							var newstate = [...state.lookupQnaireValues];
							var itemIndex = newstate.indexOf(newstate[index]);

							if (itemIndex > -1) {
								newstate.splice(itemIndex, 1);
							}

							setState((state) => ({ ...state, lookupQnaireValues: _.orderBy(newstate, ["seq"]) }));
							updateValuesToLookupQnaire(newstate)
							onValueQnaireSelectionChanged(0)
						}} />)}
						</Table.Cell>
				</Table.Row>
			);
	}

	const lookupQnaireEdit = () => {
		if (state.isEditLookupQnaireOpen) {
			return (
				<FuseInputModal
					label='Lookup Questionnaire Name:'
					initialValue={state.selectedLookupQnaire.name}
					onOk={(valueChanged, returnValue) => {

						const isQuestionnaireActive = props.questionaire.status == "active" || props.questionaire.status == "underReview";

						if (isQuestionnaireActive) {
							setState((state) => ({ ...state, showConfirmIsActive: true,valueChanged: valueChanged, returnValue: returnValue  }));
						}else{
							setState((state) => ({ ...state, isEditLookupQnaireOpen: false }));
							if (valueChanged === true) {
								if(state.selectedLookupQnaire.id===0){
									var toCommit =_.omit({ ...state.selectedLookupQnaire }, ['id'])
									toCommit.questionaireId = props.questionaire.id;
									toCommit.name = returnValue;
									toCommit.status = 'active'
									props.saveLookupsQnaireAction(toCommit, props.questionaire.tenantId);
								}else{
									var toUpdate = state.selectedLookupQnaire
									toUpdate.name = returnValue
									props.saveLookupsQnaireAction(toUpdate, props.questionaire.tenantId);
								}
							}
						}
					}}
					onCancel={() => {
						setState((state) => ({
							...state,
							isEditLookupQnaireOpen: false
						}));
					}}
				/>
			)
		}
	}
	
	const lookupQnaireValuesRender = () => {
		if (_.isEmpty(state.selectedLookupQnaire) || (state.selectedLookupQnaire.id < 1)) {
			return;
		}
		else {
			return (
				<div>
					<Divider />
					<Grid>
						<Grid.Row>
							<Grid.Column width='8'>
								<h3>{`${state.selectedLookupQnaire.name} Values:`}</h3>
							</Grid.Column>
							<Grid.Column width='8'>
							{ canCreateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
								<FuseContentModal
									header={`Add menu/questionnaire link in: ${state.selectedLookupQnaire.name}`}
									content={<LookupsQnaireItem lookupQnaire={state.selectedLookupQnaire} lookupQnaireValues={state.lookupQnaireValues} questionaire={props.questionaire} qnaction={'add'}/>}
									trigger={<Button compact size='mini' icon='add' className='success' floated='right'/>}
									tooltip={`Add menu/questionnaire link in: ${state.selectedLookupQnaire.name}`}
									onClose={() => {setState((state) => ({ ...state, lookupQnaireValues: _.orderBy(valueQnaireObjectsFromString(state.selectedLookupQnaire.values),["seq"]) }));;}}									
								/>)}
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Menu/Option Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Sequence</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Link to Questionnaire Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'></Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{lookupQnaireValues()}
						</Table.Body>
					</Table>
				</div>
			);
		}
	}

	return (
		<Container fluid>
			{confirmDelete()}
			{confirmEditDeactivate()}
			{confirmDeleteValueDeactivate()}
			{confirmDeletelookupQnaire()}
			{deletelookupQnaire()}
			<Grid>
				<Grid.Row>
					<Grid.Column width='8'>
						<h3>Menu/Link to Questionnaire Lookups</h3>
					</Grid.Column>
					<Grid.Column width='8'>
					{ canCreateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
						<Button compact size='mini' icon='add' className='success' floated='right' onClick={(e) => { e.stopPropagation(); onEditLookupQnaireRow('new') }} />
					)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{lookupQnaireEdit()}
			{lookupQnaireItems()}
			{lookupQnaireValuesRender()}
		</Container>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		fetchingData: state.fetchingData === true,
		lookupsQnaires: _.isEmpty(state.lookupsQnaires) ? [] : _.orderBy(Object.values(state.lookupsQnaires).filter(lookup => lookup.questionaireId === ownProps.questionaire.id), ['name']),
		lookupQnaireValues: _.isEmpty(state.lookupQnaireValues) ? [] : _.orderBy(Object.values(state.lookupQnaireValues),['seq']),
		tenant: state.tenantManagement.activeTenant,
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		// fetchQuestionairesAction: (tenantId) => dispatch(fetchQuestionairesAction(tenantId)),
		fetchLookupsQnaireAction: (questionaireId, tenantId) => dispatch(LOOKUPQNAIRE_ACTIONS.fetchLookupsQnaireAction(questionaireId, tenantId)),
		deactiveQuestionaireAction: (questionaire) => dispatch(QUESTIONNAIRE_ACTIONS.deactiveQuestionaireAction(questionaire)),
		saveLookupsQnaireAction: (lookupQnaire, tenantId) => dispatch(LOOKUPQNAIRE_ACTIONS.lookupQnaireSaveAction(lookupQnaire, tenantId)),
		deleteLookupQnaire: (lookupQnaire) => dispatch(LOOKUPQNAIRE_ACTIONS.lookupQnaireDeleteAction(lookupQnaire)),
	})
)(LookupsQnairePage);