import { createItemWs, updateItemWs } from '../webservices/saveWS';
import { fetchItemsWS} from '../webservices/fetchWS';
import { userManagementWs, createCognitoUserWs} from '../webservices/userManagementWS';
import { TenantUserDTO } from './DTOs/TenantUserDTO';
import { TenantUserRoleDTO } from './DTOs/TenantUserRoleDTO';
import { toast } from 'react-toastify';
import _ from "lodash";

export const FETCHING_TENANT_USER_ROLES_ACTION = 'FETCHING_TENANT_USER_ROLES_ACTION';
export const FETCH_TENANT_USER_ROLES_SUCCESS_ACTION = 'FETCH_TENANT_USER_ROLES_SUCCESS_ACTION';
export const FETCH_TENANT_USER_ROLES_ERROR_ACTION = 'FETCH_TENANT_USER_ROLES_ERROR_ACTION';

export const DELETING_TENANT_USER_ROLES_ACTION = 'DELETING_TENANT_USER_ROLES_ACTION';
export const DELETE_TENANT_USER_ROLES_SUCCESS_ACTION = 'DELETE_TENANT_USER_ROLES_SUCCESS_ACTION';
export const DELETE_TENANT_USER_ROLES_ERROR_ACTION = 'DELETE_TENANT_USER_ROLES_ERROR_ACTION';

export const SAVING_TENANT_USER_ROLES_ACTION = 'SAVING_TENANT_USER_ROLES_ACTION';
export const SAVE_TENANT_USER_ROLES_SUCCESS_ACTION = 'SAVE_TENANT_USER_ROLES_SUCCESS_ACTION';
export const SAVE_TENANT_USER_ROLES_ERROR_ACTION = 'SAVE_TENANT_USER_ROLES_ERROR_ACTION';

// ================ FETCH USERS ================

export const FETCHING_TENANT_USERS_ACTION = 'FETCHING_TENANT_USERS_ACTION';
export const FETCH_TENANT_USERS_SUCCESS_ACTION = 'FETCH_TENANT_USERS_SUCCESS_ACTION';
export const FETCH_TENANT_USERS_ERROR_ACTION = 'FETCH_TENANT_USERS_ERROR_ACTION';

export const fetchUsersAction = (tenant) => {
	return (dispatch, getState) => {
			const queryParams = {
				PK: `TENANT%23${tenant.tenantName}`,
				SK: `USER`,
			};

			dispatch({ type: FETCHING_TENANT_USERS_ACTION, payload: tenant });
			fetchItemsWS('get-dynamodb', queryParams, getState)
			.then((data) => {
					let items = data.Items;
					items = items.filter((item) => item.status !== 'isDeleted');
					dispatch({ type: FETCH_TENANT_USERS_SUCCESS_ACTION, payload: items });
				})
				.catch((err) => {
					console.log(err);
					dispatch({ type: FETCH_TENANT_USERS_ERROR_ACTION, payload: err })
				});
    }
}

// ================ USER PASSWORD RESET ================

export const TENANT_USER_RESET_PASSWORD_ACTION = 'TENANT_USER_RESET_PASSWORD_ACTION';
export const TENANT_USER_RESET_PASSWORD_SUCCESS_ACTION = 'TENANT_USER_RESET_PASSWORD_SUCCESS_ACTION';
export const TENANT_USER_RESET_PASSWORD_ERROR_ACTION = 'TENANT_USER_RESET_PASSWORD_ERROR_ACTION';

export const resetUserPasswordAction = (tenant, user) => {
	return (dispatch, getState) => {
		const state = getState();
		const userPoolId = state.authData[0].pool.userPoolId;

		const qData = {
			username: user.userName,
			action: `resetpassword`, //actions: (enable/disable/delete/resetpassword)
			userpoolid: userPoolId,
		};

		dispatch({ type: TENANT_USER_RESET_PASSWORD_ACTION, payload: {tenant, user} });
		userManagementWs(qData, getState)
			.then((data) => {
				console.log(data);
				dispatch({ type: TENANT_USER_RESET_PASSWORD_SUCCESS_ACTION, payload: data });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: TENANT_USER_RESET_PASSWORD_ERROR_ACTION, payload: err });
			});
	}
}

// ================ CREATE USER ================

export const CREATE_TENANT_USER_ACTION = 'CREATE_TENANT_USER_ACTION';
export const CREATE_TENANT_USER_SUCCESS_ACTION = 'CREATE_TENANT_USER_SUCCESS_ACTION';
export const CREATE_TENANT_USER_ERROR_ACTION = 'CREATE_TENANT_USER_ERROR_ACTION';

export const createUserAction = (tenant, user) => {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			dispatch({ type: CREATE_TENANT_USER_ACTION, payload: { tenant, user } });

			const state = getState();
			const userPoolId = state.authData[0].pool.userPoolId;

			const dto = new TenantUserDTO(user, tenant);

			const cognitoData = {
				"username": dto.userName,
				"email": dto.email,
				"custom:firstname": dto.firstname,
				"custom:lastname": dto.lastname,
				"custom:tenantName": dto.tenantName,
				"userpoolid": userPoolId,
				"action": "create",
			};

			createCognitoUserWs(cognitoData, getState)
				.then(() => {
					createItemWs(dto, getState)
						.then(() => {
							dispatch({ type: UPDATE_TENANT_USER_SUCCESS_ACTION, payload: _ });
							dispatch(fetchUsersAction(tenant));
							resolve(); // Resolve the promise if creation is successful
						})
						.catch((err) => {
							console.log(err);
							const qData = {
								username: dto.userName,
								action: `delete`,
								userpoolid: userPoolId,
							};
							userManagementWs(qData, getState)
								.then(() => {
									dispatch({ type: UPDATE_TENANT_USER_ERROR_ACTION, payload: err });
									dispatch(fetchUsersAction(tenant));
									reject(err); // Reject the promise if creation fails
								})
								.catch((err) => {
									console.log(err);
									dispatch({ type: DELETE_TENANT_USERS_ERROR_ACTION, payload: err });
									reject(err); // Reject the promise if deletion fails
								});
						});
				})
				.catch((err) => {
					if (err.response.status === 500) {
						dispatch({ type: UPDATE_TENANT_USER_ERROR_ACTION, payload: err });
						toast.error(err.response.data);
					} else {
						dispatch({ type: UPDATE_TENANT_USER_ERROR_ACTION, payload: err });
						toast.error("Failed to create user.");
					}
					reject(err); // Reject the promise if creation fails
				});
		});
	};
};

// ================ ENABLE USER ================

export const ENABLE_TENANT_USER_ACTION = 'ENABLE_TENANT_USER_ACTION';
export const ENABLE_TENANT_USER_SUCCESS_ACTION = 'ENABLE_TENANT_USER_SUCCESS_ACTION';
export const ENABLE_TENANT_USER_ERROR_ACTION = 'ENABLE_TENANT_USER_ERROR_ACTION';

export const enableUserAction = (tenant, user) => {
	return (dispatch, getState) => {
		dispatch({ type: ENABLE_TENANT_USER_ACTION, payload: {tenant, user} });

		const state = getState();
		const userPoolId = state.authData[0].pool.userPoolId;

		const dto = new TenantUserDTO(user, tenant);
		dto.status = 'isEnabled';

		const qData = {
			username: user.userName,
			action: `enable`, //actions: (enable/disable/delete/resetpassword)
			userpoolid: userPoolId,
		};

		userManagementWs(qData, getState)
			.then((res) => {
				updateItemWs(dto, getState).then((data) => {
					dispatch({ type: ENABLE_TENANT_USER_SUCCESS_ACTION, payload: data })
					dispatch(fetchUsersAction(tenant));
				})
				.catch((err) => {
					console.log(err);
					dispatch({ type: ENABLE_TENANT_USER_ERROR_ACTION, payload: err })
				});
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: ENABLE_TENANT_USER_ERROR_ACTION, payload: err });
			});
	}
}

// ================ DISABLE USER ================

export const DISABLE_TENANT_USER_ACTION = 'DISABLE_TENANT_USER_ACTION';
export const DISABLE_TENANT_USER_SUCCESS_ACTION = 'DISABLE_TENANT_USER_SUCCESS_ACTION';
export const DISABLE_TENANT_USER_ERROR_ACTION = 'DISABLE_TENANT_USER_ERROR_ACTION';

export const disableUserAction = (tenant, user) => {
	return (dispatch, getState) => {
		dispatch({ type: DISABLE_TENANT_USER_ACTION, payload: {tenant, user} });

		const state = getState();
		const userPoolId = state.authData[0].pool.userPoolId;

		const dto = new TenantUserDTO(user, tenant);
		dto.status = 'isDisabled';

		const qData = {
			username: user.userName,
			action: `disable`, //actions: (enable/disable/delete/resetpassword)
			userpoolid: userPoolId,
		};

		userManagementWs(qData, getState)
			.then((res) => {
				updateItemWs(dto, getState).then((data) => {
					dispatch({ type: DISABLE_TENANT_USER_SUCCESS_ACTION, payload: data })
					dispatch(fetchUsersAction(tenant));
				})
				.catch((err) => {
					console.log(err);
					toast.error("Failed to disable user. Please try again")
					dispatch({ type: DISABLE_TENANT_USER_ERROR_ACTION, payload: err })
				});
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to disable user. Please try again")
				dispatch({ type: DISABLE_TENANT_USER_ERROR_ACTION, payload: err });
			});
	}
}

// ================ DELETE USER ================

export const DELETE_TENANT_USERS_ACTION = 'DELETE_TENANT_USERS_ACTION';
export const DELETE_TENANT_USERS_SUCCESS_ACTION = 'DELETE_TENANT_USERS_SUCCESS_ACTION';
export const DELETE_TENANT_USERS_ERROR_ACTION = 'DELETE_TENANT_USERS_ERROR_ACTION';

export const deleteUserAction = (tenant, user) => {
	return (dispatch, getState) => {
		dispatch({ type: DELETE_TENANT_USERS_ACTION, payload: {tenant, user} });
		
		const state = getState();
		const userPoolId = state.authData[0].pool.userPoolId;

		const dto = new TenantUserDTO(user, tenant);
		dto.status = 'isDeleted';
		
		const qData = {
			username: dto.userName,
			action: `delete`, //actions: (enable/disable/delete/resetpassword)
			userpoolid: userPoolId,
		};

		userManagementWs(qData, getState)
			.then((res) => {
				updateItemWs(dto, getState).then((data) => {
					updateAllUserRoles(getState, tenant, user, true)
					.then((_)=>{
						dispatch({ type: DELETE_TENANT_USERS_SUCCESS_ACTION, payload: data })
						dispatch(fetchUsersAction(tenant));
					})
					.catch((err) => {
						console.log(err);
						toast.error("Failed to delete user. Please try again")
						dispatch({ type: DELETE_TENANT_USERS_ERROR_ACTION, payload: err })
					});
				})
				.catch((err) => {
					console.log(err);
					toast.error("Failed to delete user. Please try again")
					dispatch({ type: DELETE_TENANT_USERS_ERROR_ACTION, payload: err })
				});
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to delete user. Please try again")
				dispatch({ type: DELETE_TENANT_USERS_ERROR_ACTION, payload: err });
			});
	}
}

// ================ UPDATE USER ================

export const UPDATE_TENANT_USER_ACTION = 'UPDATE_TENANT_USER_ACTION';
export const UPDATE_TENANT_USER_SUCCESS_ACTION = 'UPDATE_TENANT_USER_SUCCESS_ACTION';
export const UPDATE_TENANT_USER_ERROR_ACTION = 'UPDATE_TENANT_USER_ERROR_ACTION';

export const updateUserAction = (tenant, user) => {
	return (dispatch, getState) => {
		dispatch({ type: UPDATE_TENANT_USER_ACTION, payload: {tenant, user} });
		
		const state = getState();
		const userPoolId = state.authData[0].pool.userPoolId;

		const dto = new TenantUserDTO(user, tenant);
		dto.status = 'isEnabled';
		
		const cognitoData =  {
			"username": dto.userName,
			"email": dto.email,
			"custom:firstname": dto.firstname,
			"custom:lastname": dto.lastname,
			"userpoolid": userPoolId,
			"action": "update",
		};

		createCognitoUserWs(cognitoData, getState)
			.then((res) => {
				updateItemWs(dto, getState).then((data) => {
					updateAllUserRoles(getState, tenant, user, false)
					.then((_)=>{
						dispatch({ type: UPDATE_TENANT_USER_SUCCESS_ACTION, payload: data })
						dispatch(fetchUsersAction(tenant));
					})
					.catch((err) => {
						console.log(err);
						dispatch({ type: UPDATE_TENANT_USER_ERROR_ACTION, payload: err })
					});
				})
				.catch((err) => {
					console.log(err);
					dispatch({ type: UPDATE_TENANT_USER_ERROR_ACTION, payload: err })
				});
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: UPDATE_TENANT_USER_ERROR_ACTION, payload: err });
			});
	}
}


const updateAllUserRoles = (getState, tenant, user, isDeleting = false) => {
	return new Promise((resolve,reject) => {
		const queryParams = {
			PK: `USER%23${user.userName}`,
			SK: `ROLE`,
		};

		fetchItemsWS('get-dynamodb', queryParams, getState)
			.then((data) => {
				const userRoles = data.Items;
				userRoles.forEach((userRole) => {
					const newUserRole = {
						...userRole,
						userName: user.userName,
						firstname: user.firstname,
						lastname: user.lastname,
						email: user.email,
					};
					const qData = new TenantUserRoleDTO(newUserRole, tenant);
					if(isDeleting === true) qData.status = 'isDeleted';
					updateItemWs(qData, getState).then((_) => {}).catch(reject);
				});
				resolve();
			})
			.catch(reject);
	});
}

// ================ FETCH ROLES FOR A USER ================

export const fetchUserRolesAction = (tenant, user) => {
	const queryParams = {
    PK: `USER%23${user.userName}`,
    SK: `ROLE`,
  };

	return (dispatch, getState) => {
		dispatch({ type: FETCHING_TENANT_USER_ROLES_ACTION, payload: {tenant , user}});
		fetchItemsWS('get-dynamodb', queryParams, getState)
		.then((data) => {
				let items = data.Items;
				items = items.filter((item) => item.status !== 'isDeleted');
				dispatch({ type: FETCH_TENANT_USER_ROLES_SUCCESS_ACTION, payload: items });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: FETCH_TENANT_USER_ROLES_ERROR_ACTION, payload: err })
			});
}
}

export const userRoleDeleteAction = (tenant, userRole, user) => {
	return (dispatch, getState) => {
	 dispatch(userRoleSaveAction(tenant, userRole, user, true));
	}
}

export const userRoleSaveAction = (tenant, userRole, user, isDeleting = false) => {
	return (dispatch, getState) => {
			dispatch({ type: SAVING_TENANT_USER_ROLES_ACTION, payload: { tenant, userRole, isDeleting} });
			const qData = new TenantUserRoleDTO(userRole, tenant);

			let promise;
			if(isDeleting === true) {
				qData.status = 'isDeleted';
				promise= updateItemWs(qData, getState);
			} else {
				promise = createItemWs(qData, getState);
			}

			promise.then((_) => {
				dispatch({ type: SAVE_TENANT_USER_ROLES_SUCCESS_ACTION, payload: _ })
				// const user = { username: userRole.userName };
				dispatch(fetchUserRolesAction(tenant, user));
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: SAVE_TENANT_USER_ROLES_ERROR_ACTION, payload: err })
			});
	}
}