import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Container } from 'semantic-ui-react';
import { toast } from 'react-toastify';

export function DashBoardPage(props) {
  const dashboardRef = useRef(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);

  const hasPermissions = (category) => {
    return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
  };

  const isAdmin = () => {
    return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
  };

  const canViewDashboard = () => {
    return hasPermissions('Dashboard') && props.userPermissions['Dashboard'].actions.READ || isAdmin();
  };

  useEffect(() => {
    if (props.tenantId != undefined) {
      if (props.isPermissionsFetched) {
        if (canViewDashboard()) {
          const timeout = setTimeout(() => {
              fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ENV}/getEmbedURL?TenantId=${props.tenantId}`
              ).then((response) => response.json()
              ).then((response) => {
                  setDashboardUrl(response.EmbedUrl);
              });
          }, 10);
          return () => clearTimeout(timeout);
        } else {
          toast.error('You do not have permission to view the dashboard.');
        }
      }
    }
  }, [props.tenantId]);

  useEffect(() => {
    if (dashboardRef.current && dashboardRef.current.firstChild) {
      dashboardRef.current.removeChild(dashboardRef.current.firstChild);
    }
  }, [props.tenantId]);

  useEffect(() => {
    if (dashboardUrl) {
      createEmbeddingContext().then(context => {
        setEmbeddingContext(context);
        embedDashboard(context);
      });
    }
  }, [dashboardUrl]);

  const embedDashboard = async (context) => {
    if (!context || !dashboardUrl || !dashboardRef.current) return;

    const options = {
      url: dashboardUrl,
      container: dashboardRef.current,
      height: "100%",
      width: "100%",
    };

    await context.embedDashboard(options);
  };

  return (
    <Container fluid style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {canViewDashboard() && (
        <div 
          ref={dashboardRef} 
          style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        ></div>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  tenantId: state.tenantManagement.activeTenant.tenantId,
  userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
  isPermissionsFetched: state.isPermissionsFetched === true,
});

export default connect(mapStateToProps)(DashBoardPage);
