import{
    FETCH_KML_FILES_SUCCESS_ACTION,  
    KML_FILES_ADD_ACTION,
    KML_FILES_EDIT_ACTION, 
    DELETE_KML_FILES_ACTION,
} from "../actions/kmlUploadActions";

export const kmlFileReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_KML_FILES_SUCCESS_ACTION:
            const KmlFiles = action.payload;
            return KmlFiles;
        case KML_FILES_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case KML_FILES_EDIT_ACTION:
            const newKml = action.payload;
            const kmlCopy = Object.values({...state}).filter(s => (newKml.id !== s.id));
            kmlCopy.push(newKml);
            return kmlCopy;
        case DELETE_KML_FILES_ACTION:
            return state.filter(element => element.id !== action.payload);
        default:
            return state;

    }
}