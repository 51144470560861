import {
	USER_GROUP_EDIT_ACTION,
	USER_GROUP_DELETE_ACTION,
	FETCH_USER_GROUPS_SUCCESS_ACTION,
	USER_GROUP_ADD_ACTION
} from '../actions/userGroupsActions';

export const userGroupsReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_USER_GROUPS_SUCCESS_ACTION:
			const newUserGroups = action.payload;
			// const oldUserGroups = Object.values({ ...state }).filter(userGroup => !newUserGroups.some(newUserGroup => newUserGroup.id === userGroup.id));
			// const newUserGroupState = oldUserGroups.concat(newUserGroups);
			return newUserGroups;
		case USER_GROUP_ADD_ACTION:
			const clonedState = [...state];
			clonedState.push(action.payload);
			return clonedState;
		case USER_GROUP_EDIT_ACTION:
			const newUserGroup = action.payload;
			const userGroupsCopy = Object.values({ ...state }).filter(userGroup => !(newUserGroup.id === userGroup.id));
			userGroupsCopy.push(newUserGroup);
			return userGroupsCopy;
		case USER_GROUP_DELETE_ACTION:
			return state.filter(element => element.id !== action.payload);
		default:
			return state;
	}
};