export class ConditionDTO {
    PK;
    SK;
    id;
    createdAt;
    updatedAt;
    sectionId;
    questionId;
    questionaireId;
    targetSectionId;
    targetQuestionId;
    conditionOperator;
    value;
    status;
    type;
    tenantId;
  
    constructor(data, tenantId) {
      if (data.id != null) {
        this.PK = data.PK;
        this.SK = data.SK;
      } else {
        if(data.sectionCondition){
          this.PK = `${tenantId}#SECTION#${data.sectionId}`;
          this.SK = "CONDITION#";
        }
        else{
          this.PK = `${tenantId}#QUESTION#${data.questionId}`;
          this.SK = "CONDITION#";
        }
      }
  
      this.id = data.id;
      this.sectionId = data.sectionId;
      this.questionId = data.questionId;
      this.questionaireId = data.questionaireId;
      this.targetSectionId = data.targetSectionId;
      this.targetQuestionId = data.targetQuestionId;
      this.conditionOperator = data.conditionOperator;
      this.value = data.value;
      this.status = data.status;
      this.type = 'CONDITION';
      this.tenantId = tenantId;
    }
  }
  