import { result } from "lodash";
import { fetchItemsWS } from "../webservices/fetchWS";
import { toast } from "react-toastify";

export const FETCHING_SELECTED_BOUGHT_CV_ACTION =
  "FETCHING_SELECTED_BOUGHT_CV_ACTION";
export const FETCH_SELECTED_BOUGHT_CV_ANSWERS_SUCCESS_ACTION =
  "FETCH_SELECTED_BOUGHT_CV_ANSWERS_SUCCESS_ACTION";
export const FETCH_SELECTED_BOUGHT_CV_QNAIRE_SUCCESS_ACTION =
  "FETCH_SELECTED_BOUGHT_CV_QNAIRE_SUCCESS_ACTION";
export const FETCH_SELECTED_BOUGHT_CV_ERROR_ACTION =
  "FETCH_SELECTED_BOUGHT_CV_ERROR_ACTION";

const filterData = (items, cv) => {
  let filteredItems = items.filter(
    (item) =>
      item.op !== "d" &&
      item.completionPercentage === 100 &&
      item.questionaireId === cv.questionnaireId
  );

  let newestItem = filteredItems.reduce((maxItem, currentItem) => {
    let maxDate = maxItem
      ? maxItem.createdAt.substring(0, 14)
      : "00000101T000000";
    let currentDate = currentItem.createdAt.substring(0, 14);

    return maxDate < currentDate ? currentItem : maxItem;
  }, null);

  return newestItem;
};

export const fetchSelectedBoughtCVAction = (cv) => {
  const qnaireParams = {
    PK: `USER%23${cv.number}`,
    // PK: `USER%2327787792306`,
    SK: `USERQNAIRE%23`,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_SELECTED_BOUGHT_CV_ACTION });
    fetchItemsWS("get-dynamodb", qnaireParams, getState)
      .then((result) => {
        let items = filterData(result.Items, cv);
        if(items === null){
          toast.warning("User CV details unavailable. The user might have chosen to delete his personal information.");
          return
        }
        const userQnaireParams = {
          PK: `${items.tenantId}%23USERQNAIRE%23${items.id}`,
          SK: `ANSWER`,
        };
        const QnaireParams = {
          PK: `${items.tenantId}%23QNAIRE%23${cv.questionnaireId}`,
        };
        fetchItemsWS("get-dynamodb", userQnaireParams, getState)
          .then((result) => {
            let items = result.Items.filter(
              (item) => item.status !== "isDeleted"
            );
            dispatch({
              type: FETCH_SELECTED_BOUGHT_CV_ANSWERS_SUCCESS_ACTION,
              payload: items,
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_SELECTED_BOUGHT_CV_ERROR_ACTION, err });
          });
        fetchItemsWS("get-dynamodb", QnaireParams, getState)
          .then((result) => {
            let items = result.Items.filter(
              (item) => item.status !== "isDeleted"
            );
            dispatch({
              type: FETCH_SELECTED_BOUGHT_CV_QNAIRE_SUCCESS_ACTION,
              payload: items,
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_SELECTED_BOUGHT_CV_ERROR_ACTION, err });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_SELECTED_BOUGHT_CV_ERROR_ACTION, err });
      });
  };
};

export const downloadSelectedCVAction = (cv) => {
    return async (dispatch, getState) => {
      try {
        const qnaireParams = {
          PK: `USER%23${cv.number}`,
          SK: `USERQNAIRE%23`,
        };
  
        const qnaireResult = await fetchItemsWS("get-dynamodb", qnaireParams, getState);
        let userQnaire = filterData(qnaireResult.Items, cv);

        // const userDocsParams = {
        //   PK: `USER%23${cv.number}`,
        //   SK: `DOCUMENT`,
        // };
  
        // const docsResult = await fetchItemsWS("get-dynamodb", userDocsParams, getState);
        // console.log("docsResult", docsResult)
        // let userDoc = docsResult.Items.find(
        //   (item) => item.userQuestionaireId === userQnaire.id
        // );
        
        // return userDoc;
        return userQnaire;
      } catch (err) {
        console.error(err);
        throw err; // Re-throw the error so it can be caught in the component
      }
    };
  };
