import { 
	FETCH_SOURCE_TENANT_SUCCESS_ACTION, FETCH_ALL_TENANTS_SUCCESS_ACTION, SET_ACTIVE_TENANT_ACTION,SET_PERMISSIONS_FETCHED_ACTION
} from "../actions/registrationActions";
import { TENANT_EDIT_ACTION } from "../actions/tenantActions";

export const sourceTenantReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_SOURCE_TENANT_SUCCESS_ACTION:
            const Tenant = action.payload;
            return Tenant;
        // case TENANT_ADD_ACTION:
		// 	let clonedState = [];
		// 	if (!_.isEmpty(state)) {
		// 		const newState = Array.isArray(state) ? [...state, action.payload] : [action.payload];
        //    		return newState;
		// 		// clonedState = [...state, action.payload];
		// 	}
        //     clonedState.push(action.payload);
        //     return clonedState;
		case TENANT_EDIT_ACTION:
			const editedTenant = action.payload;
			const updatedState = Array.isArray(state)
				? state.map(tenant => (tenant.id === editedTenant.id ? editedTenant : tenant))
				: [editedTenant];
			return updatedState;
        default:
            return state;
    }
};

export const activeTenantReducer = (state = [], action) => {
	switch (action.type) {
			case SET_ACTIVE_TENANT_ACTION:
					const Tenant = action.payload;
					return Tenant;
			default:
					return state;
	}
};

export const allTenantsReducer = (state = [], action) => {
	switch (action.type) {
			case FETCH_ALL_TENANTS_SUCCESS_ACTION:
					const Tenant = action.payload;
					return Tenant;
			default:
					return state;
	}
};

export const permissionsFetchedReducer = (state = false, action) => {
	switch (action.type) {
			case SET_PERMISSIONS_FETCHED_ACTION:
					const isFetched = action.payload;
					return isFetched;
			default:
					return state;
	}
};