export class LookUpDTO {
    PK;
    SK;
    id;
    type;
    status;
    values;
    name;
    questionaireId;
    createdAt;
    updatedAt;
    tenantId;

    constructor(data, tenantId) {
      if (data.id != null) {
        this.PK = data.PK;
        this.SK = data.SK;
      } else {
        this.PK = `${tenantId}#QNAIRE#${data.questionaireId}`;
        this.SK = "LOOKUP#";
      }
      this.id = data.id;
      this.type = 'LOOKUP';
      this.status = data.status;
      this.values = data.values;
      this.name = data.name;
      this.questionaireId = data.questionaireId;
      this.tenantId = tenantId;
    }
  }