import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Dropdown
} from "semantic-ui-react";

import * as USER_ACTIONS from '../../../actions/tenantUsersActions';
import * as ROLE_ACTIONS from '../../../actions/tenantRolesActions';

export function UserRoleDetails(props) {
  const [state, setState] = useState({
    isChanged: true,
		selectedRoleId: '',
  });

	const getSelectedRole = () => {
		const role = props.roles.filter((role) => role.id === state.selectedRoleId);
		if(_.isEmpty(role)) return {id: "", name: "", description: "",};
		return role[0];
	}

	
	useEffect(() => {
		props.fetchTenantRolesAction(props.tenant);
	}, [props.tenant, props.tenant.id])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
		const role = getSelectedRole();
		if(!_.isEmpty(role.id)){

			const {id: userId, ...userDetails} = props.user;
			const userRole = {
				...userDetails,
				roleId: role.id,
				roleName: role.name,
				roleDescription: role.description,
				permissions: role.permissions,
			};

			props.saveUserRolesAction(props.tenant, userRole, props.user);
			if (_.isEmpty(props.onComplete)) {
				props.onComplete();
			}
		}
  };

	
	const _buildRoleDropdown = () => {
		return <Form.Field>
		<label>User Role</label>
		<select
		name="role"
		className={errors.question_text ? "invalid-input-field" : ""}
		{...register("role", { required: true, maxLength: 128 })}
		placeholder="Select a role"
		value={state.selectedRoleId}

		onChange={(e) => {
			setState((prevState) => ({
				...prevState,
				isChanged: true,
				selectedRoleId: e.target.value,
			}));
		}}
		>
			<option value="" disabled selected>Select a role</option>
			{props.roles.map((role) => {
				return (<option key={`${role.id}`} value={`${role.id}`} >{role.name}</option>);
			})}
		</select>
		{errors.role && errors.role.type === "required" && (
			<p className="validation-error">* Please enter a definition</p>
		)}
		{errors.question_text && errors.role.type === "pattern" && (
			<p className="validation-error">
				* May only contain alphabetical characters
			</p>
		)}
		{errors.question_text && errors.role.type === "maxLength" && (
			<p className="validation-error">
				* Name may not be more than 128 characters long
			</p>
		)}
	</Form.Field>;
	}
    
  
  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
		{_buildRoleDropdown()}

  <Divider />
    <Button.Group floated="right">
      <Button
        className="danger"
        onClick={() => {
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success" disabled={!state.isChanged}>
        <Icon name="checkmark" /> Add
      </Button>
    </Button.Group>
  </Form>
  );
}

const mapStateToProps = (state, ownProps) => {
	

	const tenantRoles = state.userManagement.roles;
	const allRoles = _.isEmpty(tenantRoles) ? [] : Object.values(tenantRoles);

	const selectedUserRoles = state.userManagement.selectedUserRoles;
	const userRoles = _.isEmpty(selectedUserRoles) ? [] : Object.values(selectedUserRoles);

	const roles = allRoles.filter((tRole) => {
		const doesInclude = userRoles.filter((uRole) => {
			if(uRole.id === tRole.id) return true;
			return uRole.roleName === tRole.name;
		}).length > 0;
		return !doesInclude;
	});

  return {
    tenant: state.tenantManagement.activeTenant,
    user: ownProps.user,
		roles: roles,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		fetchTenantRolesAction: (tenant) => dispatch(ROLE_ACTIONS.fetchRolesAction(tenant)),
		saveUserRolesAction: (tenant, userRole, user) => dispatch(USER_ACTIONS.userRoleSaveAction(tenant, userRole, user)),
  })
  )(UserRoleDetails);
