import {
    FETCH_QUESTIONS_SUCCESS_ACTION,
    QUESTION_ADD_ACTION,
    QUESTION_EDIT_ACTION,
    QUESTION_DELETE_ACTION,
} from '../actions/questionActions';

export const QuestionsReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_QUESTIONS_SUCCESS_ACTION:
            const newQuestions = action.payload;
            // const oldQuestions = Object.values({...state}).filter(s => !newQuestions.some(newS => newS.fingerprint === s.fingerprint));
            const oldQuestions = Object.values({...state}).filter(s => !newQuestions.some(newS => newS.id === s.id));
            const newQuestionState = oldQuestions.concat(newQuestions); //Creates a copy...a new state
            return newQuestionState;
        case QUESTION_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case QUESTION_EDIT_ACTION:
            const newQuestion = action.payload;
            // const questionsCopy = Object.values({...state}).filter(s => !(newQuestion.fingerprint === s.fingerprint)); //Creates a copy...a new state
            const questionsCopy = Object.values({...state}).filter(s => !(newQuestion.id === s.id)); //Creates a copy...a new state
            questionsCopy.push(newQuestion);
            return questionsCopy;
        case QUESTION_DELETE_ACTION:
            return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
};