import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_COUNTRY_CODES_ACTION = 'FETCHING_COUNTRY_CODES_ACTION';
export const FETCH_COUNTRY_CODES_SUCCESS_ACTION = 'FETCH_COUNTRY_CODES_SUCCESS_ACTION';
export const FETCH_COUNTRY_CODES_ERROR_ACTION = 'FETCH_COUNTRY_CODES_ERROR_ACTION';

export const COUNTRY_CODES_ADD_ACTION = 'COUNTRY_CODES_ADD_ACTION';
export const COUNTRY_CODES_EDIT_ACTION = 'COUNTRY_CODES_EDIT_ACTION';
export const COUNTRY_CODES_DELETE_ACTION = 'COUNTRY_CODES_DELETE_ACTION';

export const fetchCountryCodesAction = () => {
  const queryParams = {
    PK: 'COUNTRYCODES',
    SK: 'COUNTRYCODES',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_COUNTRY_CODES_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = JSON.parse(result.Items[0].countryCodes);
        dispatch({ type: FETCH_COUNTRY_CODES_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_COUNTRY_CODES_ERROR_ACTION, err });
      });
  };
};