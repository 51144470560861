import _ from 'lodash'
import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { BRQnaireDTO } from './DTOs/BRQnaireDTO';
import { createItemWs,updateItemWs } from '../webservices/saveWS';
import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_BRQNAIRES_ACTION = 'FETCHING_BRQNAIRES_ACTION';
export const FETCH_BRQNAIRES_SUCCESS_ACTION = 'FETCH_BRQNAIRES_SUCCESS_ACTION';
export const FETCH_BRQNAIRES_ERROR_ACTION = 'FETCH_BRQNAIRES_ERROR_ACTION';

export const BRQNAIRE_ADD_ACTION = 'BRQNAIRE_ADD_ACTION';
export const BRQNAIRE_EDIT_ACTION = 'BRQNAIRE_EDIT_ACTION';
export const BRQNAIRE_DELETE_ACTION = 'BRQNAIRE_DELETE_ACTION';

export const fetchbRQnairesAction = (qnaire, tenantId) => {
  const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${qnaire.id}`,
    SK: 'BRULE%23',
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_BRQNAIRES_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_BRQNAIRES_ERROR_ACTION, err });
      });
  };
};

export const bRQnaireSaveAction = (brQnaire,tenantId) => {
  return (dispatch, getState) => {
    const saveObject = new BRQnaireDTO(brQnaire,tenantId);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (brQnaire.id === undefined) {
      saveObject.status = 'active'
      saveObject.values = [];
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: BRQNAIRE_ADD_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The Questionnaire Business Rule has been added successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to add Questionnaire Business Rule please try again');
        })
    }
    else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: BRQNAIRE_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The Questionnaire Business Rule has been updated successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to update Questionnaire Business Rule please try again');
        })
    }
  }
}

export const bRQnaireDeleteAction = (brQnaire) => {
  return (dispatch, getState) => {
    const saveObject = new BRQnaireDTO(brQnaire);
    saveObject.status = 'isDeleted';
    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: BRQNAIRE_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success('The Questionnaire Business Rule has been deleted successfully');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error('Failed to delete Questionnaire Business Rule please try again');
      })
  }
}
