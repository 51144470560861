import {
	GROUP_EDIT_ACTION,
	GROUP_DELETE_ACTION,
	FETCH_GROUPS_SUCCESS_ACTION,
	GROUP_ADD_ACTION
} from '../actions/groupsActions';

export const groupsReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_GROUPS_SUCCESS_ACTION:
			const newGroups = action.payload;
			// const oldGroups = Object.values({ ...state }).filter(group => !newGroups.some(newGroup => newGroup.id === group.id));
			// const newGroupState = oldGroups.concat(newGroups);
			return newGroups;
		case GROUP_ADD_ACTION:
			const clonedState = [...state];
			clonedState.push(action.payload);
			return clonedState;
		case GROUP_EDIT_ACTION:
			const newGroup = action.payload;
			const groupsCopy = Object.values({ ...state }).filter(group => !(newGroup.id === group.id));
			groupsCopy.push(newGroup);
			return groupsCopy;
		case GROUP_DELETE_ACTION:
			return state.filter(element => element.id !== action.payload.id);
		default:
			return state;
	}
};