import { FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_SUCCESS_ACTION } from "../actions/questionWhatsappTemplateActions";


export const questionWhatsAppTemplateReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_QUESTION_WHATSAPPTEMPLATES_WHATSAPPTEMPLATES_SUCCESS_ACTION:
            const templates = action.payload;
            return templates;
        // case KML_FILES_ADD_ACTION:
        //     const clonedState = [...state];
        //     clonedState.push(action.payload);
        //     return clonedState;
        // case KML_FILES_EDIT_ACTION:
        //     const newKml = action.payload;
        //     const kmlCopy = Object.values({...state}).filter(s => (newKml.id !== s.id));
        //     kmlCopy.push(newKml);
        //     return kmlCopy;
        // case DELETE_KML_FILES_ACTION:
        //     return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
}