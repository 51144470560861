import _ from 'lodash';
import { SAVE_PROCESS_DONE } from "./fetchingDataActions";
import {updateItemWs} from "../webservices/saveWS";
import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_CONDITIONS_ACTION = 'FETCHING_CONDITIONS_ACTION';
export const FETCH_CONDITIONS_SUCCESS_ACTION = 'FETCH_CONDITIONS_SUCCESS_ACTION';
export const FETCH_CONDITIONS_ERROR_ACTION = 'FETCH_CONDITIONS_ERROR_ACTION';

export const CONDITION_ADD_ACTION = 'CONDITION_ADD_ACTION';
export const CONDITION_EDIT_ACTION = 'CONDITION_EDIT_ACTION';
export const CONDITION_DELETE_ACTION = 'CONDITION_DELETE_ACTION';
export const CONDITION_CLEAR_SELECTED_ACTION = 'CONDITION_CLEAR_SELECTED_ACTION';

export const fetchConditionsAction = (targetId, tenantId, sectionCondition) => {
  const queryParams = {
    PK: `${tenantId}%23${sectionCondition ? 'SECTION' : 'QUESTION'}%23${targetId}`,
    SK: 'CONDITION%23',
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_CONDITIONS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_CONDITIONS_ERROR_ACTION, err });
      });
  };
};

export const conditionDeleteAction = (condition) => {
  return (dispatch, getState) => {
    const saveObject = _.omit({...condition}, ['createdAt', 'updatedAt']);

    saveObject.status = 'isDeleted';
    updateItemWs(saveObject, getState)
    .then((result) => {
      dispatch({type: CONDITION_DELETE_ACTION, payload: result.data.updateDisplayCondition.id});
      dispatch({type: SAVE_PROCESS_DONE});
    })
    .catch((err) => {
      console.log(err);
      dispatch({type: SAVE_PROCESS_DONE});
    })
  }
} 

export const conditionClearSelectedAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: CONDITION_CLEAR_SELECTED_ACTION });
  };
}
