import axios from "axios";

export function approveQnaireNotifWs(qData, getState) {
    const state = getState();
    const jwtToken = state.authData[0].signInUserSession.accessToken.jwtToken;
  
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
    };
  
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ENV}/approveQnaireNotif`,
        qData,
        { headers }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          console.error("Error response from server:", err.response.data);
          console.error("Status code:", err.response.status);
          console.error("Headers:", err.response.headers);
        } else if (err.request) {
          console.error("No response received:", err.request);
        } else {
          console.error("Error setting up request:", err.message);
        }
  
        console.error("Error getting distribution rule logs:", err);
  
        throw err;
      });
  }