import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from 'moment';
import { Container, Button, Table, Confirm } from "semantic-ui-react";

import * as REVIEW_QNAIRE_ACTIONS from "../../../actions/reviewQuestionaireActions";


export function ReviewQuestionnaires(props) {
    const [state, setState] = useState({
        tenant: { ...props.tenant },
        selectedQuestionnaire: null,
        actionType: null,
        confirmOpen: false
    });

    useEffect(() => {
        props.fetchTenantReviewQnaires(props.tenant);
    }, []);

    const validateQnaire = () => {
        props.reviewQuestionaireAction(state.selectedQuestionnaire, state.actionType, props.tenant);
        
        closeConfirmModal();
    }

    const denyQnaire = () => {
        props.reviewQuestionaireAction(state.selectedQuestionnaire, state.actionType, props.tenant);

        closeConfirmModal();
    }

    const openConfirmModal = (questionnaire, actionType) => {
        setState(prevState => ({
            ...prevState,
            selectedQuestionnaire: questionnaire,
            actionType,
            confirmOpen: true
        }));
    }

    const closeConfirmModal = () => {
        setState(prevState => ({
            ...prevState,
            confirmOpen: false
        }));
    }

    const _buildReviewQuestionnaires = () => {
        if (props.reviewQnaires.length === 0) {
            return (
                <div>
                    <h3>No questionnaires to review at the moment.</h3>
                </div>
            );
        } else {
            return (
                <div>
                    <h3>Questionnaires to Review:</h3>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className='nectaPrimaryBg'>Name</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg'>Description</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg'>Review Request Date</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg'>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {props.reviewQnaires.map((questionnaire) => (
                                <Table.Row key={questionnaire.id}>
                                    <Table.Cell>{questionnaire.name}</Table.Cell>
                                    <Table.Cell>{questionnaire.description}</Table.Cell>
                                    <Table.Cell>{moment.utc(questionnaire.updatedAt).format("DD-MM-YYYY")}</Table.Cell>
                                    <Table.Cell>
                                        <Button compact className="success" icon="check" content='Validate' onClick={() => openConfirmModal(questionnaire, 'validate')} />
                                        <Button compact className="danger" icon="x" content='Deny' onClick={() => openConfirmModal(questionnaire, 'deny')} />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            );
        }
    }
    

    return (
        <Container>
            {_buildReviewQuestionnaires()}
            <Confirm
                open={state.confirmOpen}
                onCancel={closeConfirmModal}
                onConfirm={state.actionType === 'validate' ? validateQnaire : denyQnaire}
                content={state.actionType === 'validate' ? 'Are you sure you want to validate this questionnaire?' : 'Are you sure you want to deny this questionnaire?'}
            />
        </Container>
    )
}
const mapStateToProps = (state, ownProps) => {
    return {
        tenantZero: state.tenantManagement.activeTenant,
        reviewQnaires: state.underReviewQuestionnaires,
    };
};

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch, ownProps) => ({
        fetchTenantReviewQnaires: (tenant) => dispatch(REVIEW_QNAIRE_ACTIONS.fetchTenantReviewQnaires(tenant)),
        reviewQuestionaireAction: (questionaire, action, tenant) => dispatch(REVIEW_QNAIRE_ACTIONS.reviewQuestionaireAction(questionaire, action, tenant)),
    })
)(ReviewQuestionnaires);
