import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Dropdown
} from "semantic-ui-react";

import * as ROLE_ACTIONS from '../../../actions/tenantRolesActions';
import { produceWithPatches } from "immer";

export function RoleDetails(props) {
  const [state, setState] = useState({
    role: { ...props.role },
    isChanged: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {

    let toCommit = {...state.role};

		if (!_.isEmpty(data.name)) toCommit.roleName = data.name;
		if (!_.isEmpty(data.description)) toCommit.roleDescription = data.description;

		if(props.isNew) props.createRoleAction(props.tenant, toCommit);
		else props.updateRoleAction(props.tenant, toCommit);
		
    setState((state) => ({ ...state, isChanged: false}));

    if (_.isEmpty(props.onComplete)) {
      props.onComplete();
    }
  };
    
	const _buildRoleName = () => {
		return <Form.Field>
		<label>Role Name</label>
		<input
			name="name"
			className={errors.question_text ? "invalid-input-field" : ""}
			{...register("name", { required: true, maxLength: 128 })}
			placeholder="Role Name"
			value={state.role.name}
			onChange={(e) => {
				setState((prevState) => ({
					...prevState,
					role: { ...prevState.role, name: e.target.value },
				}));
				setState((state) => ({...state, isChanged: true}));
			}}
		/>
		{errors.name && errors.name.type === "required" && (
			<p className="validation-error">* Please enter a definition</p>
		)}
		{errors.question_text && errors.name.type === "pattern" && (
			<p className="validation-error">
				* May only contain alphabetical characters
			</p>
		)}
		{errors.question_text && errors.name.type === "maxLength" && (
			<p className="validation-error">
				* Name may not be more than 128 characters long
			</p>
		)}
	</Form.Field>;
	}

	const _buildRoleDescription = () => {
		return <Form.Field>
		<label>Role Description</label>
		<input
			name="description"
			className={errors.question_text ? "invalid-input-field" : ""}
			{...register("description", { required: true, maxLength: 128 })}
			placeholder="Role Description"
			value={state.role.description}
			onChange={(e) => {
				setState((prevState) => ({
					...prevState,
					role: { ...prevState.role, description: e.target.value },
				}));
				setState((state) => ({...state, isChanged: true}));
			}}
		/>
		{errors.description && errors.description.type === "required" && (
			<p className="validation-error">* Please enter a definition</p>
		)}
		{errors.question_text && errors.description.type === "pattern" && (
			<p className="validation-error">
				* May only contain alphabetical characters
			</p>
		)}
		{errors.question_text && errors.description.type === "maxLength" && (
			<p className="validation-error">
				* Name may not be more than 128 characters long
			</p>
		)}
	</Form.Field>;
	}

  
  
  
  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
		{_buildRoleName()}
		{_buildRoleDescription()}

  <Divider />
    <Button.Group floated="right">
      <Button
        className="danger"
        onClick={() => {
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success" disabled={!state.isChanged}>
        <Icon name="checkmark" /> Ok
      </Button>
    </Button.Group>
  </Form>
  );
}

const mapStateToProps = (state, ownProps) => {
  var role = {};
  var isNew = false;
  var newRole = {
    name: '',
    description: '',
		permissions: '',
  };

  if (ownProps.bdaction === 'add') {
    role = newRole;
    isNew = true;
  } else {
    role = _.isEmpty(ownProps.role)
      ? newRole
      : ownProps.role;
    isNew = false;
  }

  return {
    tenant: state.tenantManagement.activeTenant,
    isNew: isNew,
    role: role,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		createRoleAction: (tenant, role) => dispatch(ROLE_ACTIONS.createRoleAction(tenant, role)),
		updateRoleAction: (tenant, role) => dispatch(ROLE_ACTIONS.createRoleAction(tenant, role)),
  })
  )(RoleDetails);
