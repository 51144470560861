import { toast } from "react-toastify";
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { createItemWs, updateItemWs } from "../webservices/saveWS";
import { FrequencyRuleDTO } from "./DTOs/FrequencyRuleDTO";

export const FETCHING_FREQUENCY_RULE_ACTION = "FETCHING_FREQUENCY_RULE_ACTION";
export const FETCH_FREQUENCY_RULE_SUCCESS_ACTION = "FETCH_FREQUENCY_RULE_SUCCESS_ACTION";
export const FETCH_FREQUENCY_RULE_ERROR_ACTION = "FETCH_FREQUENCY_RULE_ERROR_ACTION";

export const FREQUENCY_RULE_ADD_ACTION = 'FREQUENCY_RULE_ADD_ACTION';
export const FREQUENCY_RULE_EDIT_ACTION = 'FREQUENCY_RULE_EDIT_ACTION';
export const FREQUENCY_RULE_DELETE_ACTION = 'FREQUENCY_RULE_DELETE_ACTION';

export const saveActivationRuleAction = (Rule, tenant, isNew) => {
  return (dispatch, getState) => {
    const saveObject = new FrequencyRuleDTO(Rule, tenant);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (isNew === true) {
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: FREQUENCY_RULE_ADD_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The new questionnaire has been added successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to add the new questionnaire please try again');
        })
    } else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: FREQUENCY_RULE_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The questionnaire has been updated successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to update the questionnaire please try again');
        })
    }
  }
}