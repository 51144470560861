import {
    FETCH_LOOKUPS_SUCCESS_ACTION,
    LOOKUP_ADD_ACTION,
    LOOKUP_EDIT_ACTION,
    LOOKUP_DELETE_ACTION
} from '../actions/lookupsActions';

export const lookupsReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_LOOKUPS_SUCCESS_ACTION:
            const newLookups = action.payload;
            // const oldLookups = Object.values({...state}).filter(s => !newLookups.some(newS => newS.fingerprint === s.fingerprint));
            const oldLookups = Object.values({...state}).filter(s => !newLookups.some(newS => newS.id === s.id));
            const newLookupState = oldLookups.concat(newLookups); //Creates a copy...a new state
            return newLookupState;
        case LOOKUP_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case LOOKUP_EDIT_ACTION:
            const newLookup = action.payload;
            // const lookupsCopy = Object.values({...state}).filter(s => !(newLookup.fingerprint === s.fingerprint)); //Creates a copy...a new state
            const lookupsCopy = Object.values({...state}).filter(s => !(newLookup.id === s.id)); //Creates a copy...a new state
            lookupsCopy.push(newLookup);
            return lookupsCopy;
        case LOOKUP_DELETE_ACTION:
            return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
};