import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";

import { InboxDTO } from './DTOs/InboxDTO';
import { createItemWs, updateItemWs } from '../webservices/saveWS';
import { fetchItemsWS } from '../webservices/fetchWS';

export const FETCHING_INBOXES_ACTION = 'FETCHING_INBOXES_ACTION';
export const FETCH_INBOXES_SUCCESS_ACTION = 'FETCH_INBOXES_SUCCESS_ACTION';
export const FETCH_INBOXES_ERROR_ACTION = 'FETCH_INBOXES_ERROR_ACTION';

export const INBOX_ADD_ACTION = 'INBOX_ADD_ACTION';
export const INBOX_EDIT_ACTION = 'INBOX_EDIT_ACTION';
export const INBOX_DELETE_ACTION = 'INBOX_DELETE_ACTION';

export const fetchInboxesAction = () => {
  const queryParams = {
    PK: 'INBOXES',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_INBOXES_ACTION });
    fetchItemsWS('get-dynamodb', queryParams)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_INBOXES_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_INBOXES_ERROR_ACTION, err });
      });
  };
}

//creating or editing an inbox
export const inboxSaveAction = (inbox, isNewItem) => {
  return (dispatch, getState) => {
    const saveObject = new InboxDTO(inbox);
    saveObject.status = "active";
    dispatch({ type: SAVE_IN_PROGRESS });
    if (isNewItem === true){
      //create new inbox
      createItemWs(saveObject, isNewItem, getState)
      .then((result) => {
        dispatch({type: INBOX_ADD_ACTION, payload: result.data});
        dispatch({type: SAVE_PROCESS_DONE});
        toast.success('The new inbox has been created successfully');
      })
      .catch((err) => {
        console.log(err);
        dispatch({type: SAVE_PROCESS_DONE});
        toast.error('Failed to create the new inbox please try again');
      })
    }
    else{
      updateItemWs(saveObject, isNewItem, getState)
      .then((result) => {
        dispatch({type: INBOX_EDIT_ACTION, payload: result.data});
        dispatch({type: SAVE_PROCESS_DONE});
        toast.success("The inbox details has been updated successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({type: SAVE_PROCESS_DONE});
        toast.error('Failed to update the inbox details please try again');
      })
    }
  }
} 

export const inboxDeleteAction = (inbox) => {
  return (dispatch, getState) => {
    const saveObject = new InboxDTO(inbox);
    saveObject.status = 'isDeleted';
    updateItemWs(saveObject, false, getState)
    .then(() => {
      dispatch({type: INBOX_DELETE_ACTION, payload: saveObject});
      dispatch({type: SAVE_PROCESS_DONE});
      toast.success('The inbox has been deleted successfully');
    })
    .catch((err) => {
      console.log(err);
      dispatch({type: SAVE_PROCESS_DONE});
      toast.error('Failed to delete the inbox please try again');
    })
  }
} 

