import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { fetchItemsWS } from '../webservices/fetchWS';
import { createItemWs, updateItemWs} from "../webservices/saveWS";
import { distRuleFundsCheck, scheduleActivRule, getWhatsappTemplates, getDistRuleLogs} from "../webservices/activationRulesWS";
import { toast } from "react-toastify";
import { ActivationRuleDTO } from "./DTOs/ActivationRuleDTO";
import { object } from "prop-types";
import _ from "lodash";

export const FETCHING_ACT_RULES_ACTION = 'FETCHING_ACT_RULES_ACTION';
export const FETCH_ACT_RULES_SUCCESS_ACTION = 'FETCH_ACT_RULES_SUCCESS_ACTION';
export const FETCH_ACT_RULES_ERROR_ACTION = 'FETCH_ACT_RULES_ERROR_ACTION';

export const fetchActivationRulesAction = (tenant) => {
    const queryParams = {
        PK: `${tenant.id}%23ACTRULES`,
        SK: `ACTRULE%23`,
      };

      return (dispatch, getState) => {
        dispatch({ type: FETCHING_ACT_RULES_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
          .then((result) => {
            let items = result.Items;
            items = items.filter((item) => item.status !== 'isDeleted');
            dispatch({ type: FETCH_ACT_RULES_SUCCESS_ACTION, payload: items });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_ACT_RULES_ERROR_ACTION, err });
          });
      };
}

export const FETCHING_RULE_QUESTIONNARES_ACTION = "FETCHING_RULE_QUESTIONNARES_ACTION";
export const FETCH_RULE_QUESTIONNARES_SUCCESS_ACTION = "FETCH_RULE_QUESTIONNARES_SUCCESS_ACTION";
export const FETCH_RULE_QUESTIONNARES_ERROR_ACTION = "FETCH_RULE_QUESTIONNARES_ERROR_ACTION";

export const fetchQuestionnairesAction = (tenantId) => {
    const queryParams = {
        GSI2PK: `${tenantId}%23QNAIRES`,
        GSI2SK: 'QNAIRE',
    };

    return async (dispatch, getState) => {
      dispatch({ type: FETCHING_RULE_QUESTIONNARES_ACTION, payload: tenantId });
  
      try {
        const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        const state = getState();
        const tenant = state.tenantManagement.activeTenant
  
        if(tenant.baseQnaires === undefined || tenant.baseQnaires.length === 0){
          dispatch({ type: FETCH_RULE_QUESTIONNARES_SUCCESS_ACTION, payload: returnItems });
        }
        else{
          const matchingBaseQnaires = await dispatch(fetchBaseQuestionairesAction(tenant));
          const combinedResults = [...returnItems, ...matchingBaseQnaires];
          dispatch({ type: FETCH_RULE_QUESTIONNARES_SUCCESS_ACTION, payload: combinedResults });
        }
      } catch (err) {
        console.log(err);
        dispatch({ type: FETCH_RULE_QUESTIONNARES_ERROR_ACTION, err });
      }
    };
};

export const fetchBaseQuestionairesAction = (tenant) => {
  const queryParams = {
    GSI2PK: `2WWjarbwyZ6IvJpoQKfzVWkGWiK%23QNAIRES`,
    GSI2SK: 'QNAIRE%23',
  };

  return async (dispatch, getState) => {

    try {
      const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const returnItems = result.Items.filter(item => item.isBaseQnaire === true && item.status !== "isDeleted");

      // Merge tenant's base questionnaires with fetched base questionnaires from tenant zero
      const matchingBaseQnaires = tenant.baseQnaires.map(tenantBaseQnaire => {
      const matchingFetchedQnaire = returnItems.find(item => item.id === tenantBaseQnaire.id);

      // Check if the fetched questionnaire with the same ID exists
      if (matchingFetchedQnaire && matchingFetchedQnaire.isBaseQnaire && matchingFetchedQnaire.status !== "isDeleted") {
        // If it exists, merge the properties and return the merged object
        return { ...tenantBaseQnaire, ...matchingFetchedQnaire };
      }

      // If it doesn't exist or is not a base questionnaire, return the original tenant base questionnaire
      return tenantBaseQnaire;
    });
      return matchingBaseQnaires;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export const FETCHING_RULE_KML_FILES_ACTION = "FETCHING_RULE_KML_FILES_ACTION";
export const FETCH_RULE_KML_FILES_SUCCESS_ACTION = "FETCH_RULE_KML_FILES_SUCCESS_ACTION";
export const FETCH_RULE_KML_FILES_ERROR_ACTION = "FETCH_RULE_KML_FILES_ERROR_ACTION";

export const fetchKmlFilesAction = (tenantId) => {
    const queryParams = {
        PK: `${tenantId}%23KMLFILES`,
        SK: 'KMLFILE%23',
    };
    
    return (dispatch, getState) => {
        fetchItemsWS('get-dynamodb', queryParams, getState)
        .then((result) => {
            const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
            dispatch({type: FETCH_RULE_KML_FILES_SUCCESS_ACTION,payload: returnItems,});
        })
        .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_RULE_KML_FILES_ERROR_ACTION, err });
        });
    };
};

export const FETCHING_WHATSAPP_TEMPLATES_ACTION = "FETCHING_WHATSAPP_TEMPLATES_ACTION";
export const FETCH_WHATSAPP_TEMPLATES_SUCCESS_ACTION = "FETCH_WHATSAPP_TEMPLATES_SUCCESS_ACTION";
export const FETCH_WHATSAPP_TEMPLATES_ERROR_ACTION = "FETCH_WHATSAPP_TEMPLATES_ERROR_ACTION";

export const fetchWhatsAppTemplatesAction = () => {
    return (dispatch, getState) => {
      getWhatsappTemplates(getState)
        .then((result) => {
            const returnItems = result.data;
            dispatch({type: FETCH_WHATSAPP_TEMPLATES_SUCCESS_ACTION,payload: returnItems,});
        })
        .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_WHATSAPP_TEMPLATES_ERROR_ACTION, err });
        });
    };
};

export const FETCHING_RULE_QUESTIONS_ACTION = "FETCHING_RULE_QUESTIONS_ACTION";
export const FETCH_RULE_QUESTIONS_SUCCESS_ACTION = "FETCH_RULE_QUESTIONS_SUCCESS_ACTION";
export const FETCH_RULE_QUESTIONS_ERROR_ACTION = "FETCH_RULE_QUESTIONS_ERROR_ACTION";

export const fetchQuestionsAction = (questionaireId, tenantId) => {
    const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${questionaireId}`,
    SK: 'QUESTION%23',
    };

    return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
        .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== "isDeleted");
        dispatch({
            type: FETCH_RULE_QUESTIONS_SUCCESS_ACTION,
            payload: returnItems,
        });
        })
        .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_RULE_QUESTIONS_ERROR_ACTION, err });
        });
    };
};

export const FETCHING_RULE_SECTIONS_ACTION = "FETCHING_RULE_SECTIONS_ACTION";
export const FETCH_RULE_SECTIONS_SUCCESS_ACTION = "FETCH_RULE_SECTIONS_SUCCESS_ACTION";
export const FETCH_RULE_SECTIONS_ERROR_ACTION = "FETCH_RULE_SECTIONS_ERROR_ACTION";

export const fetchSectionsAction = (questionaireId, tenantId) => {
    const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${questionaireId}`,
    SK: 'SECTION%23',
    };

    return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
        .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== "isDeleted");
        dispatch({
            type: FETCH_RULE_SECTIONS_SUCCESS_ACTION,
            payload: returnItems,
        });
        })
        .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_RULE_SECTIONS_ERROR_ACTION, err });
        });
    };
};



export const FETCH_RULE_LOOKUPS_ACTION = 'FETCH_LOOKUPS_ACTION';
export const FETCH_RULE_LOOKUPS_SUCCESS_ACTION = 'FETCH_LOOKUPS_SUCCESS_ACTION';
export const FETCH_RULE_LOOKUPS_ERROR_ACTION = 'FETCH_LOOKUPS_ERROR_ACTION';

export const fetchLookupsAction = (question, next = null) => {
  const queryParams = {
    PK: `${question.tenantId}%23QNAIRE%23${question.questionaireId}`,
    SK: `LOOKUP%23${question.lookupId}`,
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter((item) => item.status !== 'isDeleted');
        if(next && typeof next === "function"){
            next(returnItems, null);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_RULE_LOOKUPS_ERROR_ACTION, err });
        if(next && typeof next === "function"){
            next(null, err);

        }
      });
  };
};

export const FETCHING_RULE_USER_GROUPS_ACTION = 'FETCHING_RULE_USER_GROUPS_ACTION';
export const FETCH_RULE_USER_GROUPS_SUCCESS_ACTION = 'FETCH_RULE_USER_GROUPS_SUCCESS_ACTION';
export const FETCH_RULE_USER_GROUPS_ERROR_ACTION = 'FETCH_RULE_USER_GROUPS_ERROR_ACTION';

export const fetchUserGroupsAction = (tenant) => {
  const queryParams = {
    PK: `${tenant.id}%23GROUPS`,
    SK: `GROUP%23`,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_RULE_USER_GROUPS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        let items = result.Items;
        items = items.filter((item) => item.status !== 'isDeleted');
        dispatch({ type: FETCH_RULE_USER_GROUPS_SUCCESS_ACTION, payload: items });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_RULE_USER_GROUPS_ERROR_ACTION, err });
      });
  };
}


export const ACT_RULE_ADD_ACTION = "ACT_RULE_ADD_ACTION";
export const ACT_RULE_EDIT_ACTION = "ACT_RULE_EDIT_ACTION";

export const saveActivationRuleAction = (tenant, rule, isNew) => {
    return (dispatch, getState) => {
        const saveObject = new ActivationRuleDTO(rule, tenant);
        dispatch({ type: SAVE_IN_PROGRESS });
        if (isNew === true) {
            createItemWs(saveObject, getState)
            .then((result) => {
                dispatch({ type: ACT_RULE_ADD_ACTION, payload: result.data });
                dispatch({ type: SAVE_PROCESS_DONE });
                scheduleActivRule(result.data,getState)
                toast.success("Distribution Rule Successfully Created");
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.error("Failed to create new distribution rule. Please try again");
                });
        }
        else{
            updateItemWs(saveObject, getState)
            .then((result) => {
                dispatch({ type: ACT_RULE_EDIT_ACTION, payload: result.data });
                dispatch({ type: SAVE_PROCESS_DONE });
                scheduleActivRule(result.data,getState)
                toast.success("Successfully updated the distribution rule");
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.success("Failed to update the distribution rule. Please try again");
                });
        }
    }
}

export const ACT_RULE_DELETE_ACTION = "ACT_RULE_DELETE_ACTION";

export const deleteActivationRuleAction = (rule, tenant) => {
    return (dispatch, getState) => {
        const saveObject = new ActivationRuleDTO(rule, tenant);
        saveObject.status = "isDeleted";

        updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: ACT_RULE_DELETE_ACTION, payload: result.data.id });
          dispatch({ type: SAVE_PROCESS_DONE });
          scheduleActivRule(result.data,getState)
          toast.success("Distribution Rule Successfully Deleted.");
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to delete the Distribution Rule please try again");
        });

    }
}

export const executeActivartionRuleAction = (rule, getState) => {
  return (dispatch, getState) => {
    if(_.isEmpty(rule.ruleQuestionnaires)){
      toast.warning("Please attach a questionnaie to the dist rule before executing.");
    }else{
      const object = {
        pk: rule.PK,
        sk: rule.SK
      }
      distRuleFundsCheck(object, getState)
        .then((result) => {
          if(result.data.success === false){
            toast.error(`Failed to Execute Distribution Rule. Insufficient funds. Required funds: ${result.data.cost / 100}`);
          }else{
            rule.execute = "true";
            scheduleActivRule(rule, getState)
              .then((result) => {
                toast.success("Distribution Rule Executed");
              })
              .catch((err) => {
                toast.error("Failed to Execute Distribution Rule");
              });
          }
        })
        .catch((err) => {
          toast.error("Failed to Execute Distribution Rule");
        });
    }
  }
}

export const FETCHING_RULE_LOGS_ACTION = 'FETCHING_RULE_LOGS_ACTION';
export const FETCH_RULE_LOGS_INVOCATION_SUCCESS_ACTION = 'FETCH_RULE_LOGS_INVOCATION_SUCCESS_ACTION';
export const FETCH_RULE_LOGS_SUCCESS_SUCCESS_ACTION = 'FETCH_RULE_LOGS_SUCCESS_SUCCESS_ACTION';
export const FETCH_RULE_LOGS_ERROR_ACTION = 'FETCH_RULE_LOGS_ERROR_ACTION';

export const fetchActivationRuleLogsAction = (rule) => {
  const queryParams = {
    tenantId: rule.tenantId,
    activId: rule.id,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_RULE_LOGS_ACTION });
    
    return getDistRuleLogs(queryParams, getState)
      .then((result) => {
        let invocationEvents = result.data.invocationEvent;
        invocationEvents = JSON.parse(invocationEvents);
        let successEvents = result.data.successEvent;
        successEvents = JSON.parse(successEvents);
        
        dispatch({ type: FETCH_RULE_LOGS_INVOCATION_SUCCESS_ACTION, payload: invocationEvents });
        dispatch({ type: FETCH_RULE_LOGS_SUCCESS_SUCCESS_ACTION, payload: successEvents });
        
        return invocationEvents;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_RULE_LOGS_ERROR_ACTION, err });
        throw err;
      });
  };
}

