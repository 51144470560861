export class BusinessRuleTypeDTO {
    PK;
    SK;
    id;
    type;
    status;
    brType;
    brTypeDesc;
    targetQnaireId;
    targetQnaireName;
    brTypeAttributes;
    microService;
    createdAt;
    updatedAt;
    tenantId;
    pptxType;

    constructor(data, tenantId) {
      if (data.id != null) {
        this.PK = data.PK;
        this.SK = data.SK;
      } else {
        this.PK = `${tenantId}#BRTYPES`;
        this.SK = 'BRTYPE#';
      }
      this.id = data.id;
      this.type = 'BRTYPE';
      this.status = data.status;
      this.brType = data.brType;
      this.brTypeDesc = data.brTypeDesc;
      this.targetQnaireId = data.targetQnaireId;
      this.targetQnaireName = data.targetQnaireName;
      this.brTypeAttributes = data.brTypeAttributes;
      this.microService = data.microService;
      this.tenantId = tenantId;
      this.pptxType = data.pptxType;
    }
  }