import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Dimmer,
  Header,
  Icon,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import * as ACTIVATION_RULES from "../../../actions/activationRulesActions";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

const ActivationRuleLogDetails = (props) => {
  const [state, setState] = useState({
    loading: false,
  });

  useEffect(() => {
    setState({ loading: true });

    props
      .fetchActivationRuleLogsAction(props.activationRule)
      .then(() => {
        setState({ loading: false });
      })
      .catch(() => {
        setState({ loading: false });
        toast.error("Failed to fetch activation rule logs");
      });
  }, [props.activationRule]);

  const combineEvents = () => {
    const invocationEvents = props.invocationEvents.map((event) =>
      JSON.parse(event.message)
    );
    const successEvents = props.successEvents.map((event) =>
      JSON.parse(event.message)
    );
  
    const eventMap = {};
  
    invocationEvents.forEach((event) => {
      eventMap[event.invocationTime] = {
        ...event,
        success: false,
      };
    });
  
    successEvents.forEach((event) => {
      if (event.invocationTime in eventMap) {
        eventMap[event.invocationTime] = {
          ...eventMap[event.invocationTime],
          ...event,
          success: true,
        };
      } else {
        eventMap[event.invocationTime] = {
          ...event,
          success: true,
        };
      }
    });
  
    // Sort the keys (invocation times) in descending order
    const sortedKeys = Object.keys(eventMap).sort((a, b) => b.localeCompare(a));
  
    // Create a new ordered object
    const orderedEventMap = {};
    sortedKeys.forEach(key => {
      orderedEventMap[key] = eventMap[key];
    });
  
    return Object.values(orderedEventMap);
  };

  const activationRuleLogsRender = () => {
    if (state.loading) {
      return (
        <Dimmer active inverted>
          <Loader>Loading...</Loader>
        </Dimmer>
      );
    }

    const combinedLogs = combineEvents();

    return combinedLogs.length > 0 ? (
      <Table size="large">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="nectaPrimaryBg">
              Date Invoked
            </Table.HeaderCell>
            <Table.HeaderCell className="nectaPrimaryBg">
              WhatsApp Users
            </Table.HeaderCell>
            <Table.HeaderCell className="nectaPrimaryBg">
              Mobile Users
            </Table.HeaderCell>
            <Table.HeaderCell className="nectaPrimaryBg">
              Total Users
            </Table.HeaderCell>
            <Table.HeaderCell className="nectaPrimaryBg">
              Maximum Users
            </Table.HeaderCell>
            <Table.HeaderCell className="nectaPrimaryBg">
              Status
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {combinedLogs.map((log, index) => (
            <Table.Row key={`${log.ruleId}.${index}`}>
              <Table.Cell>
                {moment.utc(log.invocationTime).format("DD-MM-YYYY hh:mm:ss a")}
              </Table.Cell>
              <Table.Cell>{log.whatsappUsers || 0}</Table.Cell>
              <Table.Cell>{log.mobileUsers || 0}</Table.Cell>
              <Table.Cell>{(log.mobileUsers + log.whatsappUsers) || 0}</Table.Cell>
              <Table.Cell>{log.totalUsers}</Table.Cell>
              <Table.Cell>
                {log.success ? "Success" : "Failed"}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    ) : (
      <Segment>
        <Header icon>
          <Icon name="file" color="black" />
          Logs Unavailable
        </Header>
      </Segment>
    );
    
  };

  return <Container>{activationRuleLogsRender()}</Container>;
};

const mapStateToProps = (state, ownProps) => {
  let activationRule = ownProps.activationRule;
  return {
    tenant: state.tenantManagement.activeTenant,
    activationRule: activationRule,
    invocationEvents: _.isEmpty(
      state.activationRules.ruleLogs.ruleLogsInvocations
    )
      ? []
      : Object.values(state.activationRules.ruleLogs.ruleLogsInvocations),
    successEvents: _.isEmpty(state.activationRules.ruleLogs.ruleLogsSuccesses)
      ? []
      : Object.values(state.activationRules.ruleLogs.ruleLogsSuccesses),
  };
};

export default connect(mapStateToProps, (dispatch) => ({
  fetchActivationRuleLogsAction: (activationRule) =>
    dispatch(ACTIVATION_RULES.fetchActivationRuleLogsAction(activationRule)),
}))(ActivationRuleLogDetails);
