import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import {
  Form,
  Button,
  Icon,
  Divider,
  Dropdown,
  Confirm,
} from "semantic-ui-react";

import * as QNAIRE_ACTIONS from "../../../actions/questionaireActions";
import * as LOOKUPQNAIRE_ACTIONS from "../../../actions/lookupsQnaireActions";

export function LookupsQnaireItem(props) {
  const [state, setState] = useState({
    lookupQnaire: { ...props.selectedLookupQnaire },
    lookupQnaireValues: { ...props.lookupQnaireValues },
    qnaireItem: { ...props.qnaireItem },
    questionaire: { ...props.questionaire },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  
  useEffect(() => {
    props.fetchQuestionairesAction(props.tenantId);
  }, []);

	const valueQnaireObjectsFromString = (json) => {
		if (!_.isEmpty(json)) {
			return JSON.parse(json);
		}
		else {
			return [];
		}
	}

	const valueQnaireObjectsToString = (valueQnaireObjectArray) => {
		return JSON.stringify(valueQnaireObjectArray);
	}

  const confirmDeactivate = () => {
		let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";
	
		return (
		  <Confirm
			header='Warning:'
			content={contentMessage}
			open={state.showConfirmIsActive}
			onCancel={() => setState((state) => ({ ...state, showConfirmIsActive: false }))}
			onConfirm={() => {
				setState((state) => ({ ...state, showConfirmIsActive: false }));
        let values = [];
  
        let toCommit = props.lookupQnaire;
    
        if (!_.isEmpty(toCommit.values))
          values = toCommit.values;
    
        const selectedqnaire = questionaireList.filter((qnaire) => qnaire.key === state.data.questionnaire_name).map((qnaire) => ({text: qnaire.text}));
    
        if (!_.isEmpty(selectedqnaire))
          state.qnaireItem.value = selectedqnaire[0].text;
          
        let newVal = [];
    
        if (!_.isEmpty(values))
           newVal = valueQnaireObjectsFromString(values);
    
        if (props.isNew === true)
          newVal.push(state.qnaireItem);
        else
          newVal[props.valIndex] = state.qnaireItem;
          
        let strVals = valueQnaireObjectsToString(newVal);
        toCommit.values = strVals;
        setState((state) => ({ ...state, lookupQnaireValues: newVal}));
    
        props.deactiveQuestionaireAction(props.questionaire);
        props.saveLookupsQnaireAction(toCommit, props.tenantId);
        if (_.isEmpty(props.onComplete)) {
          props.onComplete();
        }
			}}
		  />
		);
	  }

  const onSubmit = (data) => {

    const isQuestionnaireActive = props.questionaire.status == "active" || props.questionaire.status == "underReview";

    if (isQuestionnaireActive) {
      setState((state) => ({ ...state, showConfirmIsActive: true, data: data }));
    }else{
      let values = [];
  
      let toCommit = props.lookupQnaire;
  
      if (!_.isEmpty(toCommit.values))
        values = toCommit.values;
  
      const selectedqnaire = questionaireList.filter((qnaire) => qnaire.key === data.questionnaire_name).map((qnaire) => ({text: qnaire.text}));
  
      if (!_.isEmpty(selectedqnaire))
        state.qnaireItem.value = selectedqnaire[0].text;
        
      let newVal = [];
  
      if (!_.isEmpty(values))
         newVal = valueQnaireObjectsFromString(values);
  
      if (props.isNew === true)
        newVal.push(state.qnaireItem);
      else
        newVal[props.valIndex] = state.qnaireItem;
        
      let strVals = valueQnaireObjectsToString(newVal);
      toCommit.values = strVals;
      setState((state) => ({ ...state, lookupQnaireValues: newVal}));
  
      props.saveLookupsQnaireAction(toCommit, props.tenantId);
      if (_.isEmpty(props.onComplete)) {
        props.onComplete();
      }
    }
  };

  function notAlreadyLinked(value) {
    console.log(value.id);
      if (props.lookupQnaireValues
        .filter((qitem) => qitem.linktoqnaireid === value.id)
        .length >= 1)
      return false;
    else
      return true;
    }
  const questionaireList = _.orderBy(props.questionaires
    //.filter((qnaire) => qnaire.id !== props.lookupQnaire.questionaireId && qnaire.status === "active" && notAlreadyLinked(qnaire))
    .map((qnaire) => ({
      key: qnaire.id,
      text: qnaire.name,
      value: qnaire.id,
    })), ['text']);

    const questionnaireDropdown = () => {
      var defaultValue = state.qnaireItem.value ? state.qnaireItem.value : "";
  
      return (
        <Controller
          name="questionnaire_name"
          control={control}
          rules={{ required: true }}
          setValue={setValue}
          defaultValue={defaultValue}
          render={({ field, fieldState, formState }) => {
            return (
              <div>
                <Dropdown
                  className={fieldState.invalid ? "invalid-input-field" : ""}
                  {...field}
                  placeholder="Select a questionnaire"
                  fluid
                  selection
                  search
                  options={questionaireList}
                  value={state.qnaireItem.linktoqnaireid}
                  onChange={(e, { value }) => {
                    setState((state) => ({
                      ...state,
                      qnaireItem: {
                        ...state.qnaireItem,
                        linktoqnaireid: value,
                      },
                    }));
                    field.onChange(value);
                  }}
                />
                {fieldState.invalid && (
                  <p className="validation-error">* Select a questionnaire</p>
                )}
              </div>
            );
          }}
        />
      );
    };
  
  return (
    <Form
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: "20px" }}
    >
    {confirmDeactivate()}
    <Form.Field>
      <label>Menu/Option Name</label>
      <input
        name="lookupqnaireitem_name"
        className={errors.question_text ? "invalid-input-field" : ""}
        {...register("lookupqnaireitem_name", { required: true, maxLength: 256 })}
        placeholder="Item Name"
        value={state.qnaireItem.name}
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            qnaireItem: { ...prevState.qnaireItem, name: e.target.value },
          }));
        }}
      />
      {errors.lookupqnaireitem_name && errors.lookupqnaireitem_name.type === "required" && (
        <p className="validation-error">* Please enter a name</p>
      )}
      {errors.question_text && errors.lookupqnaireitem_name.type === "pattern" && (
        <p className="validation-error">
          * May only contain alphabetical characters
        </p>
      )}
      {errors.question_text && errors.lookupqnaireitem_name.type === "maxLength" && (
        <p className="validation-error">
          * Name may not be more than 256 characters long
        </p>
      )}
    </Form.Field>
    <Form.Field>
      <label>Sequence</label>
      <input
        name="lookupqnaireitem_seq"
        className={errors.question_text ? "invalid-input-field" : ""}
        {...register("lookupqnaireitem_seq", { required: true })}
        placeholder="1"
        type="number"
        min="1"
        value={state.qnaireItem.seq}
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            qnaireItem: {...prevState.qnaireItem, seq: parseInt(e.target.value.replace(/\D/, ""),10),
            },
          }));
        }}
      />
      {errors.lookupqnaireitem_seq && errors.lookupqnaireitem_seq.type === "required" && (
        <p className="validation-error">* Please enter a number</p>
      )}
    </Form.Field>

    <Form.Field>
      <label>Link to Questionnaire Name</label>
      {questionnaireDropdown()}
    </Form.Field>

    <Divider />
    <Button.Group floated="right">
      <Button
        className="danger"
        onClick={() => {
          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      >
        <Icon name="remove" /> Cancel
      </Button>
      <Button type="submit" className="success">
        <Icon name="checkmark" /> Ok
      </Button>
    </Button.Group>
  </Form>
  );
}

LookupsQnaireItem.defaultProps = {
  lookupQnaire: {}
};


const mapStateToProps = (state, ownProps) => {
  var qnaireItem = {};
  var isNew = false;
  var newQnaireItem = {
    name: '',
    linktoqnaireid: '',
    questionnaireId: ownProps.lookupQnaire.questionaireId,
    seq: 1,
    value: ''
  };

  if (ownProps.qnaction === 'add') {
    qnaireItem = newQnaireItem;
    isNew = true;
  } else {
    qnaireItem = _.isEmpty(ownProps.lookupQnaireValues)
      ? newQnaireItem
      : ownProps.lookupQnaireValues[ownProps.valIndex];
    isNew = false;
  }

  return {
    qnaireItem: qnaireItem,
    lookupQnaire: ownProps.lookupQnaire,
    lookupQnaireValues: ownProps.lookupQnaireValues,
    questionaire: ownProps.questionaire,
    isNew: isNew,
		questionaires: (_.isEmpty(state.questionaires)) ? [] : Object.values(state.questionaires),
    valIndex: ownProps.valIndex,
    tenantId: state.tenantManagement.activeTenant.tenantId
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
		fetchQuestionairesAction: (tenantId) => dispatch(QNAIRE_ACTIONS.fetchQuestionairesAction(tenantId)),
		saveLookupsQnaireAction: (lookupQnaire, tenantId) => dispatch(LOOKUPQNAIRE_ACTIONS.lookupQnaireSaveAction(lookupQnaire, tenantId)),
		deactiveQuestionaireAction: (questionaire) => dispatch(QNAIRE_ACTIONS.deactiveQuestionaireAction(questionaire)),
		//deleteLookupQnaire: (lookupQnaire) => dispatch(LOOKUPQNAIRE_ACTIONS.lookupQnaireDeleteAction(lookupQnaire))
  })
  )(LookupsQnaireItem);
