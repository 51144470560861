export class ExportDTO{
    PK;
    Sk;
    id;
    createdAt;
    updatedAt;
    tenantId;
    type;
    questionaireId;
    questionaireName;
    questions;
    userGroup;
    expStatus;
    startDate;
    endDate;

    constructor(exp, tenant) {
        if (exp.id != null) {
            this.PK = exp.PK;
            this.SK = exp.SK;
        }
        else{
            this.PK = `${tenant.id}#EXPORTS`;
            this.SK = "EXP#";
        }
        this.id = exp.id;
        this.type = "EXPORT";
        this.tenantId = tenant.id;
        this.questionaireId = exp.questionaireId;
        this.questionaireName = exp.questionaireName;
        this.questions = exp.questions;
        this.userGroup = exp.userGroup;
        this.expStatus = "Export Created";
        this.startDate = exp.startDate;
        this.endDate = exp.endDate;
    }
}