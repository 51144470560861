import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Button, List } from "semantic-ui-react";
import _ from 'lodash';
import { toast } from "react-toastify";
import FuseCollapsiblePanel from '../../../../components/generic/FuseCollapsiblePanel';
import QuestionaireSectionComponent from './QuestionaireSectionComponent';
import FuseContentModal from '../../../../components/generic/FuseContentModal';
import SectionDetails from './SectionDetails';
import * as SECTION_ACTIONS from '../../../../actions/sectionActions';
import * as CONDITION_ACTIONS from '../../../../actions/conditionActions';

class QuestionaireSectionList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			questionaire: { ...this.props.questionaire }
		};
	}

	static defaultProps = {
		questions: [],
		sections: [],
		section: {}
	}

	hasPermissions(category){
		return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category)
	}

	isAdmin(){
		return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin
	}

	canReadSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.READ || this.isAdmin()
	}

	canCreateSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.CREATE || this.isAdmin()
	}
	
	componentDidUpdate(prevProps, prevState) {
		if ((!_.isEmpty(this.props.section) && _.isEmpty(prevProps.section)) || (!_.isEmpty(this.props.section) && this.props.section.id !== prevProps.section.id)) {
			if(this.props.isPermissionsFetched){
				if (this.canReadSections()) {
					this.props.fetchSectionsAction(this.props.section.questionaireId, this.props.section.tenantId);
				}
				else{
					toast.error('You do not have permission to view sections');
				}
			}
		}
	}

	listOfSections = () => {
		if (this.props.sections != null && this.props.sections.length > 0) {
			return this.props.sections
				.map((section) =>
					<FuseCollapsiblePanel actionObject={section} key={section.id} differentiate={true} trailingIcon='angle down' content={
						<QuestionaireSectionComponent section={section}
							header={`${(this.props.parentHeader === '') ? section.displayName : this.props.parentHeader + ' > ' + section.displayName}`}
						/>}
						header={`${(this.props.parentHeader === '') ? section.displayName : this.props.parentHeader + ' > ' + section.displayName}`}
					>
					</FuseCollapsiblePanel>
				);
		}
	}

	render() {
		const isBaseQnaire = this.props.questionaire.isBaseQnaire;
    	const isRootTenant = this.props.tenant.isRoot === true || false;
		return (
			<List animated>
				{this.listOfSections()}
				<List.Item key='addNew'>
					<Segment vertical textAlign='center' className='clickable'>
					{this.canCreateSections() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
						<FuseContentModal
							header={`Add section to ${this.props.section.displayName}`}
							content={<SectionDetails questionaire={this.props.questionaire} section={{sectionId: this.props.section.id}} sections={this.props.sections}/>}
							trigger={<Button circular icon='add' className='success' />}
							tooltip={`Add section to ${this.props.section.displayName}`}
							onClose={() => {
								console.log("MODEL CLOSED!");
								this.props.clearSelectedCondition();
							}}
						/>)}
					</Segment>
				</List.Item>
			</List>
		);
	}
}

const mapStateToProps = (state, ownprops) => {
	return {
		questionaire: _.isEmpty(state.questionaires) ? {} : Object.values(state.questionaires).filter(questionaire => questionaire.id === ownprops.section.questionaireId)[0],
		sections: _.isEmpty(state.sections) || _.isEmpty(ownprops.section) ? [] : _.orderBy(Object.values(state.sections).filter(section =>  section.status !== 'isDeleted' && section.parentSectionId === ownprops.section.id  &&section.questionaireId === ownprops.section.questionaireId),['sequence']),
		tenant: state.tenantManagement.activeTenant,
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchSectionsAction: (questionaireId, tenantId) => dispatch(SECTION_ACTIONS.fetchSectionsAction(questionaireId, tenantId)),
		clearSelectedCondition: () => dispatch(CONDITION_ACTIONS.conditionClearSelectedAction()),
	})
)(QuestionaireSectionList);