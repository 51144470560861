export class BusinessRuleDTO {
    PK;
    SK;
    id;
    type;
    status;
    bRule;
    brTypeId;
    brTypeName;
    bRuleAttrs;
    createdAt;
    updatedAt;
    brAggregationCount;
    microService;
    targetQnaireId;
    tenantId;
    notificationMsg;
    pptxType;
    
    constructor(data,tenantId) {
      if (data.id != null) {
        this.PK = data.PK;
        this.SK = data.SK;
      } else {
        this.PK = `${tenantId}#BRULES`;
        this.SK = 'BRULE#';
      }
      this.id = data.id;
      this.type = 'BRULE';
      this.status = data.status;
      this.bRule = data.bRule;
      this.brTypeId = data.brTypeId;
      this.brTypeName = data.brTypeName;
      this.bRuleAttrs = data.bRuleAttrs;
      this.brAggregationCount = data.brAggregationCount;
      this.microService = data.microService;
      this.targetQnaireId = data.targetQnaireId;
      this.tenantId = tenantId;
      this.notificationMsg = data.notificationMsg;
      this.pptxType = data?.pptxType;
    }
  }