export class GlobalPricesDTO {
    PK;
    SK;
    createdAt;
    updatedAt;
    type;
    id;
    prices;
    vatNumber;

    constructor(prices){
        if (prices.id != null) {
            this.PK = prices.PK;
            this.SK = prices.SK;
        }
        else {
            this.PK = "GLOBALPRICES";
            this.SK = `GLOBALPRICES`;
        } 
        this.id = prices.id;
        this.type = "GLOBALPRICES";
        this.prices = prices.prices;
    }
}