import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { fetchItemsWS } from '../webservices/fetchWS';
import { createItemWs, updateItemWs} from '../webservices/saveWS';
import {GroupDTO} from './DTOs/GroupDTO';

export const FETCHING_GROUPS_ACTION = 'FETCHING_GROUPS_ACTION';
export const FETCH_GROUPS_SUCCESS_ACTION = 'FETCH_GROUPS_SUCCESS_ACTION';
export const FETCH_GROUPS_ERROR_ACTION = 'FETCH_GROUPS_ERROR_ACTION';

export const GROUP_ADD_ACTION = 'GROUP_ADD_ACTION';
export const GROUP_EDIT_ACTION = 'GROUP_EDIT_ACTION';
export const GROUP_DELETE_ACTION = 'GROUP_DELETE_ACTION';

export const fetchGroupsAction = () => {
  const queryParams = {
    GSI7PK: 'GROUPS',
    GSI7SK: 'GROUP%23',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_GROUPS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_GROUPS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_GROUPS_ERROR_ACTION, err });
      });
  };
}

export const searchGroupsAction = (searchValue) => {
  const queryParams = {
    GSI7PK: 'GROUPS',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_GROUPS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams)
      .then((result) => {
        const returnItems = result.data.Items.filter(item => item.status !== 'isDeleted');
        const searchItems = returnItems.filter(item => item.name.includes(searchValue));
        dispatch({ type: FETCH_GROUPS_SUCCESS_ACTION, payload: searchItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_GROUPS_ERROR_ACTION, err });
      });
  };
}


export const groupSaveAction = (group, isNewItem) => {
  return (dispatch, getState) => {
    const newGroup = new GroupDTO(group);
    const saveObject = newGroup;
    dispatch({ type: SAVE_IN_PROGRESS });
    //create
    if (isNewItem === true){
      createItemWs(saveObject, isNewItem, getState)
      .then((result) => {
        dispatch({type: GROUP_ADD_ACTION, payload: result.data});
        dispatch({type: SAVE_PROCESS_DONE});
        toast.success('The new group has been created successfully');
      })
      .catch((err) => {
        console.log(err);
        dispatch({type: SAVE_PROCESS_DONE});
        toast.error('Failed to create the new group please try again');
      })
    }
    //update
    //BUG - can't toggle active button off and then on again
    else{
      updateItemWs(saveObject, isNewItem, getState)
      .then((result) => {
        dispatch({type: GROUP_EDIT_ACTION, payload: result.data});
        dispatch({type: SAVE_PROCESS_DONE});
        toast.success("The group details has been updated successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({type: SAVE_PROCESS_DONE});
        toast.error('Failed to update the group details please try again');
      })
    }
  }
} 

export const groupDeleteAction = (group) => {
  return (dispatch, getState) => {
    const newGroup = new GroupDTO(group);
    const saveObject = newGroup;
    saveObject.status = 'isDeleted';
    dispatch({ type: SAVE_IN_PROGRESS });
    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: GROUP_DELETE_ACTION, payload: result.data });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success("The group's details has been updated successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed to update the group details please try again");
      });
  };
}; 