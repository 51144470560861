import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Storage } from "aws-amplify";
import { toast } from "react-toastify";
import { Container, Form, Button, Icon, Divider } from "semantic-ui-react";

import * as USER_GROUPS from '../../../actions/userGroupsActions';

export function GroupUserDetails(props) {
    const [state, setState] = useState({
        groupUser: props.groupUser,
        userGroup: props.group
    });

    const isAdmin = () => {
        return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
    };

    const hasPermissions = (category) => {
        return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
    };

    const canReadGroupUsers = () => {
        return hasPermissions('User Groups') && props.userPermissions['User Groups'].actions['VIEW USERS'] || isAdmin();
    };

    const canCreateGroupUsers = () => {
        return hasPermissions('User Groups') && canReadGroupUsers() && props.userPermissions['User Groups'].actions['ADD USER'] || isAdmin();
    };

    const canUploadGroupUsers = () => {
        return hasPermissions('User Groups') && canReadGroupUsers() && props.userPermissions['User Groups'].actions['UPLOAD USERS'] || isAdmin();
    };

    const [selectedFile, setSelectedFile] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmitFileUpload = async () => {
        if (selectedFile && selectedFile.type === 'text/csv' && selectedFile.name.toLowerCase().endsWith('.csv')) {
            const data = {
                tenantId: state.userGroup.tenantId,
                groupId: state.userGroup.id,
                fileName: selectedFile.name
            }
    
            try {
                // Wait for the uploadFileAction to finish
                await props.uploadFileAction(selectedFile, data);
    
                setState((state) => ({ ...state }));
                if (_.isEmpty(props.onComplete)) props.onComplete();
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('Failed to upload the file. Please try again.');
            }
        } else {
            toast.error('Please select a csv file.');
        }
    };
    
    const onSubmitDoownloadFile = async () => {
        try {
            const key = `${process.env.REACT_APP_ENV}/Wakamoso Usergroup Template.csv`

            const signedUrl = await Storage.get(key);
            const response = await fetch(signedUrl);

            if (!response.ok) {
                throw new Error("Failed to fetch the file");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = `Wakamoso Usergroup Template.csv`;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error during file download:', error);
            toast.error('Failed to download the file. Please try again.');
        }
    };
    
    const onSubmitForm = () => {
        // Handle other form fields
        props.saveGroupUserAction(state.groupUser, state.userGroup, props.isNew);
    
        setState((state) => ({ ...state }));
        if (_.isEmpty(props.onComplete)) props.onComplete();
    };
    

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const _buildFileUpload = () => {
        return (
            <Form.Field>
                <label>Upload CSV File With UTF-8 Encoding:</label>
                <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange} 
                />
            </Form.Field>
        );
    };

    const _buildDownloadTemplateButton = () => {
        return (
            <Button
                type="button"
                className="primary"
                onClick={onSubmitDoownloadFile}
            >
                <Icon name="download" /> Download CSV Template 
            </Button>
        );
    };

    const _buildFileUploadButton = () => {
        return (
            <Button
                type="button"
                className="success"
                onClick={onSubmitFileUpload}
            >
                <Icon name="upload" /> Upload CSV File
            </Button>
        );
    };
    
    const _buildFormSubmitButton = () => {
        return (

            <Button.Group floated="right">
                <Button
                    className="danger"
                    onClick={() => {
                        if (_.isEmpty(props.onComplete)) {
                            props.onComplete();
                        }
                    }}
                >
                    <Icon name="remove" /> Cancel
                </Button>
                <Button type="submit" className="success">
                    <Icon name="checkmark" /> Ok
                </Button>
            </Button.Group>
        );
    };    

    const _buildName = () => {
        return (
            <Form.Field className="full-width-field">
                <label>Name: </label>
                <input
                    name="name"
                    className={errors.name ? "invalid-input-field" : ""}
                    {...register("name", { required: true, maxLength: 128 })}
                    placeholder="Name"
                    value={state.groupUser.name}
                    onChange={(e) => {
                        setState((prevState) => ({
                            ...prevState,
                            groupUser: { ...prevState.groupUser, name: e.target.value },
                        }));
                    }}
                />
                {errors.name && errors.name.type === "required" && (
                    <p className="validation-error">* Please enter a name</p>
                )}
                {errors.name && errors.name.type === "maxLength" && (
                    <p className="validation-error">
                        * Name may not be more than 128 characters long
                    </p>
                )}
            </Form.Field>
        );
    };

    const _buildLastname = () => {
        return (
            <Form.Field className="full-width-field">
                <label>Lastname: </label>
                <input
                    name="lastname"
                    className={errors.lastname ? "invalid-input-field" : ""}
                    {...register("lastname", { required: true, maxLength: 128 })}
                    placeholder="Lastname"
                    value={state.groupUser.surname}
                    onChange={(e) => {
                        setState((prevState) => ({
                            ...prevState,
                            groupUser: { ...prevState.groupUser, surname: e.target.value },
                        }));
                    }}
                />
                {errors.lastname && errors.lastname.type === "required" && (
                    <p className="validation-error">* Please enter a lastname</p>
                )}
                {errors.lastname && errors.lastname.type === "maxLength" && (
                    <p className="validation-error">
                        * Lastname may not be more than 128 characters long
                    </p>
                )}
            </Form.Field>
        );
    };

    const _buildEmail = () => {
        return (
            <Form.Field>
                <label>Email Address:</label>
                <input
                    name="email"
                    className={errors.email ? "invalid-input-field" : ""}
                    {...register("email", { maxLength: 128 })}
                    placeholder="Email"
                    value={state.groupUser.email}
                    onChange={(e) => {
                        setState((prevState) => ({
                            ...prevState,
                            groupUser: { ...prevState.groupUser, email: e.target.value },
                        }));
                    }}
                />
                {errors.email && errors.email.type === "maxLength" && (
                    <p className="validation-error">
                        * Email may not be more than 128 characters long
                    </p>
                )}
            </Form.Field>
        );
    };

    const _buildNumber = () => {
        const phoneNumberRegex = /^\+\d{11}$/; // Regex for the format +27787792345
    
        const validatePhoneNumber = (inputValue) => {
            return phoneNumberRegex.test(inputValue);
        };
    
        return (
            <Form.Field>
                <label>Phone Number:</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '5px' }} >+</div>
                    <input
                        name="number"
                        className={errors.number ? "invalid-input-field" : ""}
                        {...register("number", { required: true,})}
                        placeholder="Enter phone number with a country code: (+27787792345)"
                        value={state.groupUser.contact_nr || ''}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            const formattedNumber = inputValue.replace(/^\+/, '');

                                setState((prevState) => ({
                                    ...prevState,
                                    groupUser: { ...prevState.groupUser, contact_nr: formattedNumber },
                                }));
                        }}
                    />
                </div>
                {errors.number && (
                    <p className="validation-error">
                        * Please enter a valid phone number in the format +27787792345
                    </p>
                )}
            </Form.Field>
        );
    };

      return (
        <Container>
            {canUploadGroupUsers() && (
            <Form
                  size="small"
                  onSubmit={handleSubmit(onSubmitForm)}
                  style={{ paddingBottom: "20px" }}
              >
                  {_buildFileUpload()}
                  <Divider />
                  {_buildDownloadTemplateButton()}
                  {_buildFileUploadButton()}
              </Form>)}
              {canCreateGroupUsers() && (
              <Form
                  size="small"
                  onSubmit={handleSubmit(onSubmitForm)}
                  style={{ paddingBottom: "20px" }}
              >
                  {_buildName()}
                  <br></br>
                  {_buildLastname()}
                  <br></br>
                  {_buildEmail()}
                  <br></br>
                  {_buildNumber()}
                  <Divider />
                  {_buildFormSubmitButton()}
              </Form>)}
        </Container>
      );
}

const mapStateToProps = (state, ownProps) => {
    let groupUser = {};
    let isNew = false;
    let newUser = {
        name: '',
        lastname: '',
        email: '',
        number: 0,
    };

    if (ownProps.bdaction === 'add') {
        groupUser = newUser;
        isNew = true;
    } else {
        groupUser = _.isEmpty(ownProps.user)
            ? newUser
            : ownProps.user;
        isNew = false;
    }

    return{
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        tenant: state.tenantManagement.activeTenant,
        isNew: isNew,
        groupUser: groupUser,
    }
}

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch) => ({
        saveGroupUserAction: (user, group, isNew) => dispatch(USER_GROUPS.saveGroupUserAction(user, group, isNew)),
        uploadFileAction: (selectedFile, data) => dispatch(USER_GROUPS.uploadFileAction(selectedFile, data)),
    })
)(GroupUserDetails)