import {
    FETCH_TENANT_FINANCES_SUCCESS_ACTION,
    FETCH_TENANT_TRANSACTIONS_SUCCESS_ACTION,
    FETCH_CURRENCY_SUCCESS_ACTION
} from '../actions/financesActions';

export const TenantBalancesReducer =  (state = [], action) => {
    switch(action.type){
        case FETCH_TENANT_FINANCES_SUCCESS_ACTION:
            const Finances = action.payload;
            return Finances;
        default:
            return state;
    }
}

export const TenantTransactionsReducer =  (state = [], action) => {
    switch(action.type){
        case FETCH_TENANT_TRANSACTIONS_SUCCESS_ACTION:
            const transactions = action.payload;
            return transactions;
        default:
            return state;
    }
}

export const CurrenciesReducer =  (state = [], action) => {
    switch(action.type){
        case FETCH_CURRENCY_SUCCESS_ACTION:
            const currencies = action.payload;
            return currencies;
        default:
            return state;
    }
}