import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Grid, Button, Container, Confirm, Dimmer, Loader, Header, Icon, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import _ from 'lodash';
import * as KML_ACTIONS from '../../../actions/kmlUploadActions';
import KmlDetails from './KmlDetails';

export function KmlUploadPage(props) {
  const [state, setState] = useState({
    selectedKml: {},
    isEditKmlOpen: false,
    deleteKmlCandidate: {},
    showConfirmDeleteKml: false,
  });

  const hasPermissions = (category) => {
    return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
  }

  const isAdmin = () => {
    return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
  }

  const canReadKmlFile = () => {
    return hasPermissions('KML Uploads') && props.userPermissions['KML Uploads'].actions.READ || isAdmin()
  }

  const canCreateKmlFile = () => {
    return hasPermissions('KML Uploads') && canReadKmlFile() && props.userPermissions['KML Uploads'].actions.CREATE || isAdmin()
  }

  const canUpdateKmlFile = () => {
    return hasPermissions('KML Uploads') && canReadKmlFile() && props.userPermissions['KML Uploads'].actions.UPDATE || isAdmin()
  }

  const canDeleteKmlFile = () => {
    return hasPermissions('KML Uploads') && canReadKmlFile() && props.userPermissions['KML Uploads'].actions.DELETE || isAdmin()
  }

  useEffect(() => {
    if (!_.isEmpty(props.tenant)) {
      if (props.isPermissionsFetched) {
        if (canReadKmlFile()) {
          props.fetchKmlFilesAction(props.tenant);
        }
        else {
          toast.error('You do not have permission to view uploaded Kml Files');
        }
      }
    }
  }, [props.tenant]);

  const onKmlSelectionChanged = (rowId) => {
    const selectedKml = props.kmlFiles.find((kml) => kml.id === rowId);
    setState((state) => ({
      ...state,
      selectedKml: selectedKml || {},
    }));
  };

  const confirmDeleteKml = () => {
    return (
      <Confirm
        content={`Are you sure you want to delete the selected KML file: ${state.deleteKmlCandidate.fileName}?`}
        open={state.showConfirmDeleteKml}
        onCancel={() => setState((state) => ({ ...state, showConfirmDeleteKml: false }))}
        onConfirm={() => {
          props.deleteKmlFileAction(state.deleteKmlCandidate, props.tenant);
          setState((state) => ({ ...state, showConfirmDeleteKml: false, deleteKmlCandidate: {} }));
        }}
      />
    );
  };

  const kmlFilesRender = () => {
    if (!props.fetchingData) {
      if (!props.kmlFiles || props.kmlFiles.length === 0) {
        return (
          <Segment>
            <Header icon>
              <Icon name='file' color="black" />
              No KML Files Uploaded
            </Header>
          </Segment>
        );
      }

      // Sort kmlFiles by name before rendering
      const sortedKmlFiles = [...props.kmlFiles].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      return (
        <Table compact selectable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg'>Name</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg'>Description</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg'>File Name</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedKmlFiles.map((kml, index) => (
              <Table.Row
                key={`${kml.id}.${index}`}
                active={kml.id === state.selectedKml.id}
                onClick={() => onKmlSelectionChanged(kml.id)}
              >
                <Table.Cell>{kml.name}</Table.Cell>
                <Table.Cell>{kml.description}</Table.Cell>
                <Table.Cell>{kml.fileName}</Table.Cell>
                <Table.Cell>
                  {canUpdateKmlFile() && (
                    <FuseContentModal
                      header={`Edit KML File: ${kml.fileName}`}
                      content={<KmlDetails kml={kml} bdaction={'edit'} />}
                      trigger={<Button compact className="warning" icon='edit' size='mini' />}
                      onOpen={() => {
                        setState((state) => ({ ...state, isEditKmlOpen: true, selectedKml: kml }));
                      }}
                      onClose={() => {
                        setState((state) => ({ ...state, isEditKmlOpen: false }));
                      }}
                    />
                  )}
                  {canDeleteKmlFile() && (
                    <Button compact className='danger' icon='trash' size='mini' onClick={(e) => deleteKmlFile(e, kml)} />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <div style={{ height: '200px', margin: '10px 0px' }}>
          <Dimmer inverted active>
            <Loader content='Loading' />
          </Dimmer>
        </div>
      );
    }
  };


  const deleteKmlFile = (e, kml) => {
    e.stopPropagation();
    setState((state) => ({ ...state, deleteKmlCandidate: kml, showConfirmDeleteKml: true }));
  };

  return (
    <Container fluid>
      {confirmDeleteKml()}
      <Grid>
        <Grid.Row>
          <Grid.Column width='8'>
            <h3>Uploaded Kml Files:</h3>
          </Grid.Column>
          <Grid.Column width='8'>
            {canCreateKmlFile() && (
              <FuseContentModal
                header={`Upload Kml File`}
                content={<KmlDetails kml={state.selectedKml} bdaction={'add'} />}
                trigger={<Button compact className="success" icon='add' size='mini' floated='right' />}
                onClose={() => {
                  setState((state) => ({ ...state, kml: state.kml, kmlFiles: props.kmlFiles }));
                }}
              />)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {kmlFilesRender()}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  fetchingData: state.fetchingData === true,
  tenant: state.tenantManagement.activeTenant,
  kmlFiles: state.kmlFiles,
  userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
  isPermissionsFetched: state.isPermissionsFetched === true,
});

export default connect(
  mapStateToProps,
  (dispatch) => ({
    fetchKmlFilesAction: (tenant) => dispatch(KML_ACTIONS.fetchKmlFilesAction(tenant)),
    deleteKmlFileAction: (file, tenant) => dispatch(KML_ACTIONS.deleteKmlFileAction(file, tenant)),
  })
)(KmlUploadPage);
