import {
    FETCH_BRQNAIRES_SUCCESS_ACTION,
    BRQNAIRE_ADD_ACTION,
    BRQNAIRE_EDIT_ACTION,
    BRQNAIRE_DELETE_ACTION
} from '../actions/brQnaireActions';

export const brQnaireReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_BRQNAIRES_SUCCESS_ACTION:
            const newQnaireBR= action.payload;
            const oldQnaireBRs = Object.values({...state}).filter(s => !newQnaireBR.some(newS => newS.id === s.id));
            const newQnaireBrState = oldQnaireBRs.concat(newQnaireBR); //Creates a copy...a new state
            return newQnaireBrState;
        case BRQNAIRE_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case BRQNAIRE_EDIT_ACTION:
            const newQnaireBR1 = action.payload;
            const qnaireBRsCopy = Object.values({...state}).filter(s => !(newQnaireBR1.id === s.id)); //Creates a copy...a new state
            qnaireBRsCopy.push(newQnaireBR1);
            return qnaireBRsCopy;
        case BRQNAIRE_DELETE_ACTION:
            return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
};