import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import { Checkbox, Container, Dropdown, Grid, GridColumn, Segment, Form, FormButton, FormCheckbox, CardGroup, CardMeta, CardHeader, CardContent, Card, Divider } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from "react-hook-form";

import * as KML_ACTIONS from '../../../actions/kmlUploadActions';
import * as CV_SEARCH_ACTIONS from "../../../actions/cvSearchActions";

function CVSearchPage(props) {
    const qualificationOptions = [
        { key: uuidv4(), text: "NQF 1 / Grade 9", value: "NQF 1 / Grade 9" },
        { key: uuidv4(), text: "NQF 2 / Grade 10", value: "NQF 2 / Grade 10" },
        { key: uuidv4(), text: "NQF 3 / Grade 11", value: "NQF 3 / Grade 11" },
        { key: uuidv4(), text: "NQF 4 / Grade 12", value: "NQF 4 / Grade 12" },
        { key: uuidv4(), text: "NQF 5 / Higher Certificate", value: "NQF 5 / Higher Certificate" },
        { key: uuidv4(), text: "NQF 6 / Advanaced Certificate or National Diploma", value: "NQF 6 / Advanaced Certificate or National Diploma" },
        { key: uuidv4(), text: "NQF 7 / Advanced Diploma or Bachelor's Degree", value: "NQF 7 / Advanced Diploma or Bachelor's Degree" },
        { key: uuidv4(), text: "NQF 8 / Postgraduate Certificate or Honour's Degree", value: "NQF 8 / Postgraduate Certificate or Honour's Degree" },
        { key: uuidv4(), text: "NQF 9 / Master's Degree", value: "NQF 9 / Master's Degree" },
        { key: uuidv4(), text: "NQF 10 / Doctoral Degree", value: "NQF 10 / Doctoral Degree" }
    ]

    const languages = [
        { key: uuidv4(), text: "Zulu", value: "Zulu" },
        { key: uuidv4(), text: "Afrikaans", value: "Afrikaans" },
        { key: uuidv4(), text: "English", value: "English" },
        { key: uuidv4(), text: "Southern Sotho", value: "Southern Sotho" },
        { key: uuidv4(), text: "Swati", value: "Swati" },
        { key: uuidv4(), text: "Tswana", value: "Twana" },
        { key: uuidv4(), text: "Northern Sotho", value: "Northern Sotho" },
        { key: uuidv4(), text: "Ndebele", value: "Ndebele" },
        { key: uuidv4(), text: "Xhosa", value: "Xhosa" },
        { key: uuidv4(), text: "Tsonga", value: "Tsonga" },
        { key: uuidv4(), text: "SA Sign Language", value: "SA Sign Language" }
    ]

    const [termsFormFields, setTermsFormField] = useState([{ value: '', operator: '' }])
    const [locationRadiusFields, setLocationRadiusField] = useState([{ location: '', radius: '' }])
    const [otherFields, setOtherFields] = useState({
        minSalary: '',
        maxSalary: '',
        driversLicense: false
    })

    const [qualificationSelectedOptions, setQualificationsSelectedOptions] = useState([])
    const [languagesSelectedOptions, setLanguagesSelectedOptions] = useState([])
    const [ownsVehicle, setOwnsVehicle] = useState(false)
    const [formSubmited, setFormSubmitted] = useState(false)

    const handleVehicleChange = (event) => {
        setOwnsVehicle(event.target.checked)
    }

    useEffect(() => {
        if(! _.isEmpty(props.tenant)){
            props.fetchKmlFilesAction(props.tenant);
        }
    }, [props.tenant]);

    const handleInputChange = (index, event, fieldType, fieldName) => {
        if (fieldType === "terms") {
            const values = [...termsFormFields]
            values[index].value = event.target.value
            setTermsFormField(values)
        }
        else if (fieldType === "locationRadius") {
            const values = [...locationRadiusFields]
            if (fieldName === "location")
                values[index].location = event.target.value
            else
                values[index].radius = event.target.value
            setLocationRadiusField(values)
        }
        else if (fieldType === "minSalary") {
            const values = { ...otherFields }
            values.minSalary = event.target.value
            setOtherFields(values)
        }
        else if (fieldType === "maxSalary") {
            const values = { ...otherFields }
            values.maxSalary = event.target.value
            setOtherFields(values)
        }
        else if (fieldType === "driversLicense") {
            const values = { ...otherFields }
            if (event.target.checked)
                values.driversLicense = true
            else
                values.driversLicense = false

            setOtherFields(values)
        }
    }

    const handleOperatorChange = (event, index, operator) => {
        event.preventDefault()
        const values = [...termsFormFields]
        values[index].operator = operator
        setTermsFormField(values)

    }

    const handleAddField = (e, fieldName) => {
        e.preventDefault();
        e.stopPropagation();
        if (fieldName === "terms")
            setTermsFormField([...termsFormFields, { value: '', operator: 'AND' }])
        else if (fieldName === "locationRadius")
            setLocationRadiusField([...locationRadiusFields, { location: '', radius: '' }])
    }

    const handleRemoveField = (e, index, fieldName) => {
        e.preventDefault();
        e.stopPropagation();
        if (fieldName === "terms") {
            if (termsFormFields.length > 1) {
                const updatedTermsFormFields = [...termsFormFields];
                updatedTermsFormFields.splice(index, 1);
                setTermsFormField(updatedTermsFormFields);
            }
        } else if (fieldName === "locationRadius") {
            if (locationRadiusFields.length > 1) {
                const updatedLocationRadiusFields = [...locationRadiusFields];
                updatedLocationRadiusFields.splice(index, 1);
                setLocationRadiusField(updatedLocationRadiusFields);
            }
        }
    };

    const handleQualificationsSelectChange = (e, { value }) => {
        setQualificationsSelectedOptions(value)
    }

    const handleLanguagesSelectedOptions = (e, { value }) => {
        setLanguagesSelectedOptions(value)
    }

    const { handleSubmit } = useForm({
        mode: "all"
    })

    const onSubmit = async (data) => {
        let payload = {}

        //some validation checks to see what data was entered in the form
        if (qualificationSelectedOptions.length !== 0)
            payload["qualifications"] = qualificationSelectedOptions

        if (languagesSelectedOptions.length !== 0)
            payload["languages"] = languagesSelectedOptions

        if (termsFormFields[0]['value'] !== '')
            payload["terms"] = termsFormFields

        if (otherFields["minSalary"] !== '')
            payload["minSalary"] = otherFields["minSalary"]

        if (otherFields['maxSalary'] !== '')
            payload["maxSalary"] = otherFields["maxSalary"]

        payload["driversLicense"] = otherFields["driversLicense"] ? "hasDriversLicense" : "hasNoDriversLicense" //This will need to be updated based on whatever values are stored in DB to indicate no drivers license
        payload["ownsVehicle"] = ownsVehicle ? "hasVehicle" : "hasNoVehicle" //Same as above, needs to be updated

        // if(locationRadiusFields[0]['location'] !== '' && locationRadiusFields[0]['radius'] !== '')
        if (locationRadiusFields[0]['location'] !== '')
            payload["locationRadius"] = locationRadiusFields

        await props.cvSearchAction(payload)
        setFormSubmitted(true)
    }

    return (
        <Container>
            <Segment raised>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h1>CV Search</h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>

                            <Grid.Column width={10}>
                                <Form.Field style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                    <label>TERMS TO BE INCLUDED:</label>
                                    {termsFormFields.map((field, index) => (
                                        <div key={index + 10000} style={{ display: 'flex', alignItems: 'center' }}>
                                            {index !== 0 && (
                                                <button
                                                    key={index + 20000}
                                                    onClick={(event) => handleOperatorChange(event, index, field.operator === 'AND' ? 'OR' : 'AND')}
                                                    style={{ width: '10%', height: '2.6em', marginRight: '0.2em', color: 'white', backgroundColor: '#3A95A4', border: 'none', borderRadius: '0.2em', alignSelf: 'flex-start' }}
                                                >
                                                    {field.operator}
                                                </button>
                                            )}

                                            <input style={{ flex: 1, width: '70%', marginBottom: '0.3em' }}
                                                value={field.value}
                                                onChange={event => handleInputChange(index, event, "terms", "")}
                                            />

                                            {index === 0 && (
                                                <button style={{ width: '10%', height: '2.6em', marginLeft: '0.2em', color: 'white', backgroundColor: '#3A95A4', border: 'none', borderRadius: '0.2em', alignSelf: 'flex-start' }}
                                                    onClick={(e) => handleAddField(e, "terms")}
                                                >
                                                    +
                                                </button>
                                            )}

                                            {termsFormFields.length > 1 && index !== 0 && (
                                                <button
                                                    style={{ width: '10%', height: '2.6em', marginLeft: '0.2em', color: 'white', backgroundColor: '#FF0000', border: 'none', borderRadius: '0.2em', alignSelf: 'flex-start' }}
                                                    onClick={(e) => handleRemoveField(e, index, "terms")}
                                                >
                                                    -
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Form.Field>
                                    <label>QUALIFICATIONS:</label>
                                    <Dropdown
                                        fluid multiple selection options={qualificationOptions} name="qualifications"
                                        onChange={handleQualificationsSelectChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>LANGUAGES:</label>
                                    <Dropdown
                                        fluid multiple selection options={languages} name="languages"
                                        onChange={handleLanguagesSelectedOptions}
                                    />
                                </Form.Field>
                            </Grid.Column>

                            {/* <Grid.Column width={4}>
                            </Grid.Column> */}
                        </Grid.Row>

                        <Grid.Row>
                            {/* <Grid.Column width={2}>
                                <Form.Field>
                                    <label>
                                        MIN SALARY: 
                                    </label>
                                    <input
                                        onChange={event => handleInputChange("", event,"minSalary", "")}
                                        value={otherFields.minSalary}
                                        pattern="^[0-9]*"
                                        title="Please enter numeric data only."
                                    />
                                </Form.Field>
                            </Grid.Column>

                            <Grid.Column width={2}>
                                <Form.Field>
                                    <label>
                                        MAX SALARY:
                                    </label>
                                    <input
                                        onChange={event => handleInputChange("", event,"maxSalary", "")}
                                        value={otherFields.maxSalary}

                                        pattern="^[0-9]*"
                                        title="Please enter numeric data only."
                                    />
                                </Form.Field>
                            </Grid.Column> */}

                            <GridColumn width={10}>
                                <Form.Field>
                                    <label>
                                        <span style={{ marginRight: '50%' }}>LOCATION:</span>
                                        <span>RADIUS(km):</span>
                                    </label>

                                    {locationRadiusFields.map((field, index) => (
                                        <div key={index + 30000}>
                                            {/* <select
                                                style={{ width: '20%', marginRight: '0.5em', marginBottom: '0.3em' }}
                                                value={field.selectedKML}
                                                onChange={event => handleInputChange(index, event, "locationRadius", "location")}
                                            >
                                                <option value="">Select KML File</option>
                                                {props.kmlFiles.map((file, id) => (
                                                    <option key={id} value={file.fileName}>{file.fileName}</option>
                                                ))}
                                            </select> */}

                                            <input
                                                style={{ width: '60%', marginRight: '0.5em', marginBottom: '0.3em' }}
                                                value={field.value}
                                                onChange={event => handleInputChange(index, event, "locationRadius", "location")}
                                            />

                                            <input style={{ width: '20%' }}
                                                value={field.value}
                                                onChange={event => handleInputChange(index, event, "locationRadius", "radius")}

                                                pattern="^[0-9]*"
                                                title="Please enter numeric data only."

                                            />

                                            {index === 0 &&
                                                <button
                                                    style={{ width: '8%', height: '2.6em', marginLeft: '0.3em', color: 'white', backgroundColor: '#3A95A4', border: 'none', borderRadius: '0.2em' }}
                                                    onClick={(e) => handleAddField(e, "locationRadius")}
                                                >
                                                    +
                                                </button>
                                            }

                                            {locationRadiusFields.length > 1 && index !== 0 && (
                                                <button
                                                    style={{ width: '8%', height: '2.6em', marginLeft: '0.2em', color: 'white', backgroundColor: '#FF0000', border: 'none', borderRadius: '0.2em', alignSelf: 'flex-start' }}
                                                    onClick={(e) => handleRemoveField(e, index, "locationRadius")}
                                                >
                                                    -
                                                </button>
                                            )}
                                        </div>
                                    ))}

                                </Form.Field>
                            </GridColumn>

                            <GridColumn width={6}>
                                <Form.Field>
                                    <label>
                                        DRIVER'S LICENSE:
                                        <input type="checkbox" style={{ margin: '0 0.3em' }} checked={otherFields.driversLicense}
                                            onChange={event => handleInputChange("", event, "driversLicense", "")}

                                        />
                                    </label>
                                </Form.Field>

                                <Form.Field>
                                    <label>
                                        OWNS THEIR VEHICLE:
                                        <input type="checkbox" style={{ margin: '0 0.3em' }} checked={ownsVehicle}
                                            onChange={event => handleVehicleChange(event)}
                                        />
                                    </label>
                                </Form.Field>
                            </GridColumn>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row>
                            <GridColumn width={16}>
                                <FormButton style={{ width: '25%', backgroundColor: '#3A95A4', color: 'white', float: 'right' }} type="submit" >SEARCH</FormButton>
                            </GridColumn>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Segment>

            {formSubmited &&
                <Segment>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h1>
                                    CV Matches:
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {'dataOne' in props.cv && props.cv["dataOne"].length !== 0 &&
                                props.cv["dataOne"].map((item, index) => (
                                    <Grid.Column width={5} key={index + 40000}>
                                        <Card style={{ marginBottom: "1.0em", marginLeft: "3.5em" }}>
                                            <Card.Content>
                                                <div style={{ float: "right" }}>
                                                    <input type="checkbox" /> {/* TODO: Need to add the checked and onChange things here*/}
                                                </div>
                                                <Card.Header style={{ color: "#3A95A4" }}>
                                                    <CircleWithNumber number={index + 1} />
                                                </Card.Header>
                                                {"aggregateValues" in item._source && item._source.aggregateValues !== "" && item._source.aggregateValues !== null && item._source.aggregateValues !== undefined &&
                                                    item._source.aggregateValues.split(', ').map((value, i) => (
                                                        <div key={i + 50000}>
                                                            {i == 0 ? (
                                                                <Card.Header>{value}</Card.Header>
                                                            ) : (
                                                                <Card.Meta>{value}</Card.Meta>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {item._source.values !== "" &&
                                                    item._source.values !== "" &&
                                                    item._source.values.split(', ').map((value, i) => (
                                                        <div key={i + 60000}>
                                                            {
                                                                i == 0 ? (
                                                                    <Card.Header>{value}</Card.Header>
                                                                ) : (
                                                                    <Card.Meta>{value}</Card.Meta>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                ))
                            }

                            {'dataTwo' in props.cv && props.cv["dataTwo"].length !== 0 &&
                                props.cv["dataTwo"].map((item, index) => (
                                    <Grid.Column width={5} key={index + 70000}>
                                        <Card style={{ marginBottom: "1.0em", marginLeft: "3.5em" }}>
                                            <Card.Content>
                                                <div style={{ float: "right" }}>
                                                    <input type="checkbox" /> {/* TODO: Need to add the checked and onChange things here*/}
                                                </div>
                                                <Card.Header style={{ color: "#3A95A4" }}>
                                                    <CircleWithNumber number={index + 1 + props.cv["dataOne"].length} />
                                                </Card.Header>
                                                {"aggregateValues" in item._source && item._source.aggregateValues !== "" && item._source.aggregateValues !== null && item._source.aggregateValues !== undefined &&
                                                    item._source.aggregateValues.split(', ').map((value, i) => (
                                                        <div key={i + 80000}>
                                                            {i == 0 ? (
                                                                <Card.Header>{value}</Card.Header>
                                                            ) : (
                                                                <Card.Meta>{value}</Card.Meta>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {item._source.values !== "" &&
                                                    item._source.values !== "" &&
                                                    item._source.values.split(', ').map((value, i) => (
                                                        <div key={i + 90000}>
                                                            {
                                                                i == 0 ? (
                                                                    <Card.Header>{value}</Card.Header>
                                                                ) : (
                                                                    <Card.Meta>{value}</Card.Meta>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                ))
                            }

                            {'dataThree' in props.cv && props.cv["dataThree"].length !== 0 &&
                                props.cv["dataThree"].map((item, index) => (
                                    <Grid.Column width={5} key={index + 100000}>
                                        <Card style={{ marginBottom: "1.0em", marginLeft: "3.5em" }}>
                                            <Card.Content>
                                                <div style={{ float: "right" }}>
                                                    <input type="checkbox" /> {/* TODO: Need to add the checked and onChange things here*/}
                                                </div>
                                                <Card.Header style={{ color: "#3A95A4" }}>
                                                    <CircleWithNumber number={index + 1 + props.cv["dataOne"].length + props.cv["dataTwo"].length} />
                                                </Card.Header>
                                                {"aggregateValues" in item._source && item._source.aggregateValues !== "" && item._source.aggregateValues !== null && item._source.aggregateValues !== undefined &&
                                                    item._source.aggregateValues.split(', ').map((value, i) => (
                                                        <div key={i + 110000}>
                                                            {i == 0 ? (
                                                                <Card.Header>{value}</Card.Header>
                                                            ) : (
                                                                <Card.Meta>{value}</Card.Meta>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {item._source.values !== "" &&
                                                    item._source.values !== "" &&
                                                    item._source.values.split(', ').map((value, i) => (
                                                        <div key={i + 120000}>
                                                            {
                                                                i == 0 ? (
                                                                    <Card.Header>{value}</Card.Header>
                                                                ) : (
                                                                    <Card.Meta>{value}</Card.Meta>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                ))
                            }

                            {'dataFour' in props.cv && props.cv["dataFour"].length !== 0 &&
                                props.cv["dataFour"].map((item, index) => (
                                    <Grid.Column width={5} key={index + 130000}>
                                        <Card style={{ marginBottom: "1.0em", marginLeft: "3.5em" }}>
                                            <Card.Content>
                                                <div style={{ float: "right" }}>
                                                    <input type="checkbox" /> {/* TODO: Need to add the checked and onChange things here*/}
                                                </div>
                                                <Card.Header style={{ color: "#3A95A4" }}>
                                                    <CircleWithNumber number={index + 1 + props.cv["dataOne"].length + props.cv["dataTwo"].length + props.cv["dataThree"].length} />
                                                </Card.Header>
                                                {"aggregateValues" in item._source && item._source.aggregateValues !== "" && item._source.aggregateValues !== null && item._source.aggregateValues !== undefined &&
                                                    item._source.aggregateValues.split(', ').map((value, i) => (
                                                        <div key={i + 140000}>
                                                            {i == 0 ? (
                                                                <Card.Header>{value}</Card.Header>
                                                            ) : (
                                                                <Card.Meta>{value}</Card.Meta>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {item._source.values !== "" &&
                                                    item._source.values !== "" &&
                                                    item._source.values.split(', ').map((value, i) => (
                                                        <div key={i + 150000}>
                                                            {
                                                                i == 0 ? (
                                                                    <Card.Header>{value}</Card.Header>
                                                                ) : (
                                                                    <Card.Meta>{value}</Card.Meta>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                ))
                            }

                            {'dataFive' in props.cv && props.cv["dataFive"].length !== 0 &&
                                props.cv["dataFive"].map((item, index) => (
                                    <Grid.Column width={5} key={index + 160000}>
                                        <Card style={{ marginBottom: "1.0em", marginLeft: "3.5em" }}>
                                            <Card.Content>
                                                <div style={{ float: "right" }}>
                                                    <input type="checkbox" /> {/* TODO: Need to add the checked and onChange things here*/}
                                                </div>
                                                <Card.Header style={{ color: "#3A95A4" }}>
                                                    <CircleWithNumber number={index + 1 + props.cv["dataOne"].length + props.cv["dataTwo"].length + props.cv["dataThree"].length + props.cv["dataFive"].length} />
                                                </Card.Header>
                                                {"aggregateValues" in item._source && item._source.aggregateValues !== "" && item._source.aggregateValues !== null && item._source.aggregateValues !== undefined &&
                                                    item._source.aggregateValues.split(', ').map((value, i) => (
                                                        <div key={i + 170000}>
                                                            {i == 0 ? (
                                                                <Card.Header>{value}</Card.Header>
                                                            ) : (
                                                                <Card.Meta>{value}</Card.Meta>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {item._source.values !== "" &&
                                                    item._source.values !== "" &&
                                                    item._source.values.split(', ').map((value, i) => (
                                                        <div key={i + 180000}>
                                                            {
                                                                i == 0 ? (
                                                                    <Card.Header>{value}</Card.Header>
                                                                ) : (
                                                                    <Card.Meta>{value}</Card.Meta>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                ))
                            }
                        </Grid.Row>
                    </Grid>
                </Segment>
            }

        </Container>
    )
}

const CircleWithNumber = ({ number }) => {
    const circleStyle = {
        width: '1.2em',
        height: '1.2em',
        borderRadius: '50%',
        backgroundColor: '#3A95A4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
    };

    return (
        <div style={circleStyle}>
            {number}
        </div>
    );
};

const mapStateToProps = (state) => ({
    cv: state.cvSearch,
    tenant: state.tenantManagement.activeTenant,
    kmlFiles: state.kmlFiles,  
})

export default connect(
    mapStateToProps,
    (dispatch) => ({
        cvSearchAction: (data) => dispatch(CV_SEARCH_ACTIONS.cvSearchAction(data)),
        fetchKmlFilesAction: (tenant) => dispatch(KML_ACTIONS.fetchKmlFilesAction(tenant)),
    })
)(CVSearchPage);