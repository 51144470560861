import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Table, Button, Container, Grid, Confirm, Segment, Header, Icon, Label } from 'semantic-ui-react';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import ManageTenantsDetails from './ManageTenantsDetails';
import ReviewQuestionnaires from './ReviewQuestionnairesPage';

import * as REVIEW_QNAIRE_ACTIONS from "../../../actions/reviewQuestionaireActions";
import * as REGISTRATION_ACTIONS from '../../../actions/registrationActions';
import * as TENANT_ACTIONS from '../../../actions/tenantActions';
import { toast } from 'react-toastify';


export function ManageTenantsPage(props){

    const [state, setState] = useState({
        selectedTenant: {},
        enableTenantCandidate: {},
        disableTenantCandidate: {},
        sortBy: null,
        sortDirection: 'ascending'
    });

    const hasPermissions = (category) => {
      return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
    };
  
    const isAdmin = () => {
      return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
    };

    const canReadManageTenants = () => {
      return hasPermissions('Manage Tenants') && props.userPermissions['Manage Tenants'].actions.READ || isAdmin();
    };

    const canUpdateManageTenants = () => {
      return hasPermissions('Manage Tenants') && canReadManageTenants() && props.userPermissions['Manage Tenants'].actions.UPDATE || isAdmin();
    };

    const canReviewTenantQnaires = () => {
      return hasPermissions('Manage Tenants') && canReadManageTenants() && props.userPermissions['Manage Tenants'].actions['VALIDATE QUESTIONNAIRES'] || isAdmin();
    };

    useEffect(() => {
      if(!_.isEmpty(props.tenant)){
        if(props.isPermissionsFetched){
          if (canReadManageTenants()) {
            props.fetchAllTenantsAction();
            props.fetchAllQnaires();          
          }
          else{
            toast.error('You do not have permission to view roles');
          }
        }
      }
    }, [props.tenant])

    useEffect(() => {
    
    }, [props.tenant]);

    const handleSort = (column) => {
      setState((prevState) => ({
        ...prevState,
        sortBy: column,
        sortDirection: prevState.sortBy === column && prevState.sortDirection === 'ascending' ? 'descending' : 'ascending'
      }));
    };

    const { sortBy, sortDirection } = state;
    
    const tenantsRender = () => {
      const activeTenant = props.tenant;
      let sortedTenants = [...props.tenants];
      
      if (state.sortBy === 'name') {
        sortedTenants.sort((a, b) => {
          const aValue = a.name.toLowerCase();
          const bValue = b.name.toLowerCase();
          return state.sortDirection === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });
      } else if (state.sortBy === 'synopsis') {
        sortedTenants.sort((a, b) => {
          const aValue = (a.description || '').toLowerCase();
          const bValue = (b.description || '').toLowerCase();
          return state.sortDirection === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });
      } else if (state.sortBy === 'status') {
        sortedTenants.sort((a, b) => {
          const aValue = a.status || '';
          const bValue = b.status || '';
          return state.sortDirection === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });
      } else if (state.sortBy === 'review') {
        sortedTenants.sort((a, b) => {
          const aReviewCount = props.reviewQnaires.filter(q => q.tenantId === a.id).length;
          const bReviewCount = props.reviewQnaires.filter(q => q.tenantId === b.id).length;
    
          if (aReviewCount < bReviewCount) return state.sortDirection === 'ascending' ? -1 : 1;
          if (aReviewCount > bReviewCount) return state.sortDirection === 'ascending' ? 1 : -1;
          return 0;
        });
      } else {
        // If no sorting is applied, sort by name
        sortedTenants.sort((a, b) => {
          const aValue = a.name.toLowerCase();
          const bValue = b.name.toLowerCase();
          return aValue.localeCompare(bValue);
        });
      }
      
      sortedTenants = sortedTenants.reduce((acc, tenant) => {
        if (tenant.id === activeTenant.id) {
          acc.unshift(tenant);
        } else {
          acc.push(tenant);
        }
        return acc;
      }, []);
        
      if (!sortedTenants || sortedTenants.length === 0) {
        return (
          <Segment>
            <Header icon>
              <Icon name='file' color="black" />
              No Tenants To Display
            </Header>
          </Segment>
        );
      }
        
      return (
        <Table compact celled size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                className='nectaPrimaryBg'
                style={{ width: '20%' }}
                onClick={() => handleSort('name')}
              >
                Company Name {sortBy === 'name' && sortDirection === 'ascending' ? <Icon name='caret up' /> : <Icon name='caret down' />}
              </Table.HeaderCell>
              <Table.HeaderCell
                className='nectaPrimaryBg'
                style={{ width: '35%' }}
                onClick={() => handleSort('synopsis')}
              >
                Company Synopsis {sortBy === 'synopsis' && sortDirection === 'ascending' ? <Icon name='caret up' /> : <Icon name='caret down' />}
              </Table.HeaderCell>
              <Table.HeaderCell
                className='nectaPrimaryBg'
                style={{ width: '10%' }}
                onClick={() => handleSort('status')}
              >
                Status {sortBy === 'status' && sortDirection === 'ascending' ? <Icon name='caret up' /> : <Icon name='caret down' />}
              </Table.HeaderCell>
              {canReviewTenantQnaires() && props.reviewQnaires.length > 0 && (
                <Table.HeaderCell
                  className='nectaPrimaryBg'
                  style={{ width: '15%' }}
                  onClick={() => handleSort('review')}
                >
                  Review Questionnaires {sortBy === 'review' && sortDirection === 'ascending' ? <Icon name='caret up' /> : <Icon name='caret down' />}
                </Table.HeaderCell>
              )}
              {canUpdateManageTenants() && (
                <Table.HeaderCell
                  className='nectaPrimaryBg'
                  style={{ width: '20%' }}
                >
                  Other Actions
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>    
            {sortedTenants.map((tenant, index) => (
              <Table.Row
                key={`${tenant.id}.${index}`}
                className='clickable'
                active={tenant.id === state.selectedTenant.id}
              >
                <Table.Cell>{tenant.companyName ? tenant.companyName : tenant.name}</Table.Cell>
                <Table.Cell>{tenant.description}</Table.Cell>
                <Table.Cell>
                  {(tenant.status === 'active' || !tenant.status) ? (
                    <Label style={{ color: 'white', backgroundColor: 'green'}}>Active</Label>
                  ) : (
                    <Label style={{ color: 'white', backgroundColor: 'red' }}>Disabled</Label>
                  )}
                </Table.Cell>
                {canReviewTenantQnaires() && props.reviewQnaires.length > 0 && (
                  <Table.Cell>
                  {props.reviewQnaires.filter(q => q.tenantId === tenant.id).length > 0 && (
                    <>
                      <FuseContentModal
                        header={`Review ${tenant.companyName ? tenant.companyName : tenant.name}'s Questionnaires`}
                        content={<ReviewQuestionnaires tenant={tenant} bdaction={'review'} />}
                        trigger={<Button compact className="primary" icon='clipboard' size='small' content={`Assess (${props.reviewQnaires.filter(q => q.tenantId === tenant.id).length})`} />}
                        onOpen={() => {
                          setState((state) => ({ ...state, isEditTenantOpen: true, selectedTenant: tenant }));
                        }}
                        onClose={() => {
                          setState((state) => ({ ...state, isEditTenantOpen: false, selectedTenant: {} }));
                        }}
                      />
                    </>
                  )}
                </Table.Cell>
                
                )}
                {canUpdateManageTenants() && (
                  <Table.Cell>
                  <>
                    <FuseContentModal
                      header={`Edit Permissions: ${tenant.companyName ? tenant.companyName : tenant.name}`}
                      content={<ManageTenantsDetails tenant={tenant} bdaction={'edit'} />}
                      trigger={<Button compact className="warning" icon='edit' size='small' content='Edit' />}
                      onOpen={() => {
                        setState((state) => ({ ...state, isEditTenantOpen: true, selectedTenant: tenant }));
                      }}
                      onClose={() => {
                        setState((state) => ({ ...state, isEditTenantOpen: false, selectedTenant: {} }));
                      }}
                    />
                    {!tenant.isRoot && (
                      tenant.status === 'disabled' ? (
                        <Button
                          compact
                          className="success"
                          icon='check'
                          size='small'
                          content='Enable'
                          onClick={(e) => enableTenant(e, tenant)}
                        />
                      ) : (
                        <Button
                          compact
                          className="danger"
                          icon='cancel'
                          size='small'
                          content='Disable'
                          onClick={(e) => disableTenant(e, tenant)}
                        />
                      )
                    )}
                  </>
                </Table.Cell>
                
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    };
    
  
  const enableTenant = (e, tenant) => {
    e.preventDefault();
    e.stopPropagation();
    setState((state) => ({ ...state, enableTenantCandidate: tenant, showEnableTenant: true }));
  };

  const confirmEnableTenant = () => {
    return (
      <Confirm
        content={`Are you sure you want to enable the following tenant: ${state.enableTenantCandidate.name}?`}
        open={state.showEnableTenant}
        onCancel={() => setState((state) => ({ ...state, showEnableTenant: false }))}
        onConfirm={(e) => {
          e.stopPropagation();
          state.enableTenantCandidate.status = 'active'
          props.saveTenantAction(state.enableTenantCandidate)
          setState((state) => ({ ...state, showEnableTenant: false, enableTenantCandidate: {} }));
        }}
      />
    );
  };


  const disableTenant = (e, tenant) => {
    e.preventDefault();
    e.stopPropagation();
    setState((state) => ({ ...state, disableTenantCandidate: tenant, showDisableTenant: true }));
  };

  const confirmDisableTenant = () => {
    return (
      <Confirm
        content={`Are you sure you want to disable the following tenant: ${state.disableTenantCandidate.name}?`}
        open={state.showDisableTenant}
        onCancel={() => setState((state) => ({ ...state, showDisableTenant: false }))}
        onConfirm={(e) => {
          e.stopPropagation();
          state.disableTenantCandidate.status = 'disabled'
          props.saveTenantAction(state.disableTenantCandidate)
          setState((state) => ({ ...state, showDisableTenant: false, disableTenantCandidate: {} }));
        }}
      />
    );
  };

  return (
        <Container fluid>
            {confirmEnableTenant()}
            {confirmDisableTenant()}
            <Grid>
                <Grid.Row>
                  <Grid.Column width='8'>
                    <h3>Tenant Management</h3>
                  </Grid.Column>
                  <Grid.Column width='8'>
                      <Button compact className='primary' icon='refresh' size='mini' floated='right' onClick={(e) => {
                        props.fetchAllTenantsAction();
                        props.fetchAllQnaires();
                      }}></Button>
                  </Grid.Column>
                </Grid.Row>
            </Grid>
            {tenantsRender()}
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    
    return {
        tenant: state.tenantManagement.activeTenant,
        tenants: state.tenantManagement.allTenants,
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        reviewQnaires: state.questionaires
    };
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
    fetchAllTenantsAction: () => dispatch(REGISTRATION_ACTIONS.fetchAllTenantsAction()),
    fetchAllQnaires : () => dispatch(REVIEW_QNAIRE_ACTIONS.fetchAllQnairesAction()),
    saveTenantAction: (tenant) => dispatch(TENANT_ACTIONS.saveTenantAction(tenant)),
	})
)(ManageTenantsPage);