import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { fetchItemsWS } from '../webservices/fetchWS';
import { createItemWs, updateItemWs } from "../webservices/saveWS";
import { sendGroupMessageWs } from "../webservices/groupMessgesWS";
import { addUsersToGroups } from "../webservices/userGroupsWs";
import { toast } from "react-toastify";
import { Storage } from 'aws-amplify';
import _ from "lodash";
import { UserGroupDTO } from "./DTOs/UserGroupDTO";
import { GroupUserDTO } from "./DTOs/GroupUserDTO";
import { UserDTO } from "./DTOs/UserDTO";
import { GroupMessageDTO } from "./DTOs/GroupMessageDTO";


/// User Group Actions ///

export const FETCHING_USER_GROUPS_ACTION = 'FETCHING_USER_GROUPS_ACTION';
export const FETCH_USER_GROUPS_SUCCESS_ACTION = 'FETCH_USER_GROUPS_SUCCESS_ACTION';
export const FETCH_USER_GROUPS_ERROR_ACTION = 'FETCH_USER_GROUPS_ERROR_ACTION';

export const fetchUserGroupsAction = (tenant) => {
  const queryParams = {
    PK: `${tenant.id}%23GROUPS`,
    SK: `GROUP%23`,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_USER_GROUPS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        let items = result.Items;
        items = items.filter((item) => item.status !== 'isDeleted');
        dispatch({ type: FETCH_USER_GROUPS_SUCCESS_ACTION, payload: items });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_USER_GROUPS_ERROR_ACTION, err });
      });
  };
}


export const USER_GROUP_ADD_ACTION = "USER_GROUP_ADD_ACTION";
export const USER_GROUP_EDIT_ACTION = "USER_GROUP_EDIT_ACTION";

export const saveUserGroupAction = (tenant, group, isNew) => {
  return (dispatch, getState) => {
    const saveObject = new UserGroupDTO(group, tenant);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (isNew === true) {
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: USER_GROUP_ADD_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success("User Group Successfully Created");
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to create new user group. Please try again");
        });
    }
    else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: USER_GROUP_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success("User Group Successfully Updated");
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success("Failed to update the user group. Please try again");
        });
    }
  }
}

export const USER_GROUP_DELETE_ACTION = 'USER_GROUP_DELETE_ACTION';

export const deleteUserGroupsAction = (group, tenant) => {
  return (dispatch, getState) => {
    const saveObject = new UserGroupDTO(group, tenant);
    saveObject.status = "isDeleted";
    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: USER_GROUP_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success("User Group Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed to delete user group. Please try again");
      });

  }
}


/// Group User Actions ///

export const FETCHING_GROUP_USERS_ACTION = 'FETCHING_GROUP_USERS_ACTION';
export const FETCH_GROUP_USERS_SUCCESS_ACTION = 'FETCH_GROUP_USERS_SUCCESS_ACTION';
export const FETCH_GROUP_USERS_ERROR_ACTION = 'FETCH_GROUP_USERS_ERROR_ACTION';
export const FETCH_ITEMS_SUCCESS_ACTION = 'FETCH_ITEMS_SUCCESS_ACTION';
export const CLEAR_LASTEVALUATEDKEY = "CLEAR_LASTEVALUATEDKEY";
export const CLEAR_GROUPUSERS = "CLEAR_GROUPUSERS";


export const fetchGroupUserAction = (group) => {
  const queryParams = {
    PK: `${group.tenantId}%23GROUP%23${group.id}`,
    SK: `GROUPUSER%23`,
    limit: 50,
  };

  return (dispatch, getState) => {
    const state = getState()
    const lastEvaluatedKey = state.lastEvaluatedKey
    if (lastEvaluatedKey) {
      const encodedPK = encodeURIComponent(lastEvaluatedKey.PK);
      const encodedSK = encodeURIComponent(lastEvaluatedKey.SK);
      queryParams.lastEvaluatedKeyPK = encodedPK;
      queryParams.lastEvaluatedKeySK = encodedSK;
    }
    dispatch({ type: FETCHING_GROUP_USERS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        let items = result.Items;
        let lastKey = result.LastEvaluatedKey;
        items = items.filter((item) => item.status !== 'isDeleted');
        dispatch({ type: FETCH_GROUP_USERS_SUCCESS_ACTION, payload: items });
        if (lastKey !== undefined) {
          dispatch({ type: FETCH_ITEMS_SUCCESS_ACTION, payload: lastKey });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_GROUP_USERS_ERROR_ACTION, err });
      });
  };
}

export const clearSelectedUserGroupUsersAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_GROUPUSERS });
  };
}

export const clearLastEvaluatedKeyAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_LASTEVALUATEDKEY });
  };
}

export const FETCHING_SEARCHED_GROUP_USERS_ACTION = 'FETCHING_SEARCHED_GROUP_USERS_ACTION';
export const FETCH_SEARCHED_GROUP_USERS_SUCCESS_ACTION = 'FETCH_SEARCHED_GROUP_USERS_SUCCESS_ACTION';
export const FETCH_SEARCHED_GROUP_USERS_ERROR_ACTION = 'FETCH_SEARCHED_GROUP_USERS_ERROR_ACTION';

export const fetchSearchedUserAction = (group, term) => {
  const queryParams = {
    PK: `${group.tenantId}%23GROUP%23${group.id}`,
    SK: `GROUPUSER%23${term}`,
    limit: 30,
  };

  return (dispatch, getState) => {
    const state = getState()
    dispatch({ type: FETCHING_SEARCHED_GROUP_USERS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        let items = result.Items;
        items = items.filter((item) => item.status !== 'isDeleted');
        dispatch({ type: FETCH_SEARCHED_GROUP_USERS_SUCCESS_ACTION, payload: items });
        if(result.items === 0){
          toast.error("No user mathcing search input found")
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_SEARCHED_GROUP_USERS_ERROR_ACTION, err });
      });
  };
}

export const GROUP_USER_ADD_ACTION = "GROUP_USER_ADD_ACTION";
export const GROUP_USER_EDIT_ACTION = "GROUP_USER_EDIT_ACTION";

export const saveGroupUserAction = (user, group, isNew) => {
  return (dispatch, getState) => {
    const saveObject = new GroupUserDTO(user, group);
    dispatch({ type: SAVE_IN_PROGRESS });
    if (isNew === true) {
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: GROUP_USER_ADD_ACTION, payload: result.data });
          dispatch(saveUserAction(user, group,));
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success("User Group Successfully Created");
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to create new user group. Please try again");
        });
    }
    else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: GROUP_USER_EDIT_ACTION, payload: result.data });
          dispatch(saveUserAction(user, group));
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success("User Group Successfully Updated");
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success("Failed to update the user group. Please try again");
        });
    }
  }
}

const saveUserAction = (user, group) => {
  const queryParams = {
    PK: `USERS`,
    SK: `USER%23${user.contact_nr}`,
  };
  return (dispatch, getState) => {
    const saveObject = new UserDTO(user);
    dispatch({ type: SAVE_IN_PROGRESS });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        let items = result.Items;
        items = items.filter((item) => item.status !== 'isDeleted');
        if (_.isEmpty(items)) {
          createItemWs(saveObject, getState)
            .then((result) => {
              dispatch({ type: SAVE_PROCESS_DONE });
              dispatch(fetchGroupUserAction(group));
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: SAVE_PROCESS_DONE });
            });
        }
        // else {
        //   updateItemWs(saveObject, getState)
        //     .then((result) => {
        //       dispatch({ type: SAVE_PROCESS_DONE });
        //       dispatch(fetchGroupUserAction(group));
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //       dispatch({ type: SAVE_PROCESS_DONE });
        //     });
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export const GROUP_USER_DELETE_ACTION = 'USER_GROUP_DELETE_ACTION';

export const deleteGroupUserAction = (group, tenant) => {
  return (dispatch, getState) => {
    const saveObject = new GroupUserDTO(group, tenant);
    saveObject.status = "isDeleted";
    dispatch({ type: SAVE_IN_PROGRESS });
    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: GROUP_USER_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success("Group User Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed to delete user group. Please try again");
      });

  }
}

export const uploadFileAction = (selectedFile, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVE_IN_PROGRESS });
    await uploadFileTos3Action(selectedFile, data);

    dispatch(async (dispatch) => {
      try {
        await addUsersToGroups(data, getState);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success("Successfully uploaded file");
        toast.success("Please wait a few minutes for the users to be added to the group");
      } catch (error) {
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed to upload the file. Please try again");
        throw error;
      }
    });
  } catch (error) {
    dispatch({ type: SAVE_PROCESS_DONE });
    toast.error("Failed to upload the file. Please try again");
    throw error;
  }
};

async function uploadFileTos3Action(selectedFile, data) {
  try {
    if (selectedFile !== undefined) {
      if (selectedFile.name.includes('.csv')) {
        await Storage.put(`${process.env.REACT_APP_ENV}/Groups/${data.tenantId}/${selectedFile.name}`, selectedFile, {
          level: 'public',
        });
      }
      else {
        throw new Error(`Unsupported file type: ${selectedFile.type}`);
      }
    }
    else {
      console.log("no file to upload!")
    }
  } catch (error) {
    throw error;
  }
}

/// Group Message Actions ///

export const FETCHING_GROUP_MESSAGES_ACTION = 'FETCHING_GROUP_MESSAGES_ACTION';
export const FETCH_GROUP_MESSAGES_SUCCESS_ACTION = 'FETCH_GROUP_MESSAGES_SUCCESS_ACTION';
export const FETCH_GROUP_MESSAGES_ERROR_ACTION = 'FETCH_GROUP_MESSAGES_ERROR_ACTION';

export const fetchGroupMessageAction = (group) => {
  const queryParams = {
    PK: `${group.tenantId}%23GROUP%23${group.id}`,
    SK: `NOTIFICATION%23`,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_GROUP_MESSAGES_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        let items = result.Items;
        items = items.filter((item) => item.status !== 'isDeleted');
        dispatch({ type: FETCH_GROUP_MESSAGES_SUCCESS_ACTION, payload: items });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_GROUP_MESSAGES_ERROR_ACTION, err });
      });
  };
}


export const sendMessageAction = (message, userGroup, tenant) => {
  return (dispatch, getState) => {

    let notification = {}

    notification['message'] = {
      title: message.title,
      body: message.body
    }
    notification['senderData'] = {
      senderId: tenant.id,
      senderName: tenant.name
    }
    notification['whatsAppTemplate'] = {
      whatsAppTemplate: message.whatsAppTemplate,
      whatsAppTemplatePlaceholderValues: message.whatsAppTemplatePlaceholderValues
    }
    notification['userGroup'] = userGroup

    sendGroupMessageWs(notification, getState)
      .then((result) => {
        dispatch(createMessageAction(message, userGroup, tenant, getState));
      })
      .catch((err) => {
        dispatch({ type: SAVE_PROCESS_DONE });
        console.log(err);
        toast.error("Failed To Send Message To Groups Memebers. Please Try Again");
      });
  }
}

export const GROUP_MESSAGE_ADD_ACTION = "GROUP_MESSAGE_ADD_ACTION";

export const createMessageAction = (message, userGroup, tenant) => {
  return (dispatch, getState) => {
    let senderData = {
      senderId: tenant.id,
      senderName: tenant.name
    }
    const saveObject = new GroupMessageDTO(message, userGroup, senderData);
    dispatch({ type: SAVE_IN_PROGRESS });
    createItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: GROUP_MESSAGE_ADD_ACTION, payload: result.data });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success("Message Successfully Sent to Group Memebers");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed To Send Message To Group Memebers. Please Try Again");
      });
  }
}

// export const sendMessageAction = (message) => {
//   return (dispatch, getState) => {
//     sendGroupMessageWs(message, getState)
//       .then((result) => {
//         toast.success("Message Successfully Sent to Groups Memebers");
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({ type: SAVE_PROCESS_DONE });
//         toast.error("Failed To Send Message To Groups Memebers. Please Try Again");
//       });
//   }
// }

// export const GROUP_MESSAGE_ADD_ACTION = "GROUP_MESSAGE_ADD_ACTION";

// export const createMessageAction = (message, userGroup, tenant) => {
//   return (dispatch, getState) => {
//     let senderData = {
//       senderId: tenant.id,
//       senderName: tenant.name
//     }
//     const saveObject = new GroupMessageDTO(message, userGroup, senderData);
//     dispatch({ type: SAVE_IN_PROGRESS });

//     // Promisify both createItemWs and sendGroupMessageWs
//     const createItemPromise = new Promise((resolve, reject) => {
//       createItemWs(saveObject, getState)
//         .then((result) => {
//           dispatch({ type: GROUP_MESSAGE_ADD_ACTION, payload: result.data });
//           resolve(result.data);
//         })
//         .catch((err) => {
//           reject(err);
//         });
//     });

//     const sendMessagePromise = new Promise((resolve, reject) => {
//       createItemPromise.then((messageData) => {
//         sendGroupMessageWs(messageData, getState)
//           .then((result) => {
//             resolve(result.data);
//           })
//           .catch((err) => {
//             reject(err);
//           });
//       }).catch((err) => {
//         reject(err);
//       });
//     });

//     // Wait for both promises to resolve or reject
//     Promise.all([createItemPromise, sendMessagePromise])
//       .then(() => {
//         dispatch({ type: SAVE_PROCESS_DONE });
//         toast.success("Message Successfully Sent to Groups Members");
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({ type: SAVE_PROCESS_DONE });
//         toast.error("Failed To Send Message To Groups Members. Please Try Again");
//       });
//   }
// }

