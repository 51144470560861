import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { fetchItemsWS } from '../webservices/fetchWS';
import { createItemWs } from "../webservices/saveWS";
import { processExports } from "../webservices/exportDataWS";
import { toast } from "react-toastify";
import {ExportDTO} from "./DTOs/ExportDTO";

export const FETCHING_EXPORTS_ACTION = 'FETCHING_EXPORTS_ACTION';
export const FETCH_EXPORTS_SUCCESS_ACTION = 'FETCH_EXPORTS_SUCCESS_ACTION';
export const FETCH_EXPORTS_ERROR_ACTION = 'FETCH_EXPORTS_ERROR_ACTION';

export const fetchExportsAction = (tenantId) => {
    const queryParams = {
        PK: `${tenantId}%23EXPORTS`,
        SK: `EXP%23`,
      };

      return (dispatch, getState) => {
        dispatch({ type: FETCHING_EXPORTS_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
          .then((result) => {
            let items = result.Items;
            items = items.filter((item) => item.status !== 'isDeleted');
            dispatch({ type: FETCH_EXPORTS_SUCCESS_ACTION, payload: items });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_EXPORTS_ERROR_ACTION, err });
          });
      };
}


export const FETCHING_EXPORT_QUESTIONAIRES_ACTION = 'FETCHING_EXPORT_QUESTIONAIRES_ACTION';
export const FETCH_EXPORT_QUESTIONAIRES_SUCCESS_ACTION = 'FETCH_EXPORT_QUESTIONAIRES_SUCCESS_ACTION';
export const FETCH_EXPORT_QUESTIONAIRES_ERROR_ACTION = 'FETCH_EXPORT_QUESTIONAIRES_ERROR_ACTION';

export const fetchQuestionairesAction = (tenantId) => {
  const queryParams = {
    GSI2PK: `${tenantId}%23QNAIRES`,
    GSI2SK: 'QNAIRE',
  };

  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_EXPORT_QUESTIONAIRES_ACTION, payload: tenantId });

    try {
      const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
      const state = getState();
      const tenant = state.tenantManagement.activeTenant

      if(tenant.baseQnaires === undefined || tenant.baseQnaires.length === 0){
        dispatch({ type: FETCH_EXPORT_QUESTIONAIRES_SUCCESS_ACTION, payload: returnItems });
      }
      else{
        const matchingBaseQnaires = await dispatch(fetchBaseQuestionairesAction(tenant));
        const combinedResults = [...returnItems, ...matchingBaseQnaires];
        dispatch({ type: FETCH_EXPORT_QUESTIONAIRES_SUCCESS_ACTION, payload: combinedResults });
      }
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_EXPORT_QUESTIONAIRES_ERROR_ACTION, err });
    }
  };
};

//Fetch Base Questionnaires 
export const FETCHING_BASE_QUESTIONAIRES_ACTION = 'FETCHING_BASE_QUESTIONAIRES_ACTION';
export const FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION = 'FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION';
export const FETCH_BASE_QUESTIONAIRES_ERROR_ACTION = 'FETCH_BASE_QUESTIONAIRES_ERROR_ACTION';

export const fetchBaseQuestionairesAction = (tenant) => {
  const queryParams = {
    GSI2PK: `2WWjarbwyZ6IvJpoQKfzVWkGWiK%23QNAIRES`,
    GSI2SK: 'QNAIRE%23',
  };

  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_BASE_QUESTIONAIRES_ACTION, payload: tenant });

    try {
      const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const returnItems = result.Items.filter(item => item.isBaseQnaire === true && item.status !== "isDeleted");

      // Merge tenant's base questionnaires with fetched base questionnaires from tenant zero
      const matchingBaseQnaires = tenant.baseQnaires.map(tenantBaseQnaire => {
      const matchingFetchedQnaire = returnItems.find(item => item.id === tenantBaseQnaire.id);

      // Check if the fetched questionnaire with the same ID exists
      if (matchingFetchedQnaire && matchingFetchedQnaire.isBaseQnaire && matchingFetchedQnaire.status !== "isDeleted") {
        // If it exists, merge the properties and return the merged object
        return { ...tenantBaseQnaire, ...matchingFetchedQnaire };
      }

      // If it doesn't exist or is not a base questionnaire, return the original tenant base questionnaire
      return tenantBaseQnaire;
    });

      dispatch({ type: FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION, payload: matchingBaseQnaires });

      return matchingBaseQnaires;
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_BASE_QUESTIONAIRES_ERROR_ACTION, err });
      throw err;
    }
  };
};

//Fetch UserGroups 
export const FETCHING_EXPORT_USERGROUPS_ACTION = 'FETCHING_EXPORT_USERGROUPS_ACTION';
export const FETCH_EXPORT_USERGROUPS_SUCCESS_ACTION = 'FETCH_EXPORT_USERGROUPS_SUCCESS_ACTION';
export const FETCH_EXPORT_USERGROUPS_ERROR_ACTION = 'FETCH_EXPORT_USERGROUPS_ERROR_ACTION';

export const fetchUserGroupsAction = (tenantId) => {
  const queryParams = {
    PK: `${tenantId}%23GROUPS`,
    SK: 'GROUP%23',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_EXPORT_SECTIONS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_EXPORT_USERGROUPS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_EXPORT_USERGROUPS_ERROR_ACTION, err });
      });
  };
};


export const FETCHING_EXPORT_SECTIONS_ACTION = "FETCHING_EXPORT_SECTIONS_ACTION";
export const FETCH_EXPORT_SECTIONS_SUCCESS_ACTION = "FETCH_EXPORT_SECTIONS_SUCCESS_ACTION";
export const FETCH_EXPORT_SECTIONS_ERROR_ACTION = "FETCH_EXPORT_SECTIONS_ERROR_ACTION";

export const fetchQuestionaireSectionsAction = (tenantId, qnaire) => {
  const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${qnaire.id}`,
    SK: 'SECTION%23',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_EXPORT_SECTIONS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_EXPORT_SECTIONS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_EXPORT_SECTIONS_ERROR_ACTION, err });
      });
  };
}

export const FETCHING_EXPORT_QUESTIONS_ACTION = "FETCHING_EXPORT_QUESTIONS_ACTION";
export const FETCH_EXPORT_QUESTIONS_SUCCESS_ACTION = "FETCH_EXPORT_QUESTIONS_SUCCESS_ACTION";
export const FETCH_EXPORT_QUESTIONS_ERROR_ACTION = "FETCH_EXPORT_QUESTIONS_ERROR_ACTION";

export const fetchQuestionaireQuestionsAction = (tenantId, qnaire) => {
  const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${qnaire.id}`,
    SK: 'QUESTION%23',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_EXPORT_QUESTIONS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_EXPORT_QUESTIONS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_EXPORT_QUESTIONS_ERROR_ACTION, err });
      });
  };
}

export const ACT_RULE_ADD_ACTION = "ACT_RULE_ADD_ACTION";

export const saveExportAction = (tenant, exp, isNew) => {
    return (dispatch, getState) => {
        const saveObject = new ExportDTO(exp, tenant);
        dispatch({ type: SAVE_IN_PROGRESS });
        if (isNew === true) {
            createItemWs(saveObject, getState)
            .then((result) => {
                dispatch({ type: ACT_RULE_ADD_ACTION, payload: result.data });
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.success("Export Successfully Created");
                let payload = {}
                payload["tenant_id"] = tenant.tenantId;
                processExports(payload, getState);  
                dispatch(fetchExportsAction(tenant.tenantId));
          })
            .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.error("Failed to create new Export. Please try again");
                });
        }
    }
}

