import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Table, Button, Container, Grid, Confirm, Dimmer, Loader, Checkbox } from 'semantic-ui-react'
import FuseContentModal from '../../../components/generic/FuseContentModal';
import PlaceholderSegment from '../../../components/generic/PlaceholderSegment';
import BRQnaireDetails from './BRQnaireDetails';
import * as QNAIREBR_ACTIONS from '../../../actions/brQnaireActions';

export function BRQnairePage(props) {
	const [state, setState] = useState({
	    brQnaires: { ...props.brQnaires },
		brQnaire: null,
		BRQnaireDetails: null,
		selectedBrQnaire: {},
		fetchingData: false,
		showConfirmDelete: false,
		deleteCandidate: {},
		questionaire: { ...props.questionaire},
	});

	const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

	const canReadLookup = () => {
		return hasPermissions('Questionnaire Businness Rule Menu') && props.userPermissions['Questionnaire Businness Rule Menu'].actions.READ || isAdmin()
	}

	const canCreateLookup = () => {
		return hasPermissions('Questionnaire Businness Rule Menu') && canReadLookup() && props.userPermissions['Questionnaire Businness Rule Menu'].actions.CREATE ||isAdmin()
	}

	const canUpdateLookup = () => {
		return hasPermissions('Questionnaire Businness Rule Menu') && canReadLookup() && props.userPermissions['Questionnaire Businness Rule Menu'].actions.UPDATE || isAdmin()
	}

	const canDeleteLookup = () => {
		return hasPermissions('Questionnaire Businness Rule Menu') && canReadLookup() && props.userPermissions['Questionnaire Businness Rule Menu'].actions.DELETE || isAdmin()
	}

	useEffect(() => {
		if(props.isPermissionsFetched){
			if (canReadLookup()) {
				props.fetchBRQnairesAction(props.questionaire, props.questionaire.tenantId);
			}
			else{
				toast.error('You do not have permission to view business rules');
			}
		}
	}, [])

	const onBrQnaireSelectionChanged = (rowId) => {
		// console.log('rowId: '+ rowId);
		if (rowId !== 'new') {
			const brQnaires = props.brQnaires.filter(x => x.id === rowId);
			if (brQnaires.length > 0) {
				const brQnaire = brQnaires[0];
				setState((state) => ({
					...state,
					selectedBrQnaire: brQnaire,
				}));
			}
			else {
				setState((state) => ({
					...state,
					selectedBrQnaire: {},
				}));
			}
		}
		else {
			setState((state) => ({
				...state,
				selectedBrQnaire: {
					id: 0,
					keyName: ''
				},
			}));
		}
	}

	const onEditBrQnaireRow = (rowId) => {
		onBrQnaireSelectionChanged(rowId)
		setState((state) => ({ ...state, isEditBrQnaireOpen: true }));
	}

	const confirmDelete = () => {
		return (
			<Confirm
				content={`Are you sure you want to delete the following Business Rule linked to questionnaire: ${state.deleteCandidate.brName}?`}
				open={state.showConfirmDelete}
				onCancel={() => setState((state) => ({ ...state, showConfirmDelete: false }))}
				onConfirm={() => {
					var deleteQnaireBr = state.deleteCandidate;
					props.deleteBRQnaire(deleteQnaireBr);
					onBrQnaireSelectionChanged(0);
					setState((state) => ({ ...state, showConfirmDelete: false }));
				}}
			/>
		);
	}

	const isBaseQnaire = props.questionaire.isBaseQnaire;
	const isRootTenant = props.tenant.isRoot === true || false;

	const BRQnaireItems = () => {

		if (!props.fetchingData) {
			if (!_.isEmpty(props.brQnaires)) {
				return (
          <Table compact selectable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg'>Business Rules linked to Questionnaire</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg'>Execution Rule</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg'>Is Scheduled?</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.brQnaires.map(brQnaire => {
              return (
                <Table.Row key={brQnaire.id} className='clickable' onClick={() => onEditBrQnaireRow(brQnaire.id)} active={brQnaire.id === state.selectedBrQnaire.id}>
                  <Table.Cell>{brQnaire.brName}</Table.Cell>
                  <Table.Cell>{brQnaire.brExecutionRuleVal}</Table.Cell>
                  <Table.Cell>{<Checkbox disabled checked={brQnaire.brScheduled}/>}</Table.Cell>
                  <Table.Cell textAlign='right'>
				  { canUpdateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
                  <FuseContentModal
                    header={`Edit link to Business Rule: ${state.selectedBrQnaire.brName}`}
                    content={<BRQnaireDetails qnaire={state.questionaire} brQnaire={state.selectedBrQnaire} braction={'edit'}/>}
                    trigger={<Button compact className="warning" icon='edit' size='mini' />}
                    onClose={() => {setState((state) => ({ ...state, brQnaire: state.brQnaire}));;}}									
                  />)}
				  { canDeleteLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
                  <Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
                    e.stopPropagation();
                    setState((state) => ({ ...state, deleteCandidate: brQnaire, showConfirmDelete: true }));
                  }} />)}</Table.Cell>
                </Table.Row>
              );
            })
            }
          </Table.Body>
        </Table>
				);
			}
			else {
				return (
					<PlaceholderSegment text="There are no linked Business Rules" />
				);
			}
		}
		else {
			return (
				<div style={{ height: "200px", margin: "10px 0px" }}>
					<Dimmer inverted active>
						<Loader content='Loading' />
					</Dimmer>
				</div>
			);
		}
	}

	return (
		<Container fluid>
			{confirmDelete()}
			<Grid>
				<Grid.Row>
					<Grid.Column width='8'>
						<h3>Link to Business Rule/s</h3>
					</Grid.Column>
					<Grid.Column width='8'>
					{ canCreateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
					<FuseContentModal
						header={`Add link to Business Rule`}
						content={<BRQnaireDetails qnaire={state.questionaire} brQnaire = {''} braction={'add'}/>}
						trigger={<Button compact className="success" icon='add' size='mini' floated='right'/>}
						onClose={() => {setState((state) => ({ ...state, brQnaire: state.brQnaire}));;}}									
					/>)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{BRQnaireItems()}
		</Container>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		fetchingData: state.fetchingData === true,
		brQnaires: _.isEmpty(state.brQnaires) ? [] : Object.values(state.brQnaires.filter((brQnaire) => brQnaire.questionaireId === ownProps.questionaire.id)),
		questionaire: ownProps.questionaire,
		tenant: state.tenantManagement.activeTenant,
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchBRQnairesAction: (qnaire, tenantId) => dispatch(QNAIREBR_ACTIONS.fetchbRQnairesAction(qnaire, tenantId)),
		deleteBRQnaire: (brQnaire) => dispatch(QNAIREBR_ACTIONS.bRQnaireDeleteAction(brQnaire)),
	})
)(BRQnairePage);