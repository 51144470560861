import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { fetchItemsWS } from '../webservices/fetchWS';
import { QuestionaireDTO } from './DTOs/QuestionaireDTO';
import { updateItemWs } from '../webservices/saveWS';

export const FETCHING_QUESTIONAIRES_ACTION = 'FETCHING_QUESTIONAIRES_ACTION';
export const FETCH_QUESTIONAIRES_SUCCESS_ACTION = 'FETCH_QUESTIONAIRES_SUCCESS_ACTION';
export const FETCH_QUESTIONAIRES_ERROR_ACTION = 'FETCH_QUESTIONAIRES_ERROR_ACTION';

export const fetchAllQnairesAction = (tenantId) => {
  const queryParams = {
    GSI3PK: `QNAIRES`,
    GSI3SK: 'QNAIRE#',
  };

  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_QUESTIONAIRES_ACTION, payload: tenantId });

    try {
      const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const returnItems = result.Items.filter(item => item.status === 'underReview');
      dispatch({ type: FETCH_QUESTIONAIRES_SUCCESS_ACTION, payload: returnItems });
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_QUESTIONAIRES_ERROR_ACTION, err });
    }
  };
};

export const FETCHING_REVIEW_QNAIRES_ACTION = 'FETCHING_REVIEW_QNAIRES_ACTION';
export const FETCH_REVIEW_QNAIRES_SUCCESS_ACTION = 'FETCH_REVIEW_QNAIRES_SUCCESS_ACTION';
export const FETCH_REVIEW_QNAIRES_ERROR_ACTION = 'FETCH_REVIEW_QNAIRES_ERROR_ACTION';


export const fetchTenantReviewQnaires = (tenant) => {
    const queryParams = {
        GSI2PK: `${tenant.id}%23QNAIRES`,
        GSI2SK: 'QNAIRE%23',
    };

    return (dispatch, getState) => {
        dispatch({ type: FETCHING_REVIEW_QNAIRES_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
            .then((result) => {
                const returnItems = result.Items.filter(item => item.status === "underReview");
                dispatch({ type: FETCH_REVIEW_QNAIRES_SUCCESS_ACTION, payload: returnItems });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_REVIEW_QNAIRES_ERROR_ACTION, err });
            });
    };
}

export const reviewQuestionaireAction = (questionnaire, actionType, tenant) => {
    return (dispatch, getState) => {
        let newStatus;
        let isActive;
        if (actionType === 'validate') {
            newStatus = 'active';
            isActive = true;
        } else if (actionType === 'deny') {
            newStatus = 'denied';
            isActive = false;
        }

        questionnaire.status = newStatus;
        questionnaire.isActive = isActive;

        questionnaire.alwaysAnonymise = (questionnaire.status === "active" && questionnaire.anonymisedData === true);

        const saveObject = new QuestionaireDTO(questionnaire);
        dispatch({ type: SAVE_IN_PROGRESS });
        updateItemWs(saveObject, getState)
            .then((result) => {
                dispatch({ type: SAVE_PROCESS_DONE });
                if(actionType === "validate"){
                    toast.success('The questionnaire has been successfully validted');
                }else{
                    toast.warning('The questionnaire has been successfully denied');
                }
                dispatch(fetchTenantReviewQnaires(tenant));
                dispatch(fetchAllQnairesAction());
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
            });
    };
};
