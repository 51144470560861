import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import QuestionairePage from '../containers/pages/questionairesPage/QuestionairesPage';
import TopMenu from './TopMenu';
import StructuredQuestionairePage from '../containers/pages/questionairesPage/structuredQuestionairePage/StructuredQuestionairePage';
import BrTypesPage from '../containers/pages/brTypesPage/BrTypesPage';
import BusinessRulePage from '../containers/pages/businessRulePage/BusinessRulePage';
import ManageUsersPage from '../containers/pages/admin/ManageUsersPage';
import ManageRolesPage from '../containers/pages/admin/ManageRolesPage';
import ManageTenantPage from '../containers/pages/admin/ManageTenantPage';
import ManageTenantsPage from '../containers/pages/admin/ManageTenantsPage';
import KmlUploadPage from '../containers/pages/activationRules/KmlUploadPage';
import ActivationRulesPage from '../containers/pages/activationRules/ActivationRulesPage';
import DashBoardPage from '../containers/pages/dashBoard/DashBoardPage';
import ExportsPage from '../containers/pages/admin/ExportsPage';
import UserGroupsPage from '../containers/pages/userGroups/UserGroupsPage';
import WhatsAppTemplatesPage from '../containers/pages/whatsAppTemplates/WhatsAppTemplatesPage';
import ManageGlobalPaymentValuesPage from '../containers/pages/admin/ManageGlobalPaymentValuesPage';
import TenantFinancialPage from '../containers/pages/admin/TenantFinancialPage';
import AuditTrailPage from '../containers/pages/admin/AuditTrailPage';
import CVSearchPage from '../containers/pages/cvSearchPage/CVSearchPage';
import CVSearch from '../containers/pages/cvSearchPage/CVSearch';
import CVSearchLogPage from '../containers/pages/cvSearchPage/CVSearchLogPage';
import OwnedCVsPage from '../containers/pages/cvSearchPage/OwnedCVsPage';
import TenantPricingPage from '../containers/pages/admin/TenantPricingPage';
import TosPage from '../containers/pages/tosPage/TosPage';
import PTTReferencesPage from '../containers/pages/pdfTemplatesPage/PTTReferencesPage';

class MainAppComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	static defaultProps = {}

	componentDidMount() { }

	render() {
		return (
			<Container fluid style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
				{/* <div style={{ minWidth: '175px', marginRight: '10px', flexShrink: 0, paddingTop: '75px' }}>
					<TopMenu />
				</div> */}
				<div style={{ flex: 1, overflowX: 'auto', padding: '20px' }}>
					<ToastContainer hideProgressBar autoClose={3000} transition={Slide} position={toast.POSITION.BOTTOM_RIGHT} />
					<Router>
						<Switch>
							<Route path={`/Dashboard/View Dashboard`} exact strict component={DashBoardPage} />
							<Route path={`/Questionnaires`} exact strict component={QuestionairePage} />
							<Route path={`/Questionaires/:name`} exact strict component={StructuredQuestionairePage} />
							<Route path={`/General Actions/CVSearch`} exact strict component={CVSearch} />
							<Route path={`/General Actions/CVSearchLogs`} exact strict component={CVSearchLogPage} />
							<Route path={`/General Actions/OwnedCVs`} exact strict component={OwnedCVsPage} />
							<Route path={`/General Actions/PPTReferences`} exact strict component={PTTReferencesPage} />
							<Route path={`/General Actions/WhatsApp Templates`} exact strict component={WhatsAppTemplatesPage} />
							<Route path={`/General/Export Data`} exact strict component={ExportsPage} />

							<Route path={`/Business Rules/Business Rules`} exact strict component={BusinessRulePage} />
							<Route path={`/Business Rules/Business Rule Types`} exact strict component={BrTypesPage} />

							<Route path={`/Distribution Rules/Distribution Rules`} exact strict component={ActivationRulesPage} />
							<Route path={`/Distribution Rules/KML Uploads`} exact strict component={KmlUploadPage} />
							<Route path={`/Distribution Rules/User Groups`} exact strict component={UserGroupsPage} />

							<Route path={`/Admin/Manage Users`} exact strict component={ManageUsersPage} />
							<Route path={`/Admin/Manage Roles`} exact strict component={ManageRolesPage} />
							<Route path={`/Admin/Manage Tenant`} exact strict component={ManageTenantPage} />
							<Route path={`/Admin/Tenant Finances`} exact strict component={TenantFinancialPage} />
							<Route path={`/Admin/Tenant Pricing`} exact strict component={TenantPricingPage} />
							<Route path={`/Admin/Audit Trail`} exact strict component={AuditTrailPage} />

							<Route path={`/Admin/Manage Global Prices`} exact strict component={ManageGlobalPaymentValuesPage} />
							<Route path={`/Admin/Manage Tenants`} exact strict component={ManageTenantsPage} />

							<Route path={`/tos`} exact strict component={TosPage} />

							<Redirect to={`/Questionnaires`} />
						</Switch>
					</Router>
				</div>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		fetchingData: state.fetchingData === true
	};
}

export default connect(
	mapStateToProps,
	(dispatch, ownProps) => ({})
)(MainAppComponent);
