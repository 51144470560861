import {
    FETCH_EXPORTS_SUCCESS_ACTION,
    FETCH_EXPORT_QUESTIONAIRES_SUCCESS_ACTION,
    FETCH_EXPORT_SECTIONS_SUCCESS_ACTION,
    FETCH_EXPORT_USERGROUPS_SUCCESS_ACTION,
    FETCH_EXPORT_QUESTIONS_SUCCESS_ACTION,
} from '../actions/exportsActions';

export const exportsReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_EXPORTS_SUCCESS_ACTION:
            const newExports = action.payload;
            const oldExports = Object.values({...state}).filter(s => !newExports.some(newS => newS.id === s.id));
            const newExportsState = oldExports.concat(newExports);
            return newExportsState;
        default:
            return state;
    }
};

export const exportQuestionnaireReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_EXPORT_QUESTIONAIRES_SUCCESS_ACTION:
            const newQuestionaires = action.payload;
            return newQuestionaires;
        default:
            return state;
    }
};

// export const exportBaseQuestionnaireReducer = (state = [], action) => {
//     switch(action.type){
//         case FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION:
//             const newBaseQuestionaires = action.payload;
//             return newBaseQuestionaires;
//         default:
//             return state;
//     }
// };

export const exportQuestionnaireSectionsReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_EXPORT_SECTIONS_SUCCESS_ACTION:
            const newQuestionaireSections = action.payload;
            return newQuestionaireSections;
        default:
            return state;
    }
};

export const exportUserGroupsReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_EXPORT_USERGROUPS_SUCCESS_ACTION:
            const newUserGroups = action.payload;
            return newUserGroups;
        default:
            return state;
    }
};

export const exportQuestionnaireQuestionsReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_EXPORT_QUESTIONS_SUCCESS_ACTION:
            const newQuestionaireQuestions = action.payload;
            return newQuestionaireQuestions;
        default:
            return state;
    }
};