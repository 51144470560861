import { 
    FETCH_AUDITTRAIL_SUCCESS_ACTION
} from '../actions/auditTrailActions'

export const AuditTrailreducer =  (state = [], action) => {
    switch(action.type){
        case FETCH_AUDITTRAIL_SUCCESS_ACTION:
            const Items = action.payload;
            return Items;
        default:
            return state;
    }
}