import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Segment, Form, Dropdown } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import * as SECTION_ACTIONS from "../../../../actions/sectionActions";
import QuestionSelectComponent from "./QuestionSelectComponent";

export function ConditionComponent(props) {
  const [state, setState] = useState({
    condition: {
      ...props.condition,
    },
    isSectionCondition: props.isSectionCondition,
    sectionLookups: props.sectionLookups.map((item) => {
      return { key: item.id, text: item.displayName, value: item.value };
    }),
  });

  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    props.onConditionEdit(state.condition);
  }, []);

  useEffect(() => {
    props.onConditionEdit(state.condition);
  }, [state.condition]);

  useEffect(
    (prevProps, prevState) => {
      if (_.isEmpty(state.condition) && !_.isEmpty(props.condition)) {
        setState((state) => ({
          ...state,
          condition: props.condition,
          showCondition: !_.isEmpty(props.condition),
        }));
      }
    },
    [props.condition]
  );

  const operatorTypes = [
    { key: 1, text: "equals", value: "is_equal" },
    { key: 2, text: "does not equal", value: "is_not_equal" },
    { key: 3, text: "contains", value: "contains" },
    // { key: 4, text: 'less than', value: 'less_than' },
    // { key: 5, text: 'greater than', value: 'greater_than' },
    // { key: 6, text: 'less than or equal to', value: 'less_than_equal_to' },
    // { key: 7, text: 'greater than or equal to', value: 'greater_than_equal_to' },
  ];

  const changeSection = (value) => {
    setState((prevState) => ({
      ...prevState,
      condition: {
        ...prevState.condition,
        targetSectionId: value,
        targetQuestionId: null,
      },
    }));
  };

  const changeQuestion = (value) => {
    setState((prevState) => {
      return {
        ...prevState,
        condition: { ...prevState.condition, targetQuestionId: value },
      };
    });
  };

  const sectionDropdown = () => {
    let sectionItems = props.sectionLookups.map((section) => {
      return {
        key: section.id,
        text:
          section.parentSectionId === null ||
          section.parentSectionId === undefined
            ? `${section.displayName} (Main Section)`
            : `${section.displayName} (Subsection)`,
        value: section.id,
      };
    });
    

    let defaultValue = state.condition ? state.condition.targetSectionId : 0;

    return (
      <Controller
        name="section"
        control={control}
        rules={{ required: "Select a section" }}
        setValue={setValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <Dropdown
                className={fieldState.error ? "invalid-input-field" : ""}
                {...field}
                placeholder="Select a section"
                defaultValue={state.condition.targetSectionId}
                fluid
                selection
                search
                options={sectionItems}
                value={field.value === undefined ? defaultValue : field.value}
                onChange={(e, { value }) => {
                  field.onChange(value);
                  changeSection(value);
                }}
              />
              {fieldState.invalid && (
                <p className="validation-error">* Select a section</p>
              )}
            </div>
          );
        }}
      />
    );
  };

  const questionDropdown = () => {
    return (
      <Controller
        name="Question"
        control={control}
        rules={{ required: "Select a question" }}
        setValue={setValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <QuestionSelectComponent
                field={{ ...field }}
                sectionId={state.condition.targetSectionId}
                excludeId={props.questionId}
                questionaire={props.questionaire}
                onQuestionSelectedChanged={changeQuestion}
                selectedQuestionId={state.condition.targetQuestionId}
              />
              {fieldState.invalid && (
                <p className="validation-error">* Select a question</p>
              )}
            </div>
          );
        }}
      />
    );
  };

  const operatorDropdown = () => {
    return (
      <Controller
        name="operator"
        control={control}
        rules={{ required: "Select a operator" }}
        setValue={setValue}
        render={({ field, fieldState, formState }) => {
          return (
            <div>
              <Dropdown
                className={fieldState.error ? "invalid-input-field" : ""}
                {...field}
                placeholder="Operator"
                defaultValue={state.condition.conditionOperator}
                fluid
                selection
                search
                options={operatorTypes}
                value={state.condition.conditionOperator}
                onChange={(e, { value }) => {
                  setState((prevState) => ({
                    condition: {
                      ...prevState.condition,
                      conditionOperator: value,
                    },
                  }));
                  field.onChange(value);
                }}
              />
              {fieldState.invalid && (
                <p className="validation-error">* Select an operator</p>
              )}
            </div>
          );
        }}
      />
    );
  };

  return (
    <Segment secondary>
      <Form.Field>
        <label>Question depends on section:</label>
        {sectionDropdown()}
      </Form.Field>
      {!_.isEmpty(state.condition) && questionDropdown()}
      <Form.Field>
        <label>Question dependent when:</label>
        {operatorDropdown()}
      </Form.Field>
      <Form.Field>
        <label>And value is: </label>
        <input
          name="condition_value"
          className={errors.condition_value ? "invalid-input-field" : ""}
          {...register("condition_value", { required: true, maxLength: 255 })}
          placeholder="Value"
          value={state.condition.value}
          onChange={(e) => {
            setState((prevState) => ({
              condition: { ...prevState.condition, value: e.target.value },
            }));
          }}
        />
        {errors.condition_value &&
          errors.condition_value.type === "required" && (
            <p className="validation-error">* Please enter a value</p>
          )}
        {errors.condition_value &&
          errors.condition_value.type === "pattern" && (
            <p className="validation-error">
              * May only contain alphanumerical characters
            </p>
          )}
        {errors.condition_value &&
          errors.condition_value.type === "maxLength" && (
            <p className="validation-error">
              * Description may not be more than 255 characters long
            </p>
          )}
      </Form.Field>
    </Segment>
  );
}

ConditionComponent.defaultProps = {
  targetSectionId: 0,
  targetQuestionId: 0,
  sectionId: 0,
  questionId: 0,
  questionaireId: null,
  isSectionCondition: false,
  onConditionEdit: () => {},
};

const mapStateToProps = (state, ownProps) => {
  let conditions = [];

  if (ownProps.isSectionCondition) {
    conditions = state.conditions.filter((condition) => condition.sectionId === ownProps.sectionId);
  } else {
    conditions = state.conditions.filter((condition) => condition.questionId === ownProps.questionId);
  }

  let condition = {};
  if (conditions.length > 0) {
    condition = conditions[0];
  } else if (ownProps.isNew) {
    condition = {
      targetSectionId: ownProps.targetSectionId,
      targetQuestionId: ownProps.targetQuestionId,
      questionaireId: ownProps.questionaire.id,
      sectionId: ownProps.sectionId,
      questionId: ownProps.questionId,
      value: "",
      isNew: true,
      tenantId: ownProps.questionaire.tenantId,
    };
  } else {
    condition = {};
  }

  return {
    condition: { ...condition },
    sectionLookups: _.isEmpty(state.sections)?[]: Object.values(state.sections).filter((section) =>section.questionaireId === ownProps.questionaire.id && section.status !== "isDeleted"),
    questionLookups: _.isEmpty(state.questions)? []: Object.values(state.questions).filter((question) =>question.questionaireId === ownProps.questionaire.id &&question.status !== "isDeleted"),
    tenantId: state.tenantManagement.activeTenant.tenantId
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    fetchAllSectionsAction: (questionaireId, sectionId) =>dispatch(SECTION_ACTIONS.fetchSectionListWsAction(questionaireId, sectionId)),
  })
)(ConditionComponent);
