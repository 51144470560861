import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from "react-toastify";
import { Table, Button, Container, Divider, Grid, Confirm, Dimmer, Loader } from 'semantic-ui-react'
import { fetchLookupsAction } from '../../../actions/lookupsActions';
import FuseInputModal from '../../../components/generic/FuseInputModal';
import PlaceholderSegment from '../../../components/generic/PlaceholderSegment';

import * as QUESTIONNAIRE_ACTIONS from "../../../actions/questionaireActions";
import * as LOOKUP_ACTIONS from '../../../actions/lookupsActions';

export function LookupsPage(props) {
	const [state, setState] = useState({
		isEditLookupOpen: false,
		isEditValueOpen: false,
		selectedLookup: {},
		lookupValues: [],
		selectedValue: {},
		showConfirmDelete: false,
		deleteCandidate: {}
	});

	const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

	const canReadLookup = () => {
		return hasPermissions('Questionnaire Lookup Values') && props.userPermissions['Questionnaire Lookup Values'].actions.READ || isAdmin()
	}

	const canCreateLookup = () => {
		return hasPermissions('Questionnaire Lookup Values') && canReadLookup() && props.userPermissions['Questionnaire Lookup Values'].actions.CREATE ||isAdmin()
	}

	const canUpdateLookup = () => {
		return hasPermissions('Questionnaire Lookup Values') && canReadLookup() && props.userPermissions['Questionnaire Lookup Values'].actions.UPDATE || isAdmin()
	}

	const canDeleteLookup = () => {
		return hasPermissions('Questionnaire Lookup Values') && canReadLookup() && props.userPermissions['Questionnaire Lookup Values'].actions.DELETE || isAdmin()
	}

	useEffect(() => {
		if(props.isPermissionsFetched){
			if (canReadLookup()) {
				props.fetchLookupsAction(props.questionaire.id, props.questionaire.tenantId);
			}
			else{
				toast.error('You do not have permission to view lookup values');
			}
		}
	}, [])

	const onLookupSelectionChanged = (rowId) => {
		if (rowId !== 'new') {
			const lookups = props.lookups.filter(x => x.id === rowId);
			if (lookups.length > 0) {
				const lookup = lookups[0];
				setState((state) => ({
					...state,
					selectedLookup: lookup,
					lookupValues: valueObjectsFromString(lookup.values)
				}));
			}
			else {
				setState((state) => ({
					...state,
					selectedLookup: {},
					lookupValues: []
				}));
			}
		}
		else {
			setState((state) => ({
				...state,
				selectedLookup: {
					id: 0,
					keyName: ''
				},
				lookupValues: []
			}));
		}
	}

	const onEditLookupRow = (rowId) => {
		const isQuestionnaireActive = props.questionaire.status == "active" || props.questionaire.status == "underReview";

		if(isQuestionnaireActive){
			onLookupSelectionChanged(rowId)
			setState((state) => ({ ...state, showConfirmEditLookUp: true }));
		}else{
			onLookupSelectionChanged(rowId)
			setState((state) => ({ ...state, isEditLookupOpen: true }));
		}
	}

	const confirmEditLookUp = (rowId) => {
		let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";
	
		return (
		  <Confirm
			header='Warning:'
			content={contentMessage}
			open={state.showConfirmEditLookUp}
			onCancel={() => setState((state) => ({ ...state, showConfirmEditLookUp: false }))}
			onConfirm={() => {
				setState((state) => ({ ...state, isEditLookupOpen: true, showConfirmEditLookUp: false, deactiveQnaire: true }));
			}}
		  />
		);
	  }

	const onValueSelectionChanged = (valueId) => {
		if (valueId !== 'new') {
			const values = state.lookupValues;
			const value = values[valueId];
			setState((state) => ({
				...state,
				selectedValue: {
					id: valueId,
					value: value.value,
					questionaireId: value.questionaireId
				}
			}));
		}
		else {
			setState((state) => ({
				...state,
				selectedValue: {
					id: valueId,
					value: ''
				}
			}));
		}
	}

	const onEditValueRow = (rowId) => {
		const isQuestionnaireActive = props.questionaire.status == "active" || props.questionaire.status == "underReview";

		if(isQuestionnaireActive){
			onValueSelectionChanged(rowId)
			setState((state) => ({ ...state, showConfirmEditLookUpValue: true }));
		}else{
			onValueSelectionChanged(rowId)
			setState((state) => ({ ...state, isEditValueOpen: true }));
		}
	}

	const confirmEditLookUpValue = (rowId) => {
		let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";
	
		return (
		  <Confirm
			header='Warning:'
			content={contentMessage}
			open={state.showConfirmEditLookUpValue}
			onCancel={() => setState((state) => ({ ...state, showConfirmEditLookUpValue: false }))}
			onConfirm={() => {
				setState((state) => ({ ...state, isEditValueOpen: true, showConfirmEditLookUpValue: false, deactiveQnaire: true }));
			}}
		  />
		);
	  }

	const deactivateQnaire = () => {
		if(state.deactiveQnaire){
			props.deactiveQuestionaireAction(props.questionaire);
			setState((state) => ({ ...state, deactiveQnaire: false }));
		}
	}

	

	const valueObjectsFromString = (json) => {
		if (!_.isEmpty(json)) {
			return JSON.parse(json);
		}
		else {
			return [];
		}
	}

	const valueObjectsToString = (valueObjectArray) => {
		return JSON.stringify(valueObjectArray);
	}

	const confirmDeleteLookUpValue = () => {
		return (
			<Confirm
				content={`The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.`}
				open={state.showConfirmDeleteLookUpValue}
				onCancel={() => setState((state) => ({ ...state, showConfirmDeleteLookUpValue: false }))}
				onConfirm={() => {
					setState((state) => ({ ...state, deactiveQnaire: true, showConfirmDeleteLookUpValue: false,   }));
					updateValuesToLookup(state.lookupValues)
					props.deactiveQuestionaireAction(props.questionaire);
				}
				}
			/>
		);
	}

	const updateValuesToLookup = (values) => {
		var toCommit = state.selectedLookup;
		//Note that the intention here is not to update the old state, we just use it have an object to send to our action creator. State objects should only ever be modified using setState and not directly...
		toCommit.values = valueObjectsToString(values);
		props.saveLookupsAction(toCommit, props.questionaire.tenantId);
		setState((state) => ({ ...state, lookupValues: state.newlookupValues }));
	}

	const confirmDeleteLookup = () => {
		return (
			<Confirm
				content={`The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.`}
				open={state.showConfirmDeleteLookup}
				onCancel={() => setState((state) => ({ ...state, showConfirmDeleteLookup: false }))}
				onConfirm={() => {
					setState((state) => ({ ...state, showConfirmDeleteLookup: false, showConfirmDelete: true, deactiveQnaire: true }));
				}
				}
			/>
		);
	}
	
	const confirmDelete = () => {
		return (
			<Confirm
				content={`Are you sure you want to delete the following lookup with all its values: ${state.deleteCandidate.name}?`}
				open={state.showConfirmDelete}
				onCancel={() => setState((state) => ({ ...state, showConfirmDelete: false }))}
				onConfirm={() => {
					deactivateQnaire()
					var deleteLookup = state.deleteCandidate;
					props.deleteLookup(deleteLookup);
					onLookupSelectionChanged(0);
					setState((state) => ({ ...state, showConfirmDelete: false }));
				}
				}
			/>
		);
	}

	const lookupItems = () => {

		const isBaseQnaire = props.questionaire.isBaseQnaire;
    	const isRootTenant = props.tenant.isRoot === true || false;

		if (!props.fetchingData) {
			if (!_.isEmpty(props.lookups)) {
				props.lookups.sort((a, b) => a.name.localeCompare(b.name));
				return (
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Lookup Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg' />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{props.lookups.map(lookup => {
								return (
									<Table.Row key={lookup.id} className='clickable' onClick={() => onLookupSelectionChanged(lookup.id)} active={lookup.id === state.selectedLookup.id}>
										<Table.Cell>{lookup.name}</Table.Cell>
										<Table.Cell textAlign='right'>
										{ canUpdateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
											<Button compact className='warning' icon='edit' size='mini' onClick={(e) => { e.stopPropagation(); onEditLookupRow(lookup.id) }} />
										)}
										{ canDeleteLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
											<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
												e.stopPropagation();
												const isQuestionnaireActive = props.questionaire.status == "active" || props.questionaire.status == "underReview";

												if(isQuestionnaireActive){
													setState((state) => ({ ...state, deleteCandidate: lookup, showConfirmDeleteLookup: true }));
												}else{
													setState((state) => ({ ...state, deleteCandidate: lookup, showConfirmDelete: true }));
												}
											}} />
										)}
										</Table.Cell>
									</Table.Row>
								);
							})
							}
						</Table.Body>
					</Table>
				);
			}
			else {
				return (
					<PlaceholderSegment text="There are no lookup items yet" />
				);
			}
		}
		else {
			return (
				<div style={{ height: "200px", margin: "10px 0px" }}>
					<Dimmer inverted active>
						<Loader content='Loading' />
					</Dimmer>
				</div>
			);
		}
	}

	const lookupValues = () => {
		const isBaseQnaire = props.questionaire.isBaseQnaire;
    	const isRootTenant = props.tenant.isRoot === true || false;

		return state.lookupValues
			.map((lookupValue, index) =>
				<Table.Row key={`${lookupValue.id}.${index}`}>
					<Table.Cell>{lookupValue.value}</Table.Cell>
					<Table.Cell textAlign='right'>
						{ canUpdateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
						<Button compact className="warning" icon='edit' size='mini' onClick={(e) => { e.stopPropagation(); onEditValueRow(index); }} />
						)}
						{ canDeleteLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
						<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
							e.stopPropagation();
							var newstate = [...state.lookupValues];
							var itemIndex = newstate.indexOf(newstate[index]);

							if (itemIndex > -1) {
								newstate.splice(itemIndex, 1);
							}

							setState((state) => ({ ...state, newlookupValues: newstate }));

							const isQuestionnaireActive = props.questionaire.status == "active" || props.questionaire.status == "underReview";
							if(isQuestionnaireActive){
								setState((state) => ({ ...state, showConfirmDeleteLookUpValue: true }));
							}else{
								updateValuesToLookup(newstate)
							}
							onValueSelectionChanged(0)
						}} />
						)}
					</Table.Cell>
				</Table.Row>
			);
	}

	const lookupEdit = () => {
		if (state.isEditLookupOpen) {
			return (
				<FuseInputModal
					label='Lookup Name:'
					initialValue={state.selectedLookup.name}
					onOk={(valueChanged, returnValue) => {
						
						setState((state) => ({ ...state, isEditLookupOpen: false }));
						deactivateQnaire()

						if (valueChanged === true) {
							if(state.selectedLookup.id===0){
								let toCommit =_.omit({ ...state.selectedLookup }, ['id'])
								toCommit.questionaireId = props.questionaire.id;
								toCommit.name = returnValue;
								toCommit.status = 'active'
								props.saveLookupsAction(toCommit, props.questionaire.tenantId);
							}else{
								let toUpdate = state.selectedLookup
								toUpdate.name = returnValue
								props.saveLookupsAction(toUpdate, props.questionaire.tenantId);
							}
						}
					}
					}
					onCancel={() => {
						setState((state) => ({
							...state,
							isEditLookupOpen: false
						}));
					}}
				/>
			)
		}
	}

	const valueEdit = () => {
		if (state.isEditValueOpen === true) {
			return (
				<FuseInputModal
					label='Value:'
					initialValue={state.selectedValue.value}
					onOk={(valueChanged, returnValue) => {
						setState((state) => ({ ...state, isEditValueOpen: false }));
						deactivateQnaire()
						if (valueChanged === true) {
							
							var toCommit = state.selectedValue.id=='new'?_.omit({ ...state.selectedValue }, ['id']):state.selectedValue
							toCommit.value = returnValue;
							toCommit.questionaireId = props.questionaire.id;
							var newState = [...state.lookupValues];
							if (toCommit.id === undefined) { //We've created a new item
								newState.push(toCommit)
								setState((state) => ({ ...state, newlookupValues: newState }));
								updateValuesToLookup(newState)
							}
							else {
								newState[toCommit.id] = toCommit;
								setState((state) => ({ ...state, newlookupValues: newState }));
								updateValuesToLookup(newState)
							}
						}
					}
					}
					onCancel={() => {
						setState((state) => ({ ...state, isEditValueOpen: false }));
					}}
				/>
			)
		}
		else {
			return;
		}
	}

	const isBaseQnaire = props.questionaire.isBaseQnaire;
  	const isRootTenant = props.tenant.isRoot === true || false;

	const lookupValuesRender = () => {

		if (_.isEmpty(state.selectedLookup) || (state.selectedLookup.id < 1)) {
			return;
		}
		else {
			return (
				<div>
					<Divider />
					<Grid>
						<Grid.Row>
							<Grid.Column width='8'>
								<h3>{`${state.selectedLookup.name} Values:`}</h3>
							</Grid.Column>
							<Grid.Column width='8'>
							{ canCreateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
								<Button compact size='mini' icon='add' className='success' floated='right' onClick={(e) => { e.stopPropagation(); onEditValueRow('new') }} />
							)}
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Value</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'></Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{lookupValues()}
						</Table.Body>
					</Table>
				</div>
			);
		}
	}
	
	return (
		<Container fluid>
			{confirmDelete()}
			{confirmEditLookUp()}
			{confirmDeleteLookup()}
			{confirmEditLookUpValue()}
			{confirmDeleteLookUpValue()}
			<Grid>
				<Grid.Row>
					<Grid.Column width='8'>
						<h3>Lookups</h3>
					</Grid.Column>
					<Grid.Column width='8'>
					{ canCreateLookup() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
						<Button compact size='mini' icon='add' className='success' floated='right' onClick={(e) => { e.stopPropagation(); onEditLookupRow('new') }} /> 
					)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{lookupEdit()}
			{lookupItems()}
			{valueEdit()}
			{lookupValuesRender()}
		</Container>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		fetchingData: state.fetchingData === true,
		lookups: _.isEmpty(state.lookups) ? [] : Object.values(state.lookups).filter(lookup => lookup.questionaireId === ownProps.questionaire.id),
		tenant: state.tenantManagement.activeTenant,
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchLookupsAction: (questionaireId,tenantId) => dispatch(fetchLookupsAction(questionaireId,tenantId)),
		saveLookupsAction: (lookup, tenantId) => dispatch(LOOKUP_ACTIONS.lookupSaveAction(lookup, tenantId)),
		deactiveQuestionaireAction: (questionaire) => dispatch(QUESTIONNAIRE_ACTIONS.deactiveQuestionaireAction(questionaire)),
		deleteLookup: (lookup) => dispatch(LOOKUP_ACTIONS.lookupDeleteAction(lookup))
	})
)(LookupsPage);