import { fetchItemsWS } from '../webservices/fetchWS';


export const FETCHING_BASE_QNAIRES_ACTION = 'FETCHING_BASE_QNAIRES_ACTION';
export const FETCH_BASE_QNAIRES_SUCCESS_ACTION = 'FETCH_BASE_QNAIRES_SUCCESS_ACTION';
export const FETCH_BASE_QNAIRES_ERROR_ACTION = 'FETCH_BASE_QNAIRES_ERROR_ACTION';

export const fetchTenZeroBaseQnairesAction = (tenant) => {
    const queryParams = {
        GSI2PK: `${tenant.id}%23QNAIRES`,
        GSI2SK: 'QNAIRE%23',
    };

    return (dispatch, getState) => {
        dispatch({ type: FETCHING_BASE_QNAIRES_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
            .then((result) => {
                const returnItems = result.Items.filter(item => item.isBaseQnaire === true);
                dispatch({ type: FETCH_BASE_QNAIRES_SUCCESS_ACTION, payload: returnItems });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_BASE_QNAIRES_ERROR_ACTION, err });
            });
    };
}