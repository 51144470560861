import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { toast } from "react-toastify";
import { Table, Button, Container, Grid, Confirm, Dimmer, Loader, Segment, Header, Icon, Input, Pagination, Tab } from 'semantic-ui-react'

import FuseContentModal from '../../../components/generic/FuseContentModal';
import UserGroupDetails from './UserGroupsDetails';
import GroupUserDetails from './GroupUserDetails';
import GroupMessageDetails from './GroupMessageDetails';

import * as USER_GROUPS from '../../../actions/userGroupsActions';


export function UserGroupsPage(props) {
    const [state, setState] = useState({
        selectedUserGroup: {},
        selectedGroupUser: {},
        selectedGroupMessage: {},
        deleteUserGroupCandidate: {},
    });

    const [searchInputUser, setUserSearchInput] = useState('');
    const [searchInputMessage, setMessageSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);


    const hasPermissions = (category) => {
        return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
    };

    const isAdmin = () => {
        return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
    };

    const canReadUserGroup = () => {
        return hasPermissions('User Groups') && props.userPermissions['User Groups'].actions.READ || isAdmin();
    };

    const canCreateUserGroup = () => {
        return hasPermissions('User Groups') && canReadUserGroup() && props.userPermissions['User Groups'].actions.CREATE || isAdmin();
    };

    const canUpdateUserGroup = () => {
        return hasPermissions('User Groups') && canReadUserGroup() && props.userPermissions['User Groups'].actions.UPDATE || isAdmin();
    };

    const canDeleteUserGroup = () => {
        return hasPermissions('User Groups') && canReadUserGroup() && props.userPermissions['User Groups'].actions.DELETE || isAdmin();
    };

    const canReadGroupUsers = () => {
        return hasPermissions('User Groups') && props.userPermissions['User Groups'].actions['VIEW USERS'] || isAdmin();
    };

    const canCreateGroupUsers = () => {
        return hasPermissions('User Groups') && canReadGroupUsers() && props.userPermissions['User Groups'].actions['ADD USER'] || isAdmin();
    };

    const canUploadGroupUsers = () => {
        return hasPermissions('User Groups') && canReadGroupUsers() && props.userPermissions['User Groups'].actions['UPLOAD USERS'] || isAdmin();
    };

    const canUpdateGroupUsers = () => {
        return hasPermissions('User Groups') && canReadGroupUsers() && props.userPermissions['User Groups'].actions['UPDATE USER'] || isAdmin();
    };

    const canDeleteGroupUsers = () => {
        return hasPermissions('User Groups') && canReadGroupUsers() && props.userPermissions['User Groups'].actions['REMOVE USER'] || isAdmin();
    };

    const canReadGroupMessages = () => {
        return hasPermissions('User Groups') && canReadUserGroup() && props.userPermissions['User Groups'].actions['VIEW MESSAGS'] || isAdmin();
    };

    const canSendGroupMessages = () => {
        return hasPermissions('User Groups') && canReadGroupMessages() && props.userPermissions['User Groups'].actions['SEND MESSAGS'] || isAdmin();
    };

    useEffect(() => {
        if (!_.isEmpty(props.tenant)) {
            if (props.isPermissionsFetched) {
                if (canReadUserGroup()) {
                    props.fetchUserGroupsAction(props.tenant);
                } else {
                    toast.error('You do not have permission to view user groups.');
                }
            }
        }
    }, [props.tenant]);

    const confirmDeleteUserGroup = () => {
        return (
            <Confirm
                content={`Are you sure you want to delete the selected User Group: ${state.deleteUserGroupCandidate.name}?`}
                open={state.showConfirmDeleteuserGroup}
                onCancel={() => setState((state) => ({ ...state, showConfirmDeleteuserGroup: false }))}
                onConfirm={() => {
                    props.deleteUserGroupsAction(state.deleteUserGroupCandidate, props.tenant);
                    setState((state) => ({ ...state, showConfirmDeleteuserGroup: false, deleteUserGroupCandidate: {} }));
                }}
            />
        );
    };

    const handleUserGroupClick = (userGroup) => {
        if (userGroup.id == state.selectedUserGroup.id) return;
        props.clearLastEvaluatedKeyAction();
        props.clearSelectedUserGroupUsersAction();
        if (canReadGroupUsers()) {
            props.fetchGroupUserAction(userGroup);
        }
        if (canReadGroupMessages()) {
            props.fetchGroupMessageAction(userGroup);
        }
        setState((state) => ({ ...state, selectedUserGroup: userGroup }));
    };

    const userGroupRender = () => {
        if (!props.fetchingData) {
            if (!props.userGroups || props.userGroups.length === 0) {
                return (
                    <Segment>
                        <Header icon>
                            <Icon name='file' color="black" />
                            There Aren't Any User Groups
                        </Header>
                    </Segment>
                );
            }

            const sorteduserGroups = [...props.userGroups].sort((a, b) => a.name.localeCompare(b.name));

            return (
                <Table compact selectable size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='nectaPrimaryBg'>Group Name</Table.HeaderCell>
                            <Table.HeaderCell className='nectaPrimaryBg'>Group Description</Table.HeaderCell>
                            <Table.HeaderCell className='nectaPrimaryBg' />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {sorteduserGroups.map((userGroup, index) => (
                            <Table.Row
                                key={`${userGroup.id}.${index}`}
                                className='clickable'
                                active={userGroup.id === state.selectedUserGroup.id}
                            >
                                <Table.Cell onClick={() => handleUserGroupClick(userGroup)}>{userGroup.name}</Table.Cell>
                                <Table.Cell onClick={() => handleUserGroupClick(userGroup)}>{userGroup.description}</Table.Cell>
                                <Table.Cell>
                                    {canUpdateUserGroup() && (
                                        <FuseContentModal
                                            header={`Edit User Group: ${userGroup.name}`}
                                            content={<UserGroupDetails group={userGroup} bdaction={'edit'} />}
                                            size={"fullscreen"}
                                            trigger={<Button compact className="warning" icon='edit' size='small' />}
                                            onOpen={() => {
                                                setState((state) => ({ ...state, isEdituserGroupOpen: true, selectedUserGroup: userGroup }));
                                            }}
                                            onClose={() => {
                                                setState((state) => ({ ...state, isEdituserGroupOpen: false, selectedUserGroup: {} }));
                                            }}
                                        />)}
                                    {canDeleteUserGroup() && (
                                        <Button compact className='danger' icon='trash' size='small' onClick={(e) => deleteUserGroup(e, userGroup)} />
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                </Table>
            );
        } else {
            return (
                <div style={{ height: '200px', margin: '10px 0px' }}>
                    <Dimmer inverted active>
                        <Loader content='Loading' />
                    </Dimmer>
                </div>
            );
        }
    };

    const calculateFilteredAndPaginatedUserData = (allGroupUsers, currentPage) => {

        // const sortedGroupUsers = allGroupUsers.sort((a, b) => {
        //     if (a.name === null && b.name === null) {
        //         return 0;
        //     } else if (a.name === null) {
        //         return 1;
        //     } else if (b.name === null) {
        //         return -1;
        //     } else {
        //         return a.name.localeCompare(b.name);
        //     }
        // });

        const sortedGroupUsers = allGroupUsers;

        const ITEMS_PER_PAGE = 25;
        const totalPages = Math.ceil(sortedGroupUsers.length / ITEMS_PER_PAGE);
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        let paginatedGroupUsers = sortedGroupUsers;

        if (startIndex < sortedGroupUsers.length) {
            paginatedGroupUsers = sortedGroupUsers.slice(startIndex, endIndex);
        }
        return { paginatedGroupUsers, totalPages };
    };

    const handleSearch = () => {
        if (searchInputUser === '') {
            // props.fetchGroupUserAction(state.selectedUserGroup);
            setState((state) => ({ ...state, searchGroupUsers: [] }));
        } else {
            props.fetchSearchedUser(state.selectedUserGroup, searchInputUser);
        }
    };
    
    useEffect(() => {
        handleSearch();
    }, [searchInputUser]);

    const groupUsersRender = () => {

        const allGroupUsers = props.groupUsers;
        const { paginatedGroupUsers, totalPages } = calculateFilteredAndPaginatedUserData(allGroupUsers, currentPage);

        if (_.isEmpty(allGroupUsers) && _.isEmpty(state.selectedUserGroup)) {
            return null;
        }
    
        const fetchUsers = (pageNumber) => {
            props.fetchGroupUserAction(state.selectedUserGroup)
        };

        return (
            <Container fluid>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width='6'>
                            <h3>Users In Group: {state.selectedUserGroup.name}</h3>
                        </Grid.Column>
                        <Grid.Column width='10'>
                            <Input
                                type="number"
                                placeholder="Search users by their numbers"
                                value={searchInputUser}
                                onChange={(e) => setUserSearchInput(e.target.value)}
                                style={{ width: '60%', borderRadius: '5px' }}
                            />
                            {(canCreateGroupUsers() || canUploadGroupUsers()) && (
                                <FuseContentModal
                                    header={`Add User To Group`}
                                    content={<GroupUserDetails user={state.selectedGroupUser} group={state.selectedUserGroup} bdaction={'add'} />}
                                    trigger={<Button compact className="success" icon='add' size='small' floated='right' content="Add User/s" />}
                                    onClose={() => {
                                        setState((state) => ({ ...state, selectedGroupUser: state.selectedGroupUser }));
                                    }}
                                />
                            )}
                            {canReadGroupUsers() && (
                                <Button compact className='primary' icon='refresh' size='small' floated='right' onClick={(e) => {
                                    props.fetchGroupUserAction(state.selectedUserGroup);
                                }}></Button>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {_.isEmpty(props.groupUsers) && !_.isEmpty(state.selectedUserGroup) ? (
                    <Segment>
                        <Header>
                            <Icon name='user' color="black" />
                            No Users In The Selected Group
                        </Header>
                    </Segment>
                ) : (
                    <Table compact size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className='nectaPrimaryBg'>Name</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg'>Surname</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg'>Email</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg'>Number</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {(!_.isEmpty(props.searchGroupUsers) ? props.searchGroupUsers : paginatedGroupUsers).map((groupUser, index) => (
                                <Table.Row key={`${groupUser.id}.${index}`}>
                                    <Table.Cell>{groupUser.name}</Table.Cell>
                                    <Table.Cell>{groupUser.surname}</Table.Cell>
                                    <Table.Cell>{groupUser.email}</Table.Cell>
                                    <Table.Cell>+{groupUser.contact_nr}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {canUpdateGroupUsers() && (
                                            <FuseContentModal
                                                header={`Edit Group User: ${groupUser.name}`}
                                                content={<GroupUserDetails user={state.selectedGroupUser} group={state.selectedUserGroup} bdaction={'edit'} />}
                                                size={"fullscreen"}
                                                trigger={<Button compact className="warning" icon='edit' size='small' />}
                                                onOpen={() => {
                                                    setState((state) => ({ ...state, isEditGroupUserOpen: true, selectedGroupUser: groupUser }));
                                                }}
                                                onClose={() => {
                                                    setState((state) => ({ ...state, isEditGroupUserOpen: false, selectedGroupUser: {} }));
                                                }}
                                            />)}{canDeleteGroupUsers() && (
                                                <Button compact className='danger' icon='trash' size='small' onClick={(e) => deleteUserGroup(e, groupUser)} />
                                            )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>)}
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    {totalPages > 1 && (
                        <Pagination
                            activePage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(event, data) => setCurrentPage(data.activePage)}
                        />
                    )}
                </div>
                {/* {currentPage < totalPages && ( */}
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <Button onClick={fetchUsers}>Load More</Button>
                    </div>
                {/* )} */}
            </Container>
        );
    }

    const calculateFilteredAndPaginatedMessagesData = (allGroupMessages, searchInputMessage, currentPage) => {
        const filteredGroupMessages = allGroupMessages.filter((groupMessage) =>
            (groupMessage.message.title && groupMessage.message.title.toLowerCase().includes(searchInputMessage.toLowerCase())) ||
            (groupMessage.createdAt && moment(groupMessage.createdAt, 'YYYYMMDDTHHmmss').format('DD-MM-YYYY hh:mm:ss a').toLowerCase().includes(searchInputMessage.toLowerCase()))
        );

        const sortedGroupMessages = filteredGroupMessages.sort((a, b) => {
            return moment.utc(b.createdAt).valueOf() - moment.utc(a.createdAt).valueOf();
        });

        const ITEMS_PER_PAGE = 50;
        const totalPages = Math.ceil(sortedGroupMessages.length / ITEMS_PER_PAGE);
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        let paginatedGroupMessages = sortedGroupMessages;

        if (startIndex < sortedGroupMessages.length) {
            paginatedGroupMessages = sortedGroupMessages.slice(startIndex, endIndex);
        }
        return { paginatedGroupMessages, totalPages };
    };


    const userGroupMessagesRender = () => {
        const allGroupMessages = props.groupMessages;
        const { paginatedGroupMessages, totalPages } = calculateFilteredAndPaginatedMessagesData(allGroupMessages, searchInputMessage, currentPage);

        if (_.isEmpty(allGroupMessages) && _.isEmpty(state.selectedUserGroup)) {
            return null;
        }

        return (
            <Container fluid>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width='8'>
                            <h3>Messages Sent To Group: {state.selectedUserGroup.name}</h3>
                        </Grid.Column>
                        <Grid.Column width='8'>
                            <Input
                                type="text"
                                placeholder="Search message by title or date sent"
                                value={searchInputMessage}
                                onChange={(e) => setMessageSearchInput(e.target.value)}
                                style={{ width: '65%', borderRadius: '5px' }}
                            />
                            {(canSendGroupMessages()) && (
                                <FuseContentModal
                                    header={`Send Message to Users In Group: ${state.selectedUserGroup.name}`}
                                    content={<GroupMessageDetails message={state.selectedGroupMessage} group={state.selectedUserGroup} bdaction={'add'} />}
                                    trigger={<Button compact className="success" icon='paper plane' size='small' floated='right' content="Send Message" />}
                                    onClose={() => {
                                        setState((state) => ({ ...state, selectedGroupMessage: state.selectedGroupMessage }));
                                    }}
                                />
                            )}
                            {canReadGroupMessages() && (
                                <Button compact className='primary' icon='refresh' size='small' floated='right' onClick={(e) => {
                                    props.fetchGroupMessageAction(state.selectedUserGroup);
                                }}></Button>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {_.isEmpty(props.groupMessages) && !_.isEmpty(state.selectedUserGroup) ? (
                    <Segment>
                        <Header>
                            <Icon name='mail' color="black" />
                            No Messages Have Been Sent To This Group
                        </Header>
                    </Segment>
                ) : (
                    <Table compact size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '20%' }}>Message Title</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '60%' }}>Message Body</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '20%' }}>Date Sent</Table.HeaderCell>
                                <Table.HeaderCell className='nectaPrimaryBg' />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {paginatedGroupMessages.map((groupMessage, index) => (
                                <Table.Row key={`${groupMessage.id}.${index}`}>
                                    <Table.Cell>{groupMessage.message.title}</Table.Cell>
                                    <Table.Cell>{groupMessage.message.body}</Table.Cell>
                                    <Table.Cell>{moment.utc(groupMessage.createdAt).format("DD-MM-YYYY hh:mm:ss a")}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {canUpdateGroupUsers() && (
                                            <FuseContentModal
                                                header={`View Message: ${groupMessage.message.title}`}
                                                content={<GroupMessageDetails group={state.selectedUserGroup} message={state.selectedGroupMessage} bdaction={'view'} />}
                                                size={"fullscreen"}
                                                trigger={<Button compact className="primary" icon='eye' size='medium' />}
                                                onOpen={() => {
                                                    setState((state) => ({ ...state, isViewGroupMessageOpen: true, selectedGroupMessage: groupMessage }));
                                                }}
                                                onClose={() => {
                                                    setState((state) => ({ ...state, isViewGroupMessageOpen: false, selectedGroupMessage: {} }));
                                                }}
                                            />)}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>)}
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    {totalPages > 1 && (
                        <Pagination
                            activePage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(event, data) => setCurrentPage(data.activePage)}
                        />
                    )}
                </div>
            </Container>
        )
    }

    const deleteUserGroup = (e, userGroup) => {
        e.stopPropagation();
        setState((state) => ({ ...state, deleteUserGroupCandidate: userGroup, showConfirmDeleteuserGroup: true }));
    };

    const tabListRenderer = () => {
        if (_.isEmpty(state.selectedUserGroup) || (state.selectedUserGroup.id < 1)) {
            return;
        }

        const tabs = [];
        let allowedToViewUsers = false;
        let allowedToViewMessages = false;

        if (canReadGroupUsers()) {
            allowedToViewUsers = true;
        }

        if (canReadGroupMessages()) {
            allowedToViewMessages = true;
        }

        if (allowedToViewUsers) {
            tabs.push({
                menuItem: "Users",
                render: () => <Tab.Pane>{groupUsersRender()}</Tab.Pane>,
            });
        }

        if (allowedToViewMessages) {
            tabs.push({
                menuItem: "Messages",
                render: () => <Tab.Pane>{userGroupMessagesRender()}</Tab.Pane>,
            });
        }


        return <Tab
            menuPosition='left'
            defaultActiveIndex={0}
            renderActiveOnly={true}
            panes={tabs}
        />;
    }

    return (
        <Container fluid>
            {confirmDeleteUserGroup()}
            <Grid>
                <Grid.Row>
                    <Grid.Column width='8'>
                        <h3>User Groups: </h3>
                    </Grid.Column>
                    <Grid.Column width='8'>
                        {canCreateUserGroup() && (
                            <FuseContentModal
                                header={`Create User Group`}
                                content={<UserGroupDetails group={state.selectedUserGroup} bdaction={'add'} />}
                                trigger={<Button compact className="success" icon='add' size='small' floated='right' />}
                                onClose={() => {
                                    setState((state) => ({ ...state, selectedUserGroup: state.selectedUserGroup }));
                                }}
                            />)}
                        {canReadUserGroup() && (
                            <Button compact className='primary' icon='refresh' size='small' floated='right' onClick={(e) => {
                                props.fetchUserGroupsAction(props.tenant);
                            }}></Button>)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {userGroupRender()}
            <br></br>
            {tabListRenderer()}
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        fetchingData: state.fetchingData === true,
        tenant: state.tenantManagement.activeTenant,
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        userGroups: state.userGroups,
        groupUsers: state.groupUsers,
        groupMessages: state.groupMessages,
        searchGroupUsers: state.searchGroupUsers
    }
}

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch, ownProps) => ({
        fetchUserGroupsAction: (tenant) => dispatch(USER_GROUPS.fetchUserGroupsAction(tenant)),
        deleteUserGroupsAction: (group, tenant) => dispatch(USER_GROUPS.deleteUserGroupsAction(group,tenant)),
        fetchGroupUserAction: (group,) => dispatch(USER_GROUPS.fetchGroupUserAction(group,)),
        clearLastEvaluatedKeyAction: () => dispatch(USER_GROUPS.clearLastEvaluatedKeyAction()),
        clearSelectedUserGroupUsersAction: () => dispatch(USER_GROUPS.clearSelectedUserGroupUsersAction()),
        fetchGroupMessageAction: (group) => dispatch(USER_GROUPS.fetchGroupMessageAction(group)),
        fetchSearchedUser: (group, term) => dispatch(USER_GROUPS.fetchSearchedUserAction(group, term)),
    })
)(UserGroupsPage);