import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Table, Button, Container, Divider, Grid, Confirm, Dimmer, Loader, Checkbox } from 'semantic-ui-react';
import FuseContentModal from '../../../components/generic/FuseContentModal';
import PlaceholderSegment from '../../../components/generic/PlaceholderSegment';
import BrTypeDetails from './BrTypeDetails';
import BrTypeAttrDetails from './BrTypeAttrDetails';
import * as BRTYPE_ACTIONS from '../../../actions/businessRuleTypeActions';
import * as QUESTIONAIRE_ACTIONS from '../../../actions/questionaireActions';
import * as MICROSERVICE_ACTION from "../../../actions/microServiceAction"



export function BrTypesPage(props) {
	const [state, setState] = useState({
		brType: null,
		selectedBrType: false,
		fetchingData: false,
		showConfirmDelete: false,
		deleteCandidate: {}
	});

	const hasPermissions= (category) => { 
		return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category)
	}

	const isAdmin = () => {
		return hasPermissions('Other') && props.userPermissions['Other'].actions.admin
	}

	const canReadBRTypes = () => {
		return hasPermissions('Business Rule Types') && props.userPermissions['Business Rule Types'].actions.READ || isAdmin()
	}

	const canCreateBRTypes = () => {
		return hasPermissions('Business Rule Types') && canReadBRTypes() && props.userPermissions['Business Rule Types'].actions.CREATE ||isAdmin()
	}

	const canUpdateBRTypes = () => {
		return hasPermissions('Business Rule Types') && canReadBRTypes() && props.userPermissions['Business Rule Types'].actions.UPDATE || isAdmin()
	}

	const canDeleteBRTypes = () => {
		return hasPermissions('Business Rule Types') && canReadBRTypes() && props.userPermissions['Business Rule Types'].actions.DELETE || isAdmin()
	}

	useEffect(() => {
		if (props.authData.length > 0){
			if(props.isPermissionsFetched){
				if (canReadBRTypes()) {
					props.fetchBrTypesAction(props.tenantId);
					props.fetchQuestionairesAction(props.tenantId);
					props.fetchMircoServices();
				}
				else{
					toast.error('You do not have permission to view business rule types');
				}
			}
		}
	}, [props.tenantId])
	
	const onBrTypeSelectionChanged = (rowId) => {
		if (rowId !== 'new') {
			const brTypes = props.brTypes.filter(x => x.id === rowId);
			if (brTypes.length > 0) {
				const brType = brTypes[0];
				setState((state) => ({
					...state,
					selectedBrType: brType,
					brTypeAttributes: _.orderBy(valueBrTypeObjectsFromString(brType.brTypeAttributes), ['attrName'])
				}));
			}
			else {
				setState((state) => ({
					...state,
					selectedBrType: {},
					brTypeAttributes: []
				}));
			}
		}
		else {
			setState((state) => ({
				...state,
				selectedBrType: {
					id: 0,
					keyName: ''
				},
				brTypeAttributes: []
			}));
		}
	}

	const onAttrBrTypeSelectionChanged = (valueId) => {
		if (valueId !== 'new') {
			const values = state.brTypeAttributes;
			const value = values[valueId];
			setState((state) => ({
				...state,
				selectedBrTypeValue: {
					id: valueId,
					attrName: value.attrName,
					isMandatory: value.isMandatory,
					isUpload: value.isUpload == undefined? true:value.isUpload,
				}
			}));
		}
		else {
			setState((state) => ({
				...state,
				selectedBrTypeValue: {
					id: valueId,
					attrName: '',
					isMandatory: '',
					isUpload: false,
				}
			}));
		}
	}

	const onEditBrTypeRow = (rowId) => {
		onBrTypeSelectionChanged(rowId)
		setState((state) => ({ ...state, isEditBrTypeOpen: true }));
	}

	const valueBrTypeObjectsFromString = (json) => {
		if (!_.isEmpty(json)) {
			return JSON.parse(json);
		}
		else {
			return [];
		}
	}

	const valueBrTypeObjectsToString = (valueBrTypeObjectArray) => {
		return JSON.stringify(valueBrTypeObjectArray);
	}

	const updateValuesToBrType = (values) => {
		var toCommit = state.selectedBrType;
		//Note that the intention here is not to update the old state, we just use it have an object to send to our action creator. State objects should only ever be modified using setState and not directly...
		toCommit.brTypeAttributes = valueBrTypeObjectsToString(values);
		props.commitBrType(toCommit);
	}

	const confirmDelete = () => {
		return (
			<Confirm
				content={`Are you sure you want to delete the following Business Rule Type with all its attributes: ${state.deleteCandidate.brType}?`}
				open={state.showConfirmDelete}
				onCancel={() => setState((state) => ({ ...state, showConfirmDelete: false }))}
				onConfirm={() => {
					var deleteBrType = state.deleteCandidate;
					props.deleteBrType(deleteBrType);
					onBrTypeSelectionChanged(0);
					setState((state) => ({ ...state, showConfirmDelete: false }));
				}
				}
			/>
		);
	}

	const BrTypeItems = () => {
		if (!props.fetchingData) {
			if (!_.isEmpty(props.brTypes)) {
				const filteredBrTypes = props.brTypes
				  .filter((brType) => brType.tenantId === props.tenantId )
				  .sort((a, b) => a.brType.localeCompare(b.brType));
				return (
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Business Rule Type Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Business Rule Type Description</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Target Questionnaire</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Micro Service</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>Powerpoint Type</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg' />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{filteredBrTypes.map(BrType => {
								return (
									<Table.Row key={BrType.id} className='clickable' onClick={() => onEditBrTypeRow(BrType.id)} active={BrType.id === state.selectedBrType.id}>
										<Table.Cell>{BrType.brType}</Table.Cell>
										<Table.Cell>{BrType.brTypeDesc}</Table.Cell>
										<Table.Cell>{BrType.targetQnaireName}</Table.Cell>
										<Table.Cell>{BrType.microService}</Table.Cell>
										<Table.Cell>{BrType.pptxType}</Table.Cell>
										<Table.Cell textAlign='right'>
										{canUpdateBRTypes()&& (
										<FuseContentModal
											header={`Edit Business Rule Type: ${state.selectedBrType.brType}`}
											content={<BrTypeDetails brType={state.selectedBrType} brTypeAttributes={state.brTypeAttributes} braction={'edit'}/>}
											trigger={<Button compact className="warning" icon='edit' size='mini' />}
											onClose={() => {setState((state) => ({ ...state, brType: state.brType}));;}}									
										/>)}
										{canDeleteBRTypes()&& (
										<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
											e.stopPropagation();
											setState((state) => ({ ...state, deleteCandidate: BrType, showConfirmDelete: true }));
										}} />)}</Table.Cell>
									</Table.Row>
								);
							})
							}
						</Table.Body>
					</Table>
				);
			}
			else {
				return (
					<PlaceholderSegment text="There are no Business Rule Types" />
				);
			}
		}
		else {
			return (
				<div style={{ height: "200px", margin: "10px 0px" }}>
					<Dimmer inverted active>
						<Loader content='Loading' />
					</Dimmer>
				</div>
			);
		}
	}

	const BrTypeAttributes = () => {
		return state.brTypeAttributes
			.map((BrTypeAttrValue, index) =>
				<Table.Row key={`${BrTypeAttrValue.id}.${index}`}>
					<Table.Cell>{BrTypeAttrValue.attrName}</Table.Cell>
					<Table.Cell><Checkbox checked={BrTypeAttrValue.isMandatory}/></Table.Cell>
					<Table.Cell><Checkbox checked={BrTypeAttrValue.isUpload==undefined?false:BrTypeAttrValue.isUpload}/></Table.Cell>
					<Table.Cell textAlign='right'>
						{canUpdateBRTypes()&& (
						<FuseContentModal
							header={`Edit Attribute Values for: ${state.selectedBrType.brType}`}
							content={<BrTypeAttrDetails BrType={state.selectedBrType} BrTypeValues={state.brTypeAttributes} braction={'edit'} valIndex={index}/>}
							trigger={<Button compact className="warning" icon='edit' size='mini' />}
							onClose={() => {setState((state) => ({ ...state, brTypeAttributes: valueBrTypeObjectsFromString(state.selectedBrType.brTypeAttributes) }));;}}									
						/>)}
						{canDeleteBRTypes()&& (
						<Button compact className='danger' icon='trash' size='mini' onClick={(e) => {
							e.stopPropagation();
							var newstate = [...state.brTypeAttributes];
							var itemIndex = newstate.indexOf(newstate[index]);

							if (itemIndex > -1) {
								newstate.splice(itemIndex, 1);
							}

							setState((state) => ({ ...state, brTypeAttributes: _.orderBy(newstate, ["attrName"]) }));
							updateValuesToBrType(newstate)
							onAttrBrTypeSelectionChanged(0)
						}} />)}
						</Table.Cell>
				</Table.Row>
			);
	}

	const BrTypeAttributesRender = () => {
		if (_.isEmpty(state.selectedBrType) || (state.selectedBrType.id < 1)) {
			return;
		}
		else {
			return (
				<div>
					<Divider />
					<Grid>
						<Grid.Row>
							<Grid.Column width='8'>
								<h3>{`Attribute Values for: ${state.selectedBrType.brType}`}</h3>
							</Grid.Column>
							<Grid.Column width='8'>
								{canCreateBRTypes()&& (
								<FuseContentModal
									header={`Add Attribute Values for: ${state.selectedBrType.brType}`}
									content={<BrTypeAttrDetails BrType={state.selectedBrType} BrTypeValues={state.BrTypeValues}  braction={'add'}/>}
									trigger={<Button compact size='mini' icon='add' className='success' floated='right'/>}
									onClose={() => {setState((state) => ({ ...state, brTypeAttributes: valueBrTypeObjectsFromString(state.selectedBrType.brTypeAttributes) }));;}}									
								/>)}
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Table compact selectable size='small'>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className='nectaPrimaryBg'>Attribute Name</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>is Mandatory?</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'>is Upload?</Table.HeaderCell>
								<Table.HeaderCell className='nectaPrimaryBg'></Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{BrTypeAttributes()}
						</Table.Body>
					</Table>
				</div>
			);
		}
	}

	return (
		<Container fluid>
			{confirmDelete()}
			<Grid>
				<Grid.Row>
					<Grid.Column width='8'>
						<h3>Business Rule Types</h3>
					</Grid.Column>
					<Grid.Column width='8'>
					{canCreateBRTypes()&& (
					<FuseContentModal
						header={`Add New Business Rule Type`}
						content={<BrTypeDetails brType={state.selectedBrType} brTypeAttributes={state.brTypeAttributes} braction={'add'}/>}
						trigger={<Button compact className="success" icon='add' floated='right'/>}
						onClose={() => {setState((state) => ({ ...state, brType: state.brType}));;}}									
					/>)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{BrTypeItems()}
			{BrTypeAttributesRender()}
		</Container>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		fetchingData: state.fetchingData === true,
		brTypes: _.isEmpty(state.brTypes) ? [] : Object.values(state.brTypes),
		brTypeAttributes: _.isEmpty(state.brType) ? [] : _.isEmpty(state.brType.brTypeAttributes) ? [] : Object.values(state.brType.brTypeAttributes),
		microservices: (_.isEmpty(state.microServices)) ? [] : Object.values(state.microServices),
		tenantId: state.tenantManagement.activeTenant.tenantId,
		authData: _.isEmpty(state.authData) ? [] : Object.values(state.authData),
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
		isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchQuestionairesAction: (tenantId) => dispatch(QUESTIONAIRE_ACTIONS.fetchQuestionairesAction(tenantId)),
		fetchBrTypesAction: (tenantId) => dispatch(BRTYPE_ACTIONS.fetchbrTypesAction(tenantId)),
		commitBrType: (brType, isNewItem) => dispatch(BRTYPE_ACTIONS.brTypeSaveAction(brType, isNewItem)),
		deleteBrType: (brType) => dispatch(BRTYPE_ACTIONS.brTypeDeleteAction(brType)),
		fetchMircoServices: () => dispatch(MICROSERVICE_ACTION.fetchMicroServiceAction()),
	})
)(BrTypesPage);