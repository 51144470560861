import { toast } from "react-toastify";
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { createItemWs, updateItemWs } from "../webservices/saveWS";
import { SectionDTO } from "./DTOs/SectionDTO";
import { ConditionDTO } from "./DTOs/ConditionDTO";
import { fetchItemsWS } from "../webservices/fetchWS";

export const FETCHING_SECTIONS_ACTION = "FETCHING_SECTIONS_ACTION";
export const FETCH_SECTIONS_SUCCESS_ACTION = "FETCH_SECTIONS_SUCCESS_ACTION";
export const FETCH_SECTIONS_ERROR_ACTION = "FETCH_SECTIONS_ERROR_ACTION";

export const SECTION_ADD_ACTION = "SECTION_ADD_ACTION";
export const SECTION_EDIT_ACTION = "SECTION_EDIT_ACTION";
export const SECTION_DELETE_ACTION = "SECTION_DELETE_ACTION";
export const CONDITION_ADD_ACTION = "CONDITION_ADD_ACTION";
export const CONDITION_EDIT_ACTION = "CONDITION_EDIT_ACTION";

export const fetchSectionsAction = (questionaireId, tenantId) => {
  const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${questionaireId}`,
    SK: 'SECTION',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_SECTIONS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_SECTIONS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_SECTIONS_ERROR_ACTION, err });
      });
  };
}

export const fetchSectionListWsAction = (questionaireId, tenantId) => {
  const queryParams = {
    PK: `${tenantId}%23QNAIRE%23${questionaireId}`,
    SK: 'SECTION',
  };

  return (dispatch, getState) => {
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
        dispatch({ type: FETCH_SECTIONS_SUCCESS_ACTION, payload: returnItems });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_SECTIONS_ERROR_ACTION, err });
      });
  };
}

export const sectionSaveAction = (section, condition, isNewItem, tenantId) => {
  return (dispatch, getState) => {
    const saveObject = new SectionDTO(section);
    saveObject.sequence = parseInt(saveObject.sequence);
    if (section.sectionId != null) {
      saveObject.parentSectionId = section.sectionId;
    }
    saveObject.status = saveObject.isActive ? "active" : "inactive";
    dispatch({ type: SAVE_IN_PROGRESS });
    if (isNewItem === true) {
      createItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: SECTION_ADD_ACTION, payload: result.data });
          if (section.isConditional && condition.id === undefined) {
            condition.PK = saveObject.SK;
            condition.questionaireId = section.questionaireId;
            condition.sectionId = result.data.id;
            condition.status = "active";
            condition.sectionCondition = true;
            const conditionObject = new ConditionDTO(condition , tenantId);
            createItemWs(conditionObject,  getState)
              .then((conditionResult) => {
                dispatch({
                  type: CONDITION_EDIT_ACTION,
                  payload: conditionResult.data,
                });
                dispatch({ type: SAVE_PROCESS_DONE });
              })
              .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
              });
          } else if (section.isConditional) {
            condition.status = "active";
            const conditionObject = new ConditionDTO(condition , tenantId);
            updateItemWs(conditionObject,  getState)
              .then((conditionResult) => {
                dispatch({
                  type: CONDITION_EDIT_ACTION,
                  payload: conditionResult.data,
                });
                dispatch({ type: SAVE_PROCESS_DONE });
              })
              .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
              });
          }
          dispatch({ type: SAVE_PROCESS_DONE });
          if (isNewItem)
            toast.success("The new section has been added successfully");
          else
            toast.success("The section has been updated successfully");
          dispatch({ type: SAVE_PROCESS_DONE });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to add new section please try again");
        });
    }else{
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: SECTION_EDIT_ACTION, payload: result.data });
          if (section.isConditional && condition.id === undefined) {
            condition.PK = saveObject.SK;
            condition.questionaireId = section.questionaireId;
            condition.sectionId = result.data.id;
            condition.status = "active";
            condition.sectionCondition = true;
            const conditionObject = new ConditionDTO(condition , tenantId);
            createItemWs(conditionObject, getState)
              .then((conditionResult) => {
                dispatch({
                  type: CONDITION_EDIT_ACTION,
                  payload: conditionResult.data,
                });
                dispatch({ type: SAVE_PROCESS_DONE });
              })
              .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
              });
          } else if (section.isConditional) {
            condition.status = "active";
            const conditionObject = new ConditionDTO(condition , tenantId);
            updateItemWs(conditionObject, getState)
              .then((conditionResult) => {
                dispatch({
                  type: CONDITION_EDIT_ACTION,
                  payload: conditionResult.data,
                });
                dispatch({ type: SAVE_PROCESS_DONE });
              })
              .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
              });
          }
          dispatch({ type: SAVE_PROCESS_DONE });
          if (isNewItem)
            toast.success("The new section has been added successfully");
          else
            toast.success("The section has been updated successfully");
          dispatch({ type: SAVE_PROCESS_DONE });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error("Failed to add new section please try again");
        });
    };
  }
}

export const sectionDeleteAction = (section) => {
  return (dispatch, getState) => {
    const saveObject = new SectionDTO(section);
    saveObject.sequence = parseInt(saveObject.sequence);
    saveObject.status = "isDeleted";

    updateItemWs(saveObject, getState)
      .then((result) => {
        dispatch({ type: SECTION_DELETE_ACTION, payload: result.data.id });
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success("The section has been deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error("Failed to delete the section please try again");
      });
  };
};
