import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from "react-toastify";
import { Segment, Button, List } from "semantic-ui-react";
import QuestionaireQuestionItem from './QuestionaireQuestionItem';
import FuseContentModal from '../../../../components/generic/FuseContentModal'
import QuestionDetails from './QuestionDetails'
import * as QUESTION_ACTIONS from '../../../../actions/questionActions';
import * as CONDITION_ACTIONS from '../../../../actions/conditionActions';

class QuestionaireQuestionList extends Component {

	static defaultProps = {
		questions: [],
		questionaire: {}
	}

	hasPermissions(category){
		return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category)
	}

	isAdmin(){
		return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin
	}

  	canReadQuestions(){
		return this.hasPermissions('Questions') && this.props.userPermissions['Questions'].actions.READ || this.isAdmin()
	}

  	canCreateQuestions(){
		return this.hasPermissions('Questions') && this.props.userPermissions['Questions'].actions.CREATE || this.isAdmin()
	}

	canUpdateQuestions(){
		return this.hasPermissions('Questions') && this.props.userPermissions['Questions'].actions.UPDATE || this.isAdmin()
	}

	canDeleteQuestions(){
		return this.hasPermissions('Questions') && this.props.userPermissions['Questions'].actions.DELETE || this.isAdmin()
	}

	componentDidMount() {
		if(this.props.isPermissionsFetched){
			if (this.canReadQuestions()) {
				this.props.fetchQuestionsAction(this.props.section.questionaireId, this.props.section.id, this.props.section.tenantId);
			}
			else{
				toast.error('You do not have permission to view questions');
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if ((!_.isEmpty(this.props.section) && _.isEmpty(prevProps.section)) || (!_.isEmpty(this.props.section) && this.props.section.id !== prevProps.section.id)) {
			if(this.props.isPermissionsFetched){
				if (this.canReadQuestions()) {
					this.props.fetchQuestionsAction(this.props.section.questionaireId, this.props.section.id);
				}
				else{
					toast.error('You do not have permission to view questions');
				}
			}	
		}
	}

	listOfQuestions = () => {
		return this.props.questions
			.map(question =>
				<QuestionaireQuestionItem key={question.id} questionaire={this.props.questionaire} question={question} condition={this.props.condition}/>
			);
	}

	render() {
		const isBaseQnaire = this.props.questionaire.isBaseQnaire;
    	const isRootTenant = this.props.tenant.isRoot === true || false;
		return (
			<List animated divided>
				{this.listOfQuestions()}
				<List.Item key='addNew'>
					<Segment vertical textAlign='center' className='clickable'>
					{ this.canCreateQuestions() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
						<FuseContentModal
							header={`Add question in: ${this.props.section.displayName}`}
							content={<QuestionDetails questionaire={this.props.questionaire} question={{sectionId: this.props.section.id}} sections={this.props.sections}/>}
							trigger={<Button circular icon='add' className='success' />}
							tooltip={`Add question in: ${this.props.section.displayName}`}
						/>
					)}
					</Segment>
				</List.Item>
			</List>
		);
	}

}

const mapStateToProps = (state, ownprops) => {
	return {
		questionaire: _.isEmpty(state.questionaires) ? {} : Object.values(state.questionaires).filter(questionaire => questionaire.id === ownprops.section.questionaireId)[0],
		questions: _.isEmpty(state.questions) ? [] : _.orderBy(Object.values(state.questions).filter(question => question.sectionId === ownprops.section.id && question.status !== 'isDeleted'), ['sequence']),
		sections: _.isEmpty(state.sections)?[]:_.orderBy(Object.values(state.sections)),
		tenant: state.tenantManagement.activeTenant,
		userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    	isPermissionsFetched: state.isPermissionsFetched === true,
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchQuestionsAction: (questionaireId, sectionId, tenantId) => dispatch(QUESTION_ACTIONS.fetchQuestionsAction(questionaireId, sectionId, tenantId)),
	})
)(QuestionaireQuestionList);