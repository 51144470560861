export class SectionDTO {
    PK;
    SK;
    id;
    type;
    isActive;
    status;
    createdAt;
    updatedAt;
    name;
    questionaireId;
    sequence;
    displayName;
    isMandatory;
    canRepeat;
    isUserCreated;
    extraInfo;
    parentSectionId;
    isConditional;
    tenantId;

    constructor(data){
    if (data.id != null) {
        this.PK = data.PK;
        this.SK = data.SK;
    }
    else {
        this.PK = `${data.tenantId}#QNAIRE#${data.questionaireId}`;
        this.SK = "SECTION#";
    }
    this.parentSectionId = data.parentSectionId;
    this.id = data.id;
    this.type = 'SECTION';
    this.isActive = data.isActive;
    this.status = "active";
    this.name = data.name;
    this.questionaireId = data.questionaireId;
    this.sequence = data.sequence;
    this.displayName = data.displayName;
    this.isMandatory = data.isMandatory;
    this.canRepeat = data.canRepeat;
    this.isUserCreated = data.isUserCreated;
    this.extraInfo = data.extraInfo;
    this.isConditional = data.isConditional;
    this.tenantId = data.tenantId;
    }
}
