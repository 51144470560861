import {
	FETCH_GROUP_MESSAGES_SUCCESS_ACTION,
	GROUP_MESSAGE_ADD_ACTION,
	// GROUP_USER_EDIT_ACTION,
	// GROUP_USER_DELETE_ACTION
} from '../actions/userGroupsActions';

export const groupMessagesReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_GROUP_MESSAGES_SUCCESS_ACTION:
			const newUserGroupMessages = action.payload;
			// const oldUserGroupMembers = Object.values({ ...state }).filter(userGroupMember => !newUserGroupMembers.some(newUserGroupMember => newUserGroupMember.id === userGroupMember.id));
			// const newUserGroupMemberstate = oldUserGroupMembers.concat(newUserGroupMembers);
			return newUserGroupMessages;
		case GROUP_MESSAGE_ADD_ACTION:
			const clonedState = [...state];
			clonedState.push(action.payload);
			return clonedState;
		// case GROUP_USER_EDIT_ACTION:
		// 	const newUserGroupMember = action.payload;
		// 	const userGroupMembersCopy = Object.values({ ...state }).filter(userGroupMember => !(newUserGroupMember.id === userGroupMember.id));
		// 	userGroupMembersCopy.push(newUserGroupMember);
		// 	return userGroupMembersCopy;
		// case GROUP_USER_DELETE_ACTION:
		// 	return state.filter(element => element.id !== action.payload);
		default:
			return state;
	}
};