import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Segment, Button, Header, Icon, Tab , Label, Dimmer, Loader, Divider } from "semantic-ui-react";
import _ from 'lodash';
import { useHistory} from "react-router-dom";
import QuestionaireSideMenu from './QuestionaireSideMenu';
import QuestionaireSectionComponent from './QuestionaireSectionComponent';
import LookupsPage from '../../lookupsPage/LookupsPage';
import LookupsQnairePage from '../../lookupsQnairePage/LookupsQnairePage';
import BRQnairePage from '../../brQnairePage/BRQnairePage';
import TestQuestionnaireModal from './TestQuestionnaireModal';
import FuseContentModal from '../../../../components/generic/FuseContentModal';

import * as TEST_QNAIRE_ACTIONS from "../../../../actions/testQuestionnaireActions"

export function StructuredQuestionairePage(props) {
	const history = useHistory();
	const [state, setState] = useState({
	  questionaire: { ...props.selectedQuestionaire },
	  selectedQuestionaireMainSection: null,
	  sections: { ...props.sections },
	  fetchingData: false,
	  currentPage: 0,
	  pageSize: 10,
	  isTestModalOpen: false,
	  questionaireFilter: {
		key: "All",
		text: "Filter",
		value: -1
	  }
	});

	useEffect(() => {
    if(state.questionaire === undefined || _.isEmpty(state.questionaire)) {
      history.goBack();
    }
  }, []);

  useEffect(() => {
	if(state.selectedQuestionaireMainSection != null){
    const found = props.sections.find(e => e.id == state.selectedQuestionaireMainSection.id);
	onMainSectionSelected(found);
	}
  }, [props.sections,props.sections.length]);

	const onBack = () => {
		history.goBack();
	}

	const onMainSectionSelected = (section) => {
		setState({ ...state, selectedQuestionaireMainSection: section });
	}

	const questionaireHeader = () => {
		if (!_.isEmpty(state.questionaire) && !_.isEmpty(state.questionaire.status)) {
			return (
				<Grid.Row>
					<Grid.Column width={8}>
						<h2>{state.questionaire.name}</h2>
					</Grid.Column>
					<Grid.Column width={8}>
						<div style={{float: "right"}}>
							<Label
								color={state.questionaire.status === "active" ? 'green' : state.questionaire.status === "underReview" ? 'yellow' : state.questionaire.status === "denied" ? 'red' : 'red'}
								size='small'
							>
								{state.questionaire.status === "active" ? 'Active' : state.questionaire.status === "underReview" ? 'Under Review' : state.questionaire.status === "denied" ? 'Denied' : 'Inactive'}
							</Label>
							<Label color="blue">Category: {state.questionaire.questionaireType}</Label>
							{(state.questionaire.defaultQuestionnaire === true) &&
							(<Label color='green'>Default</Label>)}
							<FuseContentModal
								header={`Test ${state.questionaire.name}`}
								content={<TestQuestionnaireModal questionaire={props.selectedQuestionaire}/>}
								trigger={<Button size='mini' className='primary'> Test Questionnaire</Button>}
								onClose={() => {
									let qnaireData = {
										PK: `USER#${props.userData[0].userName}`,
										SK: `QNAIRE#${props.selectedQuestionaire.id}`,
										status: "isDeleted"
									}
						
									props.deleteTestQnaireAction(qnaireData);
								  }}
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			);
		}
	};

	const activationRuleHeader = () => {
		return (
			<Grid.Row>
				<Grid.Column width={8}>
					<h2>Distribution Rules:</h2>
				</Grid.Column>
			</Grid.Row>
		);
	};


	const sectionContent = () => {
		if(!props.fetchingData) {
			if (!_.isEmpty(state.selectedQuestionaireMainSection))
				return (
					<Grid.Column width={12}>
						<Segment>
							<QuestionaireSectionComponent section={state.selectedQuestionaireMainSection} questionaire={state.questionaire}
								header={`${state.selectedQuestionaireMainSection.displayName}`} onSectionDeleted ={()=> onMainSectionSelected({})}
							/>
						</Segment>
					</Grid.Column>
				);
			else {
				return (
					<Grid.Column width={12}>
						<Segment placeholder vertical>
							<Header>
								<div style={{ textAlign: "center" }}>
									<Icon name="pointing left" size="large" color="grey"></Icon><span style={{ color: "#a9a9a9" }}>Select a section on the left to view its content</span>
								</div>
							</Header>
						</Segment>
					</Grid.Column>
				);
			}
		}
		else {
			return (
				<div style={{ height: "300px", }}>
					<Dimmer inverted active>
						<Loader content='Loading' />
					</Dimmer>
				</div>
			);
		}
	}	  

	const questionaire = () => {
		if (state.questionaire && !_.isEmpty(state.questionaire)) {
	
		  const panes = [
			{
			  menuItem: 'Questionnaire Details',
			  render: () => 
			  <Tab.Pane attached={true}>
				<Grid>
				  {questionaireHeader()}
				  <Divider />
				  <Grid.Row columns="2" stretched>
					<Grid.Column width={3}>
					  <QuestionaireSideMenu questionaire={state.questionaire} onSelectionChanged={onMainSectionSelected} />
					</Grid.Column>
					{sectionContent()}
				  </Grid.Row>
				</Grid>
			  </Tab.Pane>,
			},
			{
			  menuItem: 'Lookup Values',
			  render: () => 
			  <Tab.Pane attached={true}>
				<Grid> 
					{questionaireHeader()}
					<LookupsPage questionaire={state.questionaire}/>
				</Grid>
			  </Tab.Pane>,
			},
			{
			  menuItem: 'Lookup Menu/Link to Questionnaire Values',
			  render: () => 
			  <Tab.Pane attached={true}>
				<Grid>
					{questionaireHeader()}
					<LookupsQnairePage questionaire={state.questionaire}/>
				</Grid>
			  </Tab.Pane>,
			},
			{
			  menuItem: 'Link to Business Rule/s',
			  render: () => 
			  <Tab.Pane attached={true}>
				<Grid>
					{questionaireHeader()}
					<BRQnairePage questionaire={state.questionaire}/>
				</Grid>
			  </Tab.Pane>,
			},
		  ];
	
		  return (
			<div>
				<Grid>
					<Grid.Row>
					<Grid.Column width={16} floated='left'>
						<Button primary onClick={() => { onBack() }}><Icon name='arrow left' /> Back</Button>
					</Grid.Column>
					</Grid.Row>

					<Grid.Row>
					<Grid.Column width={16} floated='left'>
						<Tab menu={{ attached: true, tabular: true }} panes={panes} />
					</Grid.Column>
					</Grid.Row>
				</Grid>
				</div>
		  );
		} else return null;
	  }

  return (
    <div>
      {questionaire()}
    </div>
  );

}

const mapStateToProps = (state, ownProps) => {
	return {
        userData: state.authUserRoles.userRoles,
		fetchingData: state.fetchingData === true,
		sections: state.sections,
    	selectedQuestionaire: _.isEmpty(state.questionaires) ? {} : Object.values(state.questionaires).filter(questionaire => questionaire.id === ownProps.location.state.id)[0],
		selectedQuestionaireMainSection: (_.isEmpty(state.sections) || _.isEmpty(state.uiState) || _.isEmpty(state.uiState.selectedQuestionaireMainSectionId)) ? {} : Object.values(state.sections).filter(x => x.id === state.uiState.selectedQuestionaireMainSectionId)[0]
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
        deleteTestQnaireAction: (qnaireData) => dispatch(TEST_QNAIRE_ACTIONS.deleteTestQnaireAction(qnaireData)),
	})
)(StructuredQuestionairePage);