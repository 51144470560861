import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Confirm } from "semantic-ui-react";
import _ from 'lodash';

import FuseCollapsiblePanel from '../../../../components/generic/FuseCollapsiblePanel';
import QuestionaireQuestionList from './QuestionaireQuestionList';
import QuestionaireSectionList from './QuestionaireSectionList';
import FuseContentModal from '../../../../components/generic/FuseContentModal';
import SectionDetails from './SectionDetails';
import * as SECTION_ACTIONS from '../../../../actions/sectionActions';

class QuestionaireSectionComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showConfirmDelete: false
    };
  }

  static defaultProps = {
    questionaire: {},
    sectionName: '',
    questions: [],
    subSections: []
  }

  hasPermissions(category){
		return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category)
	}

	isAdmin(){
		return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin
	}

  canReadSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.READ || this.isAdmin()
	}

  canCreateSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.CREATE || this.isAdmin()
	}

	canUpdateSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.UPDATE || this.isAdmin()
	}

	canDeleteSections(){
		return this.hasPermissions('Sections') && this.props.userPermissions['Sections'].actions.DELETE || this.isAdmin()
	}

  confirmDelete() {
    return (
      <Confirm
        content={`Are you sure you want to delete ${this.props.section.displayName}?`}
        open={this.state.showConfirmDelete}
        onCancel={() => this.setState({ showConfirmDelete: false })}
        onConfirm={() => {
          this.props.deleteSection(this.props.section);
          this.setState({ showConfirmDelete: false});
        }
        }
      />
    );
  }

  render() {
    const isBaseQnaire = this.props.questionaire.isBaseQnaire;
    const isRootTenant = this.props.tenant.isRoot === true || false;
    return (
      <div>
        {this.confirmDelete()}
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <h3>Section: {this.props.section.displayName}</h3>
              <Button.Group size='tiny' floated='right'>
              {this.canCreateSections() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
                <FuseContentModal
                  header='Add section'
                  content={<SectionDetails questionaire={this.props.questionaire} section={{sectionId: this.props.section.id}} sections={this.props.sections} />}
                  trigger={<Button className='success' icon='plus' size='tiny'></Button>}
                />)}
                {this.canUpdateSections() && (!isBaseQnaire || (isBaseQnaire && isRootTenant))  && (
                <FuseContentModal
                  header='Edit section'
                  content={<SectionDetails questionaire={this.props.questionaire} section={this.props.section} sections={this.props.sections} />}
                  trigger={<Button className='warning' icon='edit' size='tiny'></Button>}
                />)}
                 {this.canDeleteSections() && (!isBaseQnaire || (isBaseQnaire && isRootTenant))  && (
                <Button className='danger' icon='trash' size='tiny' onClick={() => this.setState({ showConfirmDelete: true })} />
                )}
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FuseCollapsiblePanel content={<QuestionaireQuestionList section={this.props.section} />} leadingIcon='question' trailingIcon='angle down' header={`Questions - ${this.props.section.displayName}`}></FuseCollapsiblePanel>
              <br></br>
              <FuseCollapsiblePanel content={<QuestionaireSectionList parentHeader={this.props.header} section={this.props.section} />} leadingIcon='list' trailingIcon='angle down' header={`Subsections - ${this.props.section.displayName}`}></FuseCollapsiblePanel>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

}

const mapStateToProps = (state, ownprops) => {
  return {
    sections: _.isEmpty(state.sections) ? [] : Object.values(Object.values(state.sections).filter(x => x.sectionId === ownprops.section.id)),
    //subSections: _.isEmpty(state.sections) ? [] : Object.values(Object.values(state.sections).filter(x => x.sectionId === ownprops.section.parentSectionId))
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,
    tenant: _.isEmpty(state.tenantManagement.activeTenant) ? {} : state.tenantManagement.activeTenant,
  };
}

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    deleteSection: (section) => dispatch(SECTION_ACTIONS.sectionDeleteAction(section)),
  })
)(QuestionaireSectionComponent);