import {
	FETCH_GROUP_USERS_SUCCESS_ACTION,
	GROUP_USER_ADD_ACTION,
	GROUP_USER_EDIT_ACTION,
	GROUP_USER_DELETE_ACTION,
	FETCH_SEARCHED_GROUP_USERS_SUCCESS_ACTION,
	CLEAR_GROUPUSERS,
} from '../actions/userGroupsActions';

export const groupUserReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_GROUP_USERS_SUCCESS_ACTION:
			const newUserGroupMembers = action.payload;
			const existingUserGroupMembers = Object.values(state); // Get existing user group members
			const newUserIds = newUserGroupMembers.map(user => user.id); // Extract new user ids
			const filteredNewUsers = newUserGroupMembers.filter(user => !existingUserGroupMembers.some(existingUser => existingUser.id === user.id));
			const updatedUserGroupMembers = [...existingUserGroupMembers, ...filteredNewUsers];

			return updatedUserGroupMembers;

		case GROUP_USER_ADD_ACTION:
			const clonedState = [...state];
			clonedState.push(action.payload);
			return clonedState;
		case GROUP_USER_EDIT_ACTION:
			const newUserGroupMember = action.payload;
			const userGroupMembersCopy = Object.values({ ...state }).filter(userGroupMember => !(newUserGroupMember.id === userGroupMember.id));
			userGroupMembersCopy.push(newUserGroupMember);
			return userGroupMembersCopy;
		case GROUP_USER_DELETE_ACTION:
			return state.filter(element => element.id !== action.payload);
		case CLEAR_GROUPUSERS:
			return [];
		default:
			return state;
	}
};

export const searchGroupUsersReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_SEARCHED_GROUP_USERS_SUCCESS_ACTION:
			const searchGroupUsers = action.payload;
			return searchGroupUsers;
		default:
			return state;
	}
};