import {
    SECTION_EDIT_ACTION,
    SECTION_DELETE_ACTION,
    FETCH_SECTIONS_SUCCESS_ACTION,
    SECTION_ADD_ACTION
} from '../actions/sectionActions';

export const SectionReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_SECTIONS_SUCCESS_ACTION:
            const newSections = action.payload;
            const oldSections = Object.values({...state}).filter(s => !newSections.some(newS => newS.id === s.id));
            const newSectionState = oldSections.concat(newSections); //Creates a copy...a new state;
            return newSectionState;
        case SECTION_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case SECTION_EDIT_ACTION:
            const newSection = action.payload;
            const sectionsCopy = Object.values({...state}).filter(s => !(newSection.id === s.id)); //Creates a copy...a new state
            sectionsCopy.push(newSection);
            return sectionsCopy;
        case SECTION_DELETE_ACTION:
            return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
};