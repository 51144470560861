import {
    FETCH_BRTYPES_SUCCESS_ACTION,
    BRTYPE_ADD_ACTION,
    BRTYPE_EDIT_ACTION,
    BRTYPE_DELETE_ACTION
} from '../actions/businessRuleTypeActions';

export const businessRuleTypeReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_BRTYPES_SUCCESS_ACTION:
            const newBRType= action.payload;
            const oldBRTypes = Object.values({...state}).filter(s => !newBRType.some(newS => newS.id === s.id));
            const newBrTypeState = oldBRTypes.concat(newBRType); //Creates a copy...a new state
            return newBrTypeState;
        case BRTYPE_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case BRTYPE_EDIT_ACTION:
            const newBRType1 = action.payload;
            const brTypesCopy = Object.values({...state}).filter(s => !(newBRType1.id === s.id)); //Creates a copy...a new state
            brTypesCopy.push(newBRType1);
            return brTypesCopy;
        case BRTYPE_DELETE_ACTION:
            return state.filter(element => element.id !== action.payload);
        default:
            return state;
    }
};