import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import Cron from 'react-cron-generator';
import { HEADER } from 'react-cron-generator';
import { Container, Form, Button, Icon, Divider, Checkbox, Table, Dropdown, Grid, Input, Message, Label, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import FuseCollapsiblePanel from '../../../components/generic/FuseCollapsiblePanel';
import QuestionModal from "./QuestionModel";

import * as ACTIVATION_RULES from '../../../actions/activationRulesActions';


export function ActivationRulesDetails(props) {
  const [state, setState] = useState({
    activationRule: { ...props.activationRule },
    isChanged: false,
    isSchedulerCollapsed: true,
    isDemoGraphicCollapsed: true,
    isGeoGraphicCollapsed: true,
    isQuestionnaireSelectorCollapsed: true,
    Collapsed: true,
    isCronExpressionEmpty: false,
    selectedQuestionnaire: null,
    selectedQuestionnaire2: null,
    isModalOpen: false,
    isModal2Open: false,
    isTemplateModalOpen: false,
    selectedQuestion: null,
  });

  useEffect(() => {
    props.fetchQuestionairesAction(props.tenant.id);
    props.fetchKmlFilesAction(props.tenant.id);
    props.fetchUserGroupsAction(props.tenant);
    props.fetchWhatsAppTemplatesAction(props.tenant);
  }, [props.tenant]);

  useEffect(() => {
    if (!_.isEmpty(state.selectedQuestionnaire)) {
      props.fetchQuestionsAction(state.selectedQuestionnaire.id, state.selectedQuestionnaire.tenantId);
      props.fetchSectionsAction(state.selectedQuestionnaire.id, state.selectedQuestionnaire.tenantId);
    }
  }, [state.selectedQuestionnaire]);

  useEffect(() => {
    if (!_.isEmpty(state.selectedQuestionnaire2)) {
      props.fetchQuestionsAction(state.selectedQuestionnaire2.id, state.selectedQuestionnaire2.tenantId);
      props.fetchSectionsAction(state.selectedQuestionnaire2.id, state.selectedQuestionnaire2.tenantId);
    }
  }, [state.selectedQuestionnaire2]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const handleWheel = (e) => {
    e.target.blur();
  };

  const onSubmit = (data) => {
    let toCommit = {
      name: state.activationRule.name,
      description: state.activationRule.description,
      frequency: state.activationRule.frequency,
      status: state.activationRule.status,
      questionWithAnswers: state.activationRule.questionWithAnswers,
      userLocationQuestion: state.activationRule.userLocationQuestion,
      refKMLFile: state.activationRule.refKMLFile,
      ruleQuestionnaires: state.activationRule.ruleQuestionnaires,
      groupApplicable: state.activationRule.groupApplicable,
      // anonymisedData: state.activationRule.anonymisedData,
      ruleGroup: state.activationRule.ruleGroup,
      distanceOption: state.activationRule.distanceOption,
      distanceValue: state.activationRule.distanceValue,
      maxNumUsers: state.activationRule.maxNumUsers,
      whatsAppTemplate: state.activationRule.whatsAppTemplate,
      whatsAppTemplatePlaceholderValues: state.activationRule.whatsAppTemplatePlaceholderValues,
      completionLimitType: props.activationRule.completionLimitType,
      timesToBeCompleted: props.activationRule.timesToBeCompleted,
      targetAudience: state.activationRule.targetAudience === undefined ? 'both' : state.activationRule.targetAudience
    };

    if (toCommit.name === "") {
      toast.error("Please Give The Distribution Rule A Name!")
      return
    }

    if (state.activationRule.status === undefined) {
      toCommit.status = 'inActive';
    } else {
      toCommit.status = state.activationRule.status;
    }

    // if (state.activationRule.completionLimitType === undefined) {
    //   toCommit.completionLimitType = 'byTimes';
    // } else {
    //   toCommit.completionLimitType = state.activationRule.completionLimitType;
    // }

    // if (!state.activationRule.timesToBeCompleted) {
    //   toCommit.timesToBeCompleted = '0';
    // } else {
    //   toCommit.timesToBeCompleted = state.activationRule.timesToBeCompleted;
    // }

    if (props.isNew) {
      props.saveActivationRule(props.tenant, toCommit, props.isNew);
    } else {
      const combinedRule = { ...state.activationRule, ...toCommit };
      props.saveActivationRule(props.tenant, combinedRule, props.isNew);
    }

    setState((state) => ({ ...state, }));
    if (_.isEmpty(props.onComplete)) props.onComplete();
  };

  const DisplaySelectedQuestions = () => {
    return (
      <div>
        <h3>Selected Demograqhic Questions and Answers</h3>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '65%' }}>Question</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '25%' }}>Answers</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.activationRule.questionWithAnswers && state.activationRule.questionWithAnswers.map((selectedQuestion, index) => (
              <Table.Row key={index}>
                <Table.Cell>{selectedQuestion.question}</Table.Cell>
                <Table.Cell>
                  {selectedQuestion.answers.map((answer, answerIndex) => (
                    <span key={answerIndex}>
                      {answer.value}
                      {answerIndex < selectedQuestion.answers.length - 1 && ', '}
                    </span>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    compact
                    className="danger"
                    icon="remove"
                    onClick={() => {
                      const updatedQuestions = state.activationRule.questionWithAnswers.filter(
                        (question, i) => i !== index
                      );
                      setState((prevState) => ({
                        ...prevState,
                        activationRule: {
                          ...prevState.activationRule,
                          questionWithAnswers: updatedQuestions,
                        },
                      }));
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const DisplaySelectedUserSelectedQuestion = () => {
    return (
      <div>
        <br />
        <h3>Selected User Location Question</h3>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '20%' }}>Questionnaire</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '70%' }}>Question</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.activationRule.userLocationQuestion && (
              <Table.Row key={state.activationRule.userLocationQuestion.questionId}>
                <Table.Cell>{state.activationRule.userLocationQuestion.questionnaire}</Table.Cell>
                <Table.Cell>{state.activationRule.userLocationQuestion.question}</Table.Cell>
                <Table.Cell>
                  <Button
                    compact
                    className="danger"
                    icon="remove"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        activationRule: {
                          ...prevState.activationRule,
                          userLocationQuestion: {},
                        },
                      }));
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const DisplaySelectedReferenceKmlFile = () => {
    return (
      <div>
        <br />
        <h3>Selected Reference KML File</h3>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '45%' }}>Name</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '45%' }}>File Name</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.activationRule.refKMLFile && (
              <Table.Row key={state.activationRule.refKMLFile.kmlId}>
                <Table.Cell>{state.activationRule.refKMLFile.name}</Table.Cell>
                <Table.Cell>{state.activationRule.refKMLFile.fileName}</Table.Cell>
                <Table.Cell>
                  <Button
                    compact
                    className="danger"
                    icon="remove"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        activationRule: {
                          ...prevState.activationRule,
                          refKMLFile: {},
                        },
                      }));
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const DisplayruleQuestionnaires = () => {
    return (
      <div>
        <br />
        <h3>Selected Questionnaires</h3>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '30%' }}>Name</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '60%' }}>Description</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.activationRule.ruleQuestionnaires && (
              <Table.Row key={state.activationRule.ruleQuestionnaires.questionnaireId}>
                <Table.Cell>{state.activationRule.ruleQuestionnaires.questionnaire}</Table.Cell>
                <Table.Cell>{state.activationRule.ruleQuestionnaires.questionnaireDescription}</Table.Cell>
                <Table.Cell>
                  <Button
                    compact
                    className="danger"
                    icon="remove"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        activationRule: {
                          ...prevState.activationRule,
                          ruleQuestionnaires: {},
                        },
                      }));
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const DisplaySelectedUserGroup = () => {
    return (
      <div>
        <br />
        <h3>Selected User Group: </h3>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '40%' }}>Group Name</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '40%' }}>Group Description</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '20%' }}>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.activationRule.ruleGroup && (
              <Table.Row key={state.activationRule.ruleGroup.userGroupId}>
                <Table.Cell>{state.activationRule.ruleGroup.userGroupName}</Table.Cell>
                <Table.Cell>{state.activationRule.ruleGroup.userGroupDescription}</Table.Cell>
                <Table.Cell>
                  <Button
                    compact
                    className="danger"
                    icon="remove"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        activationRule: {
                          ...prevState.activationRule,
                          ruleGroup: {},
                        },
                      }));
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const _buildActiveToggle = () => {
    return (
      <Form.Field>
        <Checkbox
          style={{ paddingTop: '28px' }}
          label="Active"
          toggle
          checked={state.activationRule.status === 'active'}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              activationRule: {
                ...prevState.activationRule,
                status: prevState.activationRule.status === 'active' ? 'inActive' : 'active',
              },
            }));
          }}
        />
      </Form.Field>
    );
  };

  const _buildName = () => {
    return (
      <Form.Field className="full-width-field">
        <label>Rule Name</label>
        <input
          name="name"
          className={errors.name ? "invalid-input-field" : ""}
          {...register("name", { required: true, maxLength: 128 })}
          placeholder="Name"
          value={state.activationRule.name}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              activationRule: { ...prevState.activationRule, name: e.target.value },
            }));
          }}
        />
        {errors.name && errors.name.type === "required" && (
          <p className="validation-error">* Please enter a name</p>
        )}
        {errors.name && errors.name.type === "maxLength" && (
          <p className="validation-error">
            * Name may not be more than 128 characters long
          </p>
        )}
      </Form.Field>
    );
  };

  const _buildDescription = () => {
    return (
      <Form.Field className="full-width-field">
        <label>Rule Description</label>
        <input
          name="description"
          className={errors.description ? "invalid-input-field" : ""}
          {...register("description", { maxLength: 255 })}
          placeholder="Description"
          value={state.activationRule.description}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              activationRule: { ...prevState.activationRule, description: e.target.value },
            }));
          }}
        />
        {errors.description && errors.description.type === "maxLength" && (
          <p className="validation-error">
            * Description may not be more than 255 characters long
          </p>
        )}
      </Form.Field>
    );
  };


  const [localTemplate, setLocalTemplate] = useState(null);
  const [localInputValues, setLocalInputValues] = useState([]);

  const closeTemplateModal = () => {
    setState((prevState) => ({
      ...prevState,
      isTemplateModalOpen: false,
    }))
  };

  const handlePlaceholderChange = (index, value) => {
    const newLocalInputValues = [...localInputValues];
    newLocalInputValues[index] = { placeholder: `${index + 1}`, value: value };
    setLocalInputValues(newLocalInputValues);
  };

  const extractUniquePlaceholders = (text) => {
    const regex = /{{(\d+)}}/g;
    const placeholders = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      if (!placeholders.includes(match[1])) {
        placeholders.push(match[1]);
      }
    }
    return placeholders;
  };

  const renderInputFields = (text) => {
    const placeholders = extractUniquePlaceholders(text);
    return placeholders.map((placeholder, index) => (
      <div key={index}>
        <Input className="full-width-field"
          placeholder={`Enter value for placeholder ${placeholder}`}
          onChange={(event) => handlePlaceholderChange(index, event.target.value)}
        />
        <br />
      </div>
    ));
  };

  const setInputValuesButton = () => {
    setState(prevState => ({
      ...prevState,
      activationRule: {
        ...prevState.activationRule,
        whatsAppTemplate: localTemplate,
        whatsAppTemplatePlaceholderValues: localInputValues
      },
      isTemplateModalOpen: false,
    }));
  
    setLocalInputValues([]);
  };

  const handleTemplateSelection = (event, { value }) => {
    if (value == null) {
      setState((prevState) => ({
        ...prevState,
        activationRule: { ...prevState.activationRule.whatsAppTemplatePlaceholderValues = {} },
      }));
    } else {
      setLocalTemplate(value);
      setState(prevState => ({
        ...prevState,
        isTemplateModalOpen: true,
      }));
    }
  };  

  const templatesOptions = [
    { key: 'none', value: null, text: 'Select WhatsApp Template' },
    ...props.whatsAppTemplates
      .map((template) => ({
        key: template.id,
        value: template,
        text: template.name,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
  ];

  const _buildWhatsAppTemplate = () => {
    const defaultValue = state.activationRule.whatsAppTemplate
    ? state.activationRule.whatsAppTemplate
    : "";

    return (
      <Form.Field className="full-width-field">
        <label>WhatsApp Template</label>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Controller
                name="template"
                control={control}
                rules={{ required: "* Please select a template" }}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => (
                  <div>
                    <Dropdown
                      className={fieldState.invalid ? "invalid-input-field" : ""}
                      {...field}
                      placeholder="Select WhatsApp Template"
                      fluid
                      search
                      selection
                      options={templatesOptions}
                      value={defaultValue}
                      onChange={(e, { value }) => {
                        field.onChange(value);
                        handleTemplateSelection(e, { value });
                      }}
                    />
                    {fieldState.invalid && (
                      <p className="validation-error">{fieldState.error.message}</p>
                    )}
                  </div>
                )}
              />
            </Grid.Column>
          </Grid.Row>
          {state.activationRule.whatsAppTemplate && state.activationRule.whatsAppTemplatePlaceholderValues.length > 0 && (
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  <label>Placeholder Values For Selected Template:</label>
                </Form.Field>
                {state.activationRule.whatsAppTemplatePlaceholderValues.map((inputValue, index) => (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <Input
                      label={`Placeholder: ${inputValue.placeholder}`}
                      value={inputValue.value}
                      placeholder={inputValue.placeholder}
                      onChange={event => handlePlaceholderChange(index, event.target.value)}
                    />
                  </div>
                ))}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        {localTemplate && (
          <Modal open={state.isTemplateModalOpen} onClose={closeTemplateModal} size="large">
            <Modal.Header>WhatsApp Template: {localTemplate.name}</Modal.Header>
            <Modal.Content>
              {localTemplate.components.map((component, index) => (
                component.type === 'BODY' && (
                  <div key={index}>
                    <textarea rows={10} style={{ width: '100%' }} readOnly>
                      {component.text}
                    </textarea>
                    {renderInputFields(component.text)}
                  </div>
                )
              ))}
            </Modal.Content>
            <Modal.Actions>
              <Button.Group floated="right" style={{ paddingBottom: "10px" }}>
                <Button onClick={closeTemplateModal} className="danger">
                  <Icon name="remove" />
                  Close
                </Button>
                <Button onClick={setInputValuesButton} className="success">
                  <Icon name="checkmark" />
                  Select Template
                </Button>
              </Button.Group>
            </Modal.Actions>
          </Modal>
        )}
      </Form.Field>
    );
  };

  const _maxNumUsers = () => {
    return (
      <Form.Field className="full-width-field">
        <label>Maximum Number Of Users to Receive The Questionnaire:</label>
        <input
          name="maxNum_Users"
          className={errors.maxNum_Users ? "invalid-input-field" : ""}
          {...register("maxNum_Users", { 
            required: true,
            min: 1
          })}
          fluid
          type="number"
          placeholder="Maximum Number of Users to Receive The Questionnaire"
          min="1"
          value={state.activationRule.maxNumUsers || ''}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              activationRule: {
                ...prevState.activationRule,
                maxNumUsers: e.target.value,
              },
            }));
          }}
          onWheel={handleWheel}
        />
        {errors.maxNum_Users &&
          errors.maxNum_Users.type === "required" && (
            <p className="validation-error">* Please Enter a value</p>
          )}
        {errors.maxNum_Users &&
          errors.maxNum_Users.type === "min" && (
            <p className="validation-error">
              * Value can't be smaller than 1
            </p>
          )}
      </Form.Field>
    );
  };

  const targetAudienceOptions = [
    { key: 'both', text: 'Mobile and WhatsApp Users', value: 'both' },
    { key: 'mobileUsers', text: 'Mobile Users Only', value: 'mobileUsers' },
    { key: 'whatsAppUsers', text: 'WhatsApp Users Only', value: 'whatsAppUsers' }
  ];
  
  const _targetAudience = () => {
    return (
      <Form.Field>
        <label>Target Audience (Platform)</label>
        <Dropdown
          fluid
          selection
          options={targetAudienceOptions}
          name="targetAudience"
          value={targetAudienceOptions.find(option => option.value === state.activationRule.targetAudience)?.value || 'both'}
          onChange={(e, { value }) => {
            setState((prevState) => ({
              ...prevState,
              activationRule: {
                ...prevState.activationRule,
                targetAudience: value,
              },
            }));
          }}
        />
      </Form.Field>
    );
  };
  
  

  // const _buildTimesToComplete = () => {
  //   const options = [
  //     // { key: 'selectType', text: 'Select Limit Type', value: 'selectType' },
  //     { key: 'byTimes', text: 'Number of Times', value: 'byTimes' },
  //     { key: 'byPeople', text: 'Number of People', value: 'byPeople' },
  //   ];

  //   return (
  //     <Form.Field>
  //       <label>Completion Limit</label>
  //       <Grid>
  //         <Grid.Column width={4}>
  //           <Dropdown
  //             fluid
  //             selection
  //             options={options}
  //             name="completionLimitType"
  //             value={state.activationRule.completionLimitType || 'byTimes'}
  //             onChange={(e, { value }) => {
  //               if (value !== state.activationRule.completionLimitType) {
  //                 setState((prevState) => ({
  //                   ...prevState,
  //                   activationRule: {
  //                     ...prevState.activationRule,
  //                     completionLimitType: value,
  //                     timesToBeCompleted: '',
  //                   },
  //                 }));
  //               } else {
  //                 setState((prevState) => ({
  //                   ...prevState,
  //                   activationRule: {
  //                     ...prevState.activationRule,
  //                     completionLimitType: value,
  //                   },
  //                 }));
  //               }
  //             }}
  //           />
  //         </Grid.Column>
  //         <Grid.Column width={8}>
  //           <Input
  //             type="number"
  //             name="timesToBeCompleted"
  //             min={state.activationRule.completionLimitType === 'byTimes' ? '0' : '1'}
  //             className={errors.timesToBeCompleted ? 'invalid-input-field' : ''}
  //             {...register('timesToBeCompleted')}
  //             placeholder={`Enter the ${state.activationRule.completionLimitType === 'byTimes'
  //                 ? 'number of times it can be completed. 0 = NO LIMIT'
  //                 : 'number of people who can complete it'
  //               }`}
  //             value={state.activationRule.timesToBeCompleted}
  //             onChange={(e) => {
  //               setState((prevState) => ({
  //                 ...prevState,
  //                 activationRule: { ...prevState.activationRule, timesToBeCompleted: e.target.value },
  //               }));
  //             }}
  //           />
  //         </Grid.Column>
  //       </Grid>
  //     </Form.Field>
  //   );
  // };

  const options = {
    headers: [HEADER.HOURLY, HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY, HEADER.CUSTOM,],
  };

  const _buildCronScheduler = () => {
    return (
      <>
        <Form.Field>
          <label>Frequency Scheduler</label>
          <Cron
            showResultText
            name="frequency"
            onChange={(value) => {
              setState((prevState) => ({
                ...prevState,
                activationRule: { ...prevState.activationRule, frequency: value },
              }));
            }}
            value={state.activationRule.frequency}
            options={options}
          />
        </Form.Field>
        <input
          type="hidden"
          name="frequency"
          {...register("frequency")}
          value={state.activationRule.frequency}
        />
      </>
    );
  };

  const mapQuestionTypeToLabel = (questionType) => {
    switch (questionType) {
      case 'yes_no':
        return 'Yes/No';
      case 'mc_sel_one':
        return 'Multiple Choice (Single Answer)';
      case 'multi_select':
        return 'Multiple Choice (Multiple Answers)';
      default:
        return questionType;
    }
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      isModalOpen: false,
      selectedQuestionnaire: "",
    }))
  };

  const closeModal2 = () => {
    setState((prevState) => ({
      ...prevState,
      isModalOpen2: false,
      selectedQuestionnaire2: "",
    }))
  };

  const _buildQuestionList = (sectionId, sectionName) => {
    const { questions, sections } = props;
  
    // Get all subsections for the current section
    const subsections = sections.filter((section) => section.parentSectionId === sectionId);
  
    // Filter questions for the current section based on question types
    const filteredQuestions = questions
      .filter((question) => question.sectionId === sectionId)
      .filter((question) => ['yes_no', 'mc_sel_one', 'multi_select'].includes(question.questionType))
      .sort((a, b) => a.sequence - b.sequence);
  
    // Check if there are valid questions for this section
    const hasValidQuestions = filteredQuestions.length > 0;
  
    // Render the section and its questions only if there are valid questions
    if (hasValidQuestions) {
      return (
        <div>
          <h4>{sectionName}</h4>
          {filteredQuestions.map((question) => (
            <Table key={question.id} celled>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ width: '80%' }}>{question.question}</Table.Cell>
                  <Table.Cell style={{ width: '15%' }}>{question.questionType}</Table.Cell>
                  <Table.Cell style={{ width: '5%' }}>
                    <Button
                      compact
                      className='success'
                      icon='checkmark'
                      onClick={(event) => {
                        event.preventDefault();
                        props.fetchLookupsAction(question, (result, error) => {
                          try {
                            if (error) {
                              console.log("hello");
                            } else {
                              setState((state) => ({
                                ...state,
                                selectedQuestionId: question.id,
                                lookupValue: result,
                              }));
                            }
                          } catch (e) {
                            console.log(e);
                          }
                        });
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          ))}
          {/* Render subsections */}
          {subsections.map((subsection) => (
            <FuseCollapsiblePanel
              key={subsection.id}
              content={_buildQuestionList(subsection.id, subsection.name)}
              isCollapsed={true}
              leadingIcon="female"
              trailingIcon="angle down"
              header={subsection.name}
            />
          ))}
        </div>
      );
    } else {
      return null; // Return null if there are no valid questions for the section
    }
  };
  
  

  const _buildDemoQuestionList = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const { sections, questions } = props;

    
    const questionnaireOptions = [
      { key: 'none', value: null, text: 'Select To Hide Questions' },
      ...props.questionnaires
        .map((questionnaire) => ({
          key: questionnaire.id,
          value: questionnaire.id,
          text: questionnaire.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    ];
    

    const sectionOptions = [
      ...sections
        .filter((section) => !section.parentSectionId) // Filter out subsections
        .filter((section) => {
          // Check if the section contains any valid questions
          return questions.some((question) => {
            return (
              question.sectionId === section.id &&
              ['yes_no', 'mc_sel_one', 'multi_select'].includes(question.questionType)
            );
          });
        })
        .sort((a, b) => a.sequence - b.sequence)
        .map((section) => ({
          key: section.id,
          text: section.name,
          value: section,
        })),
    ];
    
    const filteredQuestions = (props.questions || []).filter(
      (question) =>
        question &&
        (question.questionType === 'yes_no' ||
          question.questionType === 'mc_sel_one' ||
          question.questionType === 'multi_select') &&
        (question.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          mapQuestionTypeToLabel(question.questionType).toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    return (
      <Form.Field>
        <Grid >
          <Grid.Row>
            <Grid.Column width={10} style={{ paddingBottom: "10px" }}>
              <h3>Selected Demographic Related Questions and Answers Or a User Group</h3>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={10}>
              <Form.Field>
                <label>Demographic Questions or User Group:</label>
                <Checkbox
                  style={{ paddingTop: "10px" }}
                  toggle
                  label="User Group"
                  checked={!!state.activationRule.groupApplicable}
                  onChange={() => {
                    setState((prevState) => ({
                      ...prevState,
                      activationRule: {
                        ...prevState.activationRule,
                        groupApplicable: !prevState.activationRule.groupApplicable,
                      },
                    }));
                  }}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {state.activationRule.groupApplicable ? (
            <Grid.Row>
              <Grid.Column>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='nectaPrimaryBg'>
                        Group Name
                      </Table.HeaderCell>
                      <Table.HeaderCell className='nectaPrimaryBg'>
                        Group Description
                      </Table.HeaderCell>
                      <Table.HeaderCell className='nectaPrimaryBg' />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {props.userGroups.map((userGroup) => (
                      <Table.Row key={userGroup.id}>
                        <Table.Cell>{userGroup.name}</Table.Cell>
                        <Table.Cell>{userGroup.description}</Table.Cell>
                        <Table.Cell>
                          <Button
                            compact
                            className='success'
                            icon='checkmark'
                            onClick={(event) => {
                              event.preventDefault();
                              setState((state) => ({
                                ...state,
                                activationRule: {
                                  ...state.activationRule,
                                  ruleGroup: {
                                    userGroupId: userGroup.id,
                                    userGroupName: userGroup.name,
                                    userGroupDescription: userGroup.description,
                                    tenantId: userGroup.tenantId,
                                  },
                                },
                              }));
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          ) : null
          }

          {!state.activationRule.groupApplicable && (
            <Grid.Row>
              <Grid.Column width={10} style={{ paddingBottom: "10px" }}>
                <Grid.Row style={{ paddingBottom: "10px" }}>
                  <Label>Questionnaire:</Label>
                </Grid.Row>
                <Dropdown
                  placeholder="Select Questionnaire"
                  fluid
                  search
                  selection
                  options={questionnaireOptions}
                  onChange={(event, { value }) => {
                    const selectedQuestionnaire = props.questionnaires.find(
                      (questionnaire) => questionnaire.id === value
                    );

                    setState((prevState) => ({
                      ...prevState,
                      selectedQuestionnaire,
                      selectedQuestionId: null,
                      isModalOpen: true,
                    }));
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>


        {!state.activationRule.groupApplicable && state.selectedQuestionnaire && state.selectedQuestionnaire.id !== null ? (
          <>
            {!_.isEmpty(props.questions) && !_.isEmpty(filteredQuestions) ? (
              <Modal
                open={state.isModalOpen}
                onClose={closeModal}
                size="small"
              >
                <Modal.Header>Sections for Questionnaire: {state.selectedQuestionnaire.name}</Modal.Header>
                <Modal.Content>
                  {sectionOptions.map((section, index) => (
                    <div key={section.key}>
                      <FuseCollapsiblePanel
                        content={_buildQuestionList(section.key, section.text)}
                        isCollapsed={true}
                        leadingIcon="book"
                        trailingIcon="angle down"
                        header={section.text}
                      />
                      {index !== sectionOptions.length - 1 && <div style={{ marginBottom: '10px' }}></div>}
                    </div>
                  ))}
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={closeModal} negative>
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
            ) : (
              <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f8d7da', color: '#721c24' }}>
                No relevant questions for the selected questionnaire.
              </div>
            )}
            
            {state.selectedQuestionId && (
              <QuestionModal
                question={props.questions.find(
                  (question) => question.id === state.selectedQuestionId
                )}
                onClose={() =>
                  setState((prevState) => ({
                    ...prevState,
                    selectedQuestionId: null,
                  }))
                }
                onSave={(answersWithQuestion) => {
                  const updatedAnswers = JSON.parse(answersWithQuestion);

                  // Check if the question is already in the list
                  const existingQuestionIndex = state.activationRule.questionWithAnswers.findIndex(
                    (existingAnswer) => existingAnswer.questionId === state.selectedQuestionId
                  );

                  if (existingQuestionIndex !== -1) {
                    // If the question exists, override it with the new one
                    const newQuestionWithAnswers = [...state.activationRule.questionWithAnswers];
                    newQuestionWithAnswers[existingQuestionIndex] = updatedAnswers[0];
                    setState((state) => ({
                      ...state,
                      activationRule: {
                        ...state.activationRule,
                        questionWithAnswers: newQuestionWithAnswers,
                      },
                    }));
                    toast.success("Question Added")
                  } else {
                    // If the question is not in the list, add it
                    setState((state) => ({
                      ...state,
                      activationRule: {
                        ...state.activationRule,
                        questionWithAnswers: [...state.activationRule.questionWithAnswers, ...updatedAnswers],
                      },
                    }));
                    toast.success("Question Added")
                  }
                }}

                lookupValue={state.lookupValue}
              />
            )}
          </>
        ) : null
        }
        <Divider />
        {state.activationRule.questionWithAnswers && !state.activationRule.groupApplicable && state.activationRule.questionWithAnswers.length > 0 && <DisplaySelectedQuestions />}
        {!_.isEmpty(state.activationRule.ruleGroup) && state.activationRule.groupApplicable && <DisplaySelectedUserGroup />}
      </Form.Field>
    );
  };

  const _buildQuestionList2 = (sectionId, sectionName) => {
    const { questions, sections } = props;
  
    // Get all subsections for the current section
    const subsections = sections.filter((section) => section.parentSectionId === sectionId);
  
    // Filter questions for the current section based on question types
    const filteredQuestions = questions
      .filter((question) => question.sectionId === sectionId)
      .filter((question) => ['location'].includes(question.questionType))
      .sort((a, b) => a.sequence - b.sequence);
  
    // Render the section and its questions only if there are questions
    if (filteredQuestions.length > 0) {
      return (
        <div>
          <h4>{sectionName}</h4>
          {filteredQuestions.map((question) => (
            <Table key={question.id} celled>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ width: '90%' }}>{question.question}</Table.Cell>
                  <Table.Cell style={{ width: '10%' }}>
                  <Button
                      compact
                      className='success'
                      icon='checkmark'
                      onClick={(event) => {
                        event.preventDefault();
                        setState((state) => ({
                          ...state,
                          activationRule: {
                            ...state.activationRule,
                            userLocationQuestion: {
                              questionId: question.id,
                              questionnaireId: question.questionaireId,
                              question: question.question,
                              questionnaire: question.questionaireName,
                              tenantId: question.tenantId,
                            },
                          },
                        }));
                        toast.success("Question Added")
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          ))}
          {/* Render subsections */}
          {subsections.map((subsection) => (
            <FuseCollapsiblePanel
              key={subsection.id}
              content={_buildQuestionList(subsection.id, subsection.name)}
              isCollapsed={true}
              leadingIcon="female"
              trailingIcon="angle down"
              header={subsection.name}
            />
          ))}
        </div>
      );
    } else {
      return null; // Return null if there are no questions for the section
    }
  };

  const _buildUserLocationList = () => {
    const { sections, questions } = props;

    const filteredQuestions = (props.questions || []).filter(
      (question) =>
        question &&
        (question.questionType === 'location')
    );

    const questionnaireOptions = [
      { key: 'none', value: null, text: 'Select To Hide Questions' },
      ...props.questionnaires
        .map((questionnaire) => ({
          key: questionnaire.id,
          value: questionnaire.id,
          text: questionnaire.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    ];

    const sectionOptions = [
      ...sections
        .filter((section) => !section.parentSectionId) // Filter out subsections
        .filter((section) => {
          // Check if the section contains any valid questions
          return questions.some((question) => {
            return (
              question.sectionId === section.id &&
              ['location'].includes(question.questionType)
            );
          });
        })
        .sort((a, b) => a.sequence - b.sequence)
        .map((section) => ({
          key: section.id,
          text: section.name,
          value: section,
        })),
    ];

    const distanceOptions = [
      { key: '', value: '', text: 'Select distance option' },
      { key: 'along_boundary', value: 'along_boundary', text: 'Along the boundary of the polygon' },
      { key: 'any_pin', value: 'any_pin', text: 'Any PIN (such as a school)' },
      { key: 'on_boundary', value: 'on_boundary', text: 'On or within the boundary of this polygon' },
      { key: 'within_x_meters', value: 'within_x_meters', text: 'Within x meters of any point' },
      { key: 'within_x_meters_polygon_boundary', value: 'within_x_meters_polygon_boundary', text: 'Within x meters of the boundary of the polygon' },
    ];

    return (
      <Form.Field>
        <Grid>
          <Grid.Row>
            <Grid.Column width={10} style={{ paddingBottom: "10px" }}>
              <h3>Select User Location Question</h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10} style={{ paddingBottom: "10px" }}>
              <label>Distance Options:</label>
              <Dropdown
                placeholder="Select Disatnce Option"
                fluid
                selection
                options={distanceOptions}
                value={state.activationRule.distanceOption || ''}
                onChange={(event, { value }) => {

                  setState((prevState) => ({
                    ...prevState,
                    activationRule: {
                      ...prevState.activationRule,
                      distanceOption: value,
                    }
                  }));
                }}
              />
            </Grid.Column>
            {(state.activationRule.distanceOption === 'within_x_meters' || state.activationRule.distanceOption === "within_x_meters_polygon_boundary") && (
              <Grid.Column width={6} style={{ paddingBottom: "10px" }}>
                <label>Enter distance (in meters):</label>
                <Input
                  fluid
                  type="number"
                  placeholder="Enter distance"
                  value={state.activationRule.distanceValue || ''}
                  onChange={(event, { value }) => {
                    setState((prevState) => ({
                      ...prevState,
                      activationRule: {
                        ...prevState.activationRule,
                        distanceValue: value,
                      },
                    }));
                  }}
                />
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10} style={{ paddingBottom: "10px" }}>
              <label>Questionnaire:</label>
              <Dropdown
                placeholder="Select Questionnaire"
                fluid
                search
                selection
                options={questionnaireOptions}
                onChange={(event, { value }) => {
                  const selectedQuestionnaire2 = props.questionnaires.find(
                    (questionnaire) => questionnaire.id === value
                  );

                  setState((prevState) => ({
                    ...prevState,
                    selectedQuestionnaire2,
                    selectedQuestionId: null,
                    isModalOpen2: true,
                  }));
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {state.selectedQuestionnaire2 && state.selectedQuestionnaire2.id !== null ? (
          <>
            {!_.isEmpty(props.questions) && !_.isEmpty(filteredQuestions) ? (
               <Modal
               open={state.isModalOpen2}
               onClose={closeModal2}
               size="small"
             >
               <Modal.Header>Sections for Questionnaire: {state.selectedQuestionnaire2.name}</Modal.Header>
               <Modal.Content>
                 {sectionOptions.map((section, index) => (
                   <div key={section.key}>
                     <FuseCollapsiblePanel
                       content={_buildQuestionList2(section.key, section.text)}
                       isCollapsed={true}
                       leadingIcon="book"
                       trailingIcon="angle down"
                       header={section.text}
                     />
                     {index !== sectionOptions.length - 1 && <div style={{ marginBottom: '10px' }}></div>}
                   </div>
                 ))}
               </Modal.Content>
               <Modal.Actions>
                 <Button onClick={closeModal2} negative>
                   Close
                 </Button>
               </Modal.Actions>
             </Modal>
              // <div>
              //   <Table celled>
              //     <Table.Header>
              //       <Table.Row>
              //         <Table.HeaderCell className='nectaPrimaryBg'>
              //           Question Text
              //         </Table.HeaderCell>
              //         <Table.HeaderCell className='nectaPrimaryBg'>
              //           Question Type
              //         </Table.HeaderCell>
              //         <Table.HeaderCell className='nectaPrimaryBg' />
              //       </Table.Row>
              //     </Table.Header>
              //     <Table.Body>
              //       {filteredQuestions.map((question) => (
              //         <Table.Row key={question.id}>
              //           <Table.Cell>{question.question}</Table.Cell>
              //           <Table.Cell>{question.questionType}</Table.Cell>
              //           <Table.Cell>
              //             <Button
              //               compact
              //               className='success'
              //               icon='checkmark'
              //               onClick={(event) => {
              //                 event.preventDefault();
              //                 setState((state) => ({
              //                   ...state,
              //                   activationRule: {
              //                     ...state.activationRule,
              //                     userLocationQuestion: {
              //                       questionId: question.id,
              //                       questionnaireId: question.questionaireId,
              //                       question: question.question,
              //                       questionnaire: question.questionaireName,
              //                       tenantId: question.tenantId,
              //                     },
              //                   },
              //                 }));
              //               }}
              //             />
              //           </Table.Cell>
              //         </Table.Row>
              //       ))}
              //     </Table.Body>
              //   </Table>
              // </div>
            ) : (
              <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f8d7da', color: '#721c24' }}>
                No relevant questions for the selected questionnaire.
              </div>
            )}
          </>
        ) : null
        }
        {!_.isEmpty(state.activationRule.userLocationQuestion) && <DisplaySelectedUserSelectedQuestion />}
      </Form.Field>
    );
  };

  const _buildKmlList = () => {
    const filteredQuestions = (props.questions || []).filter(
      (question) =>
        question &&
        (question.questionType === 'location')
    );

    return (
      <Form.Field>
        <Grid>
          <Grid.Row>
            <Grid.Column width={10} style={{ paddingBottom: "10px" }}>
              <h3>Select Reference KML File</h3>
              <label >Reference KML File:</label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {!_.isEmpty(props.kmlFiles) && (
          <div>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '30%' }}>
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '30%' }}>
                    Description
                  </Table.HeaderCell>
                  <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '30%' }}>
                    File Name
                  </Table.HeaderCell>
                  <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {props.kmlFiles.map((file) => (
                  <Table.Row key={file.id}>
                    <Table.Cell>{file.name}</Table.Cell>
                    <Table.Cell>{file.description}</Table.Cell>
                    <Table.Cell>{file.fileName}</Table.Cell>
                    <Table.Cell>
                      <Button
                        compact
                        className='success'
                        icon='checkmark'
                        onClick={(event) => {
                          event.preventDefault();
                          setState((state) => ({
                            ...state,
                            activationRule: {
                              ...state.activationRule,
                              refKMLFile: {
                                kmlId: file.id,
                                name: file.name,
                                fileName: file.fileName,
                                filePath: file.filePath,
                                tenantId: file.tenantId,
                              },
                            },
                          }));
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
        {!_.isEmpty(state.activationRule.refKMLFile) && <DisplaySelectedReferenceKmlFile />}
      </Form.Field>
    );
  };

  const _buildQuestionnaireselector = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([]);

    const filteredQuestionnaires = props.questionnaires
    .filter(
        (questionnaire) =>
            questionnaire.status === "active" &&
            !selectedQuestionnaires.some(
                (selectedQ) => selectedQ.questionnaireId === questionnaire.id
            ) &&
            (
                questionnaire.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                questionnaire.description.toLowerCase().includes(searchQuery.toLowerCase())
            )
    )
    .sort((a, b) => a.name.localeCompare(b.name));



    return (
      <Form.Field>
        <Grid>
          <Grid.Row>
            <Grid.Column style={{ paddingBottom: "10px" }}>
              <h3>Select Questionnaires</h3>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Input
          icon='search'
          placeholder='Search...'
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
        <Divider />
        {!_.isEmpty(filteredQuestionnaires) ? (
          <div>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '25%' }}>
                    Questionnaire Name
                  </Table.HeaderCell>
                  <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '55%' }}>
                    Questionnaire Description
                  </Table.HeaderCell>
                  <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }}>
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell className='nectaPrimaryBg' style={{ width: '10%' }} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredQuestionnaires.map((questionnaire) => (
                  <Table.Row key={questionnaire.id}>
                    <Table.Cell>{questionnaire.name}</Table.Cell>
                    <Table.Cell>{questionnaire.description}</Table.Cell>
                    <Table.Cell>{questionnaire.status}</Table.Cell>
                    <Table.Cell>
                      <Button
                        compact
                        className='success'
                        icon='checkmark'
                        onClick={(event) => {
                          event.preventDefault();
                          const updatedSelectedQuestionnaires = [
                            {
                              questionnaireId: questionnaire.id,
                              questionnaire: questionnaire.name,
                              questionnaireDescription: questionnaire.description,
                              tenantId: questionnaire.tenantId,
                            },
                          ];

                          setState((state) => ({
                            ...state,
                            activationRule: {
                              ...state.activationRule,
                              ruleQuestionnaires: {
                                questionnaireId: questionnaire.id,
                                questionnaire: questionnaire.name,
                                questionnaireDescription: questionnaire.description,
                                tenantId: questionnaire.tenantId,
                              },
                            },
                          }));

                          // Update selectedQuestionnaires in the state
                          setSelectedQuestionnaires(updatedSelectedQuestionnaires);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ) : (
          <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f8d7da', color: '#721c24' }}>
            No questionnaires matching your search query
          </div>
        )}
        {!_.isEmpty(state.activationRule.ruleQuestionnaires) && <DisplayruleQuestionnaires />}
      </Form.Field>
    );
  };

  return (
    <Container>
      <Form
        size="small"
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: "20px" }}
      >
        <Grid>
          <Grid.Column width={14}>
            <Grid.Row>{_buildName()}</Grid.Row>
          </Grid.Column>
          <Grid.Column width={2}>
            <Grid.Row>{_buildActiveToggle()}</Grid.Row>
          </Grid.Column>
        </Grid>
        <br></br>
        <Grid>
          <Grid.Column width={12}>
            <Grid.Row>{_buildDescription()}</Grid.Row>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={12}>
            <Grid.Row>{_buildWhatsAppTemplate()}</Grid.Row>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={6}>
            <Grid.Row>{_maxNumUsers()}</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>{_targetAudience()}</Grid.Row>
          </Grid.Column>
        </Grid>
        <Divider />
        <FuseCollapsiblePanel
          content={
            <>
              {/* {_buildTimesToComplete()} */}
              {_buildCronScheduler()}
            </>
          }
          isCollapsed={state.isSchedulerCollapsed}
          // onToggle={toggleFrequencyCollapse}
          leadingIcon="clock"
          trailingIcon="angle down"
          header="Frequency Scheduler"
        />
        <Divider />
        <FuseCollapsiblePanel
          content={_buildDemoQuestionList()}
          isCollapsed={state.isDemoGraphicCollapsed}
          // onToggle={toggleDemoGraphicCollapse}
          leadingIcon="male"
          trailingIcon="angle down"
          header="Demographic Questions"
        />
        <Divider />
        <FuseCollapsiblePanel
          content={
            <>
              {_buildUserLocationList()}
              <Divider />
              {_buildKmlList()}
            </>
          }
          isCollapsed={state.isGeoGraphicCollapsed}
          // onToggle={toggleGeoGraphicCollapse}
          leadingIcon="map"
          trailingIcon="angle down"
          header="Geographic Questions"
        />
        <Divider />
        <FuseCollapsiblePanel
          content={
            <>
              {_buildQuestionnaireselector()}
            </>
          }
          isCollapsed={state.isQuestionnaireSelectorCollapsed}
          // onToggle={toggleQuestionnaireSelectorCollapse}
          leadingIcon="question"
          trailingIcon="angle down"
          header="Questionnaire Selector"
        />
        <Divider />
        <Button.Group floated="right">
          <Button
            className="danger"
            onClick={() => {
              if (_.isEmpty(props.onComplete)) {
                props.onComplete();
              }
            }}
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button type="submit" className="success">
            <Icon name="checkmark" /> Ok
          </Button>
        </Button.Group>
      </Form>
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  let activationRule = {};
  let isNew = false;
  let newRule = {
    name: '',
    description: '',
    frequency: '',
    timesToBeCompleted: '',
    status: "inActive",
    isActive: true,
    questionWithAnswers: [],
    userLocationQuestion: {},
    ruleQuestionnaires: {},
    refKMLFile: {},
    ruleGroup: {},
    completionLimitType: "byTimes",
    timesToBeCompleted: "1",
    // anonymisedData: false,
    // whatsAppTemplate: {},
    groupApplicable: false,
    tenantId: state.tenantManagement.activeTenant.id,
  };

  if (ownProps.bdaction === 'add') {
    activationRule = newRule;
    isNew = true;
  } else {
    activationRule = _.isEmpty(ownProps.activationRule)
      ? newRule
      : ownProps.activationRule;
    isNew = false;
  }

  return {
    tenant: state.tenantManagement.activeTenant,
    isNew: isNew,
    activationRule: activationRule,
    questionnaires: _.isEmpty(state.activationRules.questionnaires) ? [] : Object.values(state.activationRules.questionnaires),
    sections: _.isEmpty(state.activationRules.sections) ? [] : Object.values(state.activationRules.sections),
    questions: _.isEmpty(state.activationRules.questions) ? [] : Object.values(state.activationRules.questions),
    kmlFiles: _.isEmpty(state.activationRules.kmlFiles) ? [] : Object.values(state.activationRules.kmlFiles),
    whatsAppTemplates: _.isEmpty(state.activationRules.whatsAppTemplates) ? [] : Object.values(state.activationRules.whatsAppTemplates),
    userGroups: _.isEmpty(state.activationRules.userGroups) ? [] : Object.values(state.activationRules.userGroups),
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch) => ({
    saveActivationRule: (tenant, rule, isNew) => dispatch(ACTIVATION_RULES.saveActivationRuleAction(tenant, rule, isNew)),
    fetchQuestionairesAction: (tenant) => dispatch(ACTIVATION_RULES.fetchQuestionnairesAction(tenant)),
    fetchKmlFilesAction: (tenant) => dispatch(ACTIVATION_RULES.fetchKmlFilesAction(tenant)),
    fetchWhatsAppTemplatesAction: (tenant) => dispatch(ACTIVATION_RULES.fetchWhatsAppTemplatesAction(tenant)),
    fetchQuestionsAction: (questionnaireId, tenantId) => dispatch(ACTIVATION_RULES.fetchQuestionsAction(questionnaireId, tenantId)),
    fetchSectionsAction: (questionnaireId, tenantId) => dispatch(ACTIVATION_RULES.fetchSectionsAction(questionnaireId, tenantId)),
    fetchLookupsAction: (question, next) => dispatch(ACTIVATION_RULES.fetchLookupsAction(question, next)),
    fetchUserGroupsAction: (tenant) => dispatch(ACTIVATION_RULES.fetchUserGroupsAction(tenant)),
  })
)(ActivationRulesDetails);