import {
  FETCH_QUESTIONAIRE_TYPES_SUCCESS_ACTION,
  QUESTIONAIRE_TYPE_ADD_ACTION,
  QUESTIONAIRE_TYPE_EDIT_ACTION,
  QUESTIONAIRE_TYPE_DELETE_ACTION
} from '../actions/questionaireTypeActions';

export const questionaireTypesReducer= (state = [], action) => {
  switch (action.type) {
    case FETCH_QUESTIONAIRE_TYPES_SUCCESS_ACTION:
      const newQuestionaireTypes = action.payload;
      const oldQuestionaireTypes = Object.values({ ...state }).filter(type => !newQuestionaireTypes.some(newQuestionaireType => newQuestionaireType.id === type.id));
      const newQuestionaireTypesState = oldQuestionaireTypes.concat(newQuestionaireTypes);
      return newQuestionaireTypesState;
    case QUESTIONAIRE_TYPE_ADD_ACTION:
      const clonedState = [...state];
      clonedState.push(action.payload);
      return clonedState;
    case QUESTIONAIRE_TYPE_EDIT_ACTION:
      const newQuestionaireType = action.payload;
      const questionaireTypesCopy = Object.values({ ...state }).filter(questionaireType => (newQuestionaireType.id !== questionaireType.id));
      questionaireTypesCopy.push(newQuestionaireType);
      return questionaireTypesCopy;
    case QUESTIONAIRE_TYPE_DELETE_ACTION:
      return state.filter(element => element.id !== action.payload);
    default:
      return state;
  }
};