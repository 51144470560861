import axios from "axios";

//example data
// event: {
//     "username": "",
//     "password": "",
//     "email": "",
//     "custom:firstname": "",
//     "custom:lastname": "",
//     "custom:tenantName": "",
//     "userpoolid": ""
//     "action": "update" or nothing
//   }
export function createCognitoUserWs(qData, getState) {
  const state = getState();
  const jwtToken = state.authData[0].signInUserSession.accessToken.jwtToken;

  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };

  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ENV}/createCognitoUser`,
      qData,
      { headers }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response) {
        console.error("Error response from server:", err.response.data);
        console.error("Status code:", err.response.status);
        console.error("Headers:", err.response.headers);
      } else if (err.request) {
        console.error("No response received:", err.request);
      } else {
        console.error("Error setting up request:", err.message);
      }

      console.error("Error getting distribution rule logs:", err);

      throw err;
    });
}

//example data
// event {
//     "username": "",
//     "action": "",
//     "userpoolid": ""
//   }
//actions: (enable/disable/delete/resetpassword)
export function userManagementWs(qData, getState) {
  const state = getState();
  const jwtToken = state.authData[0].signInUserSession.accessToken.jwtToken;

  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };

  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ENV}/manageCognitoUser`,
      qData,
      { headers }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response) {
        console.error("Error response from server:", err.response.data);
        console.error("Status code:", err.response.status);
        console.error("Headers:", err.response.headers);
      } else if (err.request) {
        console.error("No response received:", err.request);
      } else {
        console.error("Error setting up request:", err.message);
      }

      console.error("Error getting distribution rule logs:", err);

      throw err;
    });
}
