import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { updateItemWs } from "../webservices/saveWS";
import { TenantDTO } from './DTOs/TenantDTO';
import { toast } from "react-toastify";

export const TENANT_EDIT_ACTION = "TENANT_EDIT_ACTION";

export const saveTenantAction = (tenant) => {
    return (dispatch, getState) => {
        const saveObject = new TenantDTO(tenant);
        dispatch({ type: SAVE_IN_PROGRESS });

        updateItemWs(saveObject, getState)
            .then((result) => {
                dispatch({ type: TENANT_EDIT_ACTION, payload: result.data });
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.success("Successfully updated tenant");
                window.location.href = '/Questionaires';
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
                toast.error("Failed to update the tenant information please try again");
            });
    };
}

export const RESET_STATE_ACTION = 'RESET_STATE_ACTION';

export const resetState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_ACTION });
  };
}