import {
    FETCH_CONDITIONS_SUCCESS_ACTION,
    CONDITION_ADD_ACTION,
    CONDITION_EDIT_ACTION,
    CONDITION_DELETE_ACTION,
    CONDITION_CLEAR_SELECTED_ACTION
} from '../actions/conditionActions';

export const ConditionsReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_CONDITIONS_SUCCESS_ACTION:
            const newConditions = action.payload;
            // const oldConditions = Object.values({...state}).filter(s => !newConditions.some(newS => newS.fingerprint === s.fingerprint));
            const oldConditions = Object.values({...state}).filter(s => !newConditions.some(newS => newS.id === s.id));
            const newConditionState = oldConditions.concat(newConditions); //Creates a copy...a new state
            return newConditionState;
        case CONDITION_ADD_ACTION:
            const clonedState = [...state];
            clonedState.push(action.payload);
            return clonedState;
        case CONDITION_EDIT_ACTION:
            const newCondition = action.payload;
            // const conditionsCopy = Object.values({...state}).filter(s => !(newCondition.fingerprint === s.fingerprint)); //Creates a copy...a new state
            const conditionsCopy = Object.values({...state}).filter(s => !(newCondition.id === s.id)); //Creates a copy...a new state
            conditionsCopy.push(newCondition);
            return conditionsCopy;
        case CONDITION_DELETE_ACTION:
            return state.filter(element => element.id !== action.payload);
        case CONDITION_CLEAR_SELECTED_ACTION:
            return [];
        default:
            return state;
    }
};